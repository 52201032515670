<div class="modal-body">
    <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; text-align: center;">
        <!-- Encabezado oculto, semántico para accesibilidad -->
        <thead style="visibility: hidden; position: absolute; width: 0; height: 0;">
            <tr>
                <th>Acción</th>
                <th>Imagen</th>
                <th>Mensaje</th>
                <th>Botones</th>
            </tr>
        </thead>
        <tr>
            <td colspan="2" style="padding-left: 5px; vertical-align: middle; background: linear-gradient(to right, #d40809, #fccc00); color: white;">
                <h4>Salir</h4>
            </td>
        </tr>
        <tr>
            <td style="vertical-align: middle; width: 80px;">
                <img src="../../../assets/admiracion.png" class="rounded mx-auto d-block" style="width: 80px;" alt="admiración">
            </td>
            <td style="vertical-align: middle;">
                <p style="font-size: 13pt; text-align: left;" class="textoc">
                    ¿Seguro que quieres salir?
                    <br>
                    {{mensajeSalir}}
                </p>
            </td>
        </tr>
        <tr>
            <td colspan="2" style="vertical-align: middle;">
                <div class="modal-footer">
                    <button style="width: 50px;" type="button" class="btn btn-secondary" (click)="enviarNotificacion()">Si</button>
                    <button style="width: 50px;" type="button" class="btn btn-primary" (click)="volverValidaIdentidad()">No</button>
                </div>
            </td>
        </tr>
    </table>
</div>