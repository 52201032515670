import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { LogService } from '../../../domain/servicios/log.service'
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-descarga-pfx',
    templateUrl: './descarga-pfx.component.html',
    styleUrls: ['./descarga-pfx.component.css'],
    standalone: false
})
export class DescargaPfxComponent implements OnInit {

  formaForm!: FormGroup;
  certificado: any = {}
  buttons: any[] = [];
  totalCantidad: number = 2;
  validateformats: any[] = [];
  cantidad: number = 0;
  cambiologo: boolean = true;
  error: any = [];

  constructor(private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private componentesService: ComponentesService,
    private ssps: SspsService,
    private alert: AlertsService,
    private logger: LogService, 
    private el: ElementRef
  ) {
    /*validacion de campos validators*/
    this.formaForm = this.fb.group({
      contrasena: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(12),
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&_-])[A-Za-zd$@$!%*?&_-].{8,12}')]],
      contrasenaConfirmacion: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(12),
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&_-])[A-Za-zd$@$!%*?&_-].{8,12}')]],
      documentos: this.fb.array([]),
      documentos2: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.CreateDocument('Archivo de llave privada', 'keyfile')
    this.CreateDocument('Archivo de certificado', 'cerfile')
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  
  get passwordFormField() {
    return this.formaForm.get('contrasena');
  }

  get passwordMinMaxLengthValid() {
    return !(this.formaForm.controls["contrasena"].hasError("maxlength") || 
    this.formaForm.controls["contrasena"].hasError("minlength")) && this.formaForm.controls["contrasena"]?.value.length > 0;
  }

  /* funcion para validacion de campos*/
  camposvalidos(campo: any) {
    return this.formaForm.get(campo)!.invalid &&
      (this.formaForm.get(campo)!.touched || this.formaForm.get(campo)!.dirty);
  }

  //TODO:cerrar el modal de activo digital
  close() {
    this.alert.closesSpiner();
  }

  /*ingreso y validacion de datos de formulario*/
  ngsubmit() {
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl?.focus();
          return;
        }
      });
      return;
    }
    else {
      //this.estados= true;
      this.alert.showSpiner("Procesando petición, por favor espera un momento");
      let { contrasena } = this.formaForm.value;
      this.unirLlaves(contrasena);
    }
  }

  unirLlaves(contrasena: string) {
    let requestCertificado: any = {
      crt: this.buttons.filter((f: { id: string; }) => f.id === "cerfile")[0].file,
      key: this.buttons.filter((f: { id: string; }) => f.id === "keyfile")[0].file,
      password: contrasena
    }
    this.ssps.unirLlaves(requestCertificado)
      .subscribe({
        next: (res: any) => {
          this.logger.log("unirLlaves", res)
          this.certificado = {
            estado: "DESCARGA",
            mensaje: "Solicitud procesada exitosamente",
            nombreArchivo: "certificado_digital.pfx",
            certificadoB64: res.pfx,
            tipo: "PFX"
          }
          this.ssps.saveLocalStorage('certificado', JSON.stringify(this.certificado));
          this.alert.closesSpiner()
          this.alert.closesFiels()
          this.alert.showDescargaCertificado(false)
        },
        error: (error: any) => {
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
             (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.unirLlaves(contrasena);
          }
          else {
            this.alert.closesSpiner()
            console.error('Error en subscriber unirLlaves', error)
            let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
              error.error.listaErrores ? error.error.listaErrores.map((o: {
                campo: string; mensaje: any;
              }) => o.campo + ": " + o.mensaje).join("\n") :
                error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                  error.error.message ? error.error.message :
                    (error.message ? error.message : "No fue posible realizar la petición")) :
              (error.message ? error.message : "No fue posible realizar la petición")
            this.alert.showError(mensaje, false, null, false, null, null, false)
          }
        }
      })
  }

  get contrasenaIguales() {
    const pass1 = this.formaForm.get('contrasena')!.value;
    const pass2 = this.formaForm.get('contrasenaConfirmacion')!.value;

    return pass1 === pass2;
  }

  CreateDocument(documentName: string, id: String) {
    let indice = this.buttons.findIndex((f: { id: String; }) => f.id === id);
    this.logger.log("indicie", indice)
    if (indice > -1) {
      this.buttons = this.buttons.filter((f: { id: String; }) => f.id !== id)
      this.documentsDocuments.removeAt(indice)
    }
    else {
      const estilos = {
        fondo: '#e86a05', colores: '#fff', title: id === 'keyfile' ? 'Seleccionar archivo de llave privada' :
          'Seleccionar archivo de certificado',
        subtitle: id === 'keyfile' ?
          'El formato de archivo permitido para cargar es .key' :
          'El formato de archivo permitido para cargar es .cer',
        name: documentName,
        id: id,
        accept: id === 'keyfile' ? ".key" : ".cer"
      }
      this.buttons.push(estilos)
      this.addControlDocument();
    }
  }

  get documentsDocuments() {
    return this.formaForm.get('documentos') as FormArray;
  }

  addControlDocument() {
    this.documentsDocuments.push(this.fb.control(null));
  }

  getFileDetails(file: any, index: number) {
    let fileToUpload = <File>file[0];
    const botonActualizado = { ...this.buttons[index] };

    let tamañoEnBytes = fileToUpload.size;
    let tamañoEnMegabytes = tamañoEnBytes / (1024 * 1024);
    if ((fileToUpload.name.toLocaleLowerCase().endsWith(".key") && botonActualizado.id === "keyfile") ||
      (fileToUpload.name.toLocaleLowerCase().endsWith(".cer") && botonActualizado.id === "cerfile")) {
      if (tamañoEnMegabytes > 10) {
        botonActualizado.fondo = '#ff4d4d';
        botonActualizado.colores = '#fff';
        botonActualizado.title = 'Seleccionar archivo';
        botonActualizado.subtitle = 'El formato de archivo permitido para cargar es ' + (botonActualizado.id === "keyfile" ? ".key" : ".cer");
        this.buttons[index] = botonActualizado;
        this.validateformats[index] = true;
      }
      if (tamañoEnMegabytes < 10) {
        const reader = new FileReader()
        reader.onload = () => {
          const b64 = reader.result as string
          botonActualizado.file = b64.split(",")[1]
        }

        if (fileToUpload) {
          reader.readAsDataURL(fileToUpload)
        }

        botonActualizado.fondo = '#09CB34';
        botonActualizado.colores = '#fff';
        botonActualizado.title = 'Archivo cargado con exito';
        botonActualizado.subtitle = fileToUpload.name;

        this.buttons[index] = botonActualizado;
        this.validateformats[index] = false;
        this.cantidad += 1;
      }
    }
    else {
      botonActualizado.fondo = '#ff4d4d';
      botonActualizado.colores = '#fff';
      botonActualizado.title = botonActualizado.id === 'keyfile' ? 'Seleccionar archivo de llave privada' :
        'Seleccionar archivo de certificado';
      botonActualizado.subtitle = 'El formato de archivo permitido para cargar es ' + (botonActualizado.id === "keyfile" ? ".key" : ".cer");
      this.buttons[index] = botonActualizado;
      this.validateformats[index] = true;
      this.error[index] = 'El formato de archivo permitido para cargar es ' + (botonActualizado.id === "keyfile" ? ".key" : ".cer")
    }

  }

  validar() {

    return (this.cambiologo = true) ? ' animate__animated animate__fadeIn animate__fast' : 'animate__animated animate__fadeOut animate__fast';
  }

  isValidFieldInArray(formArray: FormArray, index: number) {
    return formArray.controls[index].errors
      && formArray.controls[index].touched;
  }

  closeModal() {
    let externalUrl = `${environment.urlWebCerti}`;
    this.alert.closesFiels();
    localStorage.clear()
    window.location.replace(externalUrl);
  }
}