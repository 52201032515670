
<div class="modal-header">
    <h4 class="titulos" >Continuar sin pago</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body  text-center ms-4 me-4">    
    <p>Señor usuario, te invitamos a que continúes el trámite en el enlace enviado a tu correo electrónico
 </p>
 <button type="submit" class="btn btn-primary" (click)="close()">Aceptar</button>
</div>
<div class="modal-footer">
  
</div>