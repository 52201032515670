import { Component, Input, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { environment as env } from "src/environments/environment"

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.css'],
    standalone: false
})
export class MessageComponent implements OnInit {
  @Input() public  redirigir: any
  constructor(private alert:AlertsService,
    private ssps: SspsService
  ) { }

  ngOnInit(): void {
    if (this.ssps.getLocalStorage("redirigirError")) {
      this.redirigir = JSON.parse(this.ssps.getLocalStorage("redirigirError")!)
    }
  }  
  
  //TODO:cerrar modal
  close(){
    if (this.redirigir) {
      let externalUrl = `${env.urlWebCerti}`;
      localStorage.clear()
      window.location.replace(externalUrl);
    }
    else{
      this.alert.closeAlert();
      this.alert.showFiels();
    }
  }
}
