import { Component, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';

@Component({
    selector: 'app-descarga-certificado',
    templateUrl: './descarga-certificado.component.html',
    styleUrls: ['./descarga-certificado.component.css'],
    standalone: false
})
export class DescargaCertificadoComponent implements OnInit {

  constructor(private alert: AlertsService,
    private ssps: SspsService
  ) { }

  ngOnInit(): void {
    if (this.ssps.getLocalStorage("solicitudId")) {
      this.alert.showFieldsDescargaCertificado();
    }
    else {
      let mensaje = "No fue posible realizar la petición"
      this.alert.showError(mensaje, true, null, false, null, null, false)
    }
  }
}
