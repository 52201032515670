import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';



@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  @ViewChild('inputRefPrimerNombre')
  inputRefPrimerNombre!: { nativeElement: any; };

  @ViewChild('inputRefSegundoNombre')
  inputRefSegundoNombre!: { nativeElement: any; };

  @ViewChild('inputRefPrimerApellido')
  inputRefPrimerApellido!: { nativeElement: any; };

  @ViewChild('inputRefSegundoApellido')
  inputRefSegundoApellido!: { nativeElement: any; };

  @ViewChild('inputRefNumero')
  inputRefNumero!: { nativeElement: any; };

  @ViewChild('inputRefCorreo')
  inputRefCorreo!: { nativeElement: any; };

  @ViewChild('inputRefCorreo1')
  inputRefCorreo1!: { nativeElement: any; };

  @Output() onMantenimiento: EventEmitter<boolean> = new EventEmitter();
  formaForm!: FormGroup;
  indicativos: any;
  contacto: any;
  codindi: any;
  usuario: any;
  comas: boolean = false;
  comastel: boolean = false;
  comastel2: boolean = false;
  comastex: boolean = false;
  tipoCliente = ""
  contactoOtro = false

  constructor(private fb: FormBuilder,
    private router: Router,
    private componentesService: ComponentesService,
    private ssps: SspsService,
    private changeDetector: ChangeDetectorRef,
    private el: ElementRef) {

    /*validacion de campos validators*/
    this.formaForm = this.fb.group({
      tipo: ['', [Validators.required]],
      numero: ['', [Validators.required,
        // Validators.pattern('([A-Za-z]+[0-9]|[0-9]+[A-Za-z])[A-Za-z0-9]*')
      ]],
      primerNombre: ['', [Validators.pattern("^[a-zA-ZÑ ]{3,30}$"),
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(30)
      ]
      ],
      segundoNombre: ['', [Validators.pattern("^[a-zA-ZÑ ]{3,30}$"),
      Validators.minLength(3),
      Validators.maxLength(30)
      ]
      ],
      primerApellido: ['', [Validators.required,
      Validators.minLength(3),
      Validators.maxLength(30),
      Validators.pattern('[a-zA-ZÑ ]{3,30}')]],
      segundoApellido: ['', [
        Validators.minLength(3),
        Validators.maxLength(30),
        Validators.pattern('[a-zA-ZÑ ]{3,30}')]],
      indicativo: [],
      telefono: ['', [
        Validators.maxLength(7),
        Validators.minLength(7)
      ]],
      extension: [0, [
        Validators.maxLength(5)
      ]],
      celular: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      correo: ['', [Validators.required,
      Validators.pattern('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$'),
      Validators.minLength(8),
      Validators.maxLength(60),
      ]],
      correo1: ['', [Validators.required,
      Validators.minLength(8),
      Validators.maxLength(60),
      Validators.pattern('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$')]]
    }, {
      validators: [this.componentesService.validalist('indicativo'),
      this.componentesService.codigonit('tipo', 'numero')]
    });
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.indicativo();
    this.tipoCliente = this.ssps.getLocalStorage('tipocliente')!;
    if (this.tipoCliente === "natural") {
      this.usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
    }
    else {
      this.usuario = JSON.parse(this.ssps.getLocalStorage('solicitante')!);
    }
    this.precargaDatos()
    this.setOnChangesInputs()
  }

  setOnChangesInputs() {
    this.formaForm.get("primerNombre")!.valueChanges.subscribe(val => {
      if (this.inputRefPrimerNombre) {
        const elRef = this.inputRefPrimerNombre?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("primerNombre")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("segundoNombre")!.valueChanges.subscribe(val => {
      if (this.inputRefSegundoNombre) {
        const elRef = this.inputRefSegundoNombre?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("segundoNombre")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });


    this.formaForm.get("primerApellido")!.valueChanges.subscribe(val => {
      if (this.inputRefPrimerApellido) {
        const elRef = this.inputRefPrimerApellido?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("primerApellido")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });


    this.formaForm.get("segundoApellido")!.valueChanges.subscribe(val => {
      if (this.inputRefSegundoApellido) {
        const elRef = this.inputRefSegundoApellido?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("segundoApellido")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numero")!.valueChanges.subscribe(val => {
      if (this.inputRefNumero) {
        const elRef = this.inputRefNumero.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("numero")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("correo")!.valueChanges.subscribe(val => {
      if (this.inputRefCorreo) {
        const elRef = this.inputRefCorreo?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("correo")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("correo1")!.valueChanges.subscribe(val => {
      if (this.inputRefCorreo1) {
        const elRef = this.inputRefCorreo1.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("correo1")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  /* implementa envio de datos */
  ngsubmit() {
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          return;
        }
      });
      return;
    }
    this.ssps.saveLocalStorage("contacto", JSON.stringify(this.contacto));
    if (this.ssps.getLocalStorage("solicitudId")) {
      this.componentesService.emitircambio("actualizarOrden");
    }
    this.componentesService.emitircambio("contacto");
    this.router.navigate(['/flujo/entrega']);
  }

  /* funcion para los errores indicados*/
  camposvalidos(campo: any) {
    return this.formaForm.controls[campo].errors && this.formaForm.controls[campo].touched;
  }

  indicativo() {
    this.ssps.indicativos()
      .subscribe((res: any) => {
        this.indicativos = res;
      });

  }


  noPuntotel(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comastel = res;
      })
  }


  noPuntote(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comastel2 = res;
      })
  }

  noPuntoex(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comastex = res;
      })
  }

  /*funcion que hace el filtro de los elementos selecionados del select*/
  verficacion(event: any) {
    this.codindi = this.indicativos.find((x: any) => x?.nomDepartamento === event.target.value);
    this.contacto.indicativo = this.codindi?.indicativo;
  }

  /*rediociona al usuario si se equivova*/
  redireciona() {
    if (this.tipoCliente == "natural") {
      this.router.navigate(['/flujo/infobasi']);
      this.componentesService.emitircambio("reveinfobasi");
    }
    /*redireciona deacuerdo al tipo de roll*/
    else if (this.tipoCliente == "juridica") {
      this.router.navigate(['/flujo/solicitante']);
      this.componentesService.emitircambio("revesolicitante");
    }
  }


  /*validacion de decimales input*/
  noPuntoComa(event: any) {
    var e = event || window.event;
    var key = e.keyCode || e.which;
    if (key === 110 || key === 190 || key === 188) {
      this.comas = true;
    } else {
      this.comas = false;
    }
  }

  /*quitar numeros ceros a la izquierda*/
  quitarceros(numero: string) {
    this.contacto.celular = numero.replace(/^0+/, '');
  }

  quitarceros2(numero: string) {
    this.contacto.telefono = numero.replace(/^0+/, '');
  }

  quitarceros3(numero: string) {
    this.contacto.extension = numero.replace(/^0+/, '');
  }

  quitarceros4(numero: string) {
    this.contacto.celular = numero.replace(/^0+/, '');
  }

  contactoOtroChange($event: any) {
    let isCheckeado = $event.target.checked;
    if (isCheckeado) {
      this.contacto = {
        tipo: '',
        numero: '',
        primerNombre: '',
        segundoNombre: '',
        primerApellido: '',
        segundoApellido: '',
        indicativo: 0,
        telefono: '',
        extension: '',
        celular: '',
        correo: '',
        correo1: '',
        contactoOtro: true
      }
    }
    else {
      this.precargaDatos()
    }
  }

  precargaDatos() {
    if (this.ssps.getLocalStorage('contacto')!) {
      this.contacto = JSON.parse(this.ssps.getLocalStorage('contacto')!);
    }
    else {
      this.contacto = {
        tipo: this.usuario.tipo,
        numero: this.usuario.numero,
        primerNombre: this.usuario.primerNombre,
        segundoNombre: this.usuario.segundoNombre,
        primerApellido: this.usuario.primerApellido,
        segundoApellido: this.usuario.segundoApellido,
        indicativo: this.usuario.indicativo,
        telefono: this.usuario.telefono,
        extension: this.usuario.extension,
        celular: this.usuario.celular,
        correo: this.usuario.correo,
        correo1: this.usuario.correo1,
        contactoOtro: false
      }
    }
    setTimeout(() => {
      this.deshabilitarCmaposFinalizados()
    }, 2000);
  }

  deshabilitarCmaposFinalizados() {
    Object.keys(this.formaForm.controls).forEach(key => {
      if (key !== "correo" && key !== "correo1" && (this.usuario.solicitudFinalizada === "APROBADO" || this.usuario.solicitudFinalizada === "FINALIZADO")) {
        this.formaForm.controls[key]?.disable({ onlySelf: true })
      }
      else if (this.usuario.solicitudFinalizada === "ASIGNADO") {
        this.formaForm.controls[key]?.disable({ onlySelf: true })
      }
    });
  }

  get correo2NoValido() {
    const pass1 = this.formaForm.get('correo')!.value;
    const pass2 = this.formaForm.get('correo1')!.value;

    return (pass1 === pass2) ? false : true;
  }

}
