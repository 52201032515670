<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">¡Tus datos han sido enviados satisfactoriamente!</h5>
		<button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
	</div>
  <div class="modal-body">
    <img src="../../../assets/exito.PNG" class="rounded mx-auto d-block" style="height:80%;" width="80%">
 <p class="textoc">
    Recuerda estar atento a tu teléfono para que uno de nuestros consultores especializados pueda comunicarse contigo en el horario que tienes disponible.

    Si tienes Dudas adicionales, puedes enviarnos un correo a preguntascerti&#64;certicamara.com
 </p>
</div>
	<div class="modal-footer">
		<button type="button" 
            class="btn btn-secondary" 
            (click)="close()">Aceptar</button>
	</div>