import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { SspsService } from '../servicios/ssps.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DatePipe } from '@angular/common'
import { environment as env } from "src/environments/environment"

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(
        public datepipe: DatePipe,
        private ssps: SspsService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request = req;
        if ((request.url.includes(`${env.ordenPedidoServiceEC2}`) ||
            request.url.includes(`${env.certificadoServiceEC2}`) ||
            request.url.includes(`${env.validacionIdentidadServiceEC2}`) ||
            request.url.includes(`${env.estadoTramiteServiceEC2}`) ||
            request.url.includes(`/historico/`) ||
            request.url.includes(`/pasarelaPago/`)) && request.body !== null
            && request.body !== undefined) {
            request = req.clone({
                body: {
                    data: this.ssps.encrypt(JSON.stringify(req.body))
                }
            });
        }
        const token = this.ssps.getLocalStorage('token');
        var token_expiration = new Date('1968-11-16T00:00:00');
        const token_date = this.ssps.getLocalStorage('token_date');
        if (token_date && !request.url.includes('oauth2/token')) {
            token_expiration = new Date(Date.parse(token_date));
            token_expiration.setSeconds(token_expiration.getSeconds() + 3600);
        }

        if (token && !request.url.includes('oauth2/token')) {
            if (new Date() > token_expiration) {
                return this.handle401Error(request, next);
            }
            else {
                request = request.clone({
                    setHeaders: {
                        Authorization: `${token}`
                    }
                });
            }
        }

        if (token === null && !request.url.includes('oauth2/token')) {
            return this.handle401Error(request, next);
        }

        return this.handleResponse(request, next)
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): any {
        return this.ssps.token()
            .pipe(
                switchMap((data: any) => {
                    this.ssps.saveLocalStorage("token", data.access_token)
                    this.ssps.saveLocalStorage("token_date", this.datepipe.transform(new Date(), "yyyy-MM-dd'T'HH:mm:ss")!)
                    request = request.clone({
                        setHeaders: {
                            authorization: `${data.access_token}`
                        }
                    });
                    return this.handleResponse(request, next)
                }),
                catchError((error) => {
                    return throwError(() => error);
                })
            );
    }

    private handleResponse(request: HttpRequest<any>, next: HttpHandler): any {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (!request.url.includes('oauth2/token') &&
                        (request.url.includes(`${env.ordenPedidoServiceEC2}`) ||
                            request.url.includes(`${env.certificadoServiceEC2}`) ||
                            request.url.includes(`${env.validacionIdentidadServiceEC2}`) ||
                            request.url.includes(`${env.estadoTramiteServiceEC2}`) ||
                            request.url.includes(`/historico/`) ||
                            request.url.includes(`/pasarelaPago/`)) &&
                        event.body.data !== undefined && event.body.data !== null) {
                        event = event.clone({ body: JSON.parse(this.ssps.decryptUsingAES256(event.body.data)) });
                    }
                    if (request.url.includes(`/assets/json/`)) {
                        event = event.clone({ body: JSON.parse(this.ssps.decryptUsingAES256(event.body.data)) });
                    }
                }
                return event;
            }),
            catchError((error) => {
                if (
                    error instanceof HttpErrorResponse &&
                    !request.url.includes('oauth2/token') &&
                    (error.status === 401 || error.status === 403)
                ) {
                    return this.handle401Error(request, next);
                }
                else if(error.status === 504) {
                    console.error("error interceptor timeout", error)
                    error.message = "timed out"
                }
                else{
                    console.error("error interceptor", error)
                }

                return throwError(() => error);
            })
        );
    }
}

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];