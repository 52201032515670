<table border="0" cellspacing="0" cellpadding="0">
  <thead style="display: none;">
    <tr>
      <th>Encabezado</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style="vertical-align: top; padding: 20px;">
        <h2 class="modal-title" id="exampleModalLabel">Señor usuario</h2>
      </td>
    </tr>
  </tbody>
</table>
<div class="modal-body">
  <p class="textoc">
    El proceso finalizará exitosamente una vez haya completado todos los requisitos, actualmente tiene pendiente:
  </p>
  <p *ngFor="let faltante of  usuario.faltantes index as t" class="textoc" style="padding-top: 0px;
  padding-bottom: 0px;">
    <i class="fas fa-info-circle icon-primary"></i>&nbsp; {{faltante}}
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">Cerrar</button>
</div>