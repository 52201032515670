import { Injectable } from '@angular/core';
import { Empresa } from '../interfaces/empresa.interface';

@Injectable({
  providedIn: 'root'
})
export class ObjetosService {

  contacto = {
    nombres: '',
    apellidos: '',
    cargoEmpresa: '',
    correo: '',
    celular: '',
    indicativo: '',
    telefono: '',
    extension: ''

  }

  entrega = {
    direccionCompleta: '',
    departamento: '',
    municipio: '',
    celular: '',
    indicativo: '',
    telefono: '',
    extension: '',
    correo: '',
    tipo: ''
  }

  facturacion = {
    tipo: '',
    numero: '',
    nombres: '',
    apellidos: '',
    razon: '',
    direccion: '',
    indicativo: '',
    telefono: '',
    extension: '',
    celular: '',
    correo: '',
    tipoCapital: '',
    regimenTributario: '',
    actividadEconomica: ''
  }

  empresa: Empresa = {
    razon: '',
    direccionp: '',
    indicativo: '',
    code: '',
    telefono: '',
    celular: '',
    extension: '',
    correo: '',
    departamento: '',
    municipio: ''
  }


  constructor() { }
}
