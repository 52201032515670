import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { LogService } from '../../../domain/servicios/log.service'
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from "src/environments/environment"
import { trigger, state, style, transition, animate } from '@angular/animations';

declare const PDFObject: any;

@Component({
  selector: 'app-estado-tramite',
  templateUrl: './estado-tramite.component.html',
  styleUrls: ['./estado-tramite.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EstadoTramiteComponent implements OnInit {

  @ViewChild('inputRefNumero')
  inputRefNumero!: { nativeElement: any; };
  formaForm!: FormGroup;
  usuarioConsulta: any = {
    ip: '',
    tipo: '',
    numero: '',
  };
  comas = false
  listadoSolicitudes: any = []
  tituloConsulta = ""
  tiposDocumentos: any = {
    'CC': 'Cédula de Ciudadanía',
    'CE': 'Cédula de Extranjería',
    'PP': 'Pasaporte'
  };
  completed = true
  constructor(private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private componentesService: ComponentesService,
    private ssps: SspsService,
    private alert: AlertsService,
    private logger: LogService,
    private el: ElementRef,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    /*validacion de campos validators*/
    this.formaForm = this.fb.group({
      tipo: ['', [Validators.required]],
      numero: ['', [Validators.required]]
    }, {
      validators: [
      ]
    });
    this.setOnChangesInputs()
  }

  columnsToDisplay = ['Orden Pedido No.', 'Nº Solicitud', 'Nombre del titular', 'Mecanismo', 'Politica'];
  columnsToDisplayValores = ['ordenPedidoId', 'solicitudId', 'nombreTitular', 'mecanismo', 'politica'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: any = null;

  async ngOnInit(): Promise<void> {
    this.ssps.obtenerIpValor()
    if (this.ssps.getLocalStorage("usuarioConsulta")) {
      this.usuarioConsulta = JSON.parse(this.ssps.getLocalStorage("usuarioConsulta")!)
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  setOnChangesInputs() {
    this.formaForm.get("numero")!.valueChanges.subscribe(val => {
      if (this.inputRefNumero && val) {
        const elRef = this.inputRefNumero?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numero")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });
  }

  /* funcion para validacion de campos*/
  camposvalidos(campo: any) {
    return this.formaForm.get(campo)!.invalid &&
      (this.formaForm.get(campo)!.touched || this.formaForm.get(campo)!.dirty);
  }

  //TODO:cerrar el modal de activo digital
  close() {
    this.alert.closesSpiner();
  }

  /*ingreso y validacion de datos de formulario*/
  ngsubmit() {
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          this.logger.log("ngsubmit", this.formaForm.controls[key].errors)
          invalidControl?.focus();
          return;
        }
      });
      return;
    }
    else {
      //this.estados= true;
      this.alert.showSpiner("Estamos cargando sus solicitudes, este proceso puede tardar un momento");
      this.logger.log("consultar", this.usuarioConsulta)
      this.validarCaptcha()
    }
  }

  validarCaptcha() {
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token: string) => {
        this.ssps.validatedCapcha(token)
          .subscribe({
            next: (res: any) => {
              this.logger.log("respuesta captcha", res)
              if (res.code == 200) {
                this.consultarSolicitudes()
              }
            },
            error: (error) => {
              this.alert.closesSpiner()
              console.error('Error en la funcion de capcha', error);
              let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
                error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                  error.error.message ? error.error.message :
                    (error.message ? error.message : "No fue posible realizar la petición")) :
                (error.message ? error.message : "No fue posible realizar la petición")
              this.alert.showError(mensaje, false, null, false, null, null)
            },
          })
      });
  }

  consultarSolicitudes() {
    let data = {
      tipo: this.usuarioConsulta.tipo,
      identificacion: this.usuarioConsulta.numero
    }
    this.ssps.consultarSolicitudesEstadoTramite(data)
      .subscribe({
        next: (res: any) => {
          this.logger.log("consultarSolicitudesEstadoTramite", res)
          this.listadoSolicitudes = res
          this.alert.closesSpiner()
          if (res === null || res === undefined ||
            res.length === 0
          ) {
            this.alert.showError(`No se encontraron tramites asociados a la identificación ${this.usuarioConsulta.numero}`, false, null, false, null, null)
          }
          else {
            this.tituloConsulta = `${this.tiposDocumentos[this.usuarioConsulta.tipo]}: ${this.usuarioConsulta.numero} - ${res[0].nombreTitular}`
          }
        },
        error: (error) => {
          console.error('Error en subscriber consultarSolicitudesEstadoTramite', error)
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.consultarSolicitudes();
          }
          else {
            this.alert.closesSpiner()
            console.error('Error en subscriber consultarSolicitudesEstadoTramite', error)
            let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
              error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                error.error.message ? error.error.message :
                  (error.message ? error.message : "No fue posible realizar la petición")) :
              (error.message ? error.message : "No fue posible realizar la petición")
            this.alert.showError(mensaje, false, null, false, null, null)
          }
        }
      })
  }

  cancelarSeleccion() {
    this.usuarioConsulta.tipo = ""
    this.usuarioConsulta.numero = ""
    this.listadoSolicitudes = []
    this.tituloConsulta = ""
  }

  noPuntoComa(event: any) {

    var e = event || window.event;
    var key = e.keyCode || e.which;


    if (key === 110 || key === 190 || key === 188) {

      this.comas = true;
    } else {
      this.comas = false;
    }
  }

  /*quitar numeros ceros a la izquierda*/
  quitarceros(numero: string) {

    this.usuarioConsulta.numero = numero.replace(/^0+/, '');
  }

  closeModal() {
    let externalUrl = `${environment.urlWebCerti}`;
    this.alert.closesFiels();
    localStorage.clear()
    window.location.replace(externalUrl);
  }

  validarEstadoClase(paso: any) {
    return paso.finalizado ? 'step ng-tns-c4-0 completed ng-star-inserted' : 'step ng-tns-c4-0 active ng-star-inserted';
  }

  mostrarComentarios(comentarios: any) {
    this.alert.showError(comentarios,
      false, "¡Informativo!", false, null, null)
  }

}