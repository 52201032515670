<div id="footer">
  <div style="position: absolute;width: 100%;text-align: center;bottom: 0px;font-size: 10pt; z-index: 1000; opacity: 1;">
    Versión {{version}}</div>
  
  <!-- <a href="https://web.certicamara.com/servicios/firmas-digitales/certificados"
    style="cursor: pointer;" class="image">
    <img style="width: 100%; cursor: pointer;" src="./../../../../assets/footer.jpg">
  </a> -->
  
  <div id="block-iconossocialesfixed" class="IconoSocial">
    <div>
      <div>
        <p><a href="https://www.facebook.com/Certicamara/" target="_blank"><img alt="facebook" data-entity-type="file"
          src="../../../../../assets/redes/Facebook.png" class="align-center"> </a></p>
      </div>
      <div>
        <p><a href="https://twitter.com/CerticamaraSA" target="_blank"><img alt="facebook" data-entity-type="file"
          src="../../../../../assets/redes/X.png" class="align-center"> </a></p>
      </div>
      <div>
        <p><a href="https://www.linkedin.com/company/certicamara/" target="_blank"><img alt="facebook"
          data-entity-type="file" src="../../../../../assets/redes/LinkedIn.png" class="align-center"> </a></p>
      </div>
      <div>
        <p><a href="https://www.instagram.com/certicamara/?igshid=NzZlODBkYWE4Ng%3D%3D%7D"><img alt="instagram"
          data-entity-type="file" src="../../../../../assets/redes/Instagram.png" class="align-center"> </a></p>
      </div>
      <div>
        <p><a href="https://www.youtube.com/user/certicamara" target="_blank"><img alt="facebook" data-entity-type="file"
          src="../../../../../assets/redes/Youtube.png" class="align-center"> </a></p>
      </div>
    </div>
  </div>
</div>
