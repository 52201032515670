import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { environment as env } from "src/environments/environment"

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.css'],
    standalone: false
})
export class ConfirmationComponent implements OnInit {
  @Input() public redirigirWeb: any
  constructor(private alert:AlertsService,
              private router:Router,
              private componets:ComponentesService,
              private ssps: SspsService,
              public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  
  //TODO:cerrar modal
  close(){
    this.activeModal.close(false);
    if(this.redirigirWeb){
      let externalUrl = `${env.urlWebCerti}`;
      localStorage.clear()
      window.location.replace(externalUrl);
    }    
  }
  
  //TODO:se reliza configuracion de permisos
  naviGation(){
    this.activeModal.close(false);
    this.alert.closesFiels();
   // this.router.navigate(['/contacto']);
    //this.componets.emitircambio("showmenu");
    this.alert.showContact(this.redirigirWeb);
  }
}
