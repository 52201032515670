
<div class="modal-header">
 
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body  text-center ms-4 me-4">
    <h3>señor usuario</h3>
    <p>En el momento presentamos fallas técnicas, lo invitamos a diligenciar el siguiente formulario para que un agente comercial pueda contactarlo, en el menor tiempo posible.
 </p>
 <button type="submit" class="btn btn-primary" (click)="naviGation()">Aceptar</button>
</div>
<div class="modal-footer">
  
</div>