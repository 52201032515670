import { Component, Input, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { environment as env } from "src/environments/environment"
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { urlRequest } from 'src/assets/config/app.conf';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-solicitudfinalizada',
  templateUrl: './solicitudfinalizada.component.html',
  styleUrls: ['./solicitudfinalizada.component.css']
})
export class SolicitudFinalizadaComponent implements OnInit {
  @Input() public  redirigir: any
  usuario: any = {};
  urlRequest = urlRequest

  constructor(private alert: AlertsService, private ssps: SspsService) { }

  ngOnInit(): void {
    if (this.ssps.getLocalStorage('usuario')) {
      this.usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
    }
  }

  //TODO:cerrar modal
  close() {
    this.alert.closeAlert();
    if (this.redirigir) {
      localStorage.clear()
      let externalUrl = `${env.urlWebCerti}`;
      localStorage.clear()
      window.location.replace(externalUrl);
    }
  }
}
