import { Component, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { urlRequest } from 'src/assets/config/app.conf';
import { datosPasarela } from 'src/assets/config/app.conf';
import { LogService } from '../../../domain/servicios/log.service'
import { Router } from '@angular/router';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';

@Component({
    selector: 'app-solicitudfaltantes',
    templateUrl: './solicitudfaltantes.component.html',
    styleUrls: ['./solicitudfaltantes.component.css'],
    standalone: false
})
export class SolicitudFaltantesComponent implements OnInit {
  pago: any = {}
  usuario: any = {}
  urlRequest = urlRequest

  constructor(private alert: AlertsService, private ssps: SspsService, private logger: LogService,
    private router: Router, private componentesService: ComponentesService,
  ) { }

  ngOnInit(): void {
    this.usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
    this.pago = JSON.parse(this.ssps.getLocalStorage('pago')!);
  }  

  //TODO:cerrar modal
  close() {
    this.alert.closeAlert();
    if (this.usuario.faltantes && this.usuario.faltantes.length > 0 && this.usuario.faltantes[0] === "Realizar pago") {
      this.router.navigate(['/flujo/pago']);
      this.componentesService.emitircambio("pagosFull");
    }
    else if (this.pago !== null && this.pago.realizoPago === "S" &&
      this.pago.fechaPago !== "" && this.pago.fechaPago !== undefined &&
      this.usuario.faltantes && this.usuario.faltantes.length > 0 &&
      this.usuario.faltantes[0].includes("validación de identidad")) {
      this.alert.showAlertaValidarIdentidad(false)
    }
    else if (this.pago !== null && this.pago.pagoPSE === true &&
      (this.pago.fechaPago === "" || this.pago.fechaPago === undefined)) {
      this.consultarPago()
    }
  }

  consultarPago() {
    this.alert.showSpiner("Ten en cuenta...Para terminar el proceso debes realizar la validación de  tu identidad.")
    this.logger.log("servicioConsultarPago", "Info-basica")
    let datosPse = datosPasarela
    datosPse.ipUsuario = this.ssps.ip
    datosPse.urlRetorno = `${location.origin}?id=${JSON.parse(this.ssps.getLocalStorage("solicitudId")!)}&redirect=pago`
    datosPse.navegador = this.ssps.getBrowserName()
    //Si el estado es aprobado, mostrar alerta de transacción exitosa y ya terminar proceso
    let body = {
      ordenPedidoId: this.pago.ordenPedidoId,
      cupon: this.pago.cuponAplicado ?? '',
      datosPasarela: datosPse
    }
    this.ssps.consultarEstadoPagoPSE(body)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta consultarEstadoPago Info-basica", res)
          if (res !== null) {
            this.alert.closesSpiner()
            this.pago.estado = res.estado
            this.pago.mensaje = res.mensaje
            this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
            if (this.pago.estado === "APROBADO") {
              this.logger.log("Estado Pago info-Basica", "APROBADO")
              if (this.usuario.identidadValidada === false) {
                this.logger.log("consulta orden, identidad validada consultarEstadoPagoPSE", "false")
                this.alert.showAlertaValidarIdentidad(false)
              }
            }
          }
          else {
            this.pago.estado = "PENDIENTE"
            this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
            this.alert.closesSpiner()
          }
        },
        error: (error: any) => {
          this.logger.log("error suscriber consultarEstadoPago Info-basica", error)
          this.pago.estado = "PENDIENTE"
          this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
          this.alert.closesSpiner()
          //this.router.navigate(['/flujo/pasarela']);
        }
      });
  }
}
