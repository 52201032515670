import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appInputMask]',
    standalone: false
})
export class InputMaskDirective {

  @HostListener('input', ['$event'])
  onKeyDown(event: any) {
    const input = event.target as HTMLInputElement;
    var isInsert = event.inputType !== "deleteContentBackward"
    const trimmed = input.value.replace(/\s+/g, '');
    if (trimmed.length === 2 && isInsert) {
      return (input.value = `${trimmed.slice(0, 2)}/${trimmed.slice(2)}`);
    }

    if (trimmed.length === 5 && isInsert) {
      return (input.value = `${trimmed.slice(0, 2)}/${trimmed.slice(3)}/`);
    }

    if (trimmed.length > 5 && isInsert) {
      return (input.value = `${trimmed.slice(0, 5)}/${trimmed.slice(6)}`);
    }

    return input
  }
  
}