<div class=" animate__animated animate__fadeInLeftBig animate__faster">

  <div class="row ms-1 mt-3">
    <h5 class="titulosintu2">Información básica solicitante</h5>
    <br>
    <br>
    <div class="mb-3" *ngIf="usuario.politica.toLowerCase() == 'representante legal'">
      <div class="mb-1 form-check">
        <input type="checkbox" class="form-check-input" id="soyRepresentante"
          [(ngModel)]="solicitante.representanteLegal">
        <label class="form-check-label" for="exampleCheck1">
          <p>Soy el representante legal o suplente</p>
        </label>
      </div>
    </div>
    <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()">
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label class="form-label">Tipo de identificación</label>
            <select [(ngModel)]="solicitante.tipo" class="form-select" formControlName="tipo">
              <option class="form-control" value="CC">Cédula de Ciudadanía</option>
              <option class="form-control" value="CE">Cédula de Extranjería</option>
              <option class="form-control" value="PP">Pasaporte</option>
              <option class="form-control" value="NI">NIT</option>
              <option class="form-control" value="TI">Tarjeta de Identidad T.I.</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label class="form-label">Número de identificación</label>
            <input [(ngModel)]="solicitante.numero" type="text" class="form-control"
              placeholder="Número de identificación" formControlName="numero">
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <label class="form-label">Primer nombre</label>
          <input type="text" class="form-control" placeholder="Primer nombre" formControlName="primerNombre"
            [class.is-invalid]="camposvalidos('primerNombre')" [(ngModel)]="solicitante.primerNombre" #inputRefPrimerNombre>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('primerNombre')">
            Primer nombre no es válido, debe ingresar solo letras</div>
        </div>
        <div class="col">
          <label class="form-label">Segundo nombre</label>
          <input type="text" class="form-control" placeholder="Segundo nombre" formControlName="segundoNombre"
            [class.is-invalid]="camposvalidos('segundoNombre')" [(ngModel)]="solicitante.segundoNombre"
            #inputRefSegundoNombre>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('segundoNombre')">
            Segundo nombre no es válido, debe ingresar solo letras</div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <label class="form-label">Primer apellido</label>
          <input type="text" class="form-control" placeholder="Primer apellido" formControlName="primerApellido"
            [class.is-invalid]="camposvalidos('primerApellido')" [(ngModel)]="solicitante.primerApellido"
            #inputRefPrimerApellido>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('primerApellido')">Primer apellido no es válido,
            debe ingresar solo letras</div>
        </div>
        <div class="col">
          <label class="form-label">Segundo apellido</label>
          <input type="text" class="form-control" placeholder="Segundo apellido" formControlName="segundoApellido"
            [class.is-invalid]="camposvalidos('segundoApellido')" [(ngModel)]="solicitante.segundoApellido"
            #inputRefSegundoApellido>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('segundoApellido')">Segundo apellido no es válido,
            debe ingresar solo letras</div>
        </div>
      </div>
      <!-- <div class="mb-3">
            <div class="row">
              <div class="col">
                <label class="form-label">Fecha de expedición documento</label>
                <div class="input-group">
                  <input class="form-control" placeholder="dd/mm/aaaa" formControlName="fechaExpDocumento"
                    [(ngModel)]="fechaExpDocumento" [class.is-invalid]="camposvalidos('fechaExpDocumento')" ngbDatepicker
                    #d="ngbDatepicker" (keyup)="fechas(fechaExpDocumento, 'fechaExpDocumento')" />
                  <button class="btn btn-primary  bi bi-calendar3" (click)="d.toggle()" type="button">
                    <i class="far fa-calendar-alt"></i>
                  </button>
                </div>
                <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                  *ngIf="statusFechaExpDocumento || camposvalidos('fechaExpDocumento')">Fecha de expedición documento no
                  válida</div>
              </div>
            </div>
          </div> -->
      <div class="row mb-4">
        <div class="col">
          <label class="form-label">Correo electrónico:</label>
          <input type="text" class="form-control" placeholder="correo electrónico" formControlName="correo"
            [(ngModel)]="solicitante.correo" [class.is-invalid]="camposvalidos('correo')" #inputRefCorreo>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('correo')">Correo no válido, debe tener la estructura correo@dominio.com(.co,.es..)
          </div>

        </div>
        <div class="col">
          <label class="form-label">Confirmación correo electrónico:</label>
          <input type="text" class="form-control" placeholder="correo electrónico" formControlName="correo1"
            [(ngModel)]="solicitante.correo1" [class.is-invalid]="camposvalidos('correo1')" appNocopiado
            #inputRefCorreo1>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('correo1')">Correo no válido, debe tener la estructura correo@dominio.com(.co,.es..)
          </div>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="correo2NoValido">
            Los correos no son iguales</div>
        </div>
      </div>

      <!-- <div class="mb-3">
        <div class="col">
          <label class="form-label">Dirección completa:</label>
          <input type="text" class="form-control" placeholder="direccion completa" formControlName="direccion"
            [(ngModel)]="solicitante.direccion" [class.is-invalid]="camposvalidos('direccion')"
            (keyup)="noPuntoComa($event)" #inputRefDireccion>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('direccion')">Dirección no válido</div>
        </div>
      </div> -->

      <div class="row mb-4">
        <div class="col">
          <label class="form-label">Departamento</label>
          <select class="form-select" formControlName="departamento" [class.is-invalid]="camposvalidos('departamento')"
            (change)="changeDepartamento($event)" [(ngModel)]="solicitante.departamento"
            placeholder="selecione un departamento">
            <option *ngFor="let muni of depar" [value]="muni.codigo_dep">{{muni.Departamento}}</option>
          </select>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('departamento')">Departamento no válido, seleccione una opción del listado</div>
        </div>
        <div class="col">
          <label class="form-label">Municipio</label>
          <select class="form-select" formControlName="municipio" [class.is-invalid]="camposvalidos('municipio')"
            [(ngModel)]="solicitante.municipio" placeholder="selecione un municipio">
            <option class="form-control" *ngFor="let muni of munifi" [value]="muni.Codigo_mu">{{muni.Municipio}}
            </option>
          </select>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('municipio')">Municipio no válido, seleccione una opción del listado</div>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label mt-3">Teléfono contacto:</label>
        <div class="row">
          <div class="col">
            <input class="form-control" list="browsers" formControlName="indicativo" placeholder="indicativo"
              [(ngModel)]="solicitante.indicativo" [class.is-invalid]="camposvalidos('indicativo')"
              (keyup)="noPuntoComa($event)">
            <datalist id="browsers">
              <option *ngFor="let d of indicativos" [value]="d.indicativo">{{d.nomDepartamento}}</option>
            </datalist>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('indicativo')">Indicativo no válido, seleccione una opción de la lista</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comas">El campo
              no
              puede tener decimales</div>
          </div>

          <div class="col">
            <input type="number" class="form-control" placeholder="telefono" formControlName="telefono"
              [class.is-invalid]="camposvalidos('telefono')" #datos2 [(ngModel)]="solicitante.telefono"
              value="{datos.toString()}" (keyup)="quitarceros2(datos2.value)" (keyup)="noPuntote($event)">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('telefono')">Teléfono no válido, debe ser un número de 7 dígitos</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastel2">El
              campo
              no puede tener comas</div>

          </div>
          <div class="col">
            <input type="number" class="form-control" placeholder="extension" formControlName="extension"
              [class.is-invalid]="camposvalidos('extension')" #datos3 [(ngModel)]="solicitante.extension"
              value="{datos3.toString()}" (keyup)="quitarceros3(datos3.value)" (keyup)="noPuntoex($event)">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('extension')">Extension no válido</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastex">El campo
              no puede tener comas</div>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <div class="row">
          <div class="col">
            <label class="form-label">Celular:</label>
            <input type="number" class="form-control" placeholder="celular" formControlName="celular"
              [class.is-invalid]="camposvalidos('celular')" #datos4 [(ngModel)]="solicitante.celular"
              value="{datos4.toString()}" (keyup)="quitarceros4(datos4.value)" (keyup)="noPuntotel($event)">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('celular')">Celular no válido, ingrese número de 10 dígitos</div>

            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastel">El campo
              no
              puede tener comas</div>
          </div>
          <div class="col">
            <label class="form-label">Cargo empresa:</label>
            <input type="text" class="form-control" placeholder="Cargo empresa" formControlName="cargoEmpresa"
              [class.is-invalid]="camposvalidos('cargoEmpresa')" #datos4 [(ngModel)]="solicitante.cargoEmpresa"
              #inputRefCargo>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('cargoEmpresa')">Cargo no válido</div>
          </div>
        </div>
      </div>

      <div class="mb-4" *ngIf="funcionPublica">
        <div class="row">
          <div class="col">
            <label class="form-label">Acta de posesión:</label>
            <input type="text" class="form-control" placeholder="Acta de posesión" formControlName="actaPosesion"
              [class.is-invalid]="camposvalidos('actaPosesion')" #datos4 [(ngModel)]="solicitante.actaPosesion"
              #inputRefActaPosesion>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('actaPosesion')">Acta de posesión no válido</div>
          </div>
          <div class="col">
            <label class="form-label">Fecha de nombramiento</label>
            <div class="input-group">
              <input class="form-control" placeholder="dd/mm/aaaa" mask="99/99/9999" appInputMask
                formControlName="fechaNombramiento" [(ngModel)]="fechaNombramiento"
                [class.is-invalid]="camposvalidos('fechaNombramiento')" ngbDatepicker #d="ngbDatepicker"
                [maxDate]="maxDate" [minDate]="minDate" (keyup)="fechas(fechaNombramiento, 'fechaNombramiento')" />
              <button class="btn btn-primary  bi bi-calendar3" (click)="d.toggle()" type="button">
                <i class="far fa-calendar-alt"></i>
              </button>
            </div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="statusFechaNombramiento || camposvalidos('fechaNombramiento')">Fecha de posesión
              no válida</div>
          </div>
        </div>
      </div>

      <div class="row mb-4" *ngIf="profesional">
        <div *ngIf="!otraProfesion" class="col">
          <label class="form-label">Institución que concedió el grado:</label>
          <input type="text" class="form-control" placeholder="Institución donde se concedió el grado"
            formControlName="institucion" [class.is-invalid]="camposvalidos('institucion')"
            [(ngModel)]="solicitante.institucionGrado" #inputRefInstitucion>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('institucion')">Institución no válida</div>
        </div>
        <div class="col">
          <label class="form-label">Profesión:</label>
          <select [(ngModel)]="profesionLista" (change)="validarProfesion($event)" class="form-select"
            formControlName="profesionLista" [class.is-invalid]="camposvalidos('profesionLista')">
            <option class="form-control" value="" disabled></option>
            <option *ngFor="let profesion of profesiones" [value]="profesion">{{profesion}}</option>
          </select>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('profesionLista')">Profesion no válido</div>
        </div>
        <div *ngIf="otraProfesion" class="col">
          <label class="form-label">&nbsp;</label>
          <input type="text" class="form-control" placeholder="Profesion" formControlName="profesion"
            placeholder="Ej. Ingeniero" [class.is-invalid]="camposvalidos('profesion')"
            [(ngModel)]="solicitante.profesion" #inputRefProfesion>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('profesion')">Profesion no válido</div>
        </div>
      </div>

      <div class="row mb-4" *ngIf="profesional">
        <div *ngIf="otraProfesion" class="col">
          <label class="form-label">Institución que concedió el grado:</label>
          <input type="text" class="form-control" placeholder="Institución donde se concedió el grado"
            formControlName="institucion" [class.is-invalid]="camposvalidos('institucion')"
            [(ngModel)]="solicitante.institucionGrado" #inputRefInstitucion>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('institucion')">Institución no válida</div>
        </div>
        <div class="col">
          <label class="form-label">Número de tarjeta profesional</label>
          <input type="text" class="form-control" placeholder="Número de tarjeta profesiona"
            formControlName="profesional" placeholder="Ej. A1234" [class.is-invalid]="camposvalidos('profesional')"
            [(ngModel)]="solicitante.tarjetaProfesional" #inputRefProfesional>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('profesional')">Número de tarjeta profesional no válido</div>
        </div>
        <div class="col">
          <label class="form-label">Fecha de expedición tarjeta profesional</label>
          <div class="input-group">
            <input class="form-control" placeholder="dd/mm/aaaa" mask="99/99/9999" appInputMask
              formControlName="fechaExpTarjeta" [(ngModel)]="fechaExpTarjeta"
              [class.is-invalid]="camposvalidos('fechaExpTarjeta')" ngbDatepicker #d="ngbDatepicker" [maxDate]="maxDate"
              [minDate]="minDate" (keyup)="fechas(fechaExpTarjeta, 'fechaExpTarjeta')" />
            <button class="btn btn-primary  bi bi-calendar3" (click)="d.toggle()" type="button">
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="statusFechaExpTarjeta || camposvalidos('fechaExpTarjeta')">Fecha de expedición de tarjeta profesional
            no válida</div>
        </div>
      </div>

      <div class="mb-3">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="cedula"
            [checked]="ExisteDocumento('cedula') || ExisteDocumentoCargado('cedula')" value="true"
            (change)="CreateDocument('Copia de cédula', 'cedula')"
            [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
          <label class="form-check-label">
             Adjuntar copia de cédula
          </label>
        </div>

        <div class="form-check" *ngIf="profesional">
          <input class="form-check-input" type="checkbox" id="tarjeta"
            [checked]="ExisteDocumento('tarjeta') || ExisteDocumentoCargado('tarjeta')" value="true"
            (change)="CreateDocument('Tarjeta profesional', 'tarjeta')"
            [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
          <label class="form-check-label">
             Adjuntar de tarjeta profesional
          </label>
        </div>
        <div class="form-check" *ngIf="funcionPublica">
          <input class="form-check-input" type="checkbox" id="posesion"
            [checked]="ExisteDocumento('posesion') || ExisteDocumentoCargado('posesion')" value="true"
            (change)="CreateDocument('Acta posesion', 'posesion')"
            [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
          <label class="form-check-label">
             Adjuntar acta de posesión
          </label>
        </div>
        <div class="form-check" *ngIf="usuario.politica == 'Pertenencia a Empresa'">
          <input class="form-check-input" type="checkbox" id="laboral"
            [checked]="ExisteDocumento('laboral') || ExisteDocumentoCargado('laboral')" value="true"
            (change)="CreateDocument('Certificado laboral', 'laboral')"
            [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
          <label class="form-check-label">
             Adjuntar certificado laboral
          </label>
        </div>
        <div class="form-check" *ngIf="usuario.politica.toLowerCase() == 'representante legal'">
          <input class="form-check-input" type="checkbox" id="representante"
            [checked]="ExisteDocumento('representante') || ExisteDocumentoCargado('representante')" value="true"
            (change)="CreateDocument('Certificado representante legal', 'representante')"
            [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
          <label class="form-check-label">
             Adjuntar certificado representate legal
          </label>
        </div>
        <div class="form-check" *ngIf="usuario.politica == 'Persona Jurídica PKCS10'">
          <input class="form-check-input" type="checkbox" id="representante"
            [checked]="ExisteDocumento('representante') || ExisteDocumentoCargado('representante')" value="true"
            (change)="CreateDocument('Certificado representante legal', 'representante')"
            [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
          <label class="form-check-label">
             Adjuntar certificado de Camara y comercio
          </label>
        </div>
      </div>

      <div formArrayName="documentos">
        <div *ngFor="let favorite of documentsDocuments.controls; let i = index">

          <div class="animate__animated animate__fadeIn animate__fast ms-5">
            <label class="form-check-label">
              Documento <strong style="color:#44494d ;">{{buttons[i].name}}</strong> ({{i + 1}} de {{totalCantidad}})
            </label>
            <div class="d-flex justify-content-center">
              <div id="div_file" class="text-center">
                <div [class]="validar()" *ngIf="cambiologo">
                  <span class="fa-stack fa-1x">
                    <i class="fa-solid fa-circle fa-stack-2x" [ngStyle]="{'color':buttons[i].fondo }"></i>
                    <i class="fa-solid fa-upload fa-stack-1x fa-inverse" [ngStyle]="{'color':buttons[i].colores }"></i>
                  </span>

                  <p id="texto">{{buttons[i].title}}
                    <br>
                    <small>{{buttons[i].subtitle}}</small>
                  </p>
                </div>
                <input type="file" class="enviar" #enviar [formControlName]="i"
                  (change)="getFileDetails(enviar.files,i)">
              </div>
            </div>
          </div>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="validateformats[i] || isValidFieldInArray(documentsDocuments,i)">{{error}}</div>
        </div>
        <div class="mb-4">
          <button type="button" class="btn btn-secondary" style="margin: 15px;"
            (click)="redireciona()">Anterior</button>
          <button *ngIf="usuario.solicitudFinalizada !== 'ASIGNADO'" type="submit"
            class="btn btn-primary">{{actualizacion ? "Guardar" : "Siguiente"}}</button>
          <button *ngIf="usuario.solicitudFinalizada === 'ASIGNADO'" type="submit"
            class="btn btn-primary">Continuar</button>
        </div>
      </div>
    </form>
  </div>

</div>