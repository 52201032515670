import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormGroup, FormBuilder, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';

@Component({
  selector: 'app-datos-facturacion',
  templateUrl: './datos-facturacion.component.html',
  styleUrls: ['./datos-facturacion.component.css']
})
export class DatosFacturacionComponent implements OnInit {

  @ViewChild('inputRefNombres')
  inputRefNombres!: { nativeElement: any; };

  @ViewChild('inputRefApellidos')
  inputRefApellidos!: { nativeElement: any; };

  @ViewChild('inputRefNumero')
  inputRefNumero!: { nativeElement: any; };

  @ViewChild('inputRefCorreo')
  inputRefCorreo!: { nativeElement: any; };

  @ViewChild('inputRefRazon')
  inputRefRazon!: { nativeElement: any; };

  @ViewChild('inputRefAgente')
  inputRefAgente!: { nativeElement: any; };

  @ViewChild('inputRefDireccion')
  inputRefDireccion!: { nativeElement: any; };
  @ViewChild('inputRefTipoNumeroDireccion')
  inputRefTipoNumeroDireccion!: { nativeElement: any; };
  @ViewChild('inputRefNumeroDireccion')
  inputRefNumeroDireccion!: { nativeElement: any; };
  @ViewChild('inputRefNumeroDireccion2')
  inputRefNumeroDireccion2!: { nativeElement: any; };
  @ViewChild('inputRefBarrioDireccion')
  inputRefBarrioDireccion!: { nativeElement: any; };
  @ViewChild('inputRefAdicionalDireccion')
  inputRefAdicionalDireccion!: { nativeElement: any; };
  @ViewChild('inputRefNumeroOtroDireccion')
  inputRefNumeroOtroDireccion!: { nativeElement: any; };

  formaForm!: FormGroup;
  usuario: any;
  comas: boolean = false;
  comasnit: boolean = false;
  comastel: boolean = false;
  comastel2: boolean = false;
  comastex: boolean = false;
  indicativos: any[] = [];
  codindi: any;
  codigo: any;
  facturacion: any;
  facturaOtro = false
  tipoCliente = ""
  facturaAEmpresa = false
  actualizacion = false
  direccion: any = {}
  tipoDireccion: any[] = [];
  ayudaAgente = false

  regimenTributarioListado: any[] = []
  regimenTributario: any[] = []
  tiposCapital: any[] = []

  depar: any[] = [];
  muni: any[] = [];
  munifi: any[] = [];

  constructor(private fb: FormBuilder,
    private router: Router,
    private componentesService: ComponentesService,
    private ssps: SspsService,
    private changeDetector: ChangeDetectorRef,
    private el: ElementRef) {

    /*validacion de campos validators*/
    this.formaForm = this.fb.group({
      tipo: ['', [Validators.required]],
      numero: ['', []],
      nombres: ['', [Validators.pattern("^[a-zA-Z ]{3,60}$"),
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(60)
      ]
      ],
      codigo: ['', [
        Validators.required,
        Validators.min(0),
        Validators.max(9),
        Validators.pattern("^[0-9]*$")
      ]
      ],
      razon: ['', [Validators.required,
      Validators.minLength(3),
      Validators.maxLength(60)
      ]
      ],
      apellidos: ['', [Validators.required,
      Validators.minLength(3),
      Validators.maxLength(60),
      Validators.pattern('[a-zA-Z ]{3,60}')]],
      indicativo: [],
      telefono: ['', [
        Validators.maxLength(7),
        Validators.minLength(7)
      ]],
      extension: [0, [
        Validators.maxLength(5)
      ]],
      celular: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      correo: ['', [Validators.required,
      Validators.pattern('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$'),
      Validators.minLength(8),
      Validators.maxLength(60),
      ]],
      agente: ['', [
        Validators.pattern('^[A-Z]{2}[0-9]{3}$'),
        Validators.minLength(5),
        Validators.maxLength(5)
      ]],
      tipoDireccion: ['', [
        Validators.required
      ]],
      tipoNumeroDireccion: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10)
      ]],
      numeroDireccion: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10)
      ]],
      numeroDireccion2: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10)
      ]],
      barrioDireccion: ['', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(200)
      ]],
      adicionalDireccion: ['', [
        Validators.maxLength(200)
      ]],
      numeroOtroDireccion: ['', [
        Validators.minLength(4),
        Validators.maxLength(200)
      ]],
      ayudaAgente: [false, []],
      tipoCapital: ['', [Validators.required]],
      regimenTributario: ['', [Validators.required]],
      departamento: ['', [Validators.required]],
      municipio: ['', [Validators.required]]
    }, {
      validators: [this.componentesService.minimos('numero'),
      this.componentesService.validalist('indicativo'),
      this.componentesService.codigonit('tipo', 'numero'),
      this.componentesService.digitoChequeo('numero', 'codigo')
      ]
    }
    );
    this.setOnChangesInputs()
  }

  /* funcion para validacion de campos*/
  camposvalidos(campo: any) {
    return this.formaForm.controls[campo].errors && this.formaForm.controls[campo].touched;
  }

  //implementacion de validacion de comas

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.ssps.obtenerIpValor()
    this.tiposRegimenTributario()
    this.indicativo();
    this.tiposDireccion()
    this.departamentos();
    this.tipoCliente = this.ssps.getLocalStorage('tipocliente')!;
    this.usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
    this.actualizacion = this.ssps.getLocalStorage("actualizacion") ?
      JSON.parse(this.ssps.getLocalStorage("actualizacion")!) : false
    this.precargaDatos()
    if (this.tipoCliente === 'natural') {
      this.clearValidatorsEmpresa()
    }
    else {
      this.clearValidatorsNatural()
    }
    this.ayudaAgente = this.facturacion.agente !== undefined && this.facturacion.agente !== null &&
      this.facturacion.agente !== ""
    this.formaForm.controls["codigo"]?.disable({ onlySelf: true })
  }

  ngsubmit() {
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl?.focus();
          return;
        }
      });
      return;
    }
    const valoresfi = this.formaForm.getRawValue();

    let objetoDireccion = {
      tipo: valoresfi.tipoDireccion,
      tipoNumero: valoresfi.tipoNumeroDireccion,
      numero: valoresfi.numeroDireccion,
      numero2: valoresfi.numeroDireccion2,
      barrio: valoresfi.barrioDireccion,
      adicional: valoresfi.adicionalDireccion ?? "",
      tipoOtroNumero: valoresfi.numeroOtroDireccion
    }

    if (valoresfi.tipo === "NI") {
      valoresfi.nombres = valoresfi.razon
      valoresfi.apellidos = ""
    }
    valoresfi.agente = valoresfi.agente ?? ""
    this.facturacion.facturaOtro = this.facturaOtro
    this.facturacion.facturaEmpresa = this.facturaAEmpresa
    this.facturacion.objetoDireccion = objetoDireccion
    this.facturacion.direccion = (objetoDireccion.tipo !== "Otro" ? (objetoDireccion.tipo + " " + objetoDireccion.tipoNumero + " #" + objetoDireccion.numero + "-" +
      objetoDireccion.numero2) : objetoDireccion.tipoOtroNumero) +
      " BARRIO " + objetoDireccion.barrio + (objetoDireccion.adicional !== "" ? " ADICIONAL " + objetoDireccion.adicional : "")
    Object.assign(this.facturacion, valoresfi);
    this.ssps.saveLocalStorage("facturacion", JSON.stringify(this.facturacion));
    /*emite orden de cambio para alerta y cambios de precios*/
    this.componentesService.emitircambio("mostrarordencambio");
  }

  setOnChangesInputs() {
    this.formaForm.get("nombres")!.valueChanges.subscribe(val => {
      if (this.inputRefNombres) {
        const elRef = this.inputRefNombres.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("nombres")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("apellidos")!.valueChanges.subscribe(val => {
      if (this.inputRefApellidos) {
        const elRef = this.inputRefApellidos.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("apellidos")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numero")!.valueChanges.subscribe(val => {
      if (this.inputRefNumero && val) {
        if (val.length > 6) {
          this.facturacion.codigo = this.componentesService.calcularDigitoChequeo(val)
        }
        else{
          this.facturacion.codigo = null
        }
        const elRef = this.inputRefNumero.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("numero")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("correo")!.valueChanges.subscribe(val => {
      if (this.inputRefCorreo) {
        const elRef = this.inputRefCorreo?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("correo")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("razon")!.valueChanges.subscribe(val => {
      if (this.inputRefRazon) {
        const elRef = this.inputRefRazon?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("razon")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("agente")!.valueChanges.subscribe(val => {
      if (this.inputRefAgente) {
        const elRef = this.inputRefAgente?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("agente")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("tipoNumeroDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefTipoNumeroDireccion) {
        const elRef = this.inputRefTipoNumeroDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("tipoNumeroDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numeroDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefNumeroDireccion) {
        const elRef = this.inputRefNumeroDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numeroDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numeroDireccion2")!.valueChanges.subscribe(val => {
      if (this.inputRefNumeroDireccion2) {
        const elRef = this.inputRefNumeroDireccion2.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numeroDireccion2")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("barrioDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefBarrioDireccion) {
        const elRef = this.inputRefBarrioDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("barrioDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("adicionalDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefAdicionalDireccion) {
        const elRef = this.inputRefAdicionalDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("adicionalDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numeroOtroDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefNumeroOtroDireccion) {
        const elRef = this.inputRefNumeroOtroDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numeroOtroDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });
  }

  precargaDatos() {
    if (this.ssps.getLocalStorage('facturacion')!) {
      this.facturacion = JSON.parse(this.ssps.getLocalStorage('facturacion')!);
      if (this.facturacion.direccion === "" || this.facturacion.direccion !== this.usuario.direccion) {
        this.facturacion.direccion = this.usuario.direccion
        this.facturacion.objetoDireccion = this.usuario.objetoDireccion
      }

      if (this.facturacion.telefono === "") {
        this.facturacion.telefono = this.usuario.telefono
        this.facturacion.indicativo = this.usuario.indicativo
        this.facturacion.extension = this.usuario.extension
      }
      if (this.facturacion.tipo === null || this.facturacion.tipo === undefined ||
        this.facturacion.tipo === "" ||
        this.facturacion.numero === null || this.facturacion.numero === undefined
        || this.facturacion.numero === ""
      ) {
        this.facturacion.tipo = this.usuario.tipo
        if (this.tipoCliente === 'natural') {
          this.facturacion.numero = this.usuario.numero
        }
        else {
          this.facturacion.numero = this.usuario.numero.length > 9 ? this.usuario.numero.substring(0, this.usuario.numero.length - 1) : this.usuario.numero
          this.facturacion.codigo = this.componentesService.calcularDigitoChequeo(this.usuario.numero.length > 9 ? this.usuario.numero.substring(0, this.usuario.numero.length - 1) : this.usuario.numero)
        }
      }
      if (this.facturacion.tipoCapital === null || this.facturacion.tipoCapital === undefined) {
        this.facturacion.tipoCapital = this.usuario.tipoCapital
      }
      if (this.facturacion.regimenTributario === null || this.facturacion.regimenTributario === undefined) {
        this.facturacion.regimenTributario = this.usuario.regimenTributario
      }
      this.direccion = this.facturacion.objetoDireccion ?? this.usuario.objetoDireccion
      if (this.direccion.tipo === "Otro") {
        this.clearValidatorsDireccion()
      }
      this.facturacion.codigo = this.componentesService.calcularDigitoChequeo(this.facturacion.numero)
      if (this.facturacion.tipoCapital === undefined || this.facturacion.tipoCapital === null ||
        this.facturacion.tipoCapital === ""
      ) {
        this.facturacion.tipoCapital = this.usuario.tipoCapital ?? ''
      }

      if (this.facturacion.regimenTributario === undefined || this.facturacion.regimenTributario === null ||
        this.facturacion.regimenTributario === ""
      ) {
        this.facturacion.regimenTributario = this.usuario.regimenTributario ?? ''
      }
      this.direccion = this.usuario.objetoDireccion ?? { barrio: "", adicional: "" }
      if (this.facturacion.departamento === undefined || this.facturacion.departamento === null
        || this.facturacion.departamento === '' || this.facturacion.departamento === 0) {
        this.facturacion.departamento = +(this.usuario.departamento ?? '')
      }
      if (this.facturacion.municipio === undefined || this.facturacion.municipio === null ||
        this.facturacion.municipio === "" || this.facturacion.municipio === 0
      ) {
        this.facturacion.municipio = +(this.usuario.municipio ?? '')
      }
      setTimeout(() => {
        this.tiposCapitalListado()
        if (this.facturacion.tipoCapital !== undefined && this.facturacion.tipoCapital !== null) {
          this.filtrarTipoRegimen({
            target: {
              value: this.facturacion.tipoCapital
            }
          }, this.facturacion.regimenTributario === undefined || this.facturacion.regimenTributario === null)
        }
      }, 2000);

      if (this.facturacion.agente === undefined || this.facturacion.agente === null
      ) {
        this.facturacion.agente = ''
      }
    }
    else {
      this.facturacion = {
        tipo: this.usuario.tipo ?? '',
        numero: this.usuario.numero ?? '',
        nombres: (this.usuario.primerNombre ?? '') + (this.usuario.segundoNombre !== null &&
          this.usuario.segundoNombre !== undefined && this.usuario.segundoNombre !== "" ?
          " " + this.usuario.segundoNombre : ""),
        apellidos: (this.usuario.primerApellido ?? '') + (this.usuario.segundoApellido !== null &&
          this.usuario.segundoApellido !== undefined && this.usuario.segundoApellido !== "" ?
          " " + this.usuario.segundoApellido : ""),
        razon: this.usuario.razon ?? '',
        indicativo: this.usuario.indicativo ?? '',
        telefono: this.usuario.telefono ?? '',
        extension: this.usuario.extension ?? '',
        celular: this.usuario.celular ?? '',
        correo: this.usuario.correo,
        facturaOtro: false,
        tipoCapital: this.usuario.tipoCapital ?? '',
        regimenTributario: this.usuario.regimenTributario ?? '',
        agente: '',
        codigo: this.usuario.codigo,
        direccion: this.usuario.direccion ?? '',
        departamento: this.usuario.departamento ?? '',
        municipio: this.usuario.municipio ?? ''
      }
      this.facturacion.codigo = this.componentesService.calcularDigitoChequeo(this.usuario.numero ?? '')
      this.direccion = this.usuario.objetoDireccion ?? { barrio: "", adicional: "" }
      if (this.direccion.tipo === "Otro") {
        this.clearValidatorsDireccion()
      }
      setTimeout(() => {
        this.tiposCapitalListado()
        if (this.facturacion.tipoCapital !== undefined && this.facturacion.tipoCapital !== null) {
          this.filtrarTipoRegimen({
            target: {
              value: this.facturacion.tipoCapital
            }
          }, this.facturacion.regimenTributario === undefined || this.facturacion.regimenTributario === null)
        }
      }, 1500);
    }
    setTimeout(() => {
      this.deshabilitarCmaposFinalizados()
    }, 1500);

    setTimeout(() => {
      this.deshabilitarCamposEditable()
    }, 2000);
  }

  deshabilitarCmaposFinalizados() {
    Object.keys(this.formaForm.controls).forEach(key => {
      if (key !== "correo" && (this.usuario.solicitudFinalizada === "APROBADO" || this.usuario.solicitudFinalizada === "FINALIZADO")) {
        this.formaForm.controls[key]?.disable({ onlySelf: true })
      }
      else if (this.usuario.solicitudFinalizada === "ASIGNADO") {
        this.formaForm.controls[key]?.disable({ onlySelf: true })
      }

      if ((key === "agente" || key === "ayudaAgente") && this.facturacion.agente !== undefined && this.facturacion.agente !== null &&
        this.facturacion.agente !== "") {
        this.formaForm.controls[key]?.disable({ onlySelf: true })
      }
    });
  }

  deshabilitarCamposEditable() {
    const controles = ['tipoDireccion', 'numeroOtroDireccion', 'tipoNumeroDireccion', 'numeroDireccion',
      'numeroDireccion2', 'barrioDireccion', 'adicionalDireccion', 'departamento', 'municipio', 'tipoCapital', 'regimenTributario'];
    for (const controlName of controles) {
      const control = this.formaForm.get(controlName);
      if (control) {
        this.formaForm.controls[controlName]?.disable({ onlySelf: true })
      }
    }
  }

  clearValidatorsEmpresa() {
    const controlesAEliminar = ['razon', 'codigo', 'tipoCapital'];
    // Iterar sobre los nombres de los controles y eliminar los validadores

    this.formaForm.clearValidators()
    this.formaForm.updateValueAndValidity()

    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    }

    this.formaForm.addValidators([this.componentesService.validalist('indicativo'),
    this.componentesService.codigonit('tipo', 'numero')])
    this.formaForm.updateValueAndValidity()
  }

  clearValidatorsNatural() {
    const controlesAEliminar = ['nombres', 'apellidos'];
    // Iterar sobre los nombres de los controles y eliminar los validadores

    this.formaForm.clearValidators()
    this.formaForm.updateValueAndValidity()

    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    }

    this.formaForm.addValidators([this.componentesService.minimos('numero'),
    this.componentesService.validalist('indicativo'),
    this.componentesService.codigonit('tipo', 'numero'),
    this.componentesService.digitoChequeo('numero', 'codigo')])
    this.formaForm.updateValueAndValidity()
  }

  /*rediociona al usuario si se equivova*/
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }


  /*carga datos select*/
  indicativo() {
    this.ssps.indicativos()
      .subscribe((res: any) => {
        this.indicativos = res;
      });

  }

  /*validacion de decimales input*/
  noPuntoComa(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comasnit = res;

      })
  }


  noPuntotel(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comastel = res;
      })
  }


  noPuntote(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comastel2 = res;
      })
  }

  noPuntoex(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comastex = res;
      })
  }


  noPuntoComacod(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comas = res;
      })
  }

  /*quitar numeros ceros a la izquierda*/
  quitarceros(numero: string) {

    this.facturacion.nit = numero.replace(/^0+/, '');
  }

  quitarceros2(numero: string) {

    this.facturacion.telefono = numero.replace(/^0+/, '');

  }

  quitarceros3(numero: string) {

    this.facturacion.extension = numero.replace(/^0+/, '');

  }

  quitarceros4(numero: string) {
    this.facturacion.celular = numero.replace(/^0+/, '');
  }

  facturaOtroChange($event: any) {
    let isCheckeado = $event.target.checked;
    if (isCheckeado) {
      // this.facturacion = {
      //   tipo: '',
      //   numero: '',
      //   nombres: '',
      //   apellidos: '',
      //   razon: '',
      //   direccion: '',
      //   indicativo: '',
      //   telefono: '',
      //   extension: '',
      //   celular: '',
      //   correo: '',
      //   agente: '',
      //   codigo: ''
      // }
      // this.direccion = { barrio: "", adicional: "" }
      this.facturacion.tipo = ''
      this.facturacion.numero = ''
      this.facturacion.nombres = ''
      this.facturacion.apellidos = ''
      this.facturacion.razon = ''
      this.facturacion.indicativo = ''
      this.facturacion.telefono = ''
      this.facturacion.extension = ''
      this.facturacion.celular = ''
      this.facturacion.correo = ''
      this.facturacion.codigo = ''
    }
    else {
      this.precargaDatos()
    }
    setTimeout(() => {
      this.filtrarTipoRegimen({
        target: {
          value: this.facturacion.tipoCapital
        }
      }, this.facturacion.regimenTributario === undefined || this.facturacion.regimenTributario === null)
    }, 2000);

  }

  /*rediociona al usuario si se equivova*/
  redireciona() {
    this.router.navigate(['/flujo/entrega']);
    this.componentesService.emitircambio("reveentregan");
  }

  tiposDireccion() {
    this.ssps.tiposDireccion()
      .subscribe((res: any) => {
        this.tipoDireccion = res;
      });
  }

  changeTipoDireccion($event: any) {
    let tipoDireccion = $event.target.value;
    if (tipoDireccion === "Otro") {
      this.direccion = {
        tipo: tipoDireccion
      }
      this.clearValidatorsDireccion()
    }
    else {
      this.addValidatorsDireccion()
    }
  }

  clearValidatorsDireccion() {
    const controlesAEliminar = ['tipoNumeroDireccion', 'numeroDireccion',
      'numeroDireccion2', 'numeroOtroDireccion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        if (controlName === "numeroOtroDireccion") {
          control.addValidators([
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(200)])
        }
        else {
          control.clearValidators();
        }
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  addValidatorsNatural() {
    const controlesAEliminar = ['nombres', 'apellidos'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        control.addValidators([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(60),
          Validators.pattern('[a-zA-Z ]{3,60}')])
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  addValidatorsEmpresa() {
    const controlesAEliminar = ['razon', 'codigo', 'tipoCapital'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        if (controlName === 'codigo') {
          control.addValidators([Validators.required,
          Validators.min(0),
          Validators.max(9),
          Validators.pattern("^[0-9]*$")])
        }
        else {
          control.addValidators([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(60)])
        }
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  addValidatorsDireccion() {
    const controlesAEliminar = ['tipoNumeroDireccion', 'numeroDireccion',
      'numeroDireccion2', 'numeroOtroDireccion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        if (controlName === "numeroOtroDireccion") {
          control.clearValidators();
        }
        else {
          control.addValidators([
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(10)
          ])
        }
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  mostrarCampoAgente($event: any) {
    this.ayudaAgente = $event.target.checked;
    const control = this.formaForm.get("agente");
    if ($event.target.checked) {
      control?.addValidators([
        Validators.required,
        Validators.pattern('^[A-Z]{2}[0-9]{3}$'),
        Validators.minLength(5),
        Validators.maxLength(5)
      ])
    }
    else {
      control?.clearValidators();
    }
    control?.setValue("")
    control?.updateValueAndValidity();
  }

  changeTipoIdentificacion($event: any) {
    let tipo = $event.target.value;
    this.tiposCapitalListado()
    if (tipo !== 'NI') {
      this.clearValidatorsEmpresa()
      this.addValidatorsNatural()
      this.facturacion.numero = this.usuario.numero
      this.facturacion.codigo = ""
    }
    else {
      this.facturacion.numero = ""
      this.facturacion.codigo = ""
      this.clearValidatorsNatural()
      this.addValidatorsEmpresa()
    }
    this.filtrarTipoRegimen({
      target: {
        value: this.facturacion.tipoCapital
      }
    }, this.facturacion.regimenTributario === undefined || this.facturacion.regimenTributario === null)
  }

  tiposCapitalListado() {
    this.ssps.tiposCapital()
      .subscribe((res: any) => {
        this.tiposCapital = res.filter((r: string) => this.facturacion.tipo !== 'NI' ? r === "PRIVADO" : r !== "");
      });
  }


  tiposRegimenTributario() {
    this.ssps.tiposRegimenTributario()
      .subscribe((res: any) => {
        this.regimenTributarioListado = res;
        this.regimenTributario = res
      });
  }

  filtrarTipoRegimen($event: any, limpiarRegimen: any) {
    let tipoCapital = $event.target.value;
    if (this.facturacion.tipo !== "NI") {
      this.regimenTributario = this.regimenTributarioListado.filter((r: string) => r === "No Responsable de IVA"
        || r === "Régimen simple de tributación PN")
    }
    else if (tipoCapital === "PUBLICO") {
      this.regimenTributario = this.regimenTributarioListado.filter((r: string) => r === "Entidad del Estado")
    }
    else {
      this.regimenTributario = this.regimenTributarioListado.filter((r: string) => r !== "Entidad del Estado" &&
        r !== "No Responsable de IVA" && r !== "Régimen simple de tributación PN")
    }
    if (limpiarRegimen) {
      const control = this.formaForm.get("regimenTributario");
      if (control) {
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  departamentos() {
    this.ssps.departamentos()
      .subscribe((res: any) => {
        this.muni = res.sort((a: any, b: any) => (a.Departamento < b.Departamento ? -1 : 1));
        this.depar = [...new Map(res.map((item: { [x: string]: any; }) => [item['Departamento'], item])).values()].sort((a: any, b: any) => (a.Departamento < b.Departamento ? -1 : 1));
        setTimeout(() => {
          let departamento = this.facturacion.departamento
          this.formaForm.get("departamento")!.setValue(this.facturacion.departamento, {
            emitEvent: true,
            emitModelToViewChange: true
          });
          this.munifi = this.muni.filter(function (obj: any, index) {
            return obj.codigo_dep == +departamento;
          }).sort((a, b) => (a.Municipio < b.Municipio ? -1 : 1))
        }, 1000);
      });
  }
  changeDepartamento($event: any) {
    let departamento = $event.target.value;
    this.munifi = this.muni.filter(function (obj: any, index) {
      return obj.codigo_dep == +departamento;
    }).sort((a, b) => (a.Municipio < b.Municipio ? -1 : 1))
    setTimeout(() => {
      this.facturacion.municipio = undefined
      this.formaForm.get("municipio")!.setValue(undefined, {
        emitEvent: true,
        emitModelToViewChange: true
      });
    }, 500);
  }
}
