import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { DatePipe } from '@angular/common'
import { ActivatedRoute } from '@angular/router';
import { LogService } from '../../../../domain/servicios/log.service'
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { urlRequest } from 'src/assets/config/app.conf';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-info-basica',
  templateUrl: './info-basica.component.html',
  styleUrls: ['./info-basica.component.css']
})
export class InfoBasicaComponent implements OnInit {

  @ViewChild('inputRefPrimerNombre')
  inputRefPrimerNombre!: { nativeElement: any; };

  @ViewChild('inputRefSegundoNombre')
  inputRefSegundoNombre!: { nativeElement: any; };

  @ViewChild('inputRefPrimerApellido')
  inputRefPrimerApellido!: { nativeElement: any; };

  @ViewChild('inputRefSegundoApellido')
  inputRefSegundoApellido!: { nativeElement: any; };
  @ViewChild('inputRefCorreo')
  inputRefCorreo!: { nativeElement: any; };
  @ViewChild('inputRefCorreo1')
  inputRefCorreo1!: { nativeElement: any; };
  @ViewChild('inputRefInstitucion')
  inputRefInstitucion!: { nativeElement: any; };
  @ViewChild('inputRefProfesional')
  inputRefProfesional!: { nativeElement: any; };
  @ViewChild('inputRefProfesion')
  inputRefProfesion!: { nativeElement: any; };

  @ViewChild('inputRefDireccion')
  inputRefDireccion!: { nativeElement: any; };
  @ViewChild('inputRefTipoNumeroDireccion')
  inputRefTipoNumeroDireccion!: { nativeElement: any; };
  @ViewChild('inputRefNumeroDireccion')
  inputRefNumeroDireccion!: { nativeElement: any; };
  @ViewChild('inputRefNumeroDireccion2')
  inputRefNumeroDireccion2!: { nativeElement: any; };
  @ViewChild('inputRefBarrioDireccion')
  inputRefBarrioDireccion!: { nativeElement: any; };
  @ViewChild('inputRefAdicionalDireccion')
  inputRefAdicionalDireccion!: { nativeElement: any; };
  @ViewChild('inputRefNumeroOtroDireccion')
  inputRefNumeroOtroDireccion!: { nativeElement: any; };

  @Output() infobasica: EventEmitter<boolean> = new EventEmitter();
  urlRequest = urlRequest
  formaForm!: FormGroup;
  cambiologo2: boolean = false;
  cambiologo: boolean = true;
  profesional: boolean = false;
  documentob: boolean = false;
  cantidad: number = 0;
  totalCantidad: number = 0;
  usuario: any = {
    documentos: [],
    ip: ''
  };
  pago: any = {};
  archivos: boolean = false;
  tipos: boolean = false;
  mayor: boolean = false;
  files: boolean = false;
  fechaExpTarjeta!: any;
  statusFechaExpTarjeta: boolean = false;
  fechaExpDocumento!: any;
  statusFechaExpDocumento: boolean = false;
  showDocumentsB: boolean = false;
  buttons: any = [] = [];
  validateformats: any[] = [];
  error: string = 'Formato no válido';
  queryRuta: any = null
  comastel: boolean = false;
  comastel2: boolean = false;
  comastex: boolean = false;
  comas: boolean = false;
  indicativos: any[] = [];
  comasnit: boolean = false;

  depar: any[] = [];
  muni: any[] = [];
  munifi: any[] = [];

  actualizacion = false

  maxDate: NgbDateStruct
  minDate: NgbDateStruct
  direccion: any = {}
  tipoDireccion: any[] = [];
  regimenTributario: any[] = []
  otraProfesion = false
  profesiones: any[] = []
  profesionLista = ""
  realizoPago = "N"
  preciosd: any[] = [];

  constructor(private fb: FormBuilder,
    private router: Router,
    private componentesService: ComponentesService,
    private ssps: SspsService,
    private changeDetector: ChangeDetectorRef,
    public datepipe: DatePipe, private route: ActivatedRoute,
    private logger: LogService,
    private alert: AlertsService,
    private el: ElementRef) {

    /*validacion de campos validators*/
    this.formaForm = this.fb.group({
      primerNombre: ['', [Validators.pattern("^[a-zA-ZÑ ]{3,30}$"),
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(30)
      ]
      ],
      segundoNombre: ['', [Validators.pattern("^[a-zA-ZÑ ]{3,30}$"),
      Validators.minLength(3),
      Validators.maxLength(30)
      ]
      ],
      primerApellido: ['', [Validators.required,
      Validators.minLength(3),
      Validators.maxLength(30),
      Validators.pattern('[a-zA-ZÑ ]{3,30}')]],
      segundoApellido: ['', [
        Validators.minLength(3),
        Validators.maxLength(30),
        Validators.pattern('[a-zA-ZÑ ]{3,30}')]],
      correo: ['', [Validators.required,
      Validators.maxLength(60),
      Validators.pattern('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$')]],
      correo1: ['', [Validators.required,
      Validators.minLength(8),
      Validators.maxLength(60),
      Validators.pattern('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$')]],
      documentos: this.fb.array([]),
      fechaExpTarjeta: [null, []],
      fechaExpDocumento: [null],
      tipo: [null],
      numero: [null],
      institucion: ['', [
        Validators.required,
        Validators.maxLength(60),
      ]],
      profesion: ['', [
        Validators.required,
        Validators.maxLength(60),
      ]],
      profesionLista: ['',
        Validators.required
      ],
      profesional: ['', [
        Validators.maxLength(20),
      ]],
      indicativo: [],
      telefono: ['', [
        Validators.maxLength(7),
        Validators.minLength(7)
      ]],
      extension: [0, [
        Validators.maxLength(5)
      ]],
      celular: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      departamento: ['', [Validators.required]],
      municipio: ['', [Validators.required]],
      tipoDireccion: ['', [
        Validators.required
      ]],
      tipoNumeroDireccion: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10)
      ]],
      numeroDireccion: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10)
      ]],
      numeroDireccion2: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10)
      ]],
      barrioDireccion: ['', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(200)
      ]],
      adicionalDireccion: ['', [
        Validators.maxLength(200)
      ]],
      numeroOtroDireccion: ['', [
        Validators.minLength(4),
        Validators.maxLength(200)
      ]],
      regimenTributario: ['', [Validators.required]]
    }, {
      //validators: this.componentesService.soniguales('correo', 'correo1')
    }
    );
    var actualFecha = new Date();
    this.maxDate = { year: actualFecha.getFullYear(), month: actualFecha.getMonth() + 1, day: actualFecha.getDate() }
    this.minDate = { year: actualFecha.getFullYear() - 100, month: actualFecha.getMonth() + 1, day: actualFecha.getDate() }
  }


  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.setOnChangesInputs()
    this.totalCantidad = 2;
    this.ssps.obtenerIpValor()
    this.tiposDireccion()
    this.cargarProfesiones()
    this.tiposRegimenTributario()
    this.indicativo();
    this.departamentos();
    this.precios()
    this.actualizacion = this.ssps.getLocalStorage("actualizacion") ?
      JSON.parse(this.ssps.getLocalStorage("actualizacion")!) : false
    //Validr los queryParams, para así ir a consultar los datos de la ordenPedido
    //Si el redirect != null, entonces redirige al path
    const querySolicitud = this.route.snapshot.queryParamMap.get('id');
    this.queryRuta = this.route.snapshot.queryParamMap.get('redirect');
    this.logger.log("querySolicitud", querySolicitud)
    this.logger.log("queryRuta", this.queryRuta)
    if (querySolicitud) {
      this.logger.log("Consultar datos de OP y mapear datos al localstorage", "TODO")
      this.consultarDatosOrdenPedido(querySolicitud)
    }
    else {
      this.logger.log("Sin query datos", "TODO")
      this.mapearDatos()
      this.componentesService.emitircambio("mostrarBarra");
    }
  }

  consultarDatosOrdenPedido(solicitudId: string) {
    this.alert.showSpiner("Estimado usuario, recuerda que para completar tu proceso, se realizará proceso de validación de identidad");
    this.componentesService.emitircambio("cargarDatosHeader");
    this.ssps.consultarOrdenPedido(solicitudId)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta consultarOrdenPedido", res)
          if (res !== null) {
            if (res.codigo && res.codigo !== 200) {
              this.alert.closesSpiner()
              let mensaje = res.mensaje ? res.mensaje : "No fue posible realizar la petición"
              this.alert.showError(mensaje, true, null, false, null, null)
            }
            else {
              this.ssps.saveLocalStorage("ordenPedidoId", JSON.stringify(res.ordenPedidoId));
              this.ssps.saveLocalStorage("solicitudId", JSON.stringify(solicitudId));
              this.ssps.saveLocalStorage("tipocliente", res.tipoPersona);
              this.ssps.saveLocalStorage("actualizacion", "true");
              this.actualizacion = true
              if (res.tipoPersona === "natural") {
                this.ssps.saveOrdenPedidoData(res)
                this.mapearDatos()
                this.componentesService.emitircambio("mostrarBarra");
                this.componentesService.emitircambio("cargarDatosHeader");

                if (this.queryRuta && this.queryRuta !== null) {
                  setTimeout(() => {
                    //this.alert.closesSpiner();
                    this.logger.log("fin load", "true")
                    if (this.queryRuta === "pago" || this.queryRuta === "cargar-pago") {
                      if(this.queryRuta === "cargar-pago"){
                        this.ssps.saveLocalStorage("cargarSoloPago", "true")
                      }
                      this.componentesService.emitircambio("pagosFull");
                      this.router.navigate(['/flujo/pago']);
                    }
                    else {
                      this.router.navigate([this.queryRuta]);
                    }
                  }, 500);
                }
                else {
                  this.router.navigate([], {
                    queryParams: {
                      'id': null
                    },
                    queryParamsHandling: 'merge'
                  })
                  this.validacionIdentidadFaltantes()
                }
              }
              else {
                this.ssps.saveOrdenPedidoDataPJ(res)
                this.mapearDatos()
                this.componentesService.emitircambio("mostrarBarra");
                this.componentesService.emitircambio("cargarDatosHeader");
                if (this.queryRuta && this.queryRuta !== null) {
                  setTimeout(() => {
                    //this.alert.closesSpiner();
                    this.logger.log("fin load", "true")
                    if (this.queryRuta === "pago" || this.queryRuta === "cargar-pago") {
                      if(this.queryRuta === "cargar-pago"){
                        this.ssps.saveLocalStorage("cargarSoloPago", "true")
                      }
                      this.componentesService.emitircambio("pagosFull");
                      this.router.navigate(['/flujo/pago']);
                    }
                    else {
                      this.router.navigate([this.queryRuta]);
                    }
                  }, 500);
                }
                else {
                  setTimeout(() => {
                    this.router.navigate(['/flujo/empresa'], {
                      queryParams: {
                        'id': null
                      },
                      queryParamsHandling: 'merge'
                    })
                    this.validacionIdentidadFaltantes()
                  }, 2500);
                }
              }
            }
          }
          else {
            this.alert.closesSpiner()
            let mensaje = "No fue posible realizar la petición"
            this.alert.showError(mensaje, true, null, false, null, null)
          }
        },
        error: (error: any) => {
          this.alert.closesSpiner()
          console.error('Error en subscriber consultarOrdenPedido', error)
          let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
            error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
              error.error.message ? error.error.message :
                (error.message ? error.message : "No fue posible realizar la petición")) :
            (error.message ? error.message : "No fue posible realizar la petición")
          this.alert.showError(mensaje, true, null, false, null, null)
        }
      });
  }

  validacionIdentidadFaltantes() {
    if (this.usuario.solicitudFinalizada !== undefined && this.usuario.solicitudFinalizada !== null &&
      this.usuario.solicitudFinalizada !== "" && this.usuario.solicitudFinalizada !== "PENDIENTE") {
      this.logger.log("Solicitud finalizada", "true")
      this.logger.log("Solicitud finalizada", this.usuario.solicitudFinalizada)
      this.alert.closesSpiner();
      this.alert.showSolicitudFinalizada(false)
    }
    else {
      //Mostrar alerta con los faltantes
      this.alert.closesSpiner();
      this.alert.showSolicitudFaltantes()
    }
  }

  setOnChangesInputs() {
    this.formaForm.get("primerNombre")!.valueChanges.subscribe(val => {
      if (this.inputRefPrimerNombre) {
        const elRef = this.inputRefPrimerNombre?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("primerNombre")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("segundoNombre")!.valueChanges.subscribe(val => {
      if (this.inputRefSegundoNombre) {
        const elRef = this.inputRefSegundoNombre?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("segundoNombre")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });


    this.formaForm.get("primerApellido")!.valueChanges.subscribe(val => {
      if (this.inputRefPrimerApellido) {
        const elRef = this.inputRefPrimerApellido?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("primerApellido")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });


    this.formaForm.get("segundoApellido")!.valueChanges.subscribe(val => {
      if (this.inputRefSegundoApellido) {
        const elRef = this.inputRefSegundoApellido?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("segundoApellido")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });


    this.formaForm.get("correo")!.valueChanges.subscribe(val => {
      if (this.inputRefCorreo) {
        const elRef = this.inputRefCorreo.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("correo")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });
    this.formaForm.get("correo1")!.valueChanges.subscribe(val => {
      if (this.inputRefCorreo1) {
        const elRef = this.inputRefCorreo1.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("correo1")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("institucion")!.valueChanges.subscribe(val => {
      if (this.inputRefInstitucion) {
        const elRef = this.inputRefInstitucion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("institucion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("profesional")!.valueChanges.subscribe(val => {
      if (this.inputRefProfesional) {
        const elRef = this.inputRefProfesional.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("profesional")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("profesion")!.valueChanges.subscribe(val => {
      if (this.inputRefProfesion) {
        const elRef = this.inputRefProfesion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("profesion")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("tipoNumeroDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefTipoNumeroDireccion) {
        const elRef = this.inputRefTipoNumeroDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("tipoNumeroDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numeroDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefNumeroDireccion) {
        const elRef = this.inputRefNumeroDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numeroDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numeroDireccion2")!.valueChanges.subscribe(val => {
      if (this.inputRefNumeroDireccion2) {
        const elRef = this.inputRefNumeroDireccion2.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numeroDireccion2")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("barrioDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefBarrioDireccion) {
        const elRef = this.inputRefBarrioDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("barrioDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("adicionalDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefAdicionalDireccion) {
        const elRef = this.inputRefAdicionalDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("adicionalDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numeroOtroDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefNumeroOtroDireccion) {
        const elRef = this.inputRefNumeroOtroDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numeroOtroDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get('tipo')?.disable();
    this.formaForm.get('numero')?.disable();
  }

  //TODO:borrar validators
  clearValidators() {
    const controlesAEliminar = ['fechaExpTarjeta', 'institucion', 'profesional', 'profesion', 'profesionLista'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    }
  }

  regresarResumen() {
    //this.alert.showSssps();
    this.ssps.saveLocalStorage("regresaFormulario", "true")
    this.router.navigate(['/']);
  }

  //TODO:creacion de forms arrays
  get documentsDocuments() {
    return this.formaForm.get('documentos') as FormArray;
  }

  //TODO:crear botones
  crearButtons() {
    const estilos = {
      fondo: '#ff4d4d', colores: '#fff', title: 'Seleccionar archivo', subtitle: 'El formato de documento permitido para cargar es .PDF'
    }
    if (this.documentsDocuments.controls.length > 0) {
      for (let i = 0; i < this.documentsDocuments.controls.length; i++) {
        this.buttons[i] = estilos;
        this.validateformats[i] = false;
      }
    }
  }

  //TODO agrega controls con valor requerido
  addControlDocument() {
    this.documentsDocuments.push(this.fb.control(null));
  }


  /* funcion para validacion de campos*/
  camposvalidos(campo: any) {
    return this.formaForm.controls[campo].errors && this.formaForm.controls[campo].touched;
  }


  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  /*ingreso y validacion de datos de formulario enviuo de los mismos localstorage*/
  ngsubmit() {
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          this.logger.log("error", key)
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          return;
        }
      });
      return;
    }

    if (this.realizoPago !== "S") {
      this.validarRegimenPrecios()
    }

    let { primerNombre, segundoNombre, primerApellido, segundoApellido, correo1, correo, profesional, institucion, fechaExpTarjeta,
      profesion, fechaExpDocumento, celular, telefono, extension, indicativo,
      departamento, municipio, tipoDireccion, tipoNumeroDireccion, numeroDireccion, numeroDireccion2,
      barrioDireccion, adicionalDireccion, numeroOtroDireccion, regimenTributario, profesionLista } = this.formaForm.getRawValue();

    let objetoDireccion = {
      tipo: tipoDireccion,
      tipoNumero: tipoNumeroDireccion,
      numero: numeroDireccion,
      numero2: numeroDireccion2,
      barrio: barrioDireccion,
      adicional: adicionalDireccion ?? "",
      tipoOtroNumero: numeroOtroDireccion
    }

    let datos = {
      primerNombre,
      segundoNombre,
      primerApellido,
      segundoApellido,
      correo: correo,
      correo1: correo1,
      documentos: this.buttons.filter((f: { file: any; }) => f.file),
      tarjetaProfesional: profesional,
      institucionGrado: institucion,
      fechaExpTarjetaProfesional: fechaExpTarjeta,
      fechaExpDocumento,
      profesion: this.otraProfesion ? profesion : profesionLista,
      ip: this.ssps.ip,
      telefono,
      extension,
      indicativo,
      celular,
      departamento,
      municipio,
      regimenTributario,
      objetoDireccion: objetoDireccion,
      direccion: (objetoDireccion.tipo !== "Otro" ? (objetoDireccion.tipo + " " + objetoDireccion.tipoNumero + " #" + objetoDireccion.numero + "-" +
        objetoDireccion.numero2) : objetoDireccion.tipoOtroNumero) +
        " BARRIO " + objetoDireccion.barrio + (objetoDireccion.adicional !== "" ? " ADICIONAL " + objetoDireccion.adicional : "")
    }

    Object.assign(this.usuario, datos);
    this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
    if (this.ssps.getLocalStorage("solicitudId")) {
      this.componentesService.emitircambio("actualizarOrden");
    }
    if (this.usuario.cliente === "natural" && this.usuario.dispo.toUpperCase() !== "PKCS#10") {
      this.componentesService.emitircambio("contacto");
      this.router.navigate(['/flujo/entrega']);
    }
    else {
      this.componentesService.emitircambio("infoperso");
      this.router.navigate(['/flujo/contacto']);
    }
  }

  //TODO:validaciones de fechas
  fechas(dato: any, campo: any) {
    if (dato === undefined || dato === null) return;
    let valida = /^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/-](?:0?[1-9]|1[0-2])|(?:29|30)[/-](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/-](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/-]0?2[/-](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/;
    let errores: boolean = false;

    if (campo === "fechaExpTarjeta") {
      if (valida.test(dato) == false) {
        this.statusFechaExpTarjeta = true;
      } else {
        this.statusFechaExpTarjeta = false;

      }
    }
    if (campo === "fechaExpDocumento") {
      if (valida.test(dato) == false) {
        this.statusFechaExpDocumento = true;
      } else {
        this.statusFechaExpDocumento = false;
      }
    }

  }


  /* estado de botones logos cambiantes*/
  estado(valor: string) {
    if (this.cantidad == 0) {
      //   this.cambiologo2 = true;
      //  this.cambiologo = false;
      this.cantidad = this.cantidad + 1;
    }

  }

  //cambio de estilos animacion boton archivos
  validar() {

    return (this.cambiologo = true) ? ' animate__animated animate__fadeIn animate__fast' : 'animate__animated animate__fadeOut animate__fast';
  }

  //TODO:implementacion de creacion de buttos
  CreateDocument(documentName: string, id: String) {
    let indice = this.buttons.findIndex((f: { id: String; }) => f.id === id);
    this.logger.log("indicie", indice)
    if (indice > -1) {
      this.buttons = this.buttons.filter((f: { id: String; }) => f.id !== id)
      this.documentsDocuments.removeAt(indice)
    }
    else {
      const estilos = {
        fondo: '#ff4d4d', colores: '#fff', title: 'Seleccionar archivo',
        subtitle: 'El formato de documento permitido para cargar es .PDF',
        name: documentName,
        id: id
      }
      this.buttons.push(estilos)
      this.addControlDocument();
    }
  }

  ExisteDocumento(id: String) {
    return this.usuario.documentos.filter((f: { id: String; }) => f.id === id).length > 0
  }

  ExisteDocumentoCargado(id: String) {
    return this.usuario.documentosCargados && this.usuario.documentosCargados.filter((f: { id: String; }) => f.id === id).length > 0
  }

  //TODO:vision de documents
  documentDocs(event: any) {
    this.showDocumentsB = true;
  }


  /*validacion de salerta campos iguales*/
  get correo2NoValido() {
    const pass1 = this.formaForm.get('correo')!.value;
    const pass2 = this.formaForm.get('correo1')!.value;

    return (pass1 === pass2) ? false : true;
  }



  /*validar tamaño de archivo*/
  getFileDetails(file: any, index: number) {

    let fileToUpload = <File>file[0];

    const botonActualizado = { ...this.buttons[index] };

    let tamañoEnBytes = fileToUpload.size;
    let tamañoEnMegabytes = tamañoEnBytes / (1024 * 1024);

    if (fileToUpload.type == "application/pdf") {
      if (tamañoEnMegabytes > 10) {
        botonActualizado.fondo = '#ff4d4d';
        botonActualizado.colores = '#fff';
        botonActualizado.title = 'Seleccionar archivo';
        botonActualizado.subtitle = 'El formato de documento permitido para cargar es .PDF';
        this.buttons[index] = botonActualizado;
        this.validateformats[index] = true;
      }
      if (tamañoEnMegabytes < 10) {
        const reader = new FileReader()
        reader.onload = () => {
          const b64 = reader.result as string
          botonActualizado.file = b64
        }

        if (fileToUpload) {
          reader.readAsDataURL(fileToUpload)
        }

        botonActualizado.fondo = '#33f0b7';
        botonActualizado.colores = '#2d3d3d';
        botonActualizado.title = 'Documento cargado con exito';
        botonActualizado.subtitle = fileToUpload.name;

        this.buttons[index] = botonActualizado;
        this.validateformats[index] = false;
        this.cantidad += 1;
      }
    }
    else {
      botonActualizado.fondo = '#ff4d4d';
      botonActualizado.colores = '#fff';
      botonActualizado.title = 'Seleccionar archivo';
      botonActualizado.subtitle = 'El formato de documento permitido para cargar es .PDF';
      this.buttons[index] = botonActualizado;
      this.validateformats[index] = true;

    }

  }

  //TODO:implementacion de datos
  isValidFieldInArray(formArray: FormArray, index: number) {
    return formArray.controls[index].errors
      && formArray.controls[index].touched;
  }

  mapearDatos() {
    /*asignacion de valores desde el cervicio crm*/
    this.usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
    if (this.ssps.getLocalStorage('pago')) {
      this.pago = JSON.parse(this.ssps.getLocalStorage('pago')!)
      this.realizoPago = this.pago.realizoPago
    }
    this.formaForm.get("departamento")!.setValue(this.usuario.departamento, {
      emitEvent: true,
      emitModelToViewChange: true
    });
    //Mapear documentos del localStorage
    if (this.usuario.documentos) {
      this.buttons = this.usuario.documentos
      this.cantidad = this.usuario.documentos.length
      this.usuario.documentos.forEach((element: any, index: any) => {
        this.validateformats[index] = false;
        this.addControlDocument();
      });
    }
    else {
      this.usuario.documentos = []
    }
    if (this.usuario.politica == "Profesional Titulado") {
      this.totalCantidad = 3;
      this.profesional = true;
      this.logger.log("fecha ExpTarjeta", this.usuario.fechaExpTarjetaProfesional)
      if (this.usuario.fechaExpTarjetaProfesional && this.usuario.fechaExpTarjetaProfesional !== '' &&
        !this.usuario.fechaExpTarjetaProfesional.includes("/")) {
        this.fechaExpTarjeta = this.datepipe.transform(new Date(this.usuario.fechaExpTarjetaProfesional), "dd/MM/yyyy")!
      }
      else if (this.usuario.fechaExpTarjetaProfesional && this.usuario.fechaExpTarjetaProfesional.includes("/")) {
        this.fechaExpTarjeta = this.usuario.fechaExpTarjetaProfesional
      }
      //Format la fecha
    }
    if (this.usuario.fechaExpDocumento && this.usuario.fechaExpDocumento !== '' &&
      !this.usuario.fechaExpDocumento.includes("/")) {
      this.fechaExpDocumento = this.datepipe.transform(new Date(this.usuario.fechaExpDocumento), "dd/MM/yyyy")!
    }
    else if (this.usuario.fechaExpDocumento && this.usuario.fechaExpDocumento.includes("/")) {
      this.fechaExpDocumento = this.usuario.fechaExpDocumento
    }
    if (this.usuario.politica !== "Profesional Titulado") {
      this.clearValidators();
    }

    this.direccion = this.usuario.objetoDireccion ?? {}
    if (this.direccion.tipo === "Otro") {
      this.clearValidatorsDireccion()
    }
    this.profesionLista = this.usuario.profesion
    setTimeout(() => {
      var existeProfesion = this.profesiones.filter(s => s === this.usuario.profesion)
      this.deshabilitarCmaposFinalizados()
      if (existeProfesion.length === 0 && this.usuario.politica === "Profesional Titulado") {
        this.otraProfesion = true
        const profesionForm = this.formaForm.get("profesion");
        if (profesionForm) {
          this.profesionLista = "OTRA"
          profesionForm.patchValue("OTRA")
          profesionForm.updateValueAndValidity();
        }
        this.addValidatorsProfesion()
      }
      else {
        this.clearValidatorsProfesion()
      }
    }, 2000);

    if (this.usuario.municipio !== undefined && this.usuario.municipio !== null &&
      this.usuario.departamento !== null && this.usuario.departamento !== undefined
    ) {
      let departamento = this.usuario.departamento;
      this.munifi = this.muni.filter(function (obj: any, index) {
        return obj.codigo_dep == +departamento;
      }).sort((a, b) => (a.Municipio < b.Municipio ? -1 : 1))
      setTimeout(() => {
        this.formaForm.get("municipio")!.setValue(this.usuario.municipio, {
          emitEvent: true,
          emitModelToViewChange: true
        });
      }, 500);
    }
  }

  deshabilitarCmaposFinalizados() {
    Object.keys(this.formaForm.controls).forEach(key => {
      if (key !== "correo" && key !== "correo1" &&
        (this.usuario.solicitudFinalizada === "APROBADO" || this.usuario.solicitudFinalizada === "FINALIZADO")) {
        this.formaForm.controls[key]?.disable({ onlySelf: true })
      }
      else if (this.usuario.solicitudFinalizada === "ASIGNADO") {
        this.formaForm.controls[key]?.disable({ onlySelf: true })
      }
    });
  }

  noPuntotel(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comastel = res;
      })
  }


  noPuntote(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comastel2 = res;
      })
  }

  noPuntoex(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comastex = res;
      })
  }

  quitarceros2(numero: string) {

    this.usuario.telefono = numero.replace(/^0+/, '');

  }

  quitarceros3(numero: string) {

    this.usuario.extension = numero.replace(/^0+/, '');

  }

  quitarceros4(numero: string) {
    this.usuario.celular = numero.replace(/^0+/, '');
  }

  noPuntoComacod(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comas = res;
      })
  }
  /*carga datos select*/
  indicativo() {
    this.ssps.indicativos()
      .subscribe((res: any) => {
        this.indicativos = res;
      });
  }

  tiposRegimenTributario() {
    this.ssps.tiposRegimenTributario()
      .subscribe((res: any) => {
        this.regimenTributario = res.filter((r: string) => r === "No Responsable de IVA" ||
          r === "Régimen simple de tributación PN")
      });
  }

  cargarProfesiones() {
    this.ssps.cargarProfesiones()
      .subscribe((res: any) => {
        this.profesiones = res
      });
  }

  noPuntoComa(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comasnit = res;
      })
  }

  departamentos() {
    this.ssps.departamentos()
      .subscribe((res: any) => {
        this.muni = res.sort((a: any, b: any) => (a.Departamento < b.Departamento ? -1 : 1));
        this.depar = [...new Map(res.map((item: { [x: string]: any; }) => [item['Departamento'], item])).values()].sort((a: any, b: any) => (a.Departamento < b.Departamento ? -1 : 1));
        setTimeout(() => {
          let departamento = this.usuario.departamento
          this.formaForm.get("departamento")!.setValue(this.usuario.departamento, {
            emitEvent: true,
            emitModelToViewChange: true
          });
          this.munifi = this.muni.filter(function (obj: any, index) {
            return obj.codigo_dep == +departamento;
          }).sort((a, b) => (a.Municipio < b.Municipio ? -1 : 1))
        }, 1000);
      });
  }
  changeDepartamento($event: any) {
    let departamento = $event.target.value;
    this.munifi = this.muni.filter(function (obj: any, index) {
      return obj.codigo_dep == +departamento;
    }).sort((a, b) => (a.Municipio < b.Municipio ? -1 : 1))
    setTimeout(() => {
      this.usuario.municipio = undefined
      this.formaForm.get("municipio")!.setValue(undefined, {
        emitEvent: true,
        emitModelToViewChange: true
      });
    }, 500);
  }

  tiposDireccion() {
    this.ssps.tiposDireccion()
      .subscribe((res: any) => {
        this.tipoDireccion = res;
      });
  }

  changeTipoDireccion($event: any) {
    let tipoDireccion = $event.target.value;
    if (tipoDireccion === "Otro") {
      this.direccion = {
        tipo: tipoDireccion
      }
      this.clearValidatorsDireccion()
    }
    else {
      this.addValidatorsDireccion()
    }
  }

  clearValidatorsDireccion() {
    const controlesAEliminar = ['tipoNumeroDireccion', 'numeroDireccion',
      'numeroDireccion2', 'numeroOtroDireccion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        if (controlName === "numeroOtroDireccion") {
          control.addValidators([
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(200)])
        }
        else {
          control.clearValidators();
        }
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  clearValidatorsProfesion() {
    const controlesAEliminar = ['profesion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        control.clearValidators();
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  addValidatorsProfesion() {
    const controlesAEliminar = ['profesion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        control.addValidators([
          Validators.required,
          Validators.maxLength(60)
        ])
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  addValidatorsDireccion() {
    const controlesAEliminar = ['tipoNumeroDireccion', 'numeroDireccion',
      'numeroDireccion2', 'numeroOtroDireccion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        if (controlName === "numeroOtroDireccion") {
          control.clearValidators();
        }
        else {
          control.addValidators([
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(10)
          ])
        }
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  validarProfesion($event: any) {
    let profesionSeleccionada = $event.target.value;
    if (profesionSeleccionada === "OTRA") {
      this.addValidatorsProfesion()
      this.otraProfesion = true
      this.usuario.profesion = ""
    }
    else {
      this.clearValidatorsProfesion()
      this.otraProfesion = false
    }
  }

  precios() {
    this.ssps.precios()
      .subscribe((res: any) => {
        this.preciosd = res.precios;
      })
  }

  validarRegimenPrecios() {
    this.usuario.costoBase = this.pago.costoBase ?? (this.usuario.costoBase ?? 0)
    let departamento = this.depar.filter(d => +d.codigo_dep === +this.usuario.departamento)[0].Departamento
    let objetoDispositivo: any = this.preciosd.filter((res) => { return res.nombre.toLowerCase() == this.usuario.dispo.toLowerCase(); })[0]
    if (departamento === "ARCHIPIÉLAGO DE SAN ANDRÉS, PROVIDENCIA Y SANTA CATALINA" || departamento === "AMAZONAS") {
      let valorIca = 0;
      let valorIva = 0
      let valorReteIva = 0
      this.usuario.iva = 0
      this.usuario.ica = 0
      this.usuario.reteiva = 0
      this.pago.costoBase = Math.trunc(this.usuario.costoBase)
      this.pago.ica = Math.trunc(this.usuario.ica)
      this.pago.icaValor = Math.trunc(valorIca)
      this.pago.iva = Math.trunc(this.usuario.iva)
      this.pago.ivaValor = Math.trunc(valorIva)
      this.pago.reteIvaValor = Math.trunc(valorReteIva)
      this.pago.reteiva = Math.trunc(this.usuario.reteiva)
      this.pago.descuentoCupon = Math.trunc(this.pago.descuentoCupon ?? 0)
      this.usuario.costoBase = Math.trunc(this.usuario.costoBase - (this.pago.descuentoCupon ?? 0))
      this.usuario.descuentoCupon = Math.trunc(this.usuario.descuentoCupon ?? 0)
      this.usuario.descuentos = 0
      this.usuario.costo = Math.trunc(this.usuario.costoBase + valorIva - valorIca - valorReteIva)
      this.pago.valorTotal = Math.trunc(this.usuario.costo)
      this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
      this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
      this.componentesService.emitircambio("cargarDatosHeader");
    }
    else {
      let valorIca = 0;
      let valorIva: any = ((this.usuario.costoBase - (this.pago.descuentoCupon ?? 0)) * (objetoDispositivo.iva / 100))
      let valorReteIva = 0
      this.usuario.iva = Math.trunc(objetoDispositivo.iva)
      this.usuario.ica = 0
      this.usuario.reteiva = 0
      this.pago.costoBase = Math.trunc(this.usuario.costoBase)
      this.pago.ica = Math.trunc(this.usuario.ica)
      this.pago.icaValor = Math.trunc(valorIca)
      this.pago.iva = Math.trunc(this.usuario.iva)
      this.pago.ivaValor = Math.trunc(valorIva)
      this.pago.reteIvaValor = Math.trunc(valorReteIva)
      this.usuario.costoBase = Math.trunc(this.usuario.costoBase - (this.pago.descuentoCupon ?? 0))
      this.pago.reteiva = Math.trunc(this.usuario.reteiva)
      this.pago.descuentoCupon = Math.trunc(this.pago.descuentoCupon ?? 0)
      this.usuario.costo = Math.trunc(this.usuario.costoBase + valorIva - valorIca - valorReteIva)
      this.usuario.descuentoCupon = Math.trunc(this.usuario.descuentoCupon ?? 0)
      this.pago.valorTotal = Math.trunc(this.usuario.costo)
      this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
      this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
      this.componentesService.emitircambio("cargarDatosHeader");
    }
  }

}

