import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css'],
    standalone: false
})
export class LoadingComponent implements OnInit, OnDestroy {
  @Input() public mensaje: any
  constructor(private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show()
  }

  ngOnDestroy(): void{
    this.spinner.hide()
  }

}
