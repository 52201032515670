import { Component, Input, OnInit } from '@angular/core';
import { SuccessResponse } from 'src/app/domain/interfaces/response-base.interface';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { PreInvoiceService } from 'src/app/domain/servicios/pre-invoice.service';
import { environment as env } from "src/environments/environment"

@Component({
  selector: 'app-aceptar-prefactura',
  templateUrl: './aceptar-prefactura.component.html',
  styleUrls: ['./aceptar-prefactura.component.css']
})
export class AceptarPrefacturaComponent implements OnInit {

  @Input() loadData: any;
  title:string = '';
  content:string = '';
  data:any;
  rechazar: boolean = false;
  preInvoice: any;

  constructor(private alert: AlertsService,
              private preInvoiceService : PreInvoiceService ) { }

  ngOnInit(): void {
    this.title = this.loadData.title;
    this.content = this.loadData.content;
    this.rechazar = this.loadData.mode === 'accept';
  }

  validateLink(){
    this.preInvoiceService.validarLinkPrefactura(this.preInvoice);
  }

  acceptPreInvoice(){

    if(this.loadData.mode === 'confirm'){
      let externalUrl = `${env.urlWebCerti}`;
      window.location.replace(externalUrl);
      this.closeModal();
    }

    if(this.loadData.mode === 'accept'){

      this.data = {
        mode: 'confirm',
        title: 'Confirmación de Aceptación',
        content: `¡Gracias por su confirmación! 
        <br>Hemos registrado su aceptación de la prefactura enviada. 
        <br>Procederemos con la generación de la factura correspondiente, la cual se enviará por el mismo mecanismo
        <br>Gracias por confiar y preferirnos.`
      };

      this.alert.showAceptarPrefactura(null, this.data);
    }

    console.log(this.preInvoice);

    this.preInvoiceService.aceptarPrefactura({idPrefactura:this.preInvoice.idPrefactura})
    .subscribe({
      next: (res: SuccessResponse) => {
          //this.loading = false;
          //this.alert.closeLoading();
          //this.message = 'Archivo cargado exitosamente';
          //this.alert.ShowToats(res.message,'success','bottom-right');         
      },
      error:(error) => {
        //this.loading = false;
        //this.alert.closeLoading(),
        //this.message = 'Error al cargar el archivo';
        console.log('error subscriber de setRecord');
        console.log(error);
        //this.alert. ShowToats('Error insertando o actualizando : ' + error?.error?.message, 'error', 'bottom-right')
      }
    });

  }

  declinePreInvoice(){
    this.alert.showRechazarPrefactura(this.preInvoice);
  }

  closeModal(){
    this.alert.closeAceptarPrefactura();
  }
}
