import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { urlRequest, tokenSolicitud } from 'src/assets/config/app.conf';
import { map, mergeMap } from 'rxjs';
import { LogService } from '../../domain/servicios/log.service'
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SspsAdapterService {
  constructor(private http: HttpClient, private logger: LogService) { }
  urlRequest = urlRequest

  historialExt(tipoDocumento: string, numeroDocumento: string, nitEmpresa: string, tipoPersona: string) {
    let data = {
      tipoDocumento: tipoDocumento,
      numeroDocumento: numeroDocumento.trim(),
      nitEmpresa,
      tipoPersona
    }

    this.logger.log("historialExt", data);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.HISTORICO, data, httpOptions);
  }

  token() {
    let body = new URLSearchParams();
    body.set('grant_type', tokenSolicitud.grant_type);
    body.set('client_id', tokenSolicitud.client_id);
    body.set('scope', tokenSolicitud.scope);
    body.set('client_secret', tokenSolicitud.client_secret);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };
    return this.http.post(this.urlRequest.TOKEN, body.toString(), httpOptions);
  }

  //TODO:valida si es persona natural esta reportado
  reportadosEx(primerNombre: string, segundoNombre: string, primerApellido: string, segundoApellido: string, documento: string, tipo: string) {
    let data = {
      nombre: primerNombre.trim() + (segundoNombre !== undefined && segundoNombre !== null && segundoNombre !== "" ? " " + segundoNombre : "").trim(),
      apellido: primerApellido.trim() + (segundoApellido !== undefined && segundoApellido !== null && segundoApellido !== "" ? " " + segundoApellido : "").trim(),
      tipoDocumento: tipo,
      numDocumento: documento.trim(),
    }

    this.logger.log("reportadosEx", data);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    //return this.http.post(this.urlRequest.LISTPN, data, httpOptions);
    return new Observable(obs => obs.next({
      result: false
    }));
  }

  //TODO:valida si la perona juridica esta reportada
  ExtReportPJ(nit: string, codigo: string, razon: string) {
    this.logger.log("ExtReportPJ", nit + "-" + codigo + "-" + razon);
    const validation = {
      nit: nit.trim(),
      digitoVerificador: codigo,
      razonSocial: razon.trim()
    }
    this.logger.log("ExtReportPJ", validation);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    //return this.http.post(this.urlRequest.LISTPJ, validation, httpOptions);
    return new Observable(obs => obs.next({
      result: false
    }));
  }

  //TODO:Aeoptacion persona natural
  ExtAsepN(primerNombre: string, segundoNombre: string, primerApellido: string, segundoApellido: string, tipo: string, numero: string, terminost: boolean, terminosp: boolean, terminostpro: boolean) {
    const validation = {
      "nombre": primerNombre.trim() + (segundoNombre !== undefined && segundoNombre !== null && segundoNombre !== "" ? " " + segundoNombre : "").trim(),
      "apellido": primerApellido.trim() + (segundoApellido !== undefined && segundoApellido !== null && segundoApellido !== "" ? " " +  segundoApellido : "").trim(),
      "tipoDocumento": tipo,
      "numDocumento": numero.trim(),
      "aceptaTerminosCondiciones": terminosp,
      "versionATC": "2.0.8",
      "polTratamientoDatos": terminost,
      "versionPTD": "2.0.8",
      "anexoSagrilaf": terminostpro,
      "versionAnexoSagrilaf": "2.0.8"
    }
    this.logger.log("ExtAsepN", validation)
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.ASEPTPN, validation, httpOptions);
  }

  //TODO:Aseptacion persona juridica
  ExtAsepJ(numero: string, codigo: string, razon: string, terminosp: boolean, terminost: boolean, terminostpro: boolean) {
    const validation = {
      "nit": numero.trim(),
      "digitoVerificador": codigo,
      "razonSocial": razon.trim(),
      "aceptaTerminosCondiciones": terminosp,
      "versionATC": "2.0.8",
      "polTratamientoDatos": terminost,
      "versionPTD": "2.0.8",
      "anexoSagrilaf": terminostpro,
      "versionAnexoSagrilaf": "2.0.8"
    }
    this.logger.log("ExtAsepJ", validation);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.ASEPTPJ, validation, httpOptions);
  }

  //TODO:Aseptacion persona juridica
  preguntasValidacion(nombres: string, apellidos: string, tipo: string, numero: string, fechaExpedicion: string,
    municipio: string, departamento: string, ip: string, solicitudTitularId: number) {
    const validation = {
      "solicitudTitularId": solicitudTitularId,
      "tipoDocumento": tipo,
      "numeroDocumento": numero.trim(),
      "nombres": nombres.trim(),
      "apellidos": apellidos.trim(),
      "codigoDepartamento": departamento,
      "codigoMunicipio": municipio,
      "fechaExpedicionDocumento": fechaExpedicion,
      "ip": ip
    }
    this.logger.log("preguntasValidacion", validation);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.VALIDACIONPREGUNTAS, validation, httpOptions);
  }

  consultarIntentosValidacion(data: any) {
    this.logger.log("consultarIntentosValidacion", data);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.INTENTOSVALIDACION, data, httpOptions);
  }

  guardarIntento(data: any) {
    this.logger.log("guardarIntento", data);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.GUARDARINTENTOVALIDACION, data, httpOptions);
  }

  consultarEstadoEBiometrics(id: any) {
    this.logger.log("consultarEstadoEBiometrics", id);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Ebm-Token': environment.accountValidacionFacial }) };
    return this.http.get(this.urlRequest.URLEBIOMETRICS + id, httpOptions);
  }

  consultarDatosSolicitanteRevocacion(data: any) {
    this.logger.log("consultarDatosSolicitanteRevocacion", data);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.CONSULTARSOLICITANTEREVOCACION, data, httpOptions);
  }

  consultarFactura(data: any) {
    this.logger.log("consultarFactura", data);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.CONSULTARFACTURA, data, httpOptions);
  }

  consultarSolicitudesEstadoTramite(data: any) {
    this.logger.log("consultarSolicitudesEstadoTramite", data);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.CONSULTARSOLICITUDESESTADOTRAMITE, data, httpOptions);
  }

  respuestasValidacion(body: any) {
    this.logger.log("respuestasValidacion", body);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.VALIDACIONRESPUESTAS, body, httpOptions);
  }

  echoTest(url: string) {
    this.logger.log("echoTest", url);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(url, httpOptions);
  }

  consultarCertificadosRevocacion(identificacion: any) {
    this.logger.log("consultarCertificadosRevocacion", identificacion);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.CERTIFICADOSREVOCACION, identificacion, httpOptions);
  }

  detalleCertificadoRevocacion(username: any) {
    this.logger.log("detalleCertificadoRevocacion", username);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.DETALLECERTIFICADOREVOCACION, username, httpOptions);
  }

  revocarCertificado(body: any) {
    this.logger.log("revocarCertificado", body);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.REVOCARCERTIFICADO, body, httpOptions);
  }

  consultarMotivosRechazo() {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(this.urlRequest.MOTIVOSRECHAZOREVOCACION, httpOptions);
  }

  formularioContacto(request: any) {
    this.logger.log("formularioContacto", request);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.FORMUARIOCONTACTO, request, httpOptions);
  }

  obtenerCertificado(request: any) {
    this.logger.log("obtenerCertificado", request);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.OBTENERCERTIFICADO, request, httpOptions);
  }

  unirLlaves(request: any) {
    this.logger.log("unirLlaves", request);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.UNIRLLAVES, request, httpOptions);
  }

  obtenerIp() {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), mode: 'cors' };
    return this.http.get(this.urlRequest.OBTENERIP, httpOptions);
  }

  crearOrdenPedidoPN(body: any) {
    this.logger.log("crearOrdenPedidoPN", body);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.CREARORDENPN, body, httpOptions);
  }

  consultarUltimaOrdenPedido(identificacion: any) {
    this.logger.log("consultarUltimaOrdenPedido", identificacion);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.URLCONSULTARULTIMAORDEN, identificacion, httpOptions);
  }

  crearOrdenPedidoPJ(body: any) {
    this.logger.log("crearOrdenPedidoPJ", body);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.CREARORDENPJ, body, httpOptions);
  }

  generarRequestJuridico(body: any) {
    this.logger.log("generarRequestJuridicoo", body);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.GENERARREQUESTJURIDICO, body, httpOptions);
  }

  generarRequestLlave(body: any) {
    this.logger.log("generarRequestLlave", body);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.GENERARREQUESTLLAVE, body, httpOptions);
  }

  solicitarOTP(body: any) {
    this.logger.log("solicitarOTP", body);
    if (isDevMode()) {
      this.logger.log("solicitarOTP devMode", isDevMode());
      return new Observable(obs => obs.next({
        "OTPSha256": "03ac674216f3e15c761ee1a5e255f067953623c8b388b4459e13f978d7c846f4",
        "statusCode": 200
      }));
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-sckey-token': `${environment.tokenOtp}`
      })
    };
    return this.http.post(this.urlRequest.OTPREQUEST, body, httpOptions);
  }

  actualizarOrdenPedidoPN(solicitudId: any, body: any) {
    body.solicitudId = solicitudId
    this.logger.log("actualizarOrdenPedidoPN", body);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put(this.urlRequest.ACTUALIZARORDENPN, body, httpOptions);
  }

  actualizarOrdenPedidoPJ(ordenPedidoId: any, body: any) {
    body.solicitudId = ordenPedidoId
    this.logger.log("actualizarOrdenPedidoPJ", body);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put(this.urlRequest.ACTUALIZARORDENPJ, body, httpOptions);
  }

  consultarOrdenPedido(ordenPedidoId: any) {
    this.logger.log("consultarOrdenPedido", ordenPedidoId);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.URLCONSULTARORDEN, ordenPedidoId, httpOptions);
  }

  consultarUrlPSE(body: any) {
    this.logger.log("consultarUrlPSE", body);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.URLPSE, body, httpOptions);
  }

  consultarEstadoPagoPSE(body: any) {
    this.logger.log("consultarEstadoPagoPSE", body);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.URLESTADOPSE, body, httpOptions);
  }

  descargarOrdenPago(solicitudId: any) {
    this.logger.log("descargarOrdenPago", solicitudId);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.DESCARGARORDENPAGO, solicitudId, httpOptions);
  }

  cargarComprobantePago(solicitudId: any, body: any) {
    body.solicitudId = solicitudId
    this.logger.log("cargarComprobantePago", body);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.URLCARGARORDENPAGO, body, httpOptions);
  }

  aplicarCodigoDescuento(body: any) {
    this.logger.log("aplicarCodigoDescuento", body);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.URLAPLICARCODIGODESCUENTO, body, httpOptions);
  }

  validarCodigoDescuento(body: any) {
    this.logger.log("validarCodigoDescuento", body);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.URLVALIDARCODIGODESCUENTO, body, httpOptions);
  }

  eliminarCodigoDescuento(ordenPedidoId: any, cupon: any) {
    let body = {
      ordenPedidoId,
      cupon
    }
    this.logger.log("eliminarCodigoDescuento", ordenPedidoId + "-" + cupon);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.urlRequest.URLELIMINARCODIGODESCUENTO, body, httpOptions);
  }

  extCapcha(items: string) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const token = { response: items }
    return this.http.post(this.urlRequest.CAPCHA, token, httpOptions)

    // return new Observable(obs => obs.next({
    //   code: 200
    // }));
  }

  politicaExt() {
    return this.http.get("../../../assets/json/politicas.json");
  }

  tiposDireccionExt() {
    return this.http.get("../../../assets/json/tiposDireccion.json");
  }

  tiposRegimenTributarioExt() {
    return this.http.get("../../../assets/json/tiposRegimenTributario.json");
  }

  cargarProfesionesExt() {
    return this.http.get("../../../assets/json/profesiones.json");
  }

  tiposCapitalExt() {
    return this.http.get("../../../assets/json/tiposCapital.json");
  }

  indicativosExt() {
    return this.http.get("../../../assets/json/indicativos.json");
  }

  preciosEx() {
    //return this.http.get(`${ this.url}/precios`);
    return this.http.get("../../../assets/json/precios.json");
  }
  usosEx() {
    //return this.http.get(`${ this.url}/precios`);
    return this.http.get("../../../assets/json/usos.json");
  }

  preciosReposicionEx() {
    //return this.http.get(`${ this.url}/precios`);
    return this.http.get("../../../assets/json/preciosReposicion.json");
  }

  departamentosEx() {
    return this.http.get("../../../assets/json/Tablas_Parametricas.json");
  }

  parametros() {
    return this.http.get("../../../assets/json/parametros.json");
  }

  //TODO:ver preguntas
  EXshowTask(person: string) {
    return this.http.get('../../../assets/json/preguntas.json')
      .pipe(
        map((res: any) => res.preguntas),
        mergeMap((preguntas: any[]) => preguntas.map((ele: any) => ele[person]))
      )
  }
}

