import { JsonpInterceptor } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { environment as env } from "src/environments/environment"

@Component({
  selector: 'app-descarga-certificado-modal',
  templateUrl: './descarga-certificado-modal.component.html',
  styleUrls: ['./descarga-certificado-modal.component.css']
})
export class DescargaCertificadoModalComponent implements OnInit {
  mensaje: any = ""
  @Input() public redirigir: any
  generarpfx = false
  titulo = "¡Proceso exitoso!"
  certificado: any = {}
  botonCerrar = false
  constructor(private alert: AlertsService,
    private router: Router,
    private componets: ComponentesService,
    private ssps: SspsService) { }

  ngOnInit(): void {
    if (this.ssps.getLocalStorage("certificado")) {
      this.certificado = JSON.parse(this.ssps.getLocalStorage('certificado')!)
      this.mensaje = this.certificado.mensaje
    }
    if (this.ssps.getLocalStorage("redirigirError")) {
      this.redirigir = JSON.parse(this.ssps.getLocalStorage("redirigirError")!)
    }
  }

  //TODO:cerrar modal
  close() {
    this.alert.closesshowReporter();
    localStorage.clear()
    if (this.redirigir) {
      let externalUrl = `${env.urlWebCerti}`;
      window.location.replace(externalUrl);
    }
  }

  descargarArchivo() {
    const linkSource =
      (this.certificado.tipo === "PFX" ? 'data:application/x-pkcs12;base64,' : 
      'data:application/pkix-cert;base64,'
      ) +
      this.certificado.certificadoB64;
    const linkDescarga = document.createElement('a');
    const archivo = this.certificado.nombreArchivo;

    linkDescarga.href = linkSource;
    linkDescarga.download = archivo;
    linkDescarga.click();
    linkDescarga.remove()
    this.generarpfx = this.certificado.tipo !== "PFX"
    this.botonCerrar = this.certificado.tipo === "PFX"
    this.redirigir = this.certificado.tipo === "PFX"
  }

  generarPfxModal() {
    this.alert.closesshowReporter();
    this.router.navigate(['descarga-pfx']);
  }
}
