<div class=" animate__animated animate__fadeInLeftBig animate__faster">
    <div class="container">
    <h5 class="mt-3 titulosintu titulos2">Selecciona un medio de pago</h5>
    <br>
    <div class="row contiene3 compras" (click)="tengoCodigoDescuento($event)" *ngIf="!cargarSoloPago">
        <div class="col-1 d-none d-sm-block"><img class="pago-img" src="../../../../assets/img/pago_cupon.png" /></div>
        <div class="col-lg-7 col-md-5 col-xs-12 divContainer">
            <label class="labelContainer">
                <h5 class="ms-4" style="cursor: pointer;">Tengo un cupón de descuento</h5>
            </label>
        </div>
        <div class="col-lg-7 col-md-5 col-xs-12" style="margin: 0 auto;" *ngIf="codigoDescuento">
            <div class="d-flex align-items-center">
                <input type="text" [(ngModel)]="pago.cuponAplicado" class="form-control" minlength="8"
                    style="width: 70%; text-transform: uppercase;" placeholder="Cupón de descuento"
                    name="codigo" #codigo required [class.is-invalid]="validacionCupon === true"
                    [class.is-valid]="pago.cuponAplicadoExitosamente" [disabled]="pago.cuponAplicadoExitosamente">
                <button *ngIf="!pago.cuponAplicadoExitosamente" class="btn btn-primary ms-2"
                    style="font-size: 12pt; background-color: #e86a05 !important;"
                    (click)="buscarcodigo(codigo.value)">
                    Aplicar
                </button>
        
                <i *ngIf="pago.cuponAplicadoExitosamente" (click)="eliminarCodigo()"
                    class="fa fa-trash titulosintu2 fa-2x ms-3" style="cursor: pointer;"></i>
            </div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast text-start mt-2"
                *ngIf="validacionCupon == true">
                Cupón de descuento no válido
            </div>
        </div>

    </div>

    <div class="row contiene3 compras" style="cursor: pointer;" (click)="pagoVirtual()" *ngIf="!cargarSoloPago">
        <div class="col-1 d-none d-sm-block"><img class="pago-img" src="../../../../assets/img/pago_pse.png" /></div>
        <div class="col-lg-7 col-md-5 col-xs-12 divContainer">
            <label class="labelContainer">
                <h5 style="cursor: pointer;">PSE, tarjeta débito, tarjeta crédito, billeteras digitales</h5>
            </label>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12 divContainer">
            <h5 class="margendere ms-4 col-lg-2 col-md-1 col-sm-1 col-sm-1 col-xs-12 labelContainer"><strong>{{pago.valorTotal |
                    currency:'COP': 'symbol' : '1.0-0'}}</strong></h5>
            <div class="col-lg-10 col-md-10  col-sm-10 col-xs-25" *ngIf="pago.estado !== 'SIN_PAGO'">
                <label class="ms-4">
                    <h6>{{pago.estado}}</h6>
                </label>
            </div>
        </div>
    </div>

    <div class="row contiene3 compras" style="cursor: pointer;" (click)="fisicos()">
        <div class="col-1 d-none d-sm-block"><img class="pago-img" src="../../../../assets/img/pago_efectivo.png" /></div>
        <div class="col-lg-7 col-md-5 col-xs-12 divContainer">
            <label class="labelContainer">
                <h5 style="cursor: pointer;">Medios de pagos físicos</h5>
            </label>
        </div>
        <div class="col divContainer">
            <h5 class="margendere ms-4 col-lg-2 col-md-1 col-sm-1 col-sm-1 col-xs-12 labelContainer"><strong>{{pago.valorTotal |
                    currency:'COP': 'symbol' : '1.0-0'}}</strong></h5>
        </div>
        <div class="col-xs-12 d-flex justify-content-center align-items-center mt-3 mb-3" *ngIf="descarga">
            <button class="btn btn-primary" 
                style="font-size: 12pt; background-color: #e86a05 !important; color: white;" 
                (click)="descargarPagoFisico()">
                Descargar PDF de pago
            </button>
        </div>        
        <div class="mb-3" *ngIf="documentoCargado" style="margin: 10pt; font-size: 15pt;">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="pago" [checked]="ExisteDocumentoCargado('pago')"
                    value="true" (change)="habilitarDocumentoPagoCargue()">
                <label class="form-check-label">
                     Comprobante de pago adjunto
                </label>
            </div>
        </div>
        <form [formGroup]="formaForm" (ngSubmit)="cargarPago()" *ngIf="carga && !documentoCargado">
            <div formArrayName="documentos">
                <div *ngFor="let favorite of documentsDocuments.controls; let i = index">

                    <div class="animate__animated animate__fadeIn animate__fast ms-5">
                        <h5 class="form-check-label">
                            Cargar soporte de pago
                        </h5>
                        <div class="d-flex justify-content-center">
                            <div id="div_file" class="text-center">
                                <div [class]="validar()" *ngIf="cambiologo">
                                    <span class="fa-stack fa-1x">
                                        <i class="fa-solid fa-circle fa-stack-2x"
                                            [ngStyle]="{'color':buttons[i].fondo }"></i>
                                        <i class="fa-solid fa-upload fa-stack-1x fa-inverse"
                                            [ngStyle]="{'color':buttons[i].colores }"></i>
                                    </span>

                                    <p id="texto">{{buttons[i].title}}
                                        <br>
                                        <small>{{buttons[i].subtitle}}</small>
                                    </p>
                                </div>
                                <input type="file" class="enviar" #enviar [formControlName]="i"
                                    (change)="getFileDetails(enviar.files,i)" accept="{{buttons[i].accept}}">
                            </div>
                        </div>
                    </div>
                    <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                        *ngIf="validateformats[i] || isValidFieldInArray(documentsDocuments,i)"
                        style="text-align: center;">
                        {{error}}</div>
                </div>
            </div>
            <div class="mb-4" style="text-align: center;">
                <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
        </form>
    </div>
    <button type="submit" class="btn btn-secondary" (click)="redireciona()" *ngIf="!cargarSoloPago">anterior</button>
    </div>
</div>