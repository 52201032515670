import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { LogService } from '../../../domain/servicios/log.service'
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { DatePipe } from '@angular/common'
import { Router } from '@angular/router';
import { environment as env } from "src/environments/environment"

@Component({
    selector: 'app-flujocomprassps',
    templateUrl: './flujocomprassps.component.html',
    styleUrls: ['./flujocomprassps.component.css'],
    standalone: false
})
export class FlujocomprasspsComponent implements OnInit {

  @ViewChild('margen', { read: ElementRef, static: false }) margen!: ElementRef;
  @ViewChild('basica', { read: ElementRef, static: false }) basica!: ElementRef;
  @ViewChild('contacto', { read: ElementRef, static: false }) contacto!: ElementRef;
  @ViewChild('entregan', { read: ElementRef, static: false }) entregan!: ElementRef;
  @ViewChild('facturacionn', { read: ElementRef, static: false }) facturacionn!: ElementRef;
  @ViewChild('pago', { read: ElementRef, static: false }) pago!: ElementRef;
  @ViewChild('solicitante', { read: ElementRef, static: false }) solicitanteElement!: ElementRef;

  tamano!: number;
  compras: any = { dispo: "" };
  respuesta: string = "";
  filtra: boolean = false;
  nopedido: boolean = false;
  p10Mecanismo: boolean = false;
  nopedidoActualizar: boolean = false;
  mostrarOpcionesPago: boolean = true
  mostrarOpcionesValidacionIdentidad: boolean = false
  noradicadopedi: any = null;
  noradicadosolicitud: any = null;
  costos!: number;
  usuario: any
  solicitante: any
  facturacion: any
  contactoTecnico: any
  entrega: any
  mostrarBack = true
  mostrarBarra = false
  reintentos = 1
  actualizaOrden = false
  errorOrden = false

  constructor(private componentesService: ComponentesService,
    private ssps: SspsService, private logger: LogService,
    private alert: AlertsService, public datepipe: DatePipe,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.componentesService.eventos$.subscribe(res => {
      this.respuesta = res;
      this.logger.log("flujoCompra-service", res)
      if (this.ssps.getLocalStorage('pago')) {
        let pago = JSON.parse(this.ssps.getLocalStorage('pago')!)
        if (pago.pagoPSE === true || pago.realizoPago === "S" ||
          (pago.fechaPago !== "" && pago.fechaPago !== undefined && pago.fechaPago !== null)) {
          this.mostrarOpcionesPago = false
          if (this.ssps.getLocalStorage('usuario')) {
            let usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
            this.mostrarOpcionesValidacionIdentidad = !usuario.identidadValidada
          }
        }
      }
      if (this.respuesta == "mostrarordencambio" || this.respuesta == "actualizarOrden") {
        this.p10Mecanismo = false
        this.reintentos = 1
        this.alert.showSpiner("Ten en cuenta...Para terminar el proceso debes realizar la validación de  tu identidad.");
        this.actualizaOrden = this.respuesta === "actualizarOrden"
        this.mostrarBack = this.respuesta === "actualizarOrden"
        //Llamar servicio de CrearOrdenPedido
        //Validar que si es una retoma, no se debe crear, sino actualizar
        this.usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
        this.facturacion = JSON.parse(this.ssps.getLocalStorage('facturacion')!);
        this.entrega = JSON.parse(this.ssps.getLocalStorage('entrega')!);
        if (this.ssps.getLocalStorage('contacto')) {
          this.contactoTecnico = JSON.parse(this.ssps.getLocalStorage('contacto')!);
        }
        if (this.ssps.getLocalStorage('solicitante')) {
          this.solicitante = JSON.parse(this.ssps.getLocalStorage('solicitante')!);
        }

        if (this.usuario.solicitudFinalizada === "ASIGNADO") {
          this.alert.closesSpiner();
          if (this.respuesta == "mostrarordencambio") {
            this.logger.log("Solicitud ASIGNADO", "true")
            this.alert.showSolicitudFinalizada(true)
          }
        }
        else if (!this.ssps.getLocalStorage("solicitudId")) {
          //Validar el tipo de persona
          this.crearOrdenPedido()
        }
        else {
          //Actualización, consumir servicio de actualizar ordenPedido
          if (this.ssps.getLocalStorage('tipocliente')! === "natural") {
            this.logger.log("actualizarOrdenPedidoPN", "TODO")
            this.actualizarOrdenPedidoPN()
            //this.nopedidoActualizar = true;
          }
          else {
            this.logger.log("actualizarPedidoPJ", "TODO")
            this.actualizarOrdenPedidoPJ()
          }
        }
      } else {
        this.nopedido = false;
        this.slidersinfomativos(res);
        if (this.respuesta == "cargarDatosHeader") {
          if (this.ssps.getLocalStorage('usuario')) {
            this.compras = JSON.parse(this.ssps.getLocalStorage('usuario')!);
            this.costos = Math.trunc(+this.compras.costo);
            if (this.compras.cantidad == null && this.compras.cliente == "natural") {
              this.compras.cantidad = 1;
            }
            this.reintentos = 1
            this.noradicadopedi = JSON.parse(this.ssps.getLocalStorage("ordenPedidoId")!)
            this.noradicadosolicitud = JSON.parse(this.ssps.getLocalStorage("solicitudId")!)
          }
        }
        if (this.respuesta == "ocultarBarra") {
          this.mostrarBarra = false
        }
        if (this.respuesta == "mostrarBarra") {
          this.mostrarBarra = true
        }
      }
    });
    this.ssps.eventos$.subscribe(res => {
      this.costos = res;
    });
  }

  ngOnInit(): void {
    this.logger.log("Validacion flussps", "1")
    if (this.respuesta === "")
      this.componentesService.emitircambio("infoperso")
    if (this.ssps.getLocalStorage('usuario')) {
      this.compras = JSON.parse(this.ssps.getLocalStorage('usuario')!);
      this.costos = Math.trunc(+this.compras.costo);
      this.logger.log("flujoCompras-onInit", this.compras)
      if (this.compras.cantidad == null && this.compras.cliente == "natural") {
        this.compras.cantidad = 1;
      }
      this.reintentos = 1
      this.noradicadopedi = JSON.parse(this.ssps.getLocalStorage("ordenPedidoId")!)
      this.noradicadosolicitud = JSON.parse(this.ssps.getLocalStorage("solicitudId")!)
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  onResize(event: any) {
    this.tamano = event.target.innerWidth;
    if (this.tamano <= 988) {
      this.margen.nativeElement.classList.remove('row-margenes');
    } else {
      this.margen.nativeElement.classList.add('row-margenes');
    }
  }

  slidersinfomativos(res: any) {
    if (res == "datosempresa") {
      this.solicitanteElement?.nativeElement.classList.remove('flujosin');
      this.solicitanteElement?.nativeElement.classList.add('flujos');
    }
    else if (res == "solicitante" && this.contacto !== undefined) {
      this.contacto?.nativeElement.classList.remove('flujosin');
      this.contacto?.nativeElement.classList.add('flujos');
    }
    else if (res == "infoperso" && this.contacto !== undefined) {
      this.contacto.nativeElement.classList.remove('flujosin');
      this.contacto.nativeElement.classList.add('flujos');

    } else if (res == "contacto") {
      this.entregan.nativeElement.classList.remove('flujosin');
      this.entregan.nativeElement.classList.add('flujos');
    }
    else if (res == "facturacionn") {
      this.facturacionn.nativeElement.classList.remove('flujosin');
      this.facturacionn.nativeElement.classList.add('flujos');
    }
    else if (res == "pagos") {
      this.logger.log("validacion", res)
      this.pago?.nativeElement.classList.remove('flujosin');
      this.pago?.nativeElement.classList.add('flujos');
      this.nopedido = false;
    }

    else if (res == "entrega") {
      this.contacto.nativeElement.classList.remove('flujosin');
      this.contacto.nativeElement.classList.add('flujos');
    }
    else if (res == "pago") {
      this.pago.nativeElement.classList.remove('flujosin');
      this.pago.nativeElement.classList.add('flujos');
      this.nopedido = false;
    }
    else if (res == "pagosFull") {
      setTimeout(() => {
        this.slidersinfomativos("infoperso");
        this.slidersinfomativos("datosempresa");
        this.slidersinfomativos("solicitante");
        this.slidersinfomativos("contacto");
        this.slidersinfomativos("facturacionn");
        this.slidersinfomativos("pagos");
      }, 300);
      this.nopedido = false;
    }

    //implementacion de rutas token fisico
    if (res == "facturacionf") {
      this.facturacionn.nativeElement.classList.remove('flujosin');
      this.facturacionn.nativeElement.classList.add('flujos');
    }

    if (res == "instalaciones") {
      this.facturacionn.nativeElement.classList.remove('flujosin');
      this.facturacionn.nativeElement.classList.add('flujos');
    }

    /* regreso de flujo*/
    else if (res == "reverfactu") {
      this.pago.nativeElement.classList.remove('flujos');
      this.pago.nativeElement.classList.add('flujosin');
      this.facturacionn.nativeElement.classList.remove('flujosin');
      this.facturacionn.nativeElement.classList.add('flujos');
    }
    else if (res == "reveentregan") {
      this.facturacionn.nativeElement.classList.remove('flujos');
      this.facturacionn.nativeElement.classList.add('flujosin');
      this.entregan.nativeElement.classList.remove('flujosin');
      this.entregan.nativeElement.classList.add('flujos');
    }
    else if (res == "revesolicitante") {
      this.entregan.nativeElement.classList.remove('flujos');
      this.entregan.nativeElement.classList.add('flujosin');
      this.solicitanteElement.nativeElement.classList.remove('flujosin');
      this.solicitanteElement.nativeElement.classList.add('flujos');
      this.contacto?.nativeElement.classList.remove('flujos');
      this.contacto?.nativeElement.classList.add('flujosin');
    }
    else if (res == "revecontacto") {
      this.entregan.nativeElement.classList.remove('flujos');
      this.entregan.nativeElement.classList.add('flujosin');
      this.contacto.nativeElement.classList.remove('flujosin');
      this.contacto.nativeElement.classList.add('flujos');
    }
    else if (res == "reveinfobasi") {
      this.contacto?.nativeElement.classList.remove('flujos');
      this.contacto?.nativeElement.classList.add('flujosin');
      this.solicitanteElement?.nativeElement.classList.remove('flujos');
      this.solicitanteElement?.nativeElement.classList.add('flujosin');
      this.basica.nativeElement.classList.remove('flujosin');
      this.basica.nativeElement.classList.add('flujos');
      this.entregan.nativeElement.classList.remove('flujos');
      this.entregan.nativeElement.classList.add('flujosin');
    }
    else if (res == "entregarf") {
      this.entregan.nativeElement.classList.remove('flujosin');
      this.entregan.nativeElement.classList.add('flujos');
    }
    else if (res == "infobasir") {
      this.basica.nativeElement.classList.remove('flujosin');
      this.basica.nativeElement.classList.add('flujos');
    }
  }

  irAPago() {
    this.logger.log("CEERRR4", "")
    this.alert.closesSpiner()
    //Validar el modal de identidad
    //if (this.usuario.identidadValidada && this.usuario.identidadValidada === true) {   
    this.mostrarBack = true
    this.nopedido = false
    this.nopedidoActualizar = false
    this.slidersinfomativos("pagosFull")
    this.router.navigate(['/flujo/pago']);
    this.componentesService.emitircambio("pagos");
  }

  ContinuarSinPago(titulo: any) {
    this.nopedido = false;
    this.nopedidoActualizar = false
    this.logger.log("CEERRR3", "")
    this.alert.closesSpiner()
    this.alert.showSinPago(titulo, "Te invitamos a continuar el trámite en el enlace enviado a tu correo electrónico.");
  }

  validarIdentidad() {
    this.logger.log("CEERRR4", "")
    this.alert.closesSpiner()
    //Validar el modal de identidad
    //if (this.usuario.identidadValidada && this.usuario.identidadValidada === true) {   
    this.mostrarBack = true
    this.nopedido = false
    this.nopedidoActualizar = false
    this.alert.showAlertaValidarIdentidad(false)
  }

  crearOrdenPedido() {
    if (this.errorOrden === true) {
      this.alert.showSpiner("Ten en cuenta...Para terminar el proceso debes realizar la validación de  tu identidad.");
    }

    this.errorOrden = false
    if (this.ssps.getLocalStorage('tipocliente')! === "natural") {
      this.crearOrdenPedidoPN()
    }
    else {
      this.logger.log("crearOrdenPedidoPJ", "TODO")
      this.crearOrdenPedidoPJ()
    }
  }

  crearOrdenPedidoPN() {
    let telefono = [{
      extension: "",
      indicativo: "",
      telefono: ""
    }]
    let datosBasicos: any = {
      aceptoTerminosSn: "S",
      primerNombre: this.usuario.primerNombre.trim(),
      primerApellido: this.usuario.primerApellido.trim(),
      segundoNombre: this.usuario.segundoNombre !== null && this.usuario.segundoNombre !== undefined ?
        this.usuario.segundoNombre.trim() : "",
      segundoApellido: this.usuario.segundoApellido !== null && this.usuario.segundoApellido !== undefined ?
        this.usuario.segundoApellido.trim() : "",
      correoElectronico: this.usuario.correo.trim(),
      numeroIdentificacion: this.usuario.numero,
      tipoIdentificacion: this.usuario.tipo,
      celular: this.usuario.celular,
      ip: this.ssps.ip,
      telefono: telefono,
    }

    //Datos a validar en el formulario InfoBasica
    datosBasicos.departamento = this.usuario.departamento
    datosBasicos.direccion = this.usuario.direccion.trim()
    datosBasicos.municipio = this.usuario.municipio
    datosBasicos.regimenTributario = this.usuario.regimenTributario

    if (this.usuario.telefono != "") {
      datosBasicos.telefono = [
        {
          extension: this.usuario.extension,
          indicativo: this.usuario.indicativo,
          telefono: this.usuario.telefono
        }
      ]
    }

    let informacionEnvio: any = this.construirInformacionEnvio(telefono);

    let datosFacturacion: any = {
      apellidos: this.facturacion.apellidos.trim(),
      celular: this.facturacion.celular,
      correoElectronico: this.facturacion.correo.trim(),
      direccion: this.facturacion.direccion.trim(),
      nombres: this.facturacion.nombres.trim(),
      numeroIdentificacion: this.facturacion.tipo === 'NI' ? this.facturacion.numero + this.facturacion.codigo : this.facturacion.numero,
      tipoIdentificacion: this.facturacion.tipo,
      telefono: telefono,
      regimenTributario: this.facturacion.regimenTributario,
      tipoCapital: this.facturacion.tipoCapital
    }
    if (this.facturacion.telefono != "") {
      datosFacturacion.telefono = [
        {
          extension: this.facturacion.extension,
          indicativo: this.facturacion.indicativo,
          telefono: this.facturacion.telefono
        }
      ]
    }

    datosFacturacion.departamento = this.facturacion.departamento
    datosFacturacion.municipio = this.facturacion.municipio

    let detallesCertificado: any = {
      mecanismoEntrega: this.usuario.dispo,
      politica: this.usuario.politica,
      porcentajeIca: this.usuario.ica ?? 0,
      porcentajeIva: Math.trunc(this.usuario.iva ?? 0),
      valorBaseLiquidacion: Math.trunc(this.usuario.costoBase),
      valorIca: Math.trunc(this.usuario.costoBase * ((this.usuario.ica ?? 0) / 100)),
      valorIva: Math.trunc(this.usuario.costoBase * (this.usuario.iva / 100)),
      porcentajeReteIva: Math.trunc(this.usuario.reteiva ?? 0),
      valorReteIva: Math.trunc((this.usuario.costoBase * (this.usuario.iva / 100)) * ((this.usuario.reteiva ?? 0) / 100)),
      valorNeto: Math.trunc(this.usuario.costo),
      vigencia: this.usuario.esVigenciaDias ? this.usuario.anos : (this.usuario.anos * 365),
      reposicion: this.usuario.esReposicion
    }

    let profesionalTitulado: any = null
    if (this.usuario.politica.toLowerCase() === 'profesional titulado') {
      profesionalTitulado = {
        fechaExpedicion: null,
        institucionGrado: this.usuario.institucionGrado?.trim(),
        numTarjetaProfesional: this.usuario.tarjetaProfesional?.trim(),
        profesion: this.usuario.profesion?.trim()
      }
      if (this.usuario.fechaExpTarjetaProfesional !== null
        && this.usuario.fechaExpTarjetaProfesional !== undefined) {
        var fechaTarjeta = this.usuario.fechaExpTarjetaProfesional.split("/", 3);
        let date = new Date(fechaTarjeta[2], +fechaTarjeta[1] - 1, fechaTarjeta[0]);
        profesionalTitulado.fechaExpedicion = this.datepipe.transform(date, "yyyy-MM-dd'T'HH:mm:ss")!
      }
    }

    let solicitantes: any = [{
      primerNombre: this.usuario.primerNombre.trim(),
      primerApellido: this.usuario.primerApellido.trim(),
      segundoNombre: this.usuario.segundoNombre !== null && this.usuario.segundoNombre !== undefined ?
        this.usuario.segundoNombre.trim() : "",
      segundoApellido: this.usuario.segundoApellido !== null && this.usuario.segundoApellido !== undefined ?
        this.usuario.segundoApellido.trim() : "",
      correoElectronico: this.usuario.correo.trim(),
      numeroIdentificacion: this.usuario.numero,
      tipoIdentificacion: this.usuario.tipo,
      celular: this.usuario.celular,
      telefono: telefono
    }]

    if (this.usuario.telefono != "") {
      solicitantes[0].telefono = [
        {
          extension: this.usuario.extension,
          indicativo: this.usuario.indicativo,
          telefono: this.usuario.telefono
        }
      ]
    }
    if (this.usuario.documentos !== undefined && this.usuario.documentos !== null &&
      this.usuario.documentos.length > 0) {
      let documentos: { tipo: any; contenido: any; }[] = []
      this.usuario.documentos.filter((ff: any) => ff.file !== null && ff.file !== undefined).forEach(function (documento: any) {
        documentos.push({
          tipo: documento.id,
          contenido: documento.file.split(",", 2)[1]
        })
      })
      solicitantes[0].documentos = documentos
    }

    solicitantes[0].departamento = this.usuario.departamento
    solicitantes[0].direccion = this.usuario.direccion.trim()
    solicitantes[0].municipio = this.usuario.municipio
    solicitantes[0].codigoAgente = this.facturacion.agente

    var datosContactoTecnico: any = null
    if (this.contactoTecnico && this.contactoTecnico !== null) {
      datosContactoTecnico = {
        primerNombre: this.contactoTecnico.primerNombre.trim(),
        primerApellido: this.contactoTecnico.primerApellido.trim(),
        segundoNombre: this.contactoTecnico.segundoNombre !== null && this.contactoTecnico.segundoNombre !== undefined ?
          this.contactoTecnico.segundoNombre.trim() : "",
        segundoApellido: this.contactoTecnico.segundoApellido !== null && this.contactoTecnico.segundoApellido !== undefined ?
          this.contactoTecnico.segundoApellido.trim() : "",
        celular: this.contactoTecnico.celular,
        correoElectronico: this.contactoTecnico.correo.trim(),
        numeroIdentificacion: this.contactoTecnico.numero,
        tipoIdentificacion: this.contactoTecnico.tipo,
        telefono: telefono
      }
      if (this.contactoTecnico.telefono != "") {
        datosContactoTecnico.telefono = [
          {
            extension: this.contactoTecnico.extension,
            indicativo: this.contactoTecnico.indicativo,
            telefono: this.contactoTecnico.telefono
          }
        ]
      }
    }

    let body: any = {
      datosBasicos,
      datosFacturacion,
      detallesCertificado,
      datosContactoTecnico,
      informacionEnvio,
      profesionalTitulado,
      solicitantes,
      origen: env.origen
    }
    this.llamarApiCreacionOrdenPN(body)
  }

  llamarApiCreacionOrdenPN(body: any) {
    this.ssps.crearOrdenPedidoPN(body)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta crearOrdenPedidoPN", res)
          if (res !== null && res.ordenPedidoId) {
            if (res.codigo !== undefined && res.codigo !== null &&
              res.codigo !== 200) {
              let urlRedireccion = `${env.urlVentaDigital}?id=` + res.solicitudId
              this.alert.closesSpiner()
              //Mostrar alerta y redireccionar a la consulta del id
              this.alert.showError(res.mensaje, true, "¡Importante!", false, urlRedireccion, "Retomar solicitud", true)
            }
            else {
              this.ssps.saveLocalStorage("ordenPedidoId", JSON.stringify(res.ordenPedidoId));
              this.ssps.saveLocalStorage("solicitudId", JSON.stringify(res.solicitudId));
              //Llamar servicio de generar llaves, que envie notificación si el dispo == pkcs#10
              if (this.usuario.dispo.toLowerCase() === "pkcs#10") {
                this.p10Mecanismo = true;
                (async () => {
                  this.llamarGenerarRequestJuridico(res.solicitudId)
                })()

              }
              setTimeout(() => {
                this.alert.closesSpiner()
                this.noradicadopedi = JSON.parse(this.ssps.getLocalStorage("ordenPedidoId")!);
                this.nopedido = true;
              }, 3000);
            }
            //this.usuario.identidadValidada  = true
          }
          else {
            //Reintentar ciertas veces, sino mostrar modal de fallas tecnicas, que muestra formulario de contacto
            //Consumir servicio de formularioContactenos
            //Respuesta error del servicio, mostrar modal
            console.error('Error en servicio crearordenPedido', res)
            this.errorOrden = true
            this.alert.closesSpiner()
          }
        },
        error: (error) => {
          //Reintentar ciertas veces, sino mostrar modal de fallas tecnicas, que muestra formulario de contacto
          //Consumir servicio de formularioContactenos
          console.error('Error en subscriber crearordenPedido', error)
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.reintentos = 0;
            this.logger.log("Error", "time out")
            this.llamarApiConsultaUltimaOrden(body)
          }
          else {
            this.errorOrden = true
            this.alert.closesSpiner()
          }
        }
      });
  }

  llamarApiConsultaUltimaOrden(body: any) {
    this.ssps.consultarUltimaOrdenPedido(body.datosBasicos.numeroIdentificacion)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta consultarUltimaOrdenPedido", res)
          if (res !== null && res !== "") {
            this.ssps.saveLocalStorage("ordenPedidoId", JSON.stringify(res.ordenPedidoId));
            this.ssps.saveLocalStorage("solicitudId", JSON.stringify(res.solicitudId));
            if (this.usuario.dispo.toLowerCase() === "pkcs#10") {
              this.p10Mecanismo = true;
              (async () => {
                this.llamarGenerarRequestJuridico(res.solicitudId)
              })()
            }
            setTimeout(() => {
              this.alert.closesSpiner()
              this.noradicadopedi = JSON.parse(this.ssps.getLocalStorage("ordenPedidoId")!);
              this.noradicadosolicitud = JSON.parse(this.ssps.getLocalStorage("solicitudId")!)
              this.nopedido = true;
            }, 3000);
          }
          else {
            if (this.ssps.getLocalStorage('tipocliente')! === "natural") {
              this.llamarApiCreacionOrdenPN(body)
            }
            else {
              this.llamarApiCreacionOrdenPJ(body)
            }
          }
        },
        error: (error) => {
          //Reintentar ciertas veces, sino mostrar modal de fallas tecnicas, que muestra formulario de contacto
          //Consumir servicio de formularioContactenos
          console.error('Error en subscriber consultarUltimaOrdenPedido', error)
          if (this.reintentos < 2) {
            this.reintentos += 1
            this.llamarApiConsultaUltimaOrden(body)
          }
          else {
            this.reintentos = 0;
            if (this.ssps.getLocalStorage('tipocliente')! === "natural") {
              this.llamarApiCreacionOrdenPN(body)
            }
            else {
              this.llamarApiCreacionOrdenPJ(body)
            }
          }
        }
      });
  }

  actualizarOrdenPedidoPN() {
    let telefono = [{
      extension: "",
      indicativo: "",
      telefono: ""
    }]
    let datosBasicos: any = {
      aceptoTerminosSn: "S",
      primerNombre: this.usuario.primerNombre.trim(),
      primerApellido: this.usuario.primerApellido.trim(),
      segundoNombre: this.usuario.segundoNombre !== null && this.usuario.segundoNombre !== undefined ?
        this.usuario.segundoNombre.trim() : "",
      segundoApellido: this.usuario.segundoApellido !== null && this.usuario.segundoApellido !== undefined ?
        this.usuario.segundoApellido.trim() : "",
      correoElectronico: this.usuario.correo.trim(),
      celular: this.usuario.celular,
      ip: this.ssps.ip,
      telefono: telefono
    }

    //Datos a validar en el formulario InfoBasica
    datosBasicos.departamento = this.usuario.departamento
    datosBasicos.direccion = this.usuario.direccion.trim()
    datosBasicos.municipio = this.usuario.municipio
    datosBasicos.regimenTributario = this.usuario.regimenTributario

    if (this.usuario.telefono != "") {
      datosBasicos.telefono = [
        {
          extension: this.usuario.extension,
          indicativo: this.usuario.indicativo,
          telefono: this.usuario.telefono
        }
      ]
    }

    let informacionEnvio: any = this.construirInformacionEnvio(telefono);

    let datosFacturacion: any = null
    if (this.facturacion !== undefined && this.facturacion !== null) {
      datosFacturacion = {
        apellidos: this.facturacion.apellidos.trim(),
        celular: this.facturacion.celular,
        correoElectronico: this.facturacion.correo.trim(),
        direccion: this.facturacion.direccion.trim(),
        nombres: this.facturacion.nombres.trim(),
        numeroIdentificacion: this.facturacion.tipo === 'NI' ? this.facturacion.numero + this.facturacion.codigo : this.facturacion.numero,
        tipoIdentificacion: this.facturacion.tipo,
        telefono: telefono,
        regimenTributario: this.facturacion.regimenTributario,
        tipoCapital: this.facturacion.tipoCapital
      }
      if (this.facturacion.telefono != "") {
        datosFacturacion.telefono = [
          {
            extension: this.facturacion.extension,
            indicativo: this.facturacion.indicativo,
            telefono: this.facturacion.telefono
          }
        ]
      }

      datosFacturacion.departamento = this.facturacion.departamento
      datosFacturacion.municipio = this.facturacion.municipio
    }
    else {
      datosFacturacion = {
        apellidos: "",
        celular: "",
        correoElectronico: "",
        direccion: "",
        nombres: "",
        numeroIdentificacion: "",
        tipoIdentificacion: "",
        telefono: telefono,
        regimenTributario: "",
        tipoCapital: ""
      }
    }

    let detallesCertificado: any = {
      mecanismoEntrega: this.usuario.dispo,
      politica: this.usuario.politica,
      porcentajeIca: this.usuario.ica ?? 0,
      porcentajeIva: Math.trunc(this.usuario.iva),
      valorBaseLiquidacion: Math.trunc(this.usuario.costoBase),
      valorIca: Math.trunc(this.usuario.costoBase * ((this.usuario.ica ?? 0) / 100)),
      valorIva: Math.trunc(this.usuario.costoBase * (this.usuario.iva / 100)),
      porcentajeReteIva: Math.trunc(this.usuario.reteiva ?? 0),
      valorReteIva: Math.trunc((this.usuario.costoBase * (this.usuario.iva / 100)) * ((this.usuario.reteiva ?? 0) / 100)),
      valorNeto: Math.trunc(this.usuario.costo),
      vigencia: this.usuario.esVigenciaDias ? this.usuario.anos : (this.usuario.anos * 365),
      reposicion: this.usuario.esReposicion
    }

    let profesionalTitulado: any = null
    if (this.usuario.politica.toLowerCase() === 'profesional titulado') {
      profesionalTitulado = {
        fechaExpedicion: null,
        institucionGrado: this.usuario.institucionGrado?.trim(),
        numTarjetaProfesional: this.usuario.tarjetaProfesional?.trim(),
        profesion: this.usuario.profesion?.trim()
      }
      if (this.usuario.fechaExpTarjetaProfesional !== null
        && this.usuario.fechaExpTarjetaProfesional !== undefined) {
        var fechaTarjeta = this.usuario.fechaExpTarjetaProfesional.split("/", 3);
        let date = new Date(fechaTarjeta[2], +fechaTarjeta[1] - 1, fechaTarjeta[0]);
        profesionalTitulado.fechaExpedicion = this.datepipe.transform(date, "yyyy-MM-dd'T'HH:mm:ss")!
      }
    }

    let solicitantes: any = [{
      primerNombre: this.usuario.primerNombre.trim(),
      primerApellido: this.usuario.primerApellido.trim(),
      segundoNombre: this.usuario.segundoNombre !== null && this.usuario.segundoNombre !== undefined ?
        this.usuario.segundoNombre.trim() : "",
      segundoApellido: this.usuario.segundoApellido !== null && this.usuario.segundoApellido !== undefined ?
        this.usuario.segundoApellido.trim() : "",
      correoElectronico: this.usuario.correo.trim(),
      celular: this.usuario.celular,
      telefono: telefono,
      solicitudTitularId: JSON.parse(this.ssps.getLocalStorage("solicitudId")!)
    }]

    if (this.usuario.telefono != "") {
      solicitantes[0].telefono = [
        {
          extension: this.usuario.extension,
          indicativo: this.usuario.indicativo,
          telefono: this.usuario.telefono
        }
      ]
    }
    if (this.usuario.documentos !== undefined && this.usuario.documentos !== null &&
      this.usuario.documentos.length > 0) {
      let documentos: { tipo: any; contenido: any; }[] = []
      this.usuario.documentos.filter((ff: any) => ff.file !== null && ff.file !== undefined).forEach(function (documento: any) {
        documentos.push({
          tipo: documento.id,
          contenido: documento.file.split(",", 2)[1]
        })
      })
      solicitantes[0].documentos = documentos
    }

    solicitantes[0].departamento = this.usuario.departamento
    solicitantes[0].direccion = this.usuario.direccion.trim()
    solicitantes[0].municipio = this.usuario.municipio
    solicitantes[0].codigoAgente = this.facturacion !== undefined && this.facturacion !== null ?
      this.facturacion.agente : ""

    var datosContactoTecnico: any = null
    if (this.contactoTecnico && this.contactoTecnico !== null) {
      datosContactoTecnico = {
        primerNombre: this.contactoTecnico.primerNombre.trim(),
        primerApellido: this.contactoTecnico.primerApellido.trim(),
        segundoNombre: this.contactoTecnico.segundoNombre !== null && this.contactoTecnico.segundoNombre !== undefined ?
          this.contactoTecnico.segundoNombre.trim() : "",
        segundoApellido: this.contactoTecnico.segundoApellido !== null && this.contactoTecnico.segundoApellido !== undefined ?
          this.contactoTecnico.segundoApellido.trim() : "",
        celular: this.contactoTecnico.celular,
        correoElectronico: this.contactoTecnico.correo.trim(),
        numeroIdentificacion: this.contactoTecnico.numero,
        tipoIdentificacion: this.contactoTecnico.tipo,
        telefono: telefono
      }
      if (this.contactoTecnico.telefono != "") {
        datosContactoTecnico.telefono = [
          {
            extension: this.contactoTecnico.extension,
            indicativo: this.contactoTecnico.indicativo,
            telefono: this.contactoTecnico.telefono
          }
        ]
      }
    }

    let body: any = {
      datosBasicos,
      datosFacturacion,
      detallesCertificado,
      informacionEnvio,
      profesionalTitulado,
      solicitantes,
      datosContactoTecnico
    }
    this.reintentos = 0;
    this.llamarApiActualizarOrdenPN(body)
  }

  llamarApiActualizarOrdenPN(body: any) {
    this.ssps.actualizarOrdenPedidoPN(this.noradicadosolicitud, body)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta actualizarOrdenPedidoPN", res)
          this.logger.log("respuesta actualizarOrdenPedidoPN2", this.actualizaOrden)
          if (res !== null && res.ordenPedidoId) {
            setTimeout(() => {
              this.alert.closesSpiner()
              if (res.solicitudFinalizada !== "PENDIENTE" && this.respuesta == "mostrarordencambio") {
                this.logger.log("Solicitud finalizada", "true")
                this.alert.closesSpiner();
                this.alert.showSolicitudFinalizada(true)
              }
              else {
                this.nopedidoActualizar = !this.actualizaOrden;
              }
            }, 500);
            //this.usuario.identidadValidada  = true
          }
          else {
            //Reintentar ciertas veces, sino mostrar modal de fallas tecnicas, que muestra formulario de contacto
            //Consumir servicio de formularioContactenos
            //Respuesta error del servicio, mostrar modal
            this.alert.closesSpiner()
            console.error('Error en servicio actualizarOrdenPedidoPN', res)
            let mensaje = res.mensaje ? res.mensaje : "No fue posible realizar la petición"
            this.alert.showError(mensaje, false, null, false, null, null, false)
          }
        },
        error: (error) => {
          this.alert.closesSpiner()
          console.error('Error en subscriber llamarApiActualizarOrdenPN', error)
          let mensaje = this.ssps.getErrorMessage(error);
          this.alert.showError(mensaje, false, null, false, null, null, false)
        }
      });
  }

  crearOrdenPedidoPJ() {
    let telefono = [{
      extension: "",
      indicativo: "",
      telefono: ""
    }]
    let datosBasicos: any = {
      aceptoTerminosSn: "S",
      razonSocial: this.usuario.razon.trim(),
      correoElectronico: this.usuario.correo.trim(),
      numeroIdentificacion: this.usuario.numero + this.usuario.codigo,
      tipoIdentificacion: this.usuario.tipo,
      celular: this.usuario.celular,
      ip: this.ssps.ip,
      telefono: telefono,
    }

    //Datos a validar en el formulario InfoBasica
    datosBasicos.departamento = this.usuario.departamento
    datosBasicos.direccion = this.usuario.direccion.trim()
    datosBasicos.municipio = this.usuario.municipio
    datosBasicos.regimenTributario = this.usuario.regimenTributario
    datosBasicos.tipoCapital = this.usuario.tipoCapital
    datosBasicos.actividadEconomica = this.usuario.actividadEconomica

    if (this.usuario.telefono != "") {
      datosBasicos.telefono = [
        {
          extension: this.usuario.extension,
          indicativo: this.usuario.indicativo,
          telefono: this.usuario.telefono
        }
      ]
    }

    let informacionEnvio: any = this.construirInformacionEnvio(telefono);

    let datosFacturacion: any = {
      apellidos: this.facturacion.apellidos.trim(),
      celular: this.facturacion.celular,
      correoElectronico: this.facturacion.correo.trim(),
      direccion: this.facturacion.direccion.trim(),
      nombres: this.facturacion.nombres.trim(),
      numeroIdentificacion: this.facturacion.tipo === 'NI' ? this.facturacion.numero + this.facturacion.codigo : this.facturacion.numero,
      tipoIdentificacion: this.facturacion.tipo,
      telefono: telefono,
      regimenTributario: this.facturacion.regimenTributario,
      tipoCapital: this.facturacion.tipoCapital
    }
    if (this.facturacion.telefono != "") {
      datosFacturacion.telefono = [
        {
          extension: this.facturacion.extension,
          indicativo: this.facturacion.indicativo,
          telefono: this.facturacion.telefono
        }
      ]
    }

    datosFacturacion.departamento = this.facturacion.departamento
    datosFacturacion.municipio = this.facturacion.municipio

    let detallesCertificado: any = {
      mecanismoEntrega: this.usuario.dispo,
      politica: this.usuario.politica,
      porcentajeIca: this.usuario.ica ?? 0,
      porcentajeIva: Math.trunc(this.usuario.iva),
      valorBaseLiquidacion: Math.trunc(this.usuario.costoBase),
      valorIca: Math.trunc(this.usuario.costoBase * ((this.usuario.ica ?? 0) / 100)),
      valorIva: Math.trunc(this.usuario.costoBase * (this.usuario.iva / 100)),
      porcentajeReteIva: Math.trunc(this.usuario.reteiva ?? 0),
      valorReteIva: Math.trunc((this.usuario.costoBase * (this.usuario.iva / 100)) * ((this.usuario.reteiva ?? 0) / 100)),
      valorNeto: Math.trunc(this.usuario.costo),
      vigencia: this.usuario.esVigenciaDias ? this.usuario.anos : (this.usuario.anos * 365),
      reposicion: this.usuario.esReposicion,
      estampillas: this.usuario.estampillas
    }

    let profesionalTitulado: any = null
    if (this.usuario.politica.toLowerCase() === 'profesional titulado') {
      profesionalTitulado = {
        fechaExpedicion: null,
        institucionGrado: this.solicitante.institucionGrado?.trim(),
        numTarjetaProfesional: this.solicitante.tarjetaProfesional?.trim(),
        profesion: this.solicitante.profesion?.trim()
      }
      if (this.solicitante.fechaExpTarjetaProfesional !== null
        && this.solicitante.fechaExpTarjetaProfesional !== undefined) {
        var fechaTarjeta = this.solicitante.fechaExpTarjetaProfesional.split("/", 3);
        let date = new Date(fechaTarjeta[2], +fechaTarjeta[1] - 1, fechaTarjeta[0]);
        profesionalTitulado.fechaExpedicion = this.datepipe.transform(date, "yyyy-MM-dd'T'HH:mm:ss")!
      }
    }

    let solicitantes: any = [{
      primerNombre: this.solicitante.primerNombre.trim(),
      primerApellido: this.solicitante.primerApellido.trim(),
      segundoNombre: this.solicitante.segundoNombre !== null && this.solicitante.segundoNombre !== undefined ?
        this.solicitante.segundoNombre.trim() : "",
      segundoApellido: this.solicitante.segundoApellido !== null && this.solicitante.segundoApellido !== undefined ?
        this.solicitante.segundoApellido.trim() : "",
      correoElectronico: this.solicitante.correo.trim(),
      numeroIdentificacion: this.solicitante.numero,
      tipoIdentificacion: this.solicitante.tipo,
      celular: this.solicitante.celular,
      telefono: telefono,
      cargoEmpresa: this.solicitante.cargoEmpresa,
      actaPosesion: this.solicitante.actaPosesion,
      esRepresentanteLegal: this.solicitante.representanteLegal === true ? "S" : "N",
      esFuncionarioPublico: this.usuario.politica === "Función Publica" ? "S" : "N"
    }]

    if (this.solicitante.fechaNombramiento && this.solicitante.fechaNombramiento !== "" &&
      this.solicitante.fechaNombramiento !== null) {
      var fechaNombramiento = this.solicitante.fechaNombramiento.split("/", 3);
      let date = new Date(fechaNombramiento[2], +fechaNombramiento[1] - 1, fechaNombramiento[0]);
      solicitantes[0].fechaNombramiento = this.datepipe.transform(date, "yyyy-MM-dd'T'HH:mm:ss")!
    }

    if (this.solicitante.telefono != "") {
      solicitantes[0].telefono = [
        {
          extension: this.solicitante.extension,
          indicativo: this.solicitante.indicativo,
          telefono: this.solicitante.telefono
        }
      ]
    }
    let documentos: { tipo: any; contenido: any; }[] = []

    if (this.usuario.documentos !== undefined && this.usuario.documentos !== null &&
      this.usuario.documentos.length > 0) {
      this.usuario.documentos.filter((ff: any) => ff.file !== null && ff.file !== undefined).forEach(function (documento: any) {
        documentos.push({
          tipo: documento.id,
          contenido: documento.file.split(",", 2)[1]
        })
      })
    }

    if (this.solicitante.documentos !== undefined && this.solicitante.documentos !== null &&
      this.solicitante.documentos.length > 0) {
      this.solicitante.documentos.filter((ff: any) => ff.file !== null && ff.file !== undefined).forEach(function (documento: any) {
        documentos.push({
          tipo: documento.id,
          contenido: documento.file.split(",", 2)[1]
        })
      })
    }

    solicitantes[0].documentos = documentos
    solicitantes[0].departamento = this.solicitante.departamento
    solicitantes[0].municipio = this.solicitante.municipio
    solicitantes[0].codigoAgente = this.facturacion.agente

    var datosContactoTecnico: any = null
    if (this.contactoTecnico && this.contactoTecnico !== null) {
      datosContactoTecnico = {
        primerNombre: this.contactoTecnico.primerNombre.trim(),
        primerApellido: this.contactoTecnico.primerApellido.trim(),
        segundoNombre: this.contactoTecnico.segundoNombre !== null && this.contactoTecnico.segundoNombre !== undefined ?
          this.contactoTecnico.segundoNombre.trim() : "",
        segundoApellido: this.contactoTecnico.segundoApellido !== null && this.contactoTecnico.segundoApellido !== undefined ?
          this.contactoTecnico.segundoApellido.trim() : "",
        celular: this.contactoTecnico.celular,
        correoElectronico: this.contactoTecnico.correo.trim(),
        numeroIdentificacion: this.contactoTecnico.numero,
        tipoIdentificacion: this.contactoTecnico.tipo,
        telefono: telefono
      }
      if (this.contactoTecnico.telefono != "") {
        datosContactoTecnico.telefono = [
          {
            extension: this.contactoTecnico.extension,
            indicativo: this.contactoTecnico.indicativo,
            telefono: this.contactoTecnico.telefono
          }
        ]
      }
    }

    let body: any = {
      datosBasicos,
      datosFacturacion,
      detallesCertificado,
      datosContactoTecnico,
      informacionEnvio,
      profesionalTitulado,
      solicitantes,
      origen: env.origen
    }
    this.llamarApiCreacionOrdenPJ(body)
  }

  llamarApiCreacionOrdenPJ(body: any) {
    this.ssps.crearOrdenPedidoPJ(body)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta crearOrdenPedidoPJ", res)
          if (res !== null && res.ordenPedidoId) {
            if (res.codigo !== undefined && res.codigo !== null &&
              res.codigo !== 200) {
              let urlRedireccion = `${env.urlVentaDigital}?id=` + res.solicitudId
              this.alert.closesSpiner()
              //Mostrar alerta y redireccionar a la consulta del id
              this.alert.showError(res.mensaje, true, "¡Importante!", false, urlRedireccion, "Retomar solicitud", true)
            }
            else {
              this.ssps.saveLocalStorage("ordenPedidoId", JSON.stringify(res.ordenPedidoId));
              this.ssps.saveLocalStorage("solicitudId", JSON.stringify(res.solicitudId));
              //Llamar servicio de generar llaves, que envie notificación si el dispo == pkcs#10
              if (this.usuario.dispo.toLowerCase() === "pkcs#10") {
                this.p10Mecanismo = true;
                (async () => {
                  this.llamarGenerarRequestJuridico(res.solicitudId)
                })()
              }
              setTimeout(() => {
                this.alert.closesSpiner()
                this.noradicadopedi = JSON.parse(this.ssps.getLocalStorage("ordenPedidoId")!);
                this.noradicadosolicitud = JSON.parse(this.ssps.getLocalStorage("solicitudId")!)
                this.nopedido = true;
              }, 3000);
            }
            //this.usuario.identidadValidada  = true
          }
          else {
            //Reintentar ciertas veces, sino mostrar modal de fallas tecnicas, que muestra formulario de contacto
            //Consumir servicio de formularioContactenos
            //Respuesta error del servicio, mostrar modal
            console.error('Error en servicio crearordenPedido', res)
            this.errorOrden = true
            this.alert.closesSpiner()
          }
        },
        error: (error) => {
          //Reintentar ciertas veces, sino mostrar modal de fallas tecnicas, que muestra formulario de contacto
          //Consumir servicio de formularioContactenos
          console.error('Error en subscriber crearordenPedidoPJ', error)
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.reintentos = 0;
            this.logger.log("Error", "time out")
            this.llamarApiConsultaUltimaOrden(body)
          }
          else {
            this.errorOrden = true
            this.alert.closesSpiner()
          }
        }
      });
  }

  actualizarOrdenPedidoPJ() {
    let telefono = [{
      extension: "",
      indicativo: "",
      telefono: ""
    }]
    let datosBasicos: any = {
      aceptoTerminosSn: "S",
      razonSocial: this.usuario.razon.trim(),
      correoElectronico: this.usuario.correo.trim(),
      celular: this.usuario.celular,
      ip: this.ssps.ip,
      telefono: telefono
    }

    //Datos a validar en el formulario InfoBasica
    datosBasicos.departamento = this.usuario.departamento
    datosBasicos.direccion = this.usuario.direccion.trim()
    datosBasicos.municipio = this.usuario.municipio
    datosBasicos.regimenTributario = this.usuario.regimenTributario
    datosBasicos.tipoCapital = this.usuario.tipoCapital
    datosBasicos.actividadEconomica = this.usuario.actividadEconomica

    if (this.usuario.telefono != "") {
      datosBasicos.telefono = [
        {
          extension: this.usuario.extension,
          indicativo: this.usuario.indicativo,
          telefono: this.usuario.telefono
        }
      ]
    }

    let informacionEnvio: any = this.construirInformacionEnvio(telefono);

    let datosFacturacion: any = null
    if (this.facturacion !== undefined && this.facturacion !== null) {
      datosFacturacion = {
        apellidos: this.facturacion.apellidos.trim(),
        celular: this.facturacion.celular,
        correoElectronico: this.facturacion.correo.trim(),
        direccion: this.facturacion.direccion.trim(),
        nombres: this.facturacion.nombres.trim(),
        numeroIdentificacion: this.facturacion.tipo === 'NI' ? this.facturacion.numero + this.facturacion.codigo : this.facturacion.numero,
        tipoIdentificacion: this.facturacion.tipo,
        telefono: telefono,
        regimenTributario: this.facturacion.regimenTributario,
        tipoCapital: this.facturacion.tipoCapital
      }
      if (this.facturacion.telefono != "") {
        datosFacturacion.telefono = [
          {
            extension: this.facturacion.extension,
            indicativo: this.facturacion.indicativo,
            telefono: this.facturacion.telefono
          }
        ]
      }

      datosFacturacion.departamento = this.facturacion.departamento
      datosFacturacion.municipio = this.facturacion.municipio
    }
    else {
      datosFacturacion = {
        apellidos: "",
        celular: "",
        correoElectronico: "",
        direccion: "",
        nombres: "",
        numeroIdentificacion: "",
        tipoIdentificacion: "",
        telefono: telefono,
        regimenTributario: "",
        tipoCapital: ""
      }
    }

    let detallesCertificado: any = {
      mecanismoEntrega: this.usuario.dispo,
      politica: this.usuario.politica,
      porcentajeIca: this.usuario.ica ?? 0,
      porcentajeIva: Math.trunc(this.usuario.iva),
      valorBaseLiquidacion: Math.trunc(this.usuario.costoBase),
      valorIca: Math.trunc(this.usuario.costoBase * ((this.usuario.ica ?? 0) / 100)),
      valorIva: Math.trunc(this.usuario.costoBase * (this.usuario.iva / 100)),
      porcentajeReteIva: Math.trunc(this.usuario.reteiva ?? 0),
      valorReteIva: Math.trunc((this.usuario.costoBase * (this.usuario.iva / 100)) * ((this.usuario.reteiva ?? 0) / 100)),
      valorNeto: Math.trunc(this.usuario.costo),
      vigencia: this.usuario.esVigenciaDias ? this.usuario.anos : (this.usuario.anos * 365),
      reposicion: this.usuario.esReposicion,
      estampillas: this.usuario.estampillas
    }

    let profesionalTitulado: any = null
    if (this.usuario.politica.toLowerCase() === 'profesional titulado') {
      profesionalTitulado = {
        fechaExpedicion: null,
        institucionGrado: this.solicitante.institucionGrado?.trim(),
        numTarjetaProfesional: this.solicitante.tarjetaProfesional?.trim(),
        profesion: this.solicitante.profesion?.trim()
      }
      if (this.solicitante.fechaExpTarjetaProfesional !== null
        && this.solicitante.fechaExpTarjetaProfesional !== undefined) {
        var fechaTarjeta = this.solicitante.fechaExpTarjetaProfesional.split("/", 3);
        let date = new Date(fechaTarjeta[2], +fechaTarjeta[1] - 1, fechaTarjeta[0]);
        profesionalTitulado.fechaExpedicion = this.datepipe.transform(date, "yyyy-MM-dd'T'HH:mm:ss")!
      }
    }

    let solicitantes: any = [{
      primerNombre: this.solicitante.primerNombre.trim(),
      primerApellido: this.solicitante.primerApellido.trim(),
      segundoNombre: this.solicitante.segundoNombre !== null && this.solicitante.segundoNombre !== undefined ?
        this.solicitante.segundoNombre.trim() : "",
      segundoApellido: this.solicitante.segundoApellido !== null && this.solicitante.segundoApellido !== undefined ?
        this.solicitante.segundoApellido.trim() : "",
      correoElectronico: this.solicitante.correo.trim(),
      celular: this.solicitante.celular,
      telefono: telefono,
      solicitudTitularId: JSON.parse(this.ssps.getLocalStorage("solicitudId")!),
      cargoEmpresa: this.solicitante.cargoEmpresa,
      actaPosesion: this.solicitante.actaPosesion,
      esRepresentanteLegal: this.solicitante.representanteLegal === true ? "S" : "N",
      esFuncionarioPublico: this.usuario.politica === "Función Publica" ? "S" : "N"
    }]

    if (this.solicitante.telefono != "") {
      solicitantes[0].telefono = [
        {
          extension: this.solicitante.extension,
          indicativo: this.solicitante.indicativo,
          telefono: this.solicitante.telefono
        }
      ]
    }

    if (this.solicitante.fechaNombramiento && this.solicitante.fechaNombramiento !== "" &&
      this.solicitante.fechaNombramiento !== null) {
      var fechaNombramiento = this.solicitante.fechaNombramiento.split("/", 3);
      let date = new Date(fechaNombramiento[2], +fechaNombramiento[1] - 1, fechaNombramiento[0]);
      solicitantes[0].fechaNombramiento = this.datepipe.transform(date, "yyyy-MM-dd'T'HH:mm:ss")!
    }

    let documentos: { tipo: any; contenido: any; }[] = []

    if (this.usuario.documentos !== undefined && this.usuario.documentos !== null &&
      this.usuario.documentos.length > 0) {
      this.usuario.documentos.filter((ff: any) => ff.file !== null && ff.file !== undefined).forEach(function (documento: any) {
        documentos.push({
          tipo: documento.id,
          contenido: documento.file.split(",", 2)[1]
        })
      })
    }

    if (this.solicitante.documentos !== undefined && this.solicitante.documentos !== null &&
      this.solicitante.documentos.length > 0) {
      this.solicitante.documentos.filter((ff: any) => ff.file !== null && ff.file !== undefined).forEach(function (documento: any) {
        documentos.push({
          tipo: documento.id,
          contenido: documento.file.split(",", 2)[1]
        })
      })
    }

    solicitantes[0].documentos = documentos
    solicitantes[0].departamento = this.solicitante.departamento
    solicitantes[0].municipio = this.solicitante.municipio
    solicitantes[0].codigoAgente = this.facturacion !== undefined && this.facturacion !== null ?
      this.facturacion.agente : ""

    var datosContactoTecnico: any = null
    if (this.contactoTecnico && this.contactoTecnico !== null) {
      datosContactoTecnico = {
        primerNombre: this.contactoTecnico.primerNombre.trim(),
        primerApellido: this.contactoTecnico.primerApellido.trim(),
        segundoNombre: this.contactoTecnico.segundoNombre !== null && this.contactoTecnico.segundoNombre !== undefined ?
          this.contactoTecnico.segundoNombre.trim() : "",
        segundoApellido: this.contactoTecnico.segundoApellido !== null && this.contactoTecnico.segundoApellido !== undefined ?
          this.contactoTecnico.segundoApellido.trim() : "",
        celular: this.contactoTecnico.celular,
        correoElectronico: this.contactoTecnico.correo.trim(),
        numeroIdentificacion: this.contactoTecnico.numero,
        tipoIdentificacion: this.contactoTecnico.tipo,
        telefono: telefono
      }
      if (this.contactoTecnico.telefono != "") {
        datosContactoTecnico.telefono = [
          {
            extension: this.contactoTecnico.extension,
            indicativo: this.contactoTecnico.indicativo,
            telefono: this.contactoTecnico.telefono
          }
        ]
      }
    }

    let body: any = {
      datosBasicos,
      datosFacturacion,
      detallesCertificado,
      informacionEnvio,
      profesionalTitulado,
      solicitantes,
      datosContactoTecnico
    }
    this.reintentos = 0;
    this.llamarApiActualizarOrdenPJ(body)
  }

  llamarApiActualizarOrdenPJ(body: any) {
    this.ssps.actualizarOrdenPedidoPJ(this.noradicadosolicitud, body)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta actualizarOrdenPedidoPJ", res)
          this.logger.log("respuesta actualizarOrdenPedidoPJ2", this.actualizaOrden)
          if (res !== null && res.ordenPedidoId) {
            setTimeout(() => {
              this.alert.closesSpiner()
              if (res.solicitudFinalizada !== "PENDIENTE" && this.respuesta == "mostrarordencambio") {
                this.logger.log("Solicitud finalizada", "true")
                this.alert.closesSpiner();
                this.alert.showSolicitudFinalizada(true)
              }
              else {
                this.nopedidoActualizar = !this.actualizaOrden;
              }
            }, 500);
            //this.usuario.identidadValidada  = true
          }
          else {
            //Reintentar ciertas veces, sino mostrar modal de fallas tecnicas, que muestra formulario de contacto
            //Consumir servicio de formularioContactenos
            //Respuesta error del servicio, mostrar modal
            this.alert.closesSpiner()
            console.error('Error en servicio actualizarOrdenPedidoPJ', res)
            let mensaje = res.mensaje ? res.mensaje : "No fue posible realizar la petición"
            this.alert.showError(mensaje, false, null, false, null, null, false)
          }
        },
        error: (error) => {
          this.alert.closesSpiner()
          console.error('Error en subscriber llamarApiActualizarOrdenPJ', error)
          let mensaje = this.ssps.getErrorMessage(error);
          this.alert.showError(mensaje, false, null, false, null, null, false)
        }
      });
  }

  async llamarGenerarRequestJuridico(solicitudId: any) {
    let body: any = {
      solicitudId: solicitudId,
      usoCertificado: this.usuario.uso.id,
      uso: this.usuario.uso.descripcion,
      otroUso: this.usuario.uso.otro ?? ""
    }

    this.ssps.generarRequestJuridico(body).subscribe({
      next: (res: any) => {
        this.logger.log("respuesta generarRequestJurido", res)
      },
      error: (error) => {
        console.error('Error en subscriber generarRequestJurido', error)
      }
    });
  }

  // Método para construir la información de envío
  private construirInformacionEnvio(telefono: any): any {
    let informacionEnvio: any = {};

    if (this.entrega.tipo === "OFICINA") {
      informacionEnvio.recogeCerti = {
        direccion: `${env.direccionCerti}`
      }
    }
    else if (this.entrega.tipo === "ENVIO") {
      informacionEnvio.envioDomicilio = {
        celular: this.entrega.celular,
        departamento: this.entrega.departamento,
        direccion: this.entrega.direccionCompleta.trim(),
        municipio: this.entrega.municipio,
        telefono: telefono
      }
      if (this.entrega.telefono != "") {
        informacionEnvio.envioDomicilio.telefono = [
          {
            extension: this.entrega.extension,
            indicativo: this.entrega.indicativo,
            telefono: this.entrega.telefono
          }
        ]
      }
    }
    else if (this.entrega.tipo === "CORREO" || this.entrega.tipo === "VIRTUAL") {
      informacionEnvio.envioViaCorreoElectronico = {
        correoElectronico: this.entrega.correo.trim()
      }
    }
    return informacionEnvio;
  }
}


