import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { httpInterceptorProviders } from '../app/domain/helpers/auth.interceptor';
import { DigitoDirective } from './directivas/digito.directive';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './ui/components/shared/shared.module';
import { ModulosModule } from './ui/components/modulos.module';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { CustomAdapter, CustomDateParserFormatter } from './domain/servicios/componentes.service';
import { LogService } from './domain/servicios/log.service';
import '@angular/common/locales/global/es-CO';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha-2';
import { environment as env } from "src/environments/environment"
import {MatTableModule} from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

@NgModule({ declarations: [
        AppComponent,
        DigitoDirective
    ],
    exports: [
        BrowserModule
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], 
    imports: [
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      CommonModule,
      DatePipe,
      TitleCasePipe,
      ModulosModule,
      AppRoutingModule,
      SharedModule,
      RecaptchaV3Module,
      MatTableModule, MatButtonModule, MatIconModule,
      NgStepperModule, CdkStepperModule,
      GoogleTagManagerModule.forRoot({
        id: env.gtmId,
      })
      ], 
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
        { provide: LOCALE_ID, useValue: 'es-CO' },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: env.recaptcha.siteKey,
        },
        httpInterceptorProviders, DatePipe, LogService, TitleCasePipe,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
