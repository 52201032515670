import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { DatePipe } from '@angular/common'
import { ActivatedRoute } from '@angular/router';
import { LogService } from '../../../../domain/servicios/log.service'
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-solicitantes',
  templateUrl: './solicitantes.component.html',
  styleUrls: ['./solicitantes.component.css']
})
export class SolicitantesComponent implements OnInit {

  @ViewChild('inputRefPrimerNombre')
  inputRefPrimerNombre!: { nativeElement: any; };

  @ViewChild('inputRefSegundoNombre')
  inputRefSegundoNombre!: { nativeElement: any; };

  @ViewChild('inputRefPrimerApellido')
  inputRefPrimerApellido!: { nativeElement: any; };

  @ViewChild('inputRefSegundoApellido')
  inputRefSegundoApellido!: { nativeElement: any; };
  @ViewChild('inputRefCorreo')
  inputRefCorreo!: { nativeElement: any; };
  @ViewChild('inputRefCorreo1')
  inputRefCorreo1!: { nativeElement: any; };
  @ViewChild('inputRefInstitucion')
  inputRefInstitucion!: { nativeElement: any; };
  @ViewChild('inputRefCargo')
  inputRefCargo!: { nativeElement: any; };
  @ViewChild('inputRefProfesional')
  inputRefProfesional!: { nativeElement: any; };
  @ViewChild('inputRefProfesion')
  inputRefProfesion!: { nativeElement: any; };
  @ViewChild('inputRefActaPosesion')
  inputRefActaPosesion!: { nativeElement: any; };

  @Output() solicitantes: EventEmitter<boolean> = new EventEmitter();
  formaForm!: FormGroup;
  cambiologo2: boolean = false;
  cambiologo: boolean = true;
  profesional: boolean = false;
  funcionPublica: boolean = false;
  documentob: boolean = false;
  cantidad: number = 0;
  totalCantidad: number = 0;
  usuario: any = {
    documentos: [],
    ip: ''
  };
  solicitante: any = {
    documentos: [],
    ip: ''
  };
  pago: any = null;
  archivos: boolean = false;
  tipos: boolean = false;
  mayor: boolean = false;
  files: boolean = false;
  fechaExpTarjeta!: any;
  statusFechaExpTarjeta: boolean = false;
  fechaNombramiento!: any;
  statusFechaNombramiento: boolean = false;
  fechaExpDocumento!: any;
  statusFechaExpDocumento: boolean = false;
  showDocumentsB: boolean = false;
  buttons: any = [] = [];
  validateformats: any[] = [];
  error: string = 'Formato no válido';
  queryRuta: any = null
  comastel: boolean = false;
  comastel2: boolean = false;
  comastex: boolean = false;
  comas: boolean = false;
  indicativos: any[] = [];
  comasnit: boolean = false;
  depar: any[] = [];
  muni: any[] = [];
  munifi: any[] = [];

  actualizacion = false

  maxDate: NgbDateStruct
  minDate: NgbDateStruct

  otraProfesion = false
  profesiones: any[] = []
  profesionLista = ""

  constructor(private fb: FormBuilder,
    private router: Router,
    private componentesService: ComponentesService,
    private ssps: SspsService,
    private changeDetector: ChangeDetectorRef,
    public datepipe: DatePipe, private route: ActivatedRoute,
    private logger: LogService,
    private alert: AlertsService,
    private el: ElementRef) {

    /*validacion de campos validators*/
    this.formaForm = this.fb.group({

      primerNombre: ['', [Validators.pattern("^[a-zA-ZÑ ]{3,60}$"),
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(60)
      ]
      ],
      segundoNombre: ['', [Validators.pattern("^[a-zA-ZÑ ]{3,60}$"),
      Validators.minLength(3),
      Validators.maxLength(60)
      ]
      ],
      primerApellido: ['', [Validators.required,
      Validators.minLength(3),
      Validators.maxLength(60),
      Validators.pattern('[a-zA-ZÑ ]{3,60}')]],
      segundoApellido: ['', [
        Validators.minLength(3),
        Validators.maxLength(60),
        Validators.pattern('[a-zA-ZÑ ]{3,60}')]],
      correo: ['', [Validators.required,
      Validators.maxLength(60),
      Validators.pattern('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$')]],
      correo1: ['', [Validators.required,
      Validators.minLength(8),
      Validators.maxLength(60),
      Validators.pattern('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$')]],
      documentos: this.fb.array([]),
      fechaExpTarjeta: [null, []],
      fechaNombramiento: [null, [Validators.required]],
      fechaExpDocumento: [null],
      tipo: [null],
      numero: [null],
      institucion: ['', [
        Validators.required,
        Validators.maxLength(60),
      ]],
      profesion: ['', [
        Validators.required,
        Validators.maxLength(60),
      ]],
      profesionLista: ['',
        Validators.required
      ],
      profesional: ['', [
        Validators.maxLength(20),
      ]],
      indicativo: [],
      telefono: ['', [
        Validators.maxLength(7),
        Validators.minLength(7)
      ]],
      extension: [0, [
        Validators.maxLength(5)
      ]],
      celular: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      departamento: ['', [Validators.required]],
      municipio: ['', [Validators.required]],
      cargoEmpresa: ['', [
        Validators.required,
        Validators.maxLength(60),
      ]],
      actaPosesion: ['', [
        Validators.required,
        Validators.maxLength(50),
      ]]
    }, {
      //validators: this.componentesService.soniguales('correo', 'correo1')
    }
    );
    var actualFecha = new Date();
    this.maxDate = { year: actualFecha.getFullYear(), month: actualFecha.getMonth() + 1, day: actualFecha.getDate() }
    this.minDate = { year: actualFecha.getFullYear() - 100, month: actualFecha.getMonth() + 1, day: actualFecha.getDate() }
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.setOnChangesInputs()
    this.totalCantidad = 2;
    this.cargarProfesiones()
    this.ssps.obtenerIpValor()
    this.indicativo();
    this.departamentos();
    this.actualizacion = this.ssps.getLocalStorage("actualizacion") ?
      JSON.parse(this.ssps.getLocalStorage("actualizacion")!) : false
    this.mapearDatos()
    this.componentesService.emitircambio("mostrarBarra");
  }

  setOnChangesInputs() {
    this.formaForm.get("primerNombre")!.valueChanges.subscribe(val => {
      if (this.inputRefPrimerNombre) {
        const elRef = this.inputRefPrimerNombre?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("primerNombre")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("segundoNombre")!.valueChanges.subscribe(val => {
      if (this.inputRefSegundoNombre) {
        const elRef = this.inputRefSegundoNombre?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("segundoNombre")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });


    this.formaForm.get("primerApellido")!.valueChanges.subscribe(val => {
      if (this.inputRefPrimerApellido) {
        const elRef = this.inputRefPrimerApellido?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("primerApellido")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });


    this.formaForm.get("segundoApellido")!.valueChanges.subscribe(val => {
      if (this.inputRefSegundoApellido) {
        const elRef = this.inputRefSegundoApellido?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("segundoApellido")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("correo")!.valueChanges.subscribe(val => {
      if (this.inputRefCorreo) {
        const elRef = this.inputRefCorreo.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("correo")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });
    this.formaForm.get("correo1")!.valueChanges.subscribe(val => {
      if (this.inputRefCorreo1) {
        const elRef = this.inputRefCorreo1.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("correo1")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("institucion")!.valueChanges.subscribe(val => {
      if (this.inputRefInstitucion) {
        const elRef = this.inputRefInstitucion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("institucion")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("cargoEmpresa")!.valueChanges.subscribe(val => {
      if (this.inputRefCargo) {
        const elRef = this.inputRefCargo.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("cargoEmpresa")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("profesional")!.valueChanges.subscribe(val => {
      if (this.inputRefProfesional) {
        const elRef = this.inputRefProfesional.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("profesional")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("profesion")!.valueChanges.subscribe(val => {
      if (this.inputRefProfesion) {
        const elRef = this.inputRefProfesion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("profesion")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("actaPosesion")!.valueChanges.subscribe(val => {
      if (this.inputRefActaPosesion) {
        const elRef = this.inputRefActaPosesion?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("actaPosesion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get('tipo')?.disable();
    this.formaForm.get('numero')?.disable();
  }

  //TODO:borrar validators
  clearValidators() {
    const controlesAEliminar = ['fechaExpTarjeta', 'institucion', 'profesional', 'profesion', 'profesionLista'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    }
  }

  regresarResumen() {
    //this.alert.showSssps();
    this.ssps.saveLocalStorage("regresaFormulario", "true")
    this.router.navigate(['/']);
  }

  //TODO:creacion de forms arrays
  get documentsDocuments() {
    return this.formaForm.get('documentos') as FormArray;
  }

  //TODO:crear botones
  crearButtons() {
    const estilos = {
      fondo: '#ff4d4d', colores: '#fff', title: 'Seleccionar archivo', subtitle: 'El formato de documento permitido para cargar es .PDF', accept: ".pdf"
    }
    if (this.documentsDocuments.controls.length > 0) {
      for (let i = 0; i < this.documentsDocuments.controls.length; i++) {
        this.buttons[i] = estilos;
        this.validateformats[i] = false;
      }
    }
  }

  //TODO agrega controls con valor requerido
  addControlDocument() {
    this.documentsDocuments.push(this.fb.control(null));
  }


  /* funcion para validacion de campos*/
  camposvalidos(campo: any) {
    return this.formaForm.controls[campo].errors && this.formaForm.controls[campo].touched;
  }


  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  /*ingreso y validacion de datos de formulario enviuo de los mismos localstorage*/
  ngsubmit() {
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          this.logger.log("error", key)
          invalidControl?.focus();
          return;
        }
      });
      return;
    }

    let { primerNombre, segundoNombre, primerApellido, segundoApellido, correo1, correo, profesional, institucion, fechaExpTarjeta,
      profesion, fechaExpDocumento, celular, telefono, extension, indicativo,
      departamento, municipio, cargoEmpresa, fechaNombramiento, actaPosesion, profesionLista } = this.formaForm.getRawValue();

    let datos = {
      primerNombre,
      segundoNombre,
      primerApellido,
      segundoApellido,
      correo: correo,
      correo1: correo1,
      documentos: this.buttons.filter((f: { file: any; }) => f.file),
      tarjetaProfesional: profesional,
      institucionGrado: institucion,
      fechaExpTarjetaProfesional: fechaExpTarjeta,
      fechaNombramiento,
      fechaExpDocumento,
      profesion: this.otraProfesion ? profesion : profesionLista,
      ip: this.ssps.ip,
      telefono,
      extension,
      indicativo,
      celular,
      departamento,
      municipio,
      cargoEmpresa,
      representanteLegal: this.solicitante.representanteLegal,
      actaPosesion
    }

    Object.assign(this.solicitante, datos);
    this.ssps.saveLocalStorage("solicitante", JSON.stringify(this.solicitante));
    if (this.ssps.getLocalStorage("solicitudId")) {
      this.componentesService.emitircambio("actualizarOrden");
    }
    if (this.usuario.dispo.toUpperCase() !== "PKCS#10") {
      this.componentesService.emitircambio("contacto");
      this.router.navigate(['/flujo/entrega']);
    }
    else {
      this.componentesService.emitircambio("infoperso");
      this.router.navigate(['/flujo/contacto']);
    }
  }

  //TODO:validaciones de fechas
  fechas(dato: any, campo: any) {
    if (dato === undefined || dato === null) return;
    let valida = /^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/-](?:0?[1-9]|1[0-2])|(?:29|30)[/-](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/-](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/-]0?2[/-](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/;
    let errores: boolean = false;

    if (campo === "fechaExpTarjeta") {
      if (valida.test(dato) == false) {
        this.statusFechaExpTarjeta = true;
      } else {
        this.statusFechaExpTarjeta = false;

      }
    }
    if (campo === "fechaNombramiento") {
      if (valida.test(dato) == false) {
        this.statusFechaNombramiento = true;
      } else {
        this.statusFechaNombramiento = false;

      }
    }
    if (campo === "fechaExpDocumento") {
      if (valida.test(dato) == false) {
        this.statusFechaExpDocumento = true;
      } else {
        this.statusFechaExpDocumento = false;
      }
    }

  }


  /* estado de botones logos cambiantes*/
  estado(valor: string) {
    if (this.cantidad == 0) {
      //   this.cambiologo2 = true;
      //  this.cambiologo = false;
      this.cantidad = this.cantidad + 1;
    }

  }

  //cambio de estilos animacion boton archivos
  validar() {

    return (this.cambiologo = true) ? ' animate__animated animate__fadeIn animate__fast' : 'animate__animated animate__fadeOut animate__fast';
  }

  //TODO:implementacion de creacion de buttos
  CreateDocument(documentName: string, id: String) {
    let indice = this.buttons.findIndex((f: { id: String; }) => f.id === id);
    this.logger.log("indicie", indice)
    if (indice > -1) {
      this.buttons = this.buttons.filter((f: { id: String; }) => f.id !== id)
      this.documentsDocuments.removeAt(indice)
    }
    else {
      const estilos = {
        fondo: '#ff4d4d', colores: '#fff', title: 'Seleccionar archivo',
        subtitle: 'El formato de documento permitido para cargar es .PDF',
        name: documentName,
        id: id,
        accept: ".pdf"
      }
      this.buttons.push(estilos)
      this.addControlDocument();
    }
  }

  ExisteDocumento(id: String) {
    return this.solicitante.documentos.filter((f: { id: String; }) => f.id === id).length > 0
  }

  ExisteDocumentoCargado(id: String) {
    return this.solicitante.documentosCargados && this.solicitante.documentosCargados.filter((f: { id: String; }) => f.id === id).length > 0
  }

  //TODO:vision de documents
  documentDocs(event: any) {
    this.showDocumentsB = true;
  }


  /*validacion de salerta campos iguales*/
  get correo2NoValido() {
    const pass1 = this.formaForm.get('correo')!.value;
    const pass2 = this.formaForm.get('correo1')!.value;

    return (pass1 === pass2) ? false : true;
  }



  /*validar tamaño de archivo*/
  getFileDetails(file: any, index: number) {

    let fileToUpload = <File>file[0];

    const botonActualizado = { ...this.buttons[index] };

    let tamañoEnBytes = fileToUpload.size;
    let tamañoEnMegabytes = tamañoEnBytes / (1024 * 1024);

    if (fileToUpload.type == "application/pdf") {
      if (tamañoEnMegabytes > 10) {
        botonActualizado.fondo = '#ff4d4d';
        botonActualizado.colores = '#fff';
        botonActualizado.title = 'Seleccionar archivo';
        botonActualizado.subtitle = 'El formato de documento permitido para cargar es .PDF';
        this.buttons[index] = botonActualizado;
        this.validateformats[index] = true;
      }
      if (tamañoEnMegabytes < 10) {
        const reader = new FileReader()
        reader.onload = () => {
          const b64 = reader.result as string
          botonActualizado.file = b64
        }

        if (fileToUpload) {
          reader.readAsDataURL(fileToUpload)
        }

        botonActualizado.fondo = '#33f0b7';
        botonActualizado.colores = '#2d3d3d';
        botonActualizado.title = 'Documento cargado con exito';
        botonActualizado.subtitle = fileToUpload.name;

        this.buttons[index] = botonActualizado;
        this.validateformats[index] = false;
        this.cantidad += 1;
      }
    }
    else {
      botonActualizado.fondo = '#ff4d4d';
      botonActualizado.colores = '#fff';
      botonActualizado.title = 'Seleccionar archivo';
      botonActualizado.subtitle = 'El formato de documento permitido para cargar es .PDF';
      this.buttons[index] = botonActualizado;
      this.validateformats[index] = true;

    }

  }

  //TODO:implementacion de datos
  isValidFieldInArray(formArray: FormArray, index: number) {
    return formArray.controls[index].errors
      && formArray.controls[index].touched;
  }

  mapearDatos() {
    /*asignacion de valores desde el cervicio crm*/
    this.solicitante = JSON.parse(this.ssps.getLocalStorage('solicitante')!);
    this.usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
    // if (!this.actualizacion) {
    //   let datosNuevos: any = {
    //     celular: this.usuario.celular,
    //     correo: this.usuario.correo,
    //     correo1: this.usuario.correo1,
    //     departamento: this.usuario.departamento,
    //     municipio: this.usuario.municipio,
    //     telefono: this.usuario.telefono,
    //     extension: this.usuario.extension,
    //     indicativo: this.usuario.indicativo,
    //   }
    //   Object.assign(this.solicitante, datosNuevos);
    // }
    if (this.ssps.getLocalStorage('pago')) {
      this.pago = JSON.parse(this.ssps.getLocalStorage('pago')!)
    }
    this.formaForm.get("departamento")!.setValue(this.solicitante.departamento, {
      emitEvent: true,
      emitModelToViewChange: true
    });
    this.logger.log("solicitante page", this.solicitante)
    //Mapear documentos del localStorage
    if (this.solicitante.documentos) {
      this.buttons = this.solicitante.documentos
      this.cantidad = this.solicitante.documentos.length
      this.solicitante.documentos.forEach((element: any, index: any) => {
        this.validateformats[index] = false;
        this.addControlDocument();
      });
    }
    else {
      this.solicitante.documentos = []
    }
    if (this.usuario.politica == "Profesional Titulado") {
      this.totalCantidad = 2;
      this.profesional = true;
      this.logger.log("fecha ExpTarjeta", this.solicitante.fechaExpTarjetaProfesional)
      if (this.solicitante.fechaExpTarjetaProfesional && this.solicitante.fechaExpTarjetaProfesional !== '' &&
        !this.solicitante.fechaExpTarjetaProfesional.includes("/")) {
        this.fechaExpTarjeta = this.datepipe.transform(new Date(this.solicitante.fechaExpTarjetaProfesional), "dd/MM/yyyy")!
      }
      else if (this.solicitante.fechaExpTarjetaProfesional && this.solicitante.fechaExpTarjetaProfesional.includes("/")) {
        this.fechaExpTarjeta = this.solicitante.fechaExpTarjetaProfesional
      }

      const control = this.formaForm.get("cargoEmpresa");
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    }

    if (this.usuario.politica == "Función Publica") {
      this.totalCantidad = 2;
      this.funcionPublica = true;
      this.logger.log("fecha posesion", this.solicitante.fechaNombramiento)
      if (this.solicitante.fechaNombramiento && this.solicitante.fechaNombramiento !== '' &&
        !this.solicitante.fechaNombramiento.includes("/")) {
        this.fechaNombramiento = this.datepipe.transform(new Date(this.solicitante.fechaNombramiento), "dd/MM/yyyy")!
      }
      else if (this.solicitante.fechaNombramiento && this.solicitante.fechaNombramiento.includes("/")) {
        this.fechaNombramiento = this.solicitante.fechaNombramiento
      }
    }
    if (this.solicitante.fechaExpDocumento && this.solicitante.fechaExpDocumento !== '' &&
      !this.solicitante.fechaExpDocumento.includes("/")) {
      this.fechaExpDocumento = this.datepipe.transform(new Date(this.solicitante.fechaExpDocumento), "dd/MM/yyyy")!
    }
    else if (this.solicitante.fechaExpDocumento && this.solicitante.fechaExpDocumento.includes("/")) {
      this.fechaExpDocumento = this.solicitante.fechaExpDocumento
    }
    if (this.usuario.politica != "Profesional Titulado") {
      this.clearValidators();
    }
    if (this.usuario.politica != "Función Publica") {
      const control = this.formaForm.get("fechaNombramiento");
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }

      const controlPosesion = this.formaForm.get("actaPosesion");
      if (controlPosesion) {
        controlPosesion.clearValidators();
        controlPosesion.updateValueAndValidity();
      }
    }
    this.OnChanges();
    this.profesionLista = this.solicitante.profesion
    setTimeout(() => {
      var existeProfesion = this.profesiones.filter(s => s === this.solicitante.profesion)
      this.deshabilitarCmaposFinalizados()
      if (existeProfesion.length === 0 && this.usuario.politica === "Profesional Titulado") {
        this.otraProfesion = true
        const profesionForm = this.formaForm.get("profesion");
        if (profesionForm) {
          this.profesionLista = "OTRA"
          profesionForm.patchValue("OTRA")
          profesionForm.updateValueAndValidity();
        }
        this.addValidatorsProfesion()
      }
      else {
        this.clearValidatorsProfesion()
      }
    }, 2000);
  }

  deshabilitarCmaposFinalizados() {
    Object.keys(this.formaForm.controls).forEach(key => {
      if (key !== "correo" && key !== "correo1" && (this.usuario.solicitudFinalizada === "APROBADO" || this.usuario.solicitudFinalizada === "FINALIZADO")) {
        this.formaForm.controls[key]?.disable({ onlySelf: true })
      }
      else if (this.usuario.solicitudFinalizada === "ASIGNADO") {
        this.formaForm.controls[key]?.disable({ onlySelf: true })
      }
    });
  }

  noPuntotel(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comastel = res;
      })
  }


  noPuntote(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comastel2 = res;
      })
  }

  noPuntoex(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comastex = res;
      })
  }

  quitarceros2(numero: string) {

    this.solicitante.telefono = numero.replace(/^0+/, '');

  }

  quitarceros3(numero: string) {

    this.solicitante.extension = numero.replace(/^0+/, '');

  }

  quitarceros4(numero: string) {
    this.solicitante.celular = numero.replace(/^0+/, '');
  }

  noPuntoComacod(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comas = res;
      })
  }
  /*carga datos select*/
  indicativo() {
    this.ssps.indicativos()
      .subscribe((res: any) => {
        this.indicativos = res;
      });

  }

  noPuntoComa(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comasnit = res;
      })
  }

  departamentos() {
    this.ssps.departamentos()
      .subscribe((res: any) => {
        this.muni = res.sort((a: any, b: any) => (a.Departamento < b.Departamento ? -1 : 1));
        this.depar = [...new Map(res.map((item: { [x: string]: any; }) => [item['Departamento'], item])).values()].sort((a: any, b: any) => (a.Departamento < b.Departamento ? -1 : 1));
        setTimeout(() => {
          let departamento = this.solicitante.departamento
          this.formaForm.get("departamento")!.setValue(this.solicitante.departamento, {
            emitEvent: true,
            emitModelToViewChange: true
          });
          this.munifi = this.muni.filter(function (obj: any, index) {
            return obj.codigo_dep == +departamento;
          }).sort((a: any, b: any) => (a.Municipio < b.Municipio ? -1 : 1))
        }, 1000);
      });
  }
  OnChanges() {
    this.formaForm.valueChanges.subscribe(valores => {
      let valor: number = valores.departamento;
      this.munifi = this.muni.filter(function (obj: any, index) {
        return obj.codigo_dep == valor;
      }).sort((a, b) => (a.Municipio < b.Municipio ? -1 : 1))
    });
  }

  redireciona() {
    this.router.navigate(['/flujo/empresa']);
    this.componentesService.emitircambio("reveinfobasi");
  }

  changeDepartamento($event: any) {
    let departamento = $event.target.value;
    this.munifi = this.muni.filter(function (obj: any, index) {
      return obj.codigo_dep == +departamento;
    }).sort((a, b) => (a.Municipio < b.Municipio ? -1 : 1))
    setTimeout(() => {
      this.usuario.municipio = undefined
      this.formaForm.get("municipio")!.setValue(undefined, {
        emitEvent: true,
        emitModelToViewChange: true
      });
    }, 500);
  }

  cargarProfesiones() {
    this.ssps.cargarProfesiones()
      .subscribe((res: any) => {
        this.profesiones = res
      });
  }

  clearValidatorsProfesion() {
    const controlesAEliminar = ['profesion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        control.clearValidators();
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  addValidatorsProfesion() {
    const controlesAEliminar = ['profesion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        control.addValidators([
          Validators.required,
          Validators.maxLength(60)
        ])
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  validarProfesion($event: any) {
    let profesionSeleccionada = $event.target.value;
    if (profesionSeleccionada === "OTRA") {
      this.addValidatorsProfesion()
      this.otraProfesion = true
      this.solicitante.profesion = ""
    }
    else {
      this.clearValidatorsProfesion()
      this.otraProfesion = false
    }
  }
}

