import { Injectable } from '@angular/core';
import { SspsService } from './ssps.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {

  public listadop: any[] = [];
  public directivaListado: any[] = [];
  public directiva: any[] = [];

  constructor(private ssps: SspsService) { }

  //TODO:validaciones de politicas
  questionsPolices(index: number, option: string, person: string) {
    this.listadop[index].respuesta = option
    if (person === 'natural') {
      if (index == 0 && option == 'si') {
        this.directiva = this.directivaListado.filter(res => res.politica == "Profesional Titulado");
        this.listadop[1].status = false;
      }

      if (index == 0 && option == 'no') {
        this.listadop[1].status = true;
        this.directiva = []
      }      

      if (index == 1 && option == 'si') {
        this.directiva = this.directivaListado.filter(res => res.politica == "Persona Natural PKCS10");
      }

      if (index == 1 && option == 'no') {
        this.directiva = this.directivaListado.filter(res => res.politica == "Persona Natural");
      }
    }
    if (person === 'juridica') {
      if (index == 0) {
        //  filter = 1;
        this.listadop[1].status = true;
        this.listadop[2].status = false;
        this.listadop[3].status = false;
        this.listadop[4].status = false;
        this.directiva = []
        this.ssps.saveLocalStorage("tipoEmpresa", option === 'si' ?  "privado": "publico")
      }

      if (index == 1 && option == 'no') {
        this.listadop[2].status = false;
        this.listadop[3].status = false;
        this.listadop[4].status = false;
        this.directiva = this.directivaListado.filter(res => res.politica == "Profesional Titulado");
      }

      if (index == 1 && option == 'si' && this.listadop[0].respuesta == "si") {
        this.listadop[2].status = true;
        this.listadop[3].status = false;
        this.directiva = []
      }

      if (index == 1 && option == 'si' && this.listadop[0].respuesta == "no") {
        this.listadop[2].status = false;
        this.listadop[3].status = true;
        this.directiva = []
      }

      if (index == 2 && option == 'si') {
        this.listadop[3].status = false;
        this.listadop[4].status = true;
        this.directiva = []
      }

      if (index == 3 && option == 'si') {
        this.listadop[4].status = true;
        this.directiva = []
      }

      if (index == 3 && option == 'no') {
        this.listadop[4].status = false;
        this.directiva = this.directivaListado.filter(res => res.politica == "Pertenencia a Empresa");
      }

      if (index == 2 && option == 'no') {
        this.listadop[3].status = false;
        this.listadop[4].status = false;
        this.directiva = this.directivaListado.filter(res => res.politica == "Pertenencia a Empresa");
      }

      if (index == 4 && option == 'no' && this.listadop[3].respuesta == 'no') {
        this.directiva = this.directivaListado.filter(res => res.politica == "Pertenencia a Empresa");
      }

      if (index == 4 && option == 'no' && this.listadop[3].respuesta == 'si') {
        this.directiva = this.directivaListado.filter(res => res.politica == "Función Publica");
      }

      if (index == 4 && option == 'si') {
        this.directiva = this.directivaListado.filter(res => res.politica == "Persona Jurídica PKCS10");
      }

      if (index == 4 && option == 'no' && this.listadop[2].respuesta == 'si') {
        this.directiva = this.directivaListado.filter(res => res.politica == "Representante legal");
      }
    }
  }
}
