import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { environment as env } from "src/environments/environment"

@Component({
    selector: 'app-sinpago',
    templateUrl: './sinpago.component.html',
    styleUrls: ['./sinpago.component.css'],
    standalone: false
})
export class SinPagoComponent implements OnInit {
  @Input() public mensaje: any
  @Input() public titulo: any
  constructor(private alert:AlertsService,
              private router:Router,
              private componets:ComponentesService) { }

  ngOnInit(): void {
  }
  
  //TODO:cerrar modal
  close(){
    this.alert.closesshowReporter();
    let externalUrl = `${ env.urlWebCerti }`;
    localStorage.clear()
    window.location.replace(externalUrl);      
  }
}
