import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { environment as env } from "src/environments/environment"
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
declare var window: any;

@Component({
  selector: 'app-validaciones',
  templateUrl: './validaciones.component.html',
  styleUrls: ['./validaciones.component.css']
})
export class ValidacionesComponent implements OnInit {
  estados: boolean = false;
  rutas: any;
  personPj: boolean = false;
  personPn: boolean = true;

  constructor(private fb: FormBuilder,
    private componentesService: ComponentesService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private alert: AlertsService,
    private ssps: SspsService
  ) {

    /*captura de rutas activas*/
    this.activateRoute.queryParams
      .subscribe((res: any) => {
        if (res.route == undefined) {
          return;
        }
      });
    if (this.ssps.getLocalStorage("regresaFormulario") !== "true" && this.ssps.getLocalStorage('tipoClienteSugerido') !== 'Entidad/Empresa') {
      localStorage.clear()
    }
  }

  ngOnInit(): void {
    if (this.router.url !== null && this.router.url !== undefined &&
      this.router.url.toLocaleLowerCase().includes("%3fid%3d")) {
      let idQuery = this.router.url.toLocaleLowerCase().substring(this.router.url.toLocaleLowerCase().lastIndexOf("%3fid%3d") + 8)
      const queryParams: any = { id: idQuery }
      this.alert.closesFiels();
      this.alert.closesSpiner();
      setTimeout(() => {
        this.router.navigate(
          ['/flujo/infobasi'],
          {
            relativeTo: this.activateRoute,
            queryParams,
            queryParamsHandling: 'merge', // remove to replace all query params by provided
          }
        );
      }, 500);
      return;
    }
    if (this.ssps.getLocalStorage("regresaFormulario") === "true") {
      this.alert.closesFiels();
      this.alert.showSssps();
    }
    else {
      this.alert.showSpiner("No olvides tener tu documento de identificación a la mano para poder continuar con el proceso")
      const querySolicitud = this.activateRoute.snapshot.queryParamMap.get('id');
      const queryRuta = this.activateRoute.snapshot.queryParamMap.get('redirect');
      if (querySolicitud) {
        this.alert.closesFiels();
        this.alert.closesSpiner();
        setTimeout(() => {
          const queryParams: any = { id: querySolicitud }
          if (queryRuta) {
            queryParams.redirect = queryRuta
          }
          this.router.navigate(
            ['/flujo/infobasi'],
            {
              relativeTo: this.activateRoute,
              queryParams,
              queryParamsHandling: 'merge', // remove to replace all query params by provided
            }
          );
        }, 500);
      }
      else if (queryRuta !== undefined && queryRuta !== null && queryRuta !== "") {
        this.alert.closesFiels();
        this.alert.closesSpiner();
        this.router.navigate([queryRuta]);
      }
      else {
        if (this.ssps.getLocalStorage('tipoClienteSugerido')! === 'Entidad/Empresa') {
          this.statusPj()
        }
        else {
          this.statusPn()
        }
        this.alert.closesSpiner();
      }
      localStorage.removeItem('tipoClienteSugerido')
    }
  }

  /*implementacion eventos modal */
  abrirmodal() {
    this.componentesService.abrirmodal();
  }


  mostrarevento() {
    let evento = this.componentesService.estados;

    if (evento) {
      this.componentesService.abrirmodal();
      this.componentesService.estados = false;
    }
  }

  //TODO:Implementacion de cierre
  closeModal() {
    let externalUrl = `${env.urlWebCerti}`;
    this.alert.closesFiels();
    localStorage.clear()
    window.location.replace(externalUrl);
  }

  statusPj() {
    this.personPj = true;
    this.personPn = false;

    //this.personPj = false;
    //this.personPn = true;
  }

  statusPn() {
    this.personPj = false;
    this.personPn = true;
  }

}
