import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';

@Component({
  selector: 'app-validacion-identidad',
  templateUrl: './validacion-identidad.component.html',
  styleUrls: ['./validacion-identidad.component.css']
})
export class ValidacionIdentidadComponent implements OnInit {

  constructor(private alert: AlertsService, private ssps: SspsService,
    private activateRoute: ActivatedRoute, private router: Router
  ) { }

  ngOnInit(): void {
    if (this.ssps.getLocalStorage("solicitudId")) {
      const origen = this.activateRoute.snapshot.queryParamMap.get('origen');
      const boton = this.activateRoute.snapshot.queryParamMap.get('boton');
      this.router.navigate([], {
        queryParams: {
          'origen': null,
          'boton': null
        },
        queryParamsHandling: 'merge'
      })
      if(origen && boton){
        this.alert.showFieldsValidacionIdentidad(origen, boton);
      }
      else{
        this.alert.showFieldsValidacionIdentidad("", null);
      }      
    }
    else {
      this.alert.showAlertaSalirMensajeValidarIdentidad(false, "")
    }
  }
}
