<div class="animate__animated animate__fadeIn animate__fast">
       <div class="modal-header">
              <h5 class="textomodal lh-1">
                     ¡Empieza el camino hacia tu firma digital!
              </h5>
       </div>
       <div style="padding: 5px; background-color: #cbc9c6; margin: 5px 25px 0 25px;">
              <i class="fas fa-info-circle icon-primary icon-primary"></i>&nbsp;Completa los siguientes campos con la
              información del solicitante.
       </div>
       <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()" id="form1">
              <!--listas negras alert -->
              <div class="margen2">

                     <app-identificacion [formaForm]="formaForm"></app-identificacion>

                     <app-nombre-apellido [formaForm]="formaForm"></app-nombre-apellido>

              </div>

                     <app-politicas [formaForm]="formaForm"></app-politicas>

              <div class="margen2" style="padding-bottom:0px ;">
                     <div class="mb-3" style="line-height: 10px; margin-bottom: 5px !important;">
                            <label for="label" class="form-label">* Campos obligatorios</label>
                     </div>
              </div>
              <div class="modal-footer">
                     <button type="button" class="btn btn-secondary" (click)="cancelar()">Anterior</button>
                     <button type="submit" class="btn btn-primary" [disabled]="formaForm.invalid">Siguiente</button>
              </div>
       </form>
</div>