<div class="modal-header">
    <h4 class="titulos" style="text-align: center;">{{titulo}}</h4>
</div>
<div class="modal-body  text-center ms-4 me-4">
    <p style="font-size: 16pt;">{{mensaje}}
    </p>
    <button type="submit" class="btn btn-primary" (click)="descargarArchivo()"
        *ngIf="certificado.estado == 'DESCARGA'">{{certificado.tipo !== 'PFX' ? 'Descargar archivo .CER' : "Descargar
        archivo .PFX"}}</button>
    <button style="margin-left: 15px;" type="submit" class="btn btn-secondary" (click)="generarPfxModal()"
        *ngIf="generarpfx">Generar PFX</button>
    <button style="margin-left: 15px;" type="submit" class="btn btn-secondary" (click)="close()"
        *ngIf="certificado.estado == 'FINALIZADO' || botonCerrar">Cerrar</button>
</div>
<div class="modal-footer">

</div>