import { Component, Input, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { environment as env } from "src/environments/environment"
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alerta-salir-valida-identidad',
  templateUrl: './alerta-salir-valida-identidad.component.html',
  styleUrls: ['./alerta-salir-valida-identidad.component.css']
})
export class AlertaSalirValidaIdentidadComponent implements OnInit {
  @Input() public mensajeSalir: any
  @Input() public cancelarValidacionFacial: any
  @Input() public usuarioValida: any
  @Input() public vista: any
  @Input() public urlRedireccionFlujoValida: any
  @Input() public botonFinalizaIdentidad: any
  constructor(private alert: AlertsService,
    private ssps: SspsService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  //TODO:cerrar modal
  enviarNotificacion() {
    this.activeModal.close(false);
    //Llamar servicio de notificación
    //Mostrar alerta de revisar correo
    if (this.cancelarValidacionFacial) {
      //Consumir servicio de intento
      if (this.usuarioValida !== null) {
        this.alert.showSpiner("Procesando petición, por favor espere un momento")
        this.guardarIntentoFacial("Operación cancelada por usuario", false)
      }
    }
    else {
      this.alert.showAlertaSalirMensajeValidarIdentidad(this.urlRedireccionFlujoValida !== null &&
        this.urlRedireccionFlujoValida !== "", this.urlRedireccionFlujoValida)
    }
  }

  volverValidaIdentidad() {
    this.activeModal.close(false);
    if (this.vista === "form") {
      this.alert.showFieldsValidacionIdentidad(this.urlRedireccionFlujoValida, this.botonFinalizaIdentidad)
    }
    else {
      this.alert.showAlertaValidarIdentidad(false)
    }
  }

  guardarIntentoFacial(respuestaServicio: any, exitoso: boolean) {
    let data = {
      numeroDocumento: this.usuarioValida.numero,
      fuente: "FACIAL",
      solicitudTitularId: this.usuarioValida.idSolicitudTitular,
      exitoso: exitoso,
      respuesta: respuestaServicio
    }
    this.ssps.guardarIntento(data)
      .subscribe({
        next: (res: any) => {
          this.alert.closesSpiner()
          this.alert.showAlertaSalirMensajeValidarIdentidad(true, this.urlRedireccionFlujoValida)
        },
        error: (error) => {
          console.error('Error en subscriber guardarIntento', error)
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
             (error.message && error.message.includes("timed out"))) {
            this.guardarIntentoFacial(respuestaServicio, exitoso);
          }
          else {
            this.alert.closesSpiner()
            console.error('Error en subscriber preguntasValidacion', error)
            let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
              error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                error.error.message ? error.error.message :
                  (error.message ? error.message : "No fue posible realizar la petición")) :
              (error.message ? error.message : "No fue posible realizar la petición")
            this.alert.showError(mensaje, false, null, false, null, null)
          }
        }
      })
  }
}
