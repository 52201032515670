<div class="modal-header">
  <h5 class="textomodal textomodal">
    Estás a punto de entrar al mundo de las firmas digitales.
  </h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <div class="modal-body">
    <div id="carouselExampleControlsNoTouching" class="carousel slide" data-bs-touch="false" data-bs-interval="false">
      <div class="carousel-inner">
        <!-- implementacion vista preguntas 0-->
        <div class="carousel-item active">
          <div class="margenuni animate__animated animate__fadeIn animate__fast">
            <h4 class="lh-1 text-center textomodal2">
              Te ayudamos a elegir, selecciona la mejor opción para ti.
            </h4>
            <table class="table mt-4">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col">SI</th>
                  <th scope="col">NO</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let pre of  questionsPolices index as t">
                  <th scope="row" *ngIf="pre.status">
                    <i class="fa-solid fa-angle-right" style="color: #ef9308"></i>
                  </th>
                  <td *ngIf="pre.status">{{ pre.pregunta }}</td>

                  <td *ngIf="pre.status">
                    <input class="form-check-input mt-0" type="radio" [(ngModel)]="pre.respuesta" value="si"
                      name="ques_{{t}}" (change)="radios($event,t)" />
                  </td>
                  <td *ngIf="pre.status">
                    <input class="form-check-input mt-0" value="no" [(ngModel)]="pre.respuesta" name="ques_{{t}}"
                      type="radio" (change)="radios($event,t)" />
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>

        <!-- implementacion vista historial 1-->
        <div class="carousel-item">
          <div class="margenuni animate__animated animate__fadeIn animate__fast overwidth">
            <h4 class="textomodal lh-1" style="color: #999696"
              *ngIf="!newstatus && !mostrarReposiciones && compras.length > 0">¡A
              continuación encuentras los
              productos que has comprado
              en CERTI!</h4>
            <h4 class="textomodal lh-1" style="color: #999696"
              *ngIf="!newstatus && mostrarReposiciones && compras.length > 0">¡A continuación
              encuentras los
              productos que puede solicitar reposición!</h4>
            <h3 class="textomodal lh-1 text-center" style="color: black" *ngIf="!newstatus && !mostrarReposiciones">
              Historial de compra
            </h3>
            <h3 class="textomodal lh-1 text-center" style="color: black" *ngIf="!newstatus && mostrarReposiciones">
              Reposición de certificados
            </h3>
            <table class="table mt-4" *ngIf="compras.length > 0  && !newstatus">
              <thead>
                <tr>
                  <th scope="col">Fecha de entrega</th>
                  <th scope="col">Política</th>
                  <th scope="col" colspan="3">Mecanismo de entrega</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let history of compras; index as i">
                  <td>{{ history.fecha }}</td>
                  <td>{{(history.politica | titlecase).replace("Pkcs10", "PKCS10") }}</td>
                  <td>{{ history.tipo.replace("FISICO", "FÍSICO").replace("Fisico", "Físico").replace("fisico",
                    "físico") | uppercase }}{{ history.tipo.toUpperCase() === "PKCS#10" &&
                    history.uso !== undefined && history.uso !== "" ? ' (' + history.uso +')' : ''}}
                  </td>
                  <td>
                    <span *ngIf="!mostrarReposiciones" class="badge rounded-pill text-bg-primary ms-3"
                      (click)="showCart(i)" (mouseDown)="showCart(i)">Volver
                      a
                      adquirir</span>
                    <span *ngIf="mostrarReposiciones" class="badge rounded-pill text-bg-primary ms-3"
                      (click)="showCartReposicion(i)" (mouseDown)="showCartReposicion(i)">Pedir reposición</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <table class="table mt-4" *ngIf="compras.length === 0  && !newstatus"> -->
            <table class="table mt-4" *ngIf="false">
            <thead>
              <tr>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>No se encontraron compras prevías como {{tipoCliente.toLowerCase() === 'natural' ? 'Persona ' +
                  tipoCliente : tipoCliente }},
                  puede verificar realizando el proceso como {{tipoClienteSugerido.toLowerCase() === 'natural' ?
                  'Persona ' + tipoClienteSugerido : tipoClienteSugerido }}</td>
                <td>
                  <span class="badge rounded-pill text-bg-primary ms-3" (click)="intentarPersonaSugerida()"
                    (mouseDown)="intentarPersonaSugerida()">Reintentar</span>
                </td>
              </tr>
            </tbody>
            </table>

            <div style="text-align: left; float: left;" *ngIf="aplicaReposicion && !newstatus">
              <span *ngIf="!mostrarReposiciones" class="badge rounded-pill text-bg-primary ms-3"
                (click)="showReposiciones()">Quiero pedir reposición de un certificado digital</span>
              <span *ngIf="mostrarReposiciones" class="badge rounded-pill text-bg-primary ms-3"
                (click)="showReposiciones()">Quiero volver a adquirir un certificado digital</span>
            </div>

            <div style="text-align: right; float: right" *ngIf="compras.length > 0 && !newstatus">
              <span class="badge rounded-pill text-bg-primary ms-3" (click)="showNewProccess()">Quiero adquirir otro
                tipo de certificado digital</span>
            </div>

            <h4 class="textomodal lh-1 text-center" style="color: #999696" *ngIf="newstatus">
              Políticas sugeridas
            </h4>
            <br />
            <br />
            <form [formGroup]="formaForm" *ngIf="newstatus">
              <div class="row">
                <div class="col-lg-6" style="width: 100% !important;" *ngFor="let poli of directivesActive">
                  <div class="mb-1 form-check">
                    <input id="exampleCheck2" type="radio" class="form-check-input" [value]="poli.id"
                      formControlName="checks" (change)="politica(poli)" [checked]="poli.id === usuario.idpoli" />
                    <a attr.data-title="{{poli.desciption}}" (click)="politica(poli)">{{poli.politica}}</a>
                  </div>
                </div>

                <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                  *ngIf="camposvalidos('checks')">
                  <p><small>selecione un solo elemento</small></p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- implementacion vista mecanismo 2-->
        <div class="carousel-item">
          <div class="margenuni animate__animated animate__fadeIn animate__fast">
            <p class="lh-1">
              <small>La opción de persona natural permite solicitar certificados de
                firma digital a titulo personal, aquí podrá solicitar
                certificados para personas natural o solicitar el certificado
                profesional para solicitantes.
              </small>
            </p>

            <h4 class="textomodal lh-1 text-center" style="color: #999696">
              Mecanismo de Entrega
            </h4>
            <br />
            <form [formGroup]="formaForm2" (ngSubmit)="ngsubmit()">
              <div class="centrado">
                <table>
                  <tr *ngFor="let pre of preciosd; index as i">
                    <td>
                      <input type="radio" class="form-check-input" value="{{ pre.id }}"
                        (change)="mecanisEntregaChange($event, i)" formControlName="dispositivo" />
                    </td>
                    <td><img [src]="pre.img" /></td>
                    <td *ngIf="pre.nombre.toLowerCase() !== 'pkcs#10'">{{ pre.nombre | titlecase }}</td>
                    <td *ngIf="pre.nombre.toLowerCase() === 'pkcs#10'">{{ pre.nombre | uppercase }}</td>
                  </tr>

                  <tr>
                    <td><label class="form-label">Vigencia</label></td>
                    <td>
                      <select class="form-select" formControlName="anos" #costvalue
                        (change)="ChagePrice(costvalue.value)">
                        <option class="form-control" *ngFor="let vige of vigencias" [value]="vige.anos">
                          {{ vige.anos }}
                        </option>
                      </select>
                    </td>

                    <td>{{ tiempo }}</td>
                  </tr>
                  <tr *ngIf="tipodis.dispo.toLowerCase() === 'pkcs#10' && tipodis.anos > 0">
                    <td><label class="form-label">Uso</label></td>
                    <td>
                      <select class="form-select" formControlName="uso" (change)="cambioUso($event.target)">
                        <option class="form-control" *ngFor="let uso of usosCertificados" [value]="uso.id">
                          {{ uso.descripcion }}
                        </option>
                      </select>
                    </td>
                    <td *ngIf="usoCertificado.descripcion.toLowerCase() === 'otros'">
                      <input type="text" class="form-control" placeholder="Especifique otro uso"
                        formControlName="otroUso" [class.is-invalid]="camposvalidos2('otroUso')" maxlength="100"
                        #inputRefOtroUso>
                      <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                        *ngIf="camposvalidos2('otroUso')">Campo no válido</div>
                    </td>
                  </tr>
                </table>
              </div>
              <!--implementacion de politicas-->
              <!--implementacion errores-->
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comas">
                El campo no puede tener comas
              </div>
              <h4 class="textomodal lh-1 text-center" style="color: #999696">
                Políticas sugeridas
              </h4>
              <br />

              <div class="row">
                <div class="col-lg-6">

                  <div class="mb-1 form-check" *ngIf="vista">

                    <input id="exampleCheck1" type="radio" class="form-check-input" formControlName="checks2"
                      [value]="elementose" [(ngModel)]="elementose" [attr.disabled]="false" />
                    <label class="form-check-label" for="exampleCheck1">
                      <p data-bs-toggle="tooltip" data-bs-placement="top" title="{{ elementose?.desciption }}">
                        <small>{{ elementose?.politica }}</small>
                      </p>
                    </label>
                  </div>

                </div>

                <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="check">
                  <p><small>Solo puede ingresar dos dígitos</small></p>
                </div>
                <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="">
                  <p><small>Selecione una sola politica</small></p>
                </div>

                <div class="row">
                  <br />
                  <div class="col">
                    <h4 class="textomodal lh-1" style="color: #999696">
                      <p data-bs-toggle="tooltip" data-bs-placement="top"
                        title="Tener en cuenta que este valor corresponde al subtotal más IVA del bien o servicio adquirido. Se aplicarán descuentos tributarios de acuerdo con su calidad tributaria">
                        Valor subtotal más IVA
                        &nbsp; <strong style="color: #ef9308">${{ resultado | currency : "COP" : "" }}</strong>
                      </p>
                    </h4>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- implementacion vista de resumen 3-->
        <div class="carousel-item #enviar">
          <div class="margenuni animate__animated animate__fadeIn animate__fast">
            <p class="lh-1">
              <small>La opción de persona natural permite solicitar certificados de
                firma digital a titulo personal , aquí podrá solicitar
                certificados para personas natural o solicitar el certificado
                profesional para solicitantes.
              </small>
            </p>
            <h4 class="textomodal lh-1 text-center" style="color: #999696">
              Detalles de su solicitud
            </h4>
            <div class="row">
              <div>
                <table *ngIf="show" style="margin: 0 auto;">
                  <tr>
                    <td><strong>Mecanismo de Entrega</strong></td>
                    <td><i class="fa-solid fa-circle-check estilos"></i></td>
                    <td *ngIf="usuario.dispo.toLowerCase() !== 'pkcs#10'">{{ usuario.dispo | titlecase }}</td>
                    <td *ngIf="usuario.dispo.toLowerCase() === 'pkcs#10'">{{ usuario.dispo | uppercase }}</td>
                    <td *ngIf="!usuario.esReposicion" (click)="showresum()"><i
                        class="fa-solid fa-square-pen estilos"></i></td>
                    <td *ngIf="showresumeA">
                      <select [(ngModel)]="usuario.idispo" (change)="changePrices($event)"
                        class="form-select form-select-sm animate__animated animate__fadeIn">
                        <option *ngFor="let preci of preciosd" [value]="preci.id"><span
                            *ngIf="usuario.dispo.toLowerCase() !== 'pkcs#10'">{{preci.nombre | titlecase}}</span>
                          <span *ngIf="usuario.dispo.toLowerCase() === 'pkcs#10'">{{preci.nombre | uppercase}}</span>
                        </option>
                      </select>
                    </td>
                  </tr>

                  <tr></tr>
                  <tr>
                    <td><strong>Política</strong></td>
                    <td><i class="fa-solid fa-circle-check estilos"></i></td>
                    <td>{{ usuario.politica | titlecase }}</td>
                    <td *ngIf="!usuario.esReposicion" (click)="showNewProccess()"><i
                        class="fa-solid fa-square-pen estilos"></i></td>
                  </tr>

                  <tr></tr>

                  <!-- <tr *ngIf="usuario.cantidad > 0">
                    <td><strong>Cantidad</strong></td>
                    <td><i class="fa-solid fa-circle-check estilos"></i></td>
                    <td>{{ usuario.cantidad }}</td>
                    <td *ngIf="!usuario.esReposicion"><i class="fa-solid fa-square-pen estilos"></i></td>
                    <td>
                      <select class="form-select" #costdata (change)="ChagePrice(costdata.value)">
                        <option class="form-control" *ngFor="let vige of vigencias" [value]="vige.anos">
                          {{ vige.anos }}
                        </option>
                      </select>

                    </td>
                  </tr>

                  <tr></tr> -->

                  <tr>
                    <td><strong>Vigencia</strong></td>
                    <td><i class="fa-solid fa-circle-check estilos"></i></td>
                    <td>{{ usuario.anos }} {{ tiempo }}</td>
                    <td *ngIf="!usuario.esReposicion"><i class="fa-solid fa-square-pen estilos"
                        (click)="showresumB()"></i></td>
                    <td *ngIf="showresumeB">
                      <select [(ngModel)]="usuario.anos"
                        class="form-select form-select-sm animate__animated animate__fadeIn" #costdatab
                        (change)="ChagePrice(costdatab.value)">
                        <option class="form-control" *ngFor="let vige of vigencias" [value]="vige.anos">
                          {{ vige.anos }}
                        </option>
                      </select>
                    </td>
                  </tr>

                  <tr></tr>
                  <tr>
                    <td>
                      <strong>
                        <p data-bs-toggle="tooltip" data-bs-placement="top"
                          title="Tener en cuenta que este valor corresponde al subtotal más IVA del bien o servicio adquirido. Se aplicarán descuentos tributarios de acuerdo con su calidad tributaria">
                          Valor
                          subtotal más IVA
                        </p>
                      </strong>
                    </td>
                    <td style="vertical-align: top;"><i class="fas fa-info-circle"
                        ngbTooltip="Tener en cuenta que este valor corresponde al subtotal más IVA del bien o servicio adquirido. Se aplicarán descuentos tributarios de acuerdo con su calidad tributaria"></i>
                    </td>
                    <td style="vertical-align: top;">${{ resultado | currency : "COP" : "" }}</td>
                  </tr>

                  <tr></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--cierre de modal body-->
      <div class="modal-footer" *ngIf="mostrarBotones === true">
        <button type="submit" class="btn btn-secondary" data-bs-target="#carouselExampleControlsNoTouching"
          data-bs-slide="prev" (click)="anteriorFlujo()" [disabled]="anterior">
          Anterior
        </button>
        <button type="submit" class="btn btn-primary" data-bs-target="#carouselExampleControlsNoTouching"
          data-bs-slide="next" (click)="estados();verifica()" [disabled]="desactiva">
          Siguiente
        </button>
      </div>

      <!--(click)="estados()" -->
    </div>
  </div>

  <!--<div class="modal-footer">
  
</div>-->
</div>