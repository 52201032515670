import { Component, Input, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { environment as env } from "src/environments/environment"
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-alerta-salir-mensaje-valida-identidad',
    templateUrl: './alerta-salir-mensaje-valida-identidad.component.html',
    styleUrls: ['./alerta-salir-mensaje-valida-identidad.component.css'],
    standalone: false
})
export class AlertaSalirMensajeValidaIdentidadComponent implements OnInit {
  @Input() public redirigirValida: any
  @Input() public urlRedireccionFlujoValida: any
  mensaje = "Recuerda que realizar el proceso de validación de identidad, es un paso obligatorio para realizar la compra de tu certificado digital."
  constructor(private alert: AlertsService,
    private ssps: SspsService,
    private router: Router,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    if (this.urlRedireccionFlujoValida === "revocar-certificado") {
      this.mensaje = "Recuerda que realizar el proceso de validación de identidad, es un paso obligatorio para realizar la revocación de tu certificado digital."
    }
  }
  cerrar() {
    this.alert.closesshowReporter()
    if (this.urlRedireccionFlujoValida !== "") {
      this.router.navigate([this.urlRedireccionFlujoValida]);
    }
    else {
      let externalUrl = `${env.urlWebCerti}`;
      localStorage.clear()
      window.location.replace(externalUrl);
    }
  }
}
