import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { LogService } from '../../../domain/servicios/log.service'
import { environment as env, environment } from "src/environments/environment"
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';

@Component({
    selector: 'app-descarga-certificado',
    templateUrl: './descarga-certificado.component.html',
    styleUrls: ['./descarga-certificado.component.css'],
    standalone: false
})
export class DescargaCertificadoComponent implements OnInit {
  @ViewChild('inputRefNombres')
  inputRefNombres!: { nativeElement: any; };

  @ViewChild('inputRefApellidos')
  inputRefApellidos!: { nativeElement: any; };

  @ViewChild('inputRefNumero')
  inputRefNumero!: { nativeElement: any; };

  formaForm!: FormGroup;
  ordenPedidoId: any = ""
  usosCertificados: any[] = [];
  usuarioDescarga: any = {
    solicitudId: 0,
    tipo: '',
    numero: '',
    nombres: '',
    apellidos: '',
    mecanismo: '',
    existeCSR: false,
    usoCertificado: -1,
    otroUso: '',
    uso: ''
  };
  usoGuardado = false
  certificado: any = {
  }

  otpValidado = false
  correoTitularOfuscado = ""
  correoSolicitante = ""
  tituloBotonOTP = "Enviar código"
  otpEnviado = false
  reenviarOtp = false
  otpValidacion = ""
  otp: string = "";
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: ''
  };
  display: any;
  timer: any;
  constructor(private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private componentesService: ComponentesService,
    private ssps: SspsService,
    private alert: AlertsService,
    private logger: LogService,
    private el: ElementRef
  ) {
    /*validacion de campos validators*/
    this.formaForm = this.fb.group({
      contrasena: ['', [
        Validators.minLength(8),
        Validators.maxLength(12),
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*[$@$!%*?&_-])[A-Za-zd$@$!%*?&_-].{8,15}')]],
      contrasenaConfirmacion: ['', [
        Validators.minLength(8),
        Validators.maxLength(12),
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*[$@$!%*?&_-])[A-Za-zd$@$!%*?&_-].{8,15}')]]
    });
  }

  ngOnInit(): void {
    this.alert.closesSpiner()
    if (this.ssps.getLocalStorage("ordenPedidoId")) {
      this.ordenPedidoId = JSON.parse(this.ssps.getLocalStorage("ordenPedidoId")!)
      var usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
      this.usuarioDescarga.solicitudId = usuario.idTitular
      this.usuarioDescarga.tipo = usuario.tipo === 'NI' ? 'NIT/RUT' : usuario.tipo
      this.usuarioDescarga.numero = usuario.numero
      this.usuarioDescarga.mecanismo = usuario.dispo
      this.correoSolicitante = usuario.correo
      this.logger.log("ngOnInit descargarCertificado", usuario)
      if (usuario.dispo === "PKCS#10") {
        this.usos();
        this.usuarioDescarga.nombreCompleto = usuario.razon !== undefined && usuario.razon !== null &&
          usuario.razon !== "" ? usuario.razon : ((usuario.primerNombre ?? '') + (usuario.segundoNombre !== null &&
            usuario.segundoNombre !== undefined && usuario.segundoNombre !== "" ?
            " " + usuario.segundoNombre : "") + (usuario.primerApellido ?? '') + (usuario.segundoApellido !== null &&
              usuario.segundoApellido !== undefined && usuario.segundoApellido !== "" ?
              " " + usuario.segundoApellido : ""))
        this.removeValidatorsContrasena()
      }
      else if (this.ssps.getLocalStorage('solicitante')! !== null &&
        this.ssps.getLocalStorage('solicitante')! !== undefined) {
        var solicitante = JSON.parse(this.ssps.getLocalStorage('solicitante')!);

        this.usuarioDescarga.tipo = solicitante.tipo
        this.usuarioDescarga.numero = solicitante.numero
        this.usuarioDescarga.nombreCompleto = (solicitante.primerNombre ?? '') + (solicitante.segundoNombre !== null &&
          solicitante.segundoNombre !== undefined && solicitante.segundoNombre !== "" ?
          " " + solicitante.segundoNombre : "") + (solicitante.primerApellido ?? '') + (solicitante.segundoApellido !== null &&
            solicitante.segundoApellido !== undefined && solicitante.segundoApellido !== "" ?
            " " + solicitante.segundoApellido : "")
        this.correoSolicitante = solicitante.correo
      }
      else {
        this.usuarioDescarga.nombreCompleto = (usuario.primerNombre ?? '') + (usuario.segundoNombre !== null &&
          usuario.segundoNombre !== undefined && usuario.segundoNombre !== "" ?
          " " + usuario.segundoNombre : "") + (usuario.primerApellido ?? '') + (usuario.segundoApellido !== null &&
            usuario.segundoApellido !== undefined && usuario.segundoApellido !== "" ?
            " " + usuario.segundoApellido : "")
        this.correoSolicitante = usuario.correo
      }

      if (this.ssps.getLocalStorage('solicitante')! !== null &&
        this.ssps.getLocalStorage('solicitante')! !== undefined) {
        var solicitante = JSON.parse(this.ssps.getLocalStorage('solicitante')!);
        this.correoSolicitante = solicitante.correo
      }

      this.usuarioDescarga.existeCSR = usuario.existeCSR
      this.usuarioDescarga.usoCertificado = usuario.usoCertificado ?? -1
      this.usoGuardado = usuario.usoCertificado !== null && usuario.usoCertificado !== undefined && usuario.usoCertificado !== -1
      this.usuarioDescarga.otroUso = usuario.otroUso ?? ''
      this.usuarioDescarga.uso = usuario.uso ?? ''
      this.correoTitularOfuscado = this.correoSolicitante.substring(0, 3) + "******" + this.correoSolicitante.substring(this.correoSolicitante.length - 7)
    }
    if (usuario.dispo !== 'PKCS#10' && usuario.dispo !== 'TOKEN VIRTUAL' && usuario.dispo !== 'REPOSICION') {
      let mensaje = "No fue posible realizar la petición"
      this.alert.showError(mensaje, true, null, false, null, null, false)
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  /* funcion para validacion de campos*/
  camposvalidos(campo: any) {
    return this.formaForm.get(campo)!.invalid &&
      (this.formaForm.get(campo)!.touched || this.formaForm.get(campo)!.dirty);
  }

  //TODO:cerrar el modal de activo digital
  close() {
    this.alert.closesSpiner();
  }

  /*ingreso y validacion de datos de formulario*/
  ngsubmit() {
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl?.focus();
          return;
        }
      });
      return;
    }
    else {
      //this.estados= true;
      this.alert.showSpiner("Estamos cargando tus solicitudes, este proceso puede tardar un momento");
      let { contrasena } = this.formaForm.value;
      this.obtenerCertificado(contrasena);
    }
  }

  obtenerCertificado(contrasena: string) {
    let requestCertificado: any = {
      solicitudId: this.usuarioDescarga.solicitudId,
      contrasena: contrasena
    }
    this.ssps.obtenerCertificado(requestCertificado)
      .subscribe({
        next: (res: any) => {
          this.logger.log("obtenerCertificado", res)
          this.certificado = res
          this.ssps.saveLocalStorage('certificado', JSON.stringify(res));
          this.alert.closesSpiner()
          //Si es Finalizado ó descarga, mostrar alerta con el mensaje
          if (res.estado === 'CONTRASENA') {
            this.agregarValidatorsContrasena()
          }
          if (res.estado === 'FINALIZADO' || res.estado === 'DESCARGA') {
            this.removeValidatorsContrasena()
            this.alert.closesFiels()
            this.alert.showDescargaCertificado(res.estado === 'FINALIZADO')
          }
        },
        error: (error: any) => {
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.obtenerCertificado(contrasena);
          }
          else {
            this.alert.closesSpiner()
            console.error('Error en subscriber obtenerCertificado', error)
            let mensaje = this.ssps.getErrorMessage(error);
            this.alert.showError(mensaje, false, null, false, null, null, false)
          }
        }
      })
  }

  removeValidatorsContrasena() {
    const controlesAEliminar = ['contrasena', 'contrasenaConfirmacion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    }
  }

  agregarValidatorsContrasena() {
    const controlesAEliminar = ['contrasena', 'contrasenaConfirmacion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        control.addValidators([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(12),
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*[$@$!%*?&_-])[A-Za-zd$@$!%*?&_-].{8,15}')
        ])
        control.updateValueAndValidity();
      }
    }
  }

  get contrasenaIguales() {
    const pass1 = this.formaForm.get('contrasena')!.value;
    const pass2 = this.formaForm.get('contrasenaConfirmacion')!.value;

    return pass1 === pass2;
  }

  generarLlave() {
    this.alert.showSpiner("Procesando petición, por favor espera un momento");
    let requestCertificado: any = {
      solicitudId: this.usuarioDescarga.solicitudId
    }
    this.ssps.generarRequestLlave(requestCertificado)
      .subscribe({
        next: (res: any) => {
          this.logger.log("generarRequestLlave", res)
          this.alert.closesSpiner()
          this.usuarioDescarga.existeCSR = true
          var usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
          usuario.existeCSR = true
          this.ssps.saveLocalStorage("usuario", JSON.stringify(usuario));
          this.alert.showError("La llave privada fue generada exitosamente y enviada al correo registrado en la solicitud.",
            false, "Exitoso", false, null, null, false)

        },
        error: (error: any) => {
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.generarLlave();
          }
          else {
            this.alert.closesSpiner()
            console.error('Error en subscriber generarRequestLlave', error)
            let mensaje = this.ssps.getErrorMessage(error);
            this.alert.showError(mensaje, false, null, false, null, null, false)
          }
        }
      })
  }

  enviarOTP() {
    this.alert.showSpiner("Procesando petición, por favor espera un momento");
    let requestOtp = {
      "fromAddress": env.mailOtp,
      "toAddress": this.correoSolicitante,
      "fromName": "Operaciones",
      "subject": "Verificación de identidad vía OTP",
      "bodyContent": "<html><head><title>C&oacute;digo de verificaci&oacute;n</title></head><body><p class=\"title\"><b>C&oacute;digo de verificaci&oacute;n</b></p><p class=\"story\">Su c&oacute;digo de verificaci&oacute;n es: CODE</p></body></html>",
      "certification": false,
      "type": "EMAIL"
    }
    this.ssps.solicitarOTP(requestOtp)
      .subscribe({
        next: (res: any) => {
          this.logger.log("solicitarOTP", res)
          this.otpValidacion = res.OTPSha256
          this.alert.closesSpiner()
          if (res.statusCode === 200) {
            this.alert.showError("El código de verificación fue enviado exitosamente",
              false, "¡Exitoso!", false, null, null, false)
            this.otpEnviado = true
            this.contadorReenvio(1)
          }
          else {
            this.alert.showError(res.responseText,
              false, null, false, null, null, false)
          }
        },
        error: (error: any) => {
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.enviarOTP();
          }
          else {
            this.alert.closesSpiner()
            console.error('Error en subscriber solicitarOTP', error)
            let mensaje = this.ssps.getErrorMessage(error);
            this.alert.showError(mensaje, false, null, false, null, null, false)
          }
        }
      })
  }

  onOtpChange(otp: any) {
    this.otp = otp;
    this.logger.log("onOtpChange", otp)
    if (otp.length === this.config.length) {
      this.validarOTP(otp)
    }
  }

  async validarOTP(otp: any) {
    this.alert.showSpiner("Procesando petición, por favor espera un momento");
    let otpSha256 = await this.ssps.sha256(otp)

    this.alert.closesSpiner()
    if (this.otpValidacion === otpSha256) {
      this.alert.showError("El código de verificación es correcto",
        false, "¡Exitoso!", false, null, null, false)
      this.otpValidado = true
      this.otpEnviado = false
      clearInterval(this.timer);
    }
    else {
      this.alert.showError("El código de verificación no es correcto",
        false, null, false, null, null, false)
    }
  }

  contadorReenvio(minute: any) {
    this.reenviarOtp = false;
    // let minute = 1;
    let seconds = minute * 60;
    let textSec: any = '0';
    let statSec = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      // if (statSec < 10) textSec = "0" + statSec;
      // textSec = statSec;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else {
        textSec = statSec;
      }

      // this.display = prefix + Math.floor(seconds / 60) + ":" + textSec;
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        clearInterval(this.timer);
        this.reenviarOtp = true;
      }
    }, 1000);
  }

  get passwordFormField() {
    return this.formaForm.get('contrasena');
  }

  get passwordMinMaxLengthValid() {
    return !(this.formaForm.controls["contrasena"].hasError("maxlength") ||
      this.formaForm.controls["contrasena"].hasError("minlength")) && this.formaForm.controls["contrasena"]?.value.length > 0;
  }

  closeModal() {
    let externalUrl = `${environment.urlWebCerti}`;
    this.alert.closesFiels();
    localStorage.clear()
    window.location.replace(externalUrl);
  }

  usos() {
    this.ssps.usos()
      .subscribe((res: any) => {
        this.usosCertificados = res.usos;
      })
  }

  cambioUso(element: any) {
    this.logger.log("cambioUso", element)
    if (+element.value > 0) {
      let usoCertificado = this.usosCertificados.find(f => +f.id === +element.value)
      this.usuarioDescarga.usoCertificado = usoCertificado.id
      this.usuarioDescarga.uso = usoCertificado.descripcion
      this.usuarioDescarga.otroUso = ""
    }
  }
  guardarUsoCertificado() {
    var usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
    let body: any = {
      solicitudId: this.usuarioDescarga.solicitudId,
      usoCertificado: this.usuarioDescarga.usoCertificado,
      uso: this.usuarioDescarga.uso,
      otroUso: this.usuarioDescarga.otroUso.toUpperCase()
    }
    this.alert.showSpiner("Procesando petición, por favor espera un momento");
    usuario.usoCertificado = this.usuarioDescarga.usoCertificado ?? -1
    usuario.otroUso = this.usuarioDescarga.otroUso ?? ''
    usuario.uso = this.usuarioDescarga.uso ?? ''
    this.ssps.generarRequestJuridico(body).subscribe({
      next: (res: any) => {
        this.logger.log("respuesta generarRequestJurido", res)
        this.alert.closesSpiner()
        if (+res.codigo === 200) {
          this.usoGuardado = true
          this.ssps.saveLocalStorage('usuario', JSON.stringify(usuario));
          this.alert.showError("Uso del certificado guardado correctamente.",
            false, "¡Exitoso!", false, null, null, false)
        }
        else {
          this.alert.showError(res.responseText,
            false, null, false, null, null, false)
        }
      },
      error: (error) => {
        console.error('Error en subscriber generarRequestJurido descargar', error)
        if ((error.error && error.error.message
          && error.error.message.includes("timed out")) ||
          (error.message && error.message.includes("timed out"))) {
          this.logger.log("Error", "time out")
          this.guardarUsoCertificado();
        }
        else {
          this.alert.closesSpiner()
          console.error('Error en subscriber generarRequestJurido descarga certificado', error)
          let mensaje = this.ssps.getErrorMessage(error);
          this.alert.showError(mensaje, false, null, false, null, null, false)
        }
      }
    });
  }


}