import * as i0 from '@angular/core';
import { Component, NgModule } from '@angular/core';
import { CdkStepper, STEPPER_GLOBAL_OPTIONS, CdkStepperModule } from '@angular/cdk/stepper';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = [[["", 8, "step-bullet"]], [["", 8, "step-title"]]];
const _c1 = [".step-bullet", ".step-title"];
const _c2 = (a0, a1, a2) => ({
  active: a0,
  completed: a1,
  done: a2
});
function NgStepperComponent_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "li", 6);
    i0.ɵɵlistener("click", function NgStepperComponent_ng_container_3_Template_li_click_1_listener() {
      const i_r2 = i0.ɵɵrestoreView(_r1).index;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.selectedIndex = i_r2);
    });
    i0.ɵɵelementContainerStart(2, 7);
    i0.ɵɵprojection(3);
    i0.ɵɵprojection(4, 1);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const step_r4 = ctx.$implicit;
    const i_r2 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(2, _c2, ctx_r2.selectedIndex === i_r2, step_r4.completed && i_r2 < ctx_r2.selectedIndex, step_r4.state === "done" && i_r2 === ctx_r2.selectedIndex));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", step_r4.stepLabel.template);
  }
}
function NgStepperComponent_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵelementContainer(1, 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const step_r5 = ctx.$implicit;
    const i_r6 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("active", ctx_r2.selectedIndex === i_r6);
    i0.ɵɵproperty("@stepTransition", ctx_r2._getAnimationDirection(i_r6));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", step_r5.content);
  }
}
class NgStepperComponent extends CdkStepper {
  ngOnInit() {}
}
NgStepperComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵNgStepperComponent_BaseFactory;
  return function NgStepperComponent_Factory(__ngFactoryType__) {
    return (ɵNgStepperComponent_BaseFactory || (ɵNgStepperComponent_BaseFactory = i0.ɵɵgetInheritedFactory(NgStepperComponent)))(__ngFactoryType__ || NgStepperComponent);
  };
})();
NgStepperComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NgStepperComponent,
  selectors: [["ng-stepper"]],
  standalone: false,
  features: [i0.ɵɵProvidersFeature([{
    provide: CdkStepper,
    useExisting: NgStepperComponent
  }, {
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: {
      displayDefaultIndicatorType: false
    }
  }]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c1,
  decls: 6,
  vars: 2,
  consts: [[1, "stepper"], [1, "stepper-header"], [1, "steps"], [4, "ngFor", "ngForOf"], [1, "stepper-body"], ["class", "content", 3, "active", 4, "ngFor", "ngForOf"], [1, "step", 3, "click", "ngClass"], [3, "ngTemplateOutlet"], [1, "content"]],
  template: function NgStepperComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c0);
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "ol", 2);
      i0.ɵɵtemplate(3, NgStepperComponent_ng_container_3_Template, 5, 6, "ng-container", 3);
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(4, "div", 4);
      i0.ɵɵtemplate(5, NgStepperComponent_div_5_Template, 2, 4, "div", 5);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngForOf", ctx.steps);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngForOf", ctx._steps);
    }
  },
  dependencies: [i1.NgClass, i1.NgForOf, i1.NgTemplateOutlet],
  styles: ["[_nghost-%COMP%]{display:block;overflow:hidden}.stepper-header[_ngcontent-%COMP%]   .steps[_ngcontent-%COMP%]{width:100%;list-style:none;display:inline-flex;align-items:center;margin:0 auto}.stepper-header[_ngcontent-%COMP%]   .steps[_ngcontent-%COMP%]   .step[_ngcontent-%COMP%]{text-align:center;font-size:1em}.stepper-header[_ngcontent-%COMP%]   .steps[_ngcontent-%COMP%]     .step .step-bullet{position:relative;display:inline-flex;justify-content:center;align-items:center;height:40px;width:40px;background-color:#b3babe;border-radius:50%;color:#fff;font-size:1.3rem;text-align:center;text-decoration:none;cursor:pointer}.stepper-header[_ngcontent-%COMP%]   .steps[_ngcontent-%COMP%]     .step .step-title{position:absolute;right:0;bottom:-5px;left:0;margin:auto 0;height:0}.stepper-header[_ngcontent-%COMP%]   .steps[_ngcontent-%COMP%]     .step.active .step-bullet{background-color:#306bff}.stepper-header[_ngcontent-%COMP%]   .steps[_ngcontent-%COMP%]     .step.completed .step-bullet{background-color:#32e67f}.stepper-header[_ngcontent-%COMP%]   .steps[_ngcontent-%COMP%]     .step.done .step-bullet{background-color:#32e67f}.stepper-body[_ngcontent-%COMP%]{margin:25px auto;padding:1rem}.stepper-body[_ngcontent-%COMP%]   .content[_ngcontent-%COMP%]:not(.active){height:0;overflow:hidden}.step[_ngcontent-%COMP%]{position:relative;width:33.33%}.step[_ngcontent-%COMP%]:after{content:\"\";position:absolute;width:100%;height:3px;background-color:#b3babe;top:50%;left:-50%;z-index:-1}.step[_ngcontent-%COMP%]:first-child:after{content:none}.step.completed[_ngcontent-%COMP%] + .step[_ngcontent-%COMP%]:after{background-color:#32e67f}"],
  data: {
    animation: [trigger('stepTransition', [state('previous', style({
      transform: 'translateX(-100%)',
      opacity: 0
    })), state('current', style({
      transform: 'translateX(0)',
      opacity: 1
    })), state('next', style({
      transform: 'translateX(100%)',
      opacity: 0
    })), transition(':enter', animate(0)), transition('previous => current', animate('500ms cubic-bezier(0.35, 0, 0.25, 1)')), transition('next => current', animate('500ms cubic-bezier(0.35, 0, 0.25, 1)'))])]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgStepperComponent, [{
    type: Component,
    args: [{
      selector: 'ng-stepper',
      providers: [{
        provide: CdkStepper,
        useExisting: NgStepperComponent
      }, {
        provide: STEPPER_GLOBAL_OPTIONS,
        useValue: {
          displayDefaultIndicatorType: false
        }
      }],
      animations: [trigger('stepTransition', [state('previous', style({
        transform: 'translateX(-100%)',
        opacity: 0
      })), state('current', style({
        transform: 'translateX(0)',
        opacity: 1
      })), state('next', style({
        transform: 'translateX(100%)',
        opacity: 0
      })), transition(':enter', animate(0)), transition('previous => current', animate('500ms cubic-bezier(0.35, 0, 0.25, 1)')), transition('next => current', animate('500ms cubic-bezier(0.35, 0, 0.25, 1)'))])],
      template: "<div class=\"stepper\">\n  <div class=\"stepper-header\">\n    <ol class=\"steps\">\n      <ng-container *ngFor=\"let step of steps; let i = index;\">\n        <li class=\"step\" (click)=\"selectedIndex = i\"\n            [ngClass]=\"{\n                active: selectedIndex === i,\n                completed: step.completed && i < selectedIndex,\n                done: step.state === 'done' && i === selectedIndex\n                }\">\n          <ng-container [ngTemplateOutlet]=\"step.stepLabel.template\">\n            <ng-content select=\".step-bullet\"></ng-content>\n            <ng-content select=\".step-title\"></ng-content>\n          </ng-container>\n        </li>\n      </ng-container>\n    </ol>\n  </div>\n  <div class=\"stepper-body\">\n    <div class=\"content\"\n         *ngFor=\"let step of _steps; let i = index\"\n         [class.active]=\"selectedIndex === i\"\n         [@stepTransition]=\"_getAnimationDirection(i)\">\n      <ng-container [ngTemplateOutlet]=\"step.content\"></ng-container>\n    </div>\n  </div>\n</div>\n\n",
      styles: [":host{display:block;overflow:hidden}.stepper-header .steps{width:100%;list-style:none;display:inline-flex;align-items:center;margin:0 auto}.stepper-header .steps .step{text-align:center;font-size:1em}.stepper-header .steps ::ng-deep .step .step-bullet{position:relative;display:inline-flex;justify-content:center;align-items:center;height:40px;width:40px;background-color:#b3babe;border-radius:50%;color:#fff;font-size:1.3rem;text-align:center;text-decoration:none;cursor:pointer}.stepper-header .steps ::ng-deep .step .step-title{position:absolute;right:0;bottom:-5px;left:0;margin:auto 0;height:0}.stepper-header .steps ::ng-deep .step.active .step-bullet{background-color:#306bff}.stepper-header .steps ::ng-deep .step.completed .step-bullet{background-color:#32e67f}.stepper-header .steps ::ng-deep .step.done .step-bullet{background-color:#32e67f}.stepper-body{margin:25px auto;padding:1rem}.stepper-body .content:not(.active){height:0;overflow:hidden}.step{position:relative;width:33.33%}.step:after{content:\"\";position:absolute;width:100%;height:3px;background-color:#b3babe;top:50%;left:-50%;z-index:-1}.step:first-child:after{content:none}.step.completed+.step:after{background-color:#32e67f}\n"]
    }]
  }], null, null);
})();
class NgStepperModule {}
NgStepperModule.ɵfac = function NgStepperModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgStepperModule)();
};
NgStepperModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgStepperModule
});
NgStepperModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, CdkStepperModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgStepperModule, [{
    type: NgModule,
    args: [{
      declarations: [NgStepperComponent],
      imports: [CommonModule, CdkStepperModule],
      exports: [NgStepperComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-stepper
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgStepperComponent, NgStepperModule };
