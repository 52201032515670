import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { DatePipe } from '@angular/common'
import { ActivatedRoute } from '@angular/router';
import { LogService } from '../../../../domain/servicios/log.service'
import { AlertsService } from 'src/app/domain/servicios/alerts.service';

@Component({
    selector: 'app-datosempresa',
    templateUrl: './datosempresa.component.html',
    styleUrls: ['./datosempresa.component.css'],
    standalone: false
})
export class DatosempresaComponent implements OnInit {

  @ViewChild('inputRefRazon')
  inputRefRazon!: { nativeElement: any; };
  @ViewChild('inputRefCorreo')
  inputRefCorreo!: { nativeElement: any; };
  @ViewChild('inputRefCorreo1')
  inputRefCorreo1!: { nativeElement: any; };
  @ViewChild('inputRefInstitucion')
  inputRefInstitucion!: { nativeElement: any; };
  @ViewChild('inputRefProfesional')
  inputRefProfesional!: { nativeElement: any; };
  @ViewChild('inputRefProfesion')
  inputRefProfesion!: { nativeElement: any; };

  @ViewChild('inputRefDireccion')
  inputRefDireccion!: { nativeElement: any; };
  @ViewChild('inputRefTipoNumeroDireccion')
  inputRefTipoNumeroDireccion!: { nativeElement: any; };
  @ViewChild('inputRefNumeroDireccion')
  inputRefNumeroDireccion!: { nativeElement: any; };
  @ViewChild('inputRefNumeroDireccion2')
  inputRefNumeroDireccion2!: { nativeElement: any; };
  @ViewChild('inputRefBarrioDireccion')
  inputRefBarrioDireccion!: { nativeElement: any; };
  @ViewChild('inputRefAdicionalDireccion')
  inputRefAdicionalDireccion!: { nativeElement: any; };
  @ViewChild('inputRefNumeroOtroDireccion')
  inputRefNumeroOtroDireccion!: { nativeElement: any; };
  @ViewChild('inputRefDescripcionEstampilla')
  inputRefDescripcionEstampilla!: { nativeElement: any; };

  @Output() datosempres: EventEmitter<boolean> = new EventEmitter();
  formaForm!: FormGroup;
  cambiologo2: boolean = false;
  cambiologo: boolean = true;
  profesional: boolean = false;
  documentob: boolean = false;
  cantidad: number = 0;
  totalCantidad: number = 0;
  usuario: any = {
    documentos: [],
    ip: ''
  };
  pago: any = {
  };
  archivos: boolean = false;
  tipos: boolean = false;
  mayor: boolean = false;
  files: boolean = false;
  showDocumentsB: boolean = false;
  buttons: any[] = [];
  validateformats: any[] = [];
  error: string = 'Formato no válido';
  queryRuta: any = null
  comastel: boolean = false;
  comastel2: boolean = false;
  comastex: boolean = false;
  comas: boolean = false;
  indicativos: any[] = [];
  comasnit: boolean = false;
  comasecnomi: boolean = false;
  depar: any[] = [];
  muni: any[] = [];
  munifi: any[] = [];
  realizoPago = "N"

  actualizacion = false
  direccion: any = {}
  tipoDireccion: any[] = [];
  regimenTributarioListado: any[] = []
  regimenTributario: any[] = []
  tiposCapital: any[] = []
  preciosd: any[] = [];

  nuevaEstampilla: any = {
    descripcion: "",
    tipo: "",
    valor: ""
  };

  estampillas: any[] = []

  agregarNuevaEstampilla = false
  totalDescuentos = 0;
  totalDescuentosValor = 0;
  departamentoExcluidoIva = ""
  tipoEmpresa = ""

  constructor(private fb: FormBuilder,
    private router: Router,
    private componentesService: ComponentesService,
    private ssps: SspsService,
    private changeDetector: ChangeDetectorRef,
    public datepipe: DatePipe, private route: ActivatedRoute,
    private logger: LogService,
    private alert: AlertsService,
    private el: ElementRef) {

    /*validacion de campos validators*/
    this.formaForm = this.fb.group({

      razon: ['', [Validators.required,
      Validators.minLength(2),
      Validators.maxLength(60)]],
      correo: ['', [Validators.required,
      Validators.maxLength(60),
      Validators.pattern('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$')]],
      correo1: ['', [Validators.required,
      Validators.minLength(8),
      Validators.maxLength(60),
      Validators.pattern('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$')]],
      documentos: this.fb.array([]),
      tipo: [null],
      numero: [null],
      indicativo: [],
      telefono: ['', [
        Validators.maxLength(7),
        Validators.minLength(7)
      ]],
      extension: [0, [
        Validators.maxLength(5)
      ]],
      celular: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      departamento: ['', [Validators.required]],
      municipio: ['', [Validators.required]],
      tipoCapital: ['', [Validators.required]],
      regimenTributario: ['', [Validators.required]],
      actividadEconomica: [0, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4)]],
      tipoDireccion: ['', [
        Validators.required
      ]],
      tipoNumeroDireccion: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10)
      ]],
      numeroDireccion: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10)
      ]],
      numeroDireccion2: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10)
      ]],
      barrioDireccion: ['', [
        Validators.minLength(4),
        Validators.maxLength(200)
      ]],
      adicionalDireccion: ['', [
        Validators.maxLength(200)
      ]],
      numeroOtroDireccion: ['', [
        Validators.minLength(4),
        Validators.maxLength(200)
      ]],
      aplicaEstampillas: ['', [
        Validators.required
      ]],
      descripcionEstampilla: ['', []],
      tipoEstampilla: ['', []],
      valorEstampilla: ['', []]
    }, {
      validators: [this.componentesService.economica('actividadEconomica')]
      //this.componentesService.soniguales('correo', 'correo1'),
    }
    );
  }


  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.setOnChangesInputs()
    this.totalCantidad = 1;
    this.ssps.obtenerIpValor()
    this.tiposDireccion()
    this.tiposRegimenTributario()
    this.indicativo();
    this.departamentos();
    this.precios()
    this.usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
    this.actualizacion = this.ssps.getLocalStorage("actualizacion") ?
      JSON.parse(this.ssps.getLocalStorage("actualizacion")!) : false
    this.logger.log("Sin query datos", "TODO")
    this.mapearDatos()
    this.componentesService.emitircambio("mostrarBarra");
    if (this.ssps.getLocalStorage("pago")) {
      this.pago = JSON.parse(this.ssps.getLocalStorage("pago")!)
      this.realizoPago = this.pago.realizoPago
      if (this.pago.realizoPago === "S") {
        this.deshabilitarCamposRegimen()
      }
    }
    else {
      let valorIva: any = Math.trunc(this.usuario.costoBase * (this.usuario.iva / 100))
      let valorReteIva: any = Math.trunc(this.usuario.costoBase * ((this.usuario.reteiva ?? 0) / 100))
      this.pago = {
        ordenPedidoId: JSON.parse(this.ssps.getLocalStorage("ordenPedidoId")!),
        solicitudId: JSON.parse(this.ssps.getLocalStorage("solicitudId")!),
        costoBase: Math.trunc(this.usuario.costoBase),
        impuestos: Math.trunc(valorIva - valorReteIva),
        valorTotal: Math.trunc(this.usuario.costo - (this.usuario.descuentoCupon ?? 0)),
        mensaje: '',
        estado: 'SIN_PAGO',
        url: '',
        iva: Math.trunc(this.usuario.iva),
        ica: this.usuario.ica ?? 0,
        reteiva: Math.trunc(this.usuario.reteiva ?? 0),
        ivaValor: valorIva,
        icaValor: Math.trunc(this.usuario.costoBase * (this.usuario.ica / 100)),
        documentos: [],
        pagoPSE: false,
        descuentoCupon: Math.trunc(this.usuario.descuentoCupon ?? 0),
        realizoPago: "N",
        fechaPago: "",
        referenciaPago: '',
        usuarioRegistraPago: 'N',
        estampillas: this.usuario.estampillas,
        reteIvaValor: Math.trunc(valorReteIva)
      }
      if (!this.pago.ordenPedidoId) {
        this.pago.ordenPedidoId = JSON.parse(this.ssps.getLocalStorage("ordenPedidoId")!)
      }
      if (!this.pago.solicitudId) {
        this.pago.solicitudId = JSON.parse(this.ssps.getLocalStorage("solicitudId")!)
      }
      this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago))
    }
  }  

  setOnChangesInputs() {
    this.formaForm.get("razon")!.valueChanges.subscribe(val => {
      if (this.inputRefRazon) {
        const elRef = this.inputRefRazon.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("razon")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("correo")!.valueChanges.subscribe(val => {
      if (this.inputRefCorreo) {
        const elRef = this.inputRefCorreo.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("correo")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });
    this.formaForm.get("correo1")!.valueChanges.subscribe(val => {
      if (this.inputRefCorreo1) {
        const elRef = this.inputRefCorreo1.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("correo1")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("tipoNumeroDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefTipoNumeroDireccion) {
        const elRef = this.inputRefTipoNumeroDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("tipoNumeroDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numeroDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefNumeroDireccion) {
        const elRef = this.inputRefNumeroDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numeroDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numeroDireccion2")!.valueChanges.subscribe(val => {
      if (this.inputRefNumeroDireccion2) {
        const elRef = this.inputRefNumeroDireccion2.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numeroDireccion2")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("barrioDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefBarrioDireccion) {
        const elRef = this.inputRefBarrioDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("barrioDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("adicionalDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefAdicionalDireccion) {
        const elRef = this.inputRefAdicionalDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("adicionalDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numeroOtroDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefNumeroOtroDireccion) {
        const elRef = this.inputRefNumeroOtroDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numeroOtroDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("descripcionEstampilla")!.valueChanges.subscribe(val => {
      if (this.inputRefDescripcionEstampilla) {
        const elRef = this.inputRefDescripcionEstampilla.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("descripcionEstampilla")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get('tipo')?.disable();
    this.formaForm.get('numero')?.disable();
  }

  regresarResumen() {
    //this.alert.showSssps();
    this.ssps.saveLocalStorage("regresaFormulario", "true")
    this.router.navigate(['/']);
  }

  //TODO:creacion de forms arrays
  get documentsDocuments() {
    return this.formaForm.get('documentos') as FormArray;
  }

  //TODO:crear botones
  crearButtons() {
    const estilos = {
      fondo: '#e86a05', colores: '#fff', title: 'Seleccionar archivo', subtitle: 'El formato de documento permitido para cargar es .PDF', accept: ".pdf"
    }
    if (this.documentsDocuments.controls.length > 0) {
      for (let i = 0; i < this.documentsDocuments.controls.length; i++) {
        this.buttons[i] = estilos;
        this.validateformats[i] = false;
      }
    }
  }

  //TODO agrega controls con valor requerido
  addControlDocument() {
    this.documentsDocuments.push(this.fb.control(null));
  }


  /* funcion para validacion de campos*/
  camposvalidos(campo: any) {
    return this.formaForm.controls[campo].errors && this.formaForm.controls[campo].touched;
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  /*ingreso y validacion de datos de formulario enviuo de los mismos localstorage*/
  ngsubmit() {
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          this.logger.log("ngSubmir field error " + key, this.formaForm.controls[key].errors)
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl?.focus();
          return;
        }
      });
      return;
    }
    if (this.campoValidacionDireccion()) return;
    if (this.totalDescuentos > 10) {
      this.alert.showError("Las estampillas no pueden superar un 10% de descuento.", false, "¡Alerta!", false, null, null, false)
      return;
    }

    if (this.realizoPago !== "S") {
      this.validarRegimenPrecios()
    }
    let { razon, correo1, correo, celular, telefono, extension, indicativo,
      departamento, municipio, tipoCapital, actividadEconomica, regimenTributario,
      tipoDireccion, tipoNumeroDireccion, numeroDireccion, numeroDireccion2,
      barrioDireccion, adicionalDireccion, numeroOtroDireccion } = this.formaForm.getRawValue();

    let objetoDireccion = {
      tipo: tipoDireccion,
      tipoNumero: tipoNumeroDireccion,
      numero: numeroDireccion,
      numero2: numeroDireccion2,
      barrio: barrioDireccion,
      adicional: adicionalDireccion ?? "",
      tipoOtroNumero: numeroOtroDireccion
    }

    let datos = {
      razon: razon,
      correo: correo,
      correo1: correo1,
      documentos: this.buttons.filter((f: { file: any; }) => f.file),
      ip: this.ssps.ip,
      telefono,
      extension,
      indicativo,
      celular,
      departamento,
      municipio,
      tipoCapital,
      actividadEconomica,
      regimenTributario,
      objetoDireccion: objetoDireccion,
      direccion: (objetoDireccion.tipo !== "Otro" ? (objetoDireccion.tipo + " " + objetoDireccion.tipoNumero + " #" + objetoDireccion.numero + "-" +
        objetoDireccion.numero2) : objetoDireccion.tipoOtroNumero) +
        " BARRIO " + objetoDireccion.barrio + (objetoDireccion.adicional !== "" ? " ADICIONAL " + objetoDireccion.adicional : "")
    }

    Object.assign(this.usuario, datos);
    this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
    if (this.ssps.getLocalStorage("solicitudId")) {
      this.componentesService.emitircambio("actualizarOrden");
    }
    this.componentesService.emitircambio("datosempresa");
    this.router.navigate(['/flujo/solicitante']);
  }


  /* estado de botones logos cambiantes*/
  estado(valor: string) {
    if (this.cantidad == 0) {
      //   this.cambiologo2 = true;
      //  this.cambiologo = false;
      this.cantidad = this.cantidad + 1;
    }

  }

  //cambio de estilos animacion boton archivos
  validar() {

    return (this.cambiologo = true) ? ' animate__animated animate__fadeIn animate__fast' : 'animate__animated animate__fadeOut animate__fast';
  }

  //TODO:implementacion de creacion de buttos
  CreateDocument(documentName: string, id: String) {
    let indice = this.buttons.findIndex((f: { id: String; }) => f.id === id);
    this.logger.log("indicie", indice)
    if (indice > -1) {
      this.buttons = this.buttons.filter((f: { id: String; }) => f.id !== id)
      this.documentsDocuments.removeAt(indice)
    }
    else {
      const estilos = {
        fondo: '#e86a05', colores: '#fff', title: 'Seleccionar archivo',
        subtitle: 'El formato de documento permitido para cargar es .PDF',
        name: documentName,
        id: id, 
        accept: ".pdf"
      }
      this.buttons.push(estilos)
      this.addControlDocument();
    }
  }

  ExisteDocumento(id: String) {
    return Array.isArray(this.usuario.documentos) && 
    this.usuario.documentos.some((f: { id: string }) => f.id === id);
  }

  ExisteDocumentoCargado(id: String) {
    return this.usuario.documentosCargados && this.usuario.documentosCargados.filter((f: { id: String; }) => f.id === id).length > 0
  }
  //TODO:vision de documents
  documentDocs(event: any) {
    this.showDocumentsB = true;
  }


  /*validacion de salerta campos iguales*/
  get correo2NoValido() {
    const pass1 = this.formaForm.get('correo')!.value;
    const pass2 = this.formaForm.get('correo1')!.value;

    return (pass1 === pass2) ? false : true;
  }



  /*validar tamaño de archivo*/
  getFileDetails(file: any, index: number) {

    let fileToUpload = <File>file[0];

    const botonActualizado = { ...this.buttons[index] };

    let tamañoEnBytes = fileToUpload.size;
    let tamañoEnMegabytes = tamañoEnBytes / (1024 * 1024);

    if (fileToUpload.type == "application/pdf") {
      if (tamañoEnMegabytes > 10) {
        botonActualizado.fondo = '#ff4d4d';
        botonActualizado.colores = '#fff';
        botonActualizado.title = 'Seleccionar archivo';
        botonActualizado.subtitle = 'El formato de documento permitido para cargar es .PDF';
        this.buttons[index] = botonActualizado;
        this.validateformats[index] = true;
      }
      if (tamañoEnMegabytes < 10) {
        const reader = new FileReader()
        reader.onload = () => {
          const b64 = reader.result as string
          botonActualizado.file = b64
        }

        if (fileToUpload) {
          reader.readAsDataURL(fileToUpload)
        }

        botonActualizado.fondo = '#09CB34';
        botonActualizado.colores = '#fff';
        botonActualizado.title = 'Documento cargado con exito';
        botonActualizado.subtitle = fileToUpload.name;

        this.buttons[index] = botonActualizado;
        this.validateformats[index] = false;
        this.cantidad += 1;
      }
    }
    else {
      botonActualizado.fondo = '#ff4d4d';
      botonActualizado.colores = '#fff';
      botonActualizado.title = 'Seleccionar archivo';
      botonActualizado.subtitle = 'El formato de documento permitido para cargar es .PDF';
      this.buttons[index] = botonActualizado;
      this.validateformats[index] = true;

    }

  }

  //TODO:implementacion de datos
  isValidFieldInArray(formArray: FormArray, index: number) {
    return formArray.controls[index].errors
      && formArray.controls[index].touched;
  }

  mapearDatos() {
    if (this.ssps.getLocalStorage("tipoEmpresa")) {
      this.tipoEmpresa = this.ssps.getLocalStorage('tipoEmpresa')!
      this.usuario.tipoCapital = this.ssps.getLocalStorage('tipoEmpresa')!.toUpperCase()
    }
    else {
      this.tipoEmpresa = this.usuario.tipoCapital
    }
    /*asignacion de valores desde el cervicio crm*/
    this.formaForm.get("departamento")!.setValue(this.usuario.departamento, {
      emitEvent: true,
      emitModelToViewChange: true
    });
    //Mapear documentos del localStorage
    if (this.usuario.documentos) {
      this.buttons = this.usuario.documentos
      this.cantidad = this.usuario.documentos.length
      this.usuario.documentos.forEach((element: any, index: any) => {
        this.validateformats[index] = false;
        this.addControlDocument();
      });
    }
    else {
      this.usuario.documentos = []
    }

    this.direccion = this.usuario.objetoDireccion ?? { barrio: "", adicional: "" }
    if (this.direccion.tipo === "Otro") {
      this.clearValidatorsDireccion()
    }
    this.estampillas = this.usuario.estampillas ?? []
    this.logger.log("this.usuario.regimenTributario", this.usuario.regimenTributario)
    this.usuario.aplicaEstampillas = this.usuario.estampillas !== undefined && this.usuario.estampillas !== null && this.usuario.estampillas.length > 0 ? 'si' : 'no'
    setTimeout(() => {
      this.tiposCapitalListado()
      this.deshabilitarCmaposFinalizados()
      this.habilitarAplicaEstampillas({
        target: {
          value: this.usuario.aplicaEstampillas
        }
      })
      if (this.usuario.tipoCapital !== undefined && this.usuario.tipoCapital !== null && this.usuario.tipoCapital !== "") {
        this.filtrarTipoRegimen({
          target: {
            value: this.usuario.tipoCapital
          }
        }, this.usuario.regimenTributario === undefined || this.usuario.regimenTributario === null)
      }
      else {
        const controlEstampilla = this.formaForm.get("aplicaEstampillas");
        if (controlEstampilla) {
          controlEstampilla.clearValidators();
          controlEstampilla.setValue("")
          controlEstampilla.updateValueAndValidity();
        }
      }
    }, 1000);
    setTimeout(() => {
      this.habilitarNuevaEstampilla(false)
      if (this.estampillas.length > 0) {
        this.totalDescuentos = this.estampillas.map(a => +a.porcentaje).reduce(function (a, b) {
          return a + b;
        }, 0);
        this.totalDescuentosValor = this.estampillas.map(a => +a.valor).reduce(function (a, b) {
          return a + b;
        }, 0);
      }

    }, 2000);
  }

  deshabilitarCmaposFinalizados() {
    Object.keys(this.formaForm.controls).forEach(key => {
      if (key !== "correo" && key !== "correo1" && (this.usuario.solicitudFinalizada === "APROBADO" || this.usuario.solicitudFinalizada === "FINALIZADO")) {
        this.formaForm.controls[key]?.disable({ onlySelf: true })
      }
      else if (this.usuario.solicitudFinalizada === "ASIGNADO") {
        this.formaForm.controls[key]?.disable({ onlySelf: true })
      }
    });
  }

  deshabilitarCamposRegimen() {
    const controlesADesactivar = ['tipoCapital', 'regimenTributario', 'departamento', 'municipio', 'aplicaEstampillas'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesADesactivar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        control.disable({ onlySelf: true })
      }
    }
  }

  noPunto(event: any, variableDestino: 'comastel' | 'comastel2' | 'comastex') {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this[variableDestino] = res;
      });
  }

  quitarceros2(numero: string) {

    this.usuario.telefono = numero.replace(/^0+/, '');

  }

  quitarceros3(numero: string) {

    this.usuario.extension = numero.replace(/^0+/, '');

  }

  quitarceros4(numero: string) {
    this.usuario.celular = numero.replace(/^0+/, '');
  }

  noPuntoComacod(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comas = res;
      })
  }
  /*carga datos select*/
  indicativo() {
    this.ssps.indicativos()
      .subscribe((res: any) => {
        this.indicativos = res;
      });

  }

  noPuntoComa(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comasnit = res;
      })
  }

  noPuntoComaeconomi(event: any) {

    this.componentesService.commas(event)
      .subscribe((res: any) => {

        this.comasecnomi = res;

      })

  }

  departamentos() {
    this.ssps.departamentos()
      .subscribe((res: any) => {
        this.muni = res.sort((a: any, b: any) => (a.Departamento < b.Departamento ? -1 : 1));
        this.depar = [...new Map(res.map((item: { [x: string]: any; }) => [item['Departamento'], item])).values()].sort((a: any, b: any) => (a.Departamento < b.Departamento ? -1 : 1));
        setTimeout(() => {
          let departamento = this.usuario.departamento
          this.formaForm.get("departamento")!.setValue(this.usuario.departamento, {
            emitEvent: true,
            emitModelToViewChange: true
          });
          this.munifi = this.muni.filter(function (obj: any, index) {
            return obj.codigo_dep == +departamento;
          }).sort((a, b) => (a.Municipio < b.Municipio ? -1 : 1))
        }, 1000);
      });
  }

  tiposDireccion() {
    this.ssps.tiposDireccion()
      .subscribe((res: any) => {
        this.tipoDireccion = res;
      });
  }

  tiposRegimenTributario() {
    this.ssps.tiposRegimenTributario()
      .subscribe((res: any) => {
        this.regimenTributarioListado = res;
        this.regimenTributario = res
      });
  }

  tiposCapitalListado() {
    this.ssps.tiposCapital()
      .subscribe((res: any) => {
        this.tiposCapital = res.filter((r: string) => r === "MIXTO" || r === this.tipoEmpresa.toUpperCase());
      });
  }

  changeTipoDireccion($event: any) {
    let tipoDireccion = $event.target.value;
    if (tipoDireccion === "Otro") {
      this.direccion = {
        tipo: tipoDireccion
      }
      this.clearValidatorsDireccion()
    }
    else {
      this.addValidatorsDireccion()
    }
  }

  changeDepartamento($event: any) {
    let departamento = $event.target.value;
    if (+departamento === 88) {
      this.departamentoExcluidoIva = "Con base al artículo ART. 423 del Estatuto tributario Exclusión del impuesto en el territorio intendencial de San Andrés y Providencia. En la Intendencia Especial de San Andrés y Providencia no se cobrará impuesto nacional a las ventas."
    }
    else if (+departamento === 91) {
      this.departamentoExcluidoIva = "Conforme al artículo 270 de la Ley 223 de 1995 los servicios prestados en el departamento de Amazonas se encuentran excluidos de IVA."
    }
    else {
      this.departamentoExcluidoIva = ""
    }
    this.munifi = this.muni.filter(function (obj: any, index) {
      return obj.codigo_dep == +departamento;
    }).sort((a, b) => (a.Municipio < b.Municipio ? -1 : 1))
    setTimeout(() => {
      this.usuario.municipio = undefined
      this.formaForm.get("municipio")!.setValue(undefined, {
        emitEvent: true,
        emitModelToViewChange: true
      });
    }, 500);
  }

  clearValidatorsDireccion() {
    const controlesAEliminar = ['tipoNumeroDireccion', 'numeroDireccion',
      'numeroDireccion2', 'numeroOtroDireccion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        if (controlName === "numeroOtroDireccion") {
          control.addValidators([
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(200)])
        }
        else {
          control.clearValidators();
        }
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  addValidatorsDireccion() {
    const controlesAEliminar = ['tipoNumeroDireccion', 'numeroDireccion',
      'numeroDireccion2', 'numeroOtroDireccion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        if (controlName === "numeroOtroDireccion") {
          control.clearValidators();
        }
        else {
          control.addValidators([
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(10)
          ])
        }
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  addValidatorsEstampillas() {
    const controlesAEliminar = ['descripcionEstampilla', 'tipoEstampilla',
      'valorEstampilla'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        control.addValidators([
          Validators.required
        ])
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  clearValidatorsEstampillas() {
    const controlesAEliminar = ['descripcionEstampilla', 'tipoEstampilla',
      'valorEstampilla'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        control.clearValidators();
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  filtrarTipoRegimen($event: any, limpiarRegimen: any) {
    let tipoCapital = $event.target.value;
    if (tipoCapital === "PUBLICO") {
      this.regimenTributario = this.regimenTributarioListado.filter((r: string) => r === "Entidad del Estado")
      const controlEstampilla = this.formaForm.get("aplicaEstampillas");
      if (controlEstampilla) {
        controlEstampilla.addValidators([
          Validators.required
        ])
        if (limpiarRegimen) {
          controlEstampilla.setValue("")
        }

        controlEstampilla.updateValueAndValidity();
      }
    }
    else {
      this.regimenTributario = this.regimenTributarioListado.filter((r: string) => r !== "Entidad del Estado" &&
        r !== "No Responsable de IVA" && r !== "Régimen simple de tributación PN")
      const controlEstampilla = this.formaForm.get("aplicaEstampillas");
      if (controlEstampilla) {
        controlEstampilla.clearValidators();
        if (limpiarRegimen) {
          controlEstampilla.setValue("")
        }
        controlEstampilla.updateValueAndValidity();
      }
    }
    if (limpiarRegimen) {
      const control = this.formaForm.get("regimenTributario");
      if (control) {
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  validarRegimenPrecios() {
    this.logger.log("this.usuario.dispo.toLowerCase()", this.usuario.dispo.toLowerCase())
    let objetoDispositivo: any = this.preciosd.filter((res) => { return res.nombre.toLowerCase() == this.usuario.dispo.toLowerCase() })[0]
    this.usuario.costoBase = Math.trunc(this.pago.costoBase ?? (this.usuario.costoBase ?? 0))
    let tipoRegimen = this.usuario.regimenTributario;
    let departamento = this.depar.filter(d => +d.codigo_dep === +this.usuario.departamento)[0].Departamento    
    if ((tipoRegimen === "Entidad del Estado" || tipoRegimen === "Gran Contribuyente DIAN") && departamento === "BOGOTA") {
      let valorIca: any = Math.trunc((this.usuario.costoBase - (this.pago.descuentoCupon ?? 0)) * (objetoDispositivo.ica / 100));
      let valorIva: any = Math.trunc((this.usuario.costoBase - (this.pago.descuentoCupon ?? 0)) * (objetoDispositivo.iva / 100))
      let valorReteIva: any = Math.trunc(valorIva * (objetoDispositivo.reteiva / 100))
      this.usuario.iva = Math.trunc(objetoDispositivo.iva)
      this.usuario.ica = objetoDispositivo.ica
      this.usuario.reteiva = Math.trunc(objetoDispositivo.reteiva)

      if (tipoRegimen === "Entidad del Estado") {
        //Guardar los descuentos por estampillas
        this.usuario.estampillas = this.estampillas
        this.pago.estampillas = this.estampillas
      }
      this.pago.costoBase = Math.trunc(this.usuario.costoBase)
      this.pago.ica = this.usuario.ica ?? 0
      this.pago.icaValor = Math.trunc(valorIca)
      this.pago.iva = Math.trunc(this.usuario.iva)
      this.pago.ivaValor = Math.trunc(valorIva)
      this.usuario.costoBase =  Math.trunc(this.usuario.costoBase - (this.pago.descuentoCupon ?? 0))
      this.pago.reteIvaValor = Math.trunc(valorReteIva)
      this.pago.reteiva = Math.trunc(this.usuario.reteiva)
      this.pago.descuentoCupon = Math.trunc(this.pago.descuentoCupon ?? 0)
      this.usuario.descuentoCupon = Math.trunc(this.usuario.descuentoCupon ?? 0)

      this.usuario.descuentos = Math.trunc(this.totalDescuentosValor)
      this.usuario.costo = Math.trunc(this.usuario.costoBase + valorIva - valorIca - valorReteIva - this.totalDescuentosValor)
      this.pago.valorTotal = Math.trunc(this.usuario.costo)
      this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
      this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
      this.componentesService.emitircambio("cargarDatosHeader");
    }
    else if (departamento === "ARCHIPIÉLAGO DE SAN ANDRÉS, PROVIDENCIA Y SANTA CATALINA" || departamento === "AMAZONAS") {
      let valorIca = 0;
      let valorIva = 0
      let valorReteIva = 0
      this.usuario.iva = 0
      this.usuario.ica = 0
      this.usuario.reteiva = 0
      if (tipoRegimen === "Entidad del Estado") {
        //Guardar los descuentos por estampillas
        this.usuario.estampillas = this.estampillas
        this.pago.estampillas = this.estampillas
      }
      this.pago.costoBase = Math.trunc(this.usuario.costoBase)
      this.pago.ica = this.usuario.ica ?? 0
      this.pago.icaValor = Math.trunc(valorIca)
      this.pago.iva = Math.trunc(this.usuario.iva)
      this.pago.ivaValor = Math.trunc(valorIva)
      this.usuario.costoBase =  Math.trunc(this.usuario.costoBase - (this.pago.descuentoCupon ?? 0))
      this.pago.reteIvaValor = Math.trunc(valorReteIva)
      this.pago.reteiva = Math.trunc(this.usuario.reteiva)
      this.pago.descuentoCupon = Math.trunc(this.pago.descuentoCupon ?? 0)
      this.usuario.descuentoCupon = Math.trunc(this.usuario.descuentoCupon ?? 0)

      this.usuario.descuentos = Math.trunc(this.totalDescuentosValor)
      this.usuario.costo = Math.trunc(this.usuario.costoBase + valorIva - valorIca - valorReteIva - this.totalDescuentosValor)
      this.pago.valorTotal = Math.trunc(this.usuario.costo)
      this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
      this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
      this.componentesService.emitircambio("cargarDatosHeader");
    }
    else if (tipoRegimen === "Entidad del Estado" || tipoRegimen === "Gran Contribuyente DIAN") {
      let valorIca = 0;
      let valorIva: any = Math.trunc((this.usuario.costoBase - (this.pago.descuentoCupon ?? 0)) * (objetoDispositivo.iva / 100))
      let valorReteIva: any = Math.trunc(valorIva * (objetoDispositivo.reteiva / 100))
      this.usuario.iva = Math.trunc(objetoDispositivo.iva)
      this.usuario.ica = 0
      this.usuario.reteiva = Math.trunc(objetoDispositivo.reteiva)

      if (tipoRegimen === "Entidad del Estado") {
        //Guardar los descuentos por estampillas
        this.usuario.estampillas = this.estampillas
        this.pago.estampillas = this.estampillas
      }
      this.pago.costoBase = Math.trunc(this.usuario.costoBase)
      this.pago.ica = this.usuario.ica ?? 0
      this.pago.icaValor = Math.trunc(valorIca)
      this.pago.iva = Math.trunc(this.usuario.iva)
      this.pago.ivaValor = Math.trunc(valorIva)
      this.pago.reteIvaValor = Math.trunc(valorReteIva)
      this.pago.reteiva = Math.trunc(this.usuario.reteiva)
      this.usuario.descuentos = Math.trunc(this.totalDescuentosValor)
      this.pago.descuentoCupon = Math.trunc(this.pago.descuentoCupon ?? 0)
      this.usuario.descuentoCupon = Math.trunc(this.usuario.descuentoCupon ?? 0)

      this.usuario.costo = Math.trunc(this.usuario.costoBase + valorIva - valorIca - valorReteIva - this.totalDescuentosValor)
      this.pago.valorTotal = Math.trunc(this.usuario.costo)
      this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
      this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
      this.componentesService.emitircambio("cargarDatosHeader");
    }
    else {
      let valorIca = 0;
      let valorIva: any = Math.trunc((this.usuario.costoBase - (this.pago.descuentoCupon ?? 0)) * (objetoDispositivo.iva / 100))
      let valorReteIva: any = 0
      this.usuario.iva = Math.trunc(objetoDispositivo.iva)
      this.usuario.ica = 0
      this.usuario.reteiva = 0      
      this.pago.costoBase = Math.trunc(this.usuario.costoBase)
      this.pago.ica = this.usuario.ica ?? 0
      this.pago.icaValor = Math.trunc(valorIca)
      this.pago.iva = Math.trunc(this.usuario.iva)
      this.usuario.costoBase =  Math.trunc(this.usuario.costoBase - (this.pago.descuentoCupon ?? 0))
      this.pago.ivaValor = Math.trunc(valorIva)
      this.pago.reteIvaValor = Math.trunc(valorReteIva)
      this.usuario.costo = Math.trunc(this.usuario.costoBase + valorIva - valorIca - valorReteIva)
      this.pago.reteiva = Math.trunc(this.usuario.reteiva)
      this.pago.valorTotal = Math.trunc(this.usuario.costo)
      this.pago.descuentoCupon = Math.trunc(this.pago.descuentoCupon ?? 0)
      this.usuario.descuentoCupon = Math.trunc(this.usuario.descuentoCupon ?? 0)
      this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
      this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
      this.componentesService.emitircambio("cargarDatosHeader");
    }
  }

  precios() {
    this.ssps.precios()
      .subscribe((res: any) => {
        this.preciosd = res.precios;
      })
  }

  agregarEstampilla() {
    if (this.verificarCampoAndTouch("descripcionEstampilla") || this.verificarCampoAndTouch("tipoEstampilla") ||
      this.verificarCampoAndTouch("valorEstampilla"))
      return;

    let nuevaEstampilla = {
      descripcion: this.nuevaEstampilla.descripcion,
      porcentaje: this.nuevaEstampilla.tipo === "P" ? Math.trunc(+this.nuevaEstampilla.valor) : Math.trunc((+this.nuevaEstampilla.valor / this.usuario.costoBase) * 100),
      valor: this.nuevaEstampilla.tipo === "V" ? Math.trunc(+this.nuevaEstampilla.valor) : Math.trunc(+this.nuevaEstampilla.valor * (this.usuario.costoBase / 100))
    }

    this.estampillas.push(nuevaEstampilla)

    this.nuevaEstampilla = {
      descripcion: "",
      tipo: "",
      valor: ""
    };
    this.agregarNuevaEstampilla = false
    this.clearValidatorsEstampillas()
    setTimeout(() => {
      if (this.estampillas.length > 0) {
        this.totalDescuentos = this.estampillas.map(a => +a.porcentaje).reduce(function (a, b) {
          return a + b;
        }, 0);
        this.totalDescuentosValor = this.estampillas.map(a => +a.valor).reduce(function (a, b) {
          return a + b;
        }, 0);
      }
    }, 1000);
  }

  verificarCampoAndTouch(campo: any) {
    this.formaForm.controls[campo].markAsTouched()
    return this.formaForm.controls[campo].errors && this.formaForm.controls[campo].touched
  }

  habilitarAplicaEstampillas($event: any) {
    let aplica = $event.target.value;
    if (aplica === "si") {
      this.agregarNuevaEstampilla = false;
      this.formaForm.get("descripcionEstampilla")!.valueChanges.subscribe(val => {
        if (this.inputRefDescripcionEstampilla) {
          const elRef = this.inputRefDescripcionEstampilla.nativeElement;
          const orVal = elRef.getAttribute('data-model-value') || '';
          val = val?.replace(orVal?.toUpperCase(), orVal);
          elRef.setAttribute('data-model-value', val);
          elRef.value = val?.toUpperCase();
          this.formaForm.get("descripcionEstampilla")!.setValue(val?.toUpperCase(), {
            emitEvent: false,
            emitModelToViewChange: false
          });
        }
      });
    }
    else {
      this.clearValidatorsEstampillas()
      this.estampillas = []
      this.usuario.estampillas = []
    }
  }

  habilitarNuevaEstampilla(valor: any) {
    this.agregarNuevaEstampilla = valor
    if (valor) {
      this.addValidatorsEstampillas()
    }
    else {
      this.clearValidatorsEstampillas()
    }
  }

  eliminarEstampilla(index: any) {
    this.estampillas.splice(index, 1)
    setTimeout(() => {
      if (this.estampillas.length > 0) {
        this.totalDescuentos = this.estampillas.map(a => +a.porcentaje).reduce(function (a, b) {
          return a + b;
        }, 0);
        this.totalDescuentosValor = this.estampillas.map(a => +a.valor).reduce(function (a, b) {
          return a + b;
        }, 0);
      }
    }, 1000);

  }

  campoValidacionDireccion() {
    const controlesAEliminar = ['tipoNumeroDireccion', 'numeroDireccion',
      'numeroDireccion2', 'barrioDireccion', 'adicionalDireccion', 'numeroOtroDireccion'];
    //Validar que si tiene el +, mostrar error y enfocar el campo
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        let valor = control.getRawValue();
        if (valor !== null && valor !== undefined && valor.includes("+")) {
          console.log("valor elemento", control.getRawValue())
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + controlName + '"]');
          invalidControl?.focus();
          control.setErrors({
            formatoValido: false
          })
          return true;
        }
      }
    }

    return false;
  }
}

