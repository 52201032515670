<table border="0" cellspacing="0" cellpadding="0">
  <thead style="display: none;">
    <tr>
      <th>Encabezado</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style="vertical-align: top; padding: 20px;">
        <h2 class="modal-title" id="exampleModalLabel">Señor usuario</h2>
      </td>
    </tr>
  </tbody>
</table>
<div class="modal-body" *ngIf="usuario.solicitudFinalizada === 'FINALIZADO'">
  <p class="textoc">
    El registro de datos finalizó exitosamente y está en proceso de revisión por parte de Certicámara.
  </p>
</div>
<div class="modal-body" *ngIf="usuario.solicitudFinalizada === 'APROBADO'">
  <p class="textoc">
    El registro de datos finalizó exitosamente y está en proceso de aprobación por parte de Certicámara.
  </p>
</div>
<div class="modal-body" *ngIf="usuario.solicitudFinalizada === 'ASIGNADO'">
  <p class="textoc">
    El registro de datos finalizó exitosamente y está pendiente por descargar.
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">Cerrar</button>
</div>