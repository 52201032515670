<div class="modal-body">
    <table border="0" cellspacing="0" cellpadding="0" style="width: 100%;  text-align: center;">
        <tr>
            <td style="vertical-align: middle;">
                <img src="../../../assets/searching.png" class="rounded mx-auto d-block" style="width: 150px;">
            </td>
        </tr>
        <!-- <tr>
            <td style="vertical-align: middle; font-weight: 400;">
                <h3>¡Revisa tu correo electrónico!</h3>
            </td>
        </tr>
        <tr>
            <td style="vertical-align: middle;">
                <p style="font-size: 13pt;" class="textoc">
                    Se envió una URL a tu correo electrónico, para que puedas realizar la validación de identidad y
                    continuar con el proceso
                </p>
            </td>
        </tr> -->
        <tr>
            <td style="vertical-align: middle;">
                <p style="font-size: 13pt;" class="textoc">
                    {{mensaje}}
                </p>
            </td>
        </tr>
        <tr>
            <td colspan="2" style="vertical-align: middle;">
                <div class="modal-footer" style="justify-content: center;">
                    <button type="button" class="btn btn-primary" (click)="cerrar()">Aceptar</button>
                </div>
            </td>
        </tr>
    </table>
</div>