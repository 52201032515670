import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SspsComponent } from './ui/components/ssps/ssps.component';
import { ContactenosComponent } from './ui/components/contactenos/contactenos.component';
import { PersonaNComponent } from './ui/components/validaciones/persona-n/persona-n.component';
import { PersonaJComponent } from './ui/components/validaciones/persona-j/persona-j.component';
import { ValidacionesComponent } from './ui/components/validaciones/validaciones.component';
import { FlujocomprasspsComponent } from './ui/components/flujocomprassps/flujocomprassps.component';
import { InfoBasicaComponent } from './ui/components/flujocomprassps/info-basica/info-basica.component';
import { PagoComponent } from './ui/components/flujocomprassps/pago/pago.component';
import { ContactoComponent } from './ui/components/flujocomprassps/contacto/contacto.component';
import { EntregaComponent } from './ui/components/flujocomprassps/entrega/entrega.component';
import { DatosFacturacionComponent } from './ui/components/flujocomprassps/datos-facturacion/datos-facturacion.component';
import { PSEComponent } from './ui/components/flujocomprassps/pse/pse.component';
import { DatosempresaComponent } from './ui/components/flujocomprassps/datosempresa/datosempresa.component';
import { SolicitantesComponent } from './ui/components/flujocomprassps/solicitantes/solicitantes.component';
import {ValidacionIdentidadComponent} from './modulos/validacion-identidad/validacion-identidad.component'
import {DescargaCertificadoComponent} from './modulos/descarga-certificado/descarga-certificado.component'
import {DescargaCertificadoPfxComponent} from './modulos/descarga-certificado-pfx/descarga-certificado-pfx.component'
import { PrefacturaComponent } from './modulos/prefactura/prefactura.component';
import { RevocarCertificadoComponent } from './modulos/revocar-certificado/revocar-certificado.component';
import { CertimailHomeComponent } from './modulos/certimail/certimail-home/certimail-home.component';
import { DescargarFacturaComponent } from './modulos/descargar-factura/descargar-factura.component';
import { EstadoTramiteComponent } from './modulos/estado-tramite/estado-tramite.component';



const routes: Routes = [
{path:'ssps' ,component:SspsComponent},
{path:'validaciones' ,component:ValidacionesComponent,
children: [
  {path: 'natural', component:PersonaNComponent},
  {path: 'juridica', component:PersonaJComponent},
  {path:'**' ,component:PersonaNComponent},
  
  ]
},
{path: 'validacion-identidad', component:ValidacionIdentidadComponent},
{path: 'descarga-certificado', component:DescargaCertificadoComponent},
{path: 'descarga-pfx', component:DescargaCertificadoPfxComponent},
{path: 'revocar-certificado', component:RevocarCertificadoComponent},
{path: 'flujo', component:FlujocomprasspsComponent,
children: [
  {path: 'infobasi', component:InfoBasicaComponent},
  {path: 'pago', component:PagoComponent},
  {path: 'contacto', component:ContactoComponent},
  {path: 'entrega', component:EntregaComponent},
  {path: 'facturacion', component:DatosFacturacionComponent},
  {path: 'pasarela', component:PSEComponent},
  {path: 'empresa', component:DatosempresaComponent},
  {path: 'solicitante', component:SolicitantesComponent},
  {path:'**' ,component:InfoBasicaComponent},
  ]
},
{path:'contacto' ,component:ContactenosComponent,},
{path:'prefactura', component:PrefacturaComponent},
{path: 'descargar-factura', component:DescargarFacturaComponent},
{path: 'estado-tramite', component:EstadoTramiteComponent},
{path:'**' ,component:CertimailHomeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
