<div class="animate__animated animate__fadeIn animate__fast"
       *ngIf="preguntas.length === 0 && tipoValidacion === 'PREGUNTAS'">
       <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()" id="form1">
              <div class="margen2">
                     <div class="mb-3">
                            <label *ngIf="solicitudId !== undefined && solicitudId !== null"
                                   class="form-label"><b>Número de solicitud: {{solicitudId}}</b></label>
                            <div style="padding: 5px; background-color: silver;">
                                   <i class="fas fa-info-circle icon-primary"></i>&nbsp;Completa los siguientes campos
                                   con la
                                   información solicitada.
                            </div>
                     </div>
                     <div class="mb-3">
                            <label for="tipoIdentificacion" class="form-label">Tipo de identificación *</label>
                            <select [(ngModel)]="usuarioValida.tipo" id="tipoIdentificacion" class="form-select" formControlName="tipo"
                                   [class.is-invalid]="camposvalidos('tipo')">
                                   <option class="form-control" value="CC">Cédula de Ciudadanía</option>
                                   <option class="form-control" value="CE">Cédula de Extranjería</option>
                                   <option class="form-control" value="PP">Pasaporte</option>
                                   <option class="form-control" value="PT">Permiso Permanencia Temporal P.P.T</option>
                            </select>
                            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                   *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de documento</div>
                     </div>

                     <div class="mb-3">
                            <div class="row">
                                   <div class="col">
                                          <label class="form-label">¡Número de identificación! *</label>
                                          <input [(ngModel)]="usuarioValida.numero" type="text" class="form-control"
                                                 placeholder="Número de identificación" formControlName="numero"
                                                 [class.is-invalid]="camposvalidos('numero')" #inputRefNumero>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('numero')">¡Identificación no válido!</div>
                                   </div>
                                   <div class="col">
                                          <label class="form-label">Fecha de expedición *</label>
                                          <div class="input-group">
                                                 <input class="form-control" placeholder="dd/mm/aaaa"
                                                        formControlName="fechaExpDocumento"
                                                        [(ngModel)]="fechaExpDocumento"
                                                        [class.is-invalid]="camposvalidos('fechaExpDocumento')"
                                                        [maxDate]="maxDate" [minDate]="minDate" mask="99/99/9999"
                                                        appInputMask ngbDatepicker #d="ngbDatepicker"
                                                        (keyup)="fechas(fechaExpDocumento, 'fechaExpDocumento')" />
                                                 <button class="btn btn-primary  bi bi-calendar3" (click)="d.toggle()"
                                                        type="button">
                                                        <i class="far fa-calendar-alt"></i>
                                                 </button>
                                          </div>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="statusFechaExpDocumento || camposvalidos('fechaExpDocumento')">
                                                 Fecha de expedición documento no
                                                 válida</div>
                                   </div>
                            </div>
                     </div>
                     <div class="row mb-4" style="display: none;">
                            <div class="col">
                                   <label class="form-label">Departamento expedición documento *</label>
                                   <select [(ngModel)]="usuarioValida.departamento" class="form-select"
                                          formControlName="departamento" [class.is-invalid]="camposvalidos('municipio')"
                                          placeholder="selecione un departamento">
                                          <option *ngFor="let muni of depar" [value]="muni.codigo_dep">
                                                 {{muni.Departamento}}</option>
                                   </select>
                                   <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                          *ngIf="camposvalidos('departamento')">Departamento no válido, selecciona una
                                          opción del listado</div>
                            </div>
                            <div class="col">
                                   <label class="form-label">Municipio expedición documento *</label>
                                   <select [(ngModel)]="usuarioValida.municipio" class="form-select"
                                          formControlName="municipio" [class.is-invalid]="camposvalidos('municipio')"
                                          placeholder="selecione un municipio">
                                          <option class="form-control" *ngFor="let muni of munifi"
                                                 [value]="muni.Codigo_mu">{{muni.Municipio}}
                                          </option>
                                   </select>
                                   <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                          *ngIf="camposvalidos('municipio')">Municipio no válido, selecciona una opción
                                          del listado</div>
                            </div>
                     </div>
                     <div class="mb-3">
                            <div class="row">
                                   <div class="col">
                                          <label class="form-label">Nombres *</label>
                                          <input [(ngModel)]="usuarioValida.nombres" type="text" class="form-control"
                                                 placeholder="Nombres completos" formControlName="nombres"
                                                 [class.is-invalid]="camposvalidos('nombres')" #inputRefNombres>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('nombres')">
                                                 ¡Los nombres no son válidos!</div>
                                   </div>
                                   <div class="col">
                                          <label class="form-label">Apellidos *</label>
                                          <input [(ngModel)]="usuarioValida.apellidos" type="text" class="form-control"
                                                 placeholder="Apellidos completos" formControlName="apellidos"
                                                 [class.is-invalid]="camposvalidos('apellidos')" #inputRefApellidos>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('apellidos')">¡Los apellidos no son válidos!</div>
                                   </div>

                            </div>
                     </div>
              </div>
              <div class="margen2" style="padding-bottom:0px ;">
                     <div class="mb-3" style="line-height: 10px; margin-bottom: 5px !important;">
                            <label for="label" class="form-label">* Campos obligatorios</label>
                     </div>
              </div>
              <div class="modal-footer">
                     <button type="button" class="btn btn-secondary" (click)="homeNavigate()">Continuar después</button>
                     <button type="submit" class="btn btn-primary" [disabled]="formaForm.invalid">Solicitar
                            preguntas</button>
              </div>
       </form>
</div>

<div class="modal-body" *ngIf="preguntas.length > 0 && tipoValidacion === 'PREGUNTAS'">
       <div style="padding: 20px; text-align: center;">
              <div class="wrapper2 fade2">
                     <div *ngFor="let seg of preguntas index as s"></div>
              </div>

              <div class="wrapper2" [style.clip-path]="
                'polygon(0 0, ' + (((actual +1)/preguntas.length)*100) + '% 0, ' + (((actual +1)/preguntas.length)*100) + '% 100%, 0% 100%)'
              ">
                     <div *ngFor="let seg of preguntas index as s"
                            [style.background-color]="actual >= s ? '#6eb16e' : 'silver'"></div>
              </div>
              <span>Pregunta {{actual + 1}} de {{preguntas.length}}</span>
       </div>
       <div class="modal-body">
              <div id="carouselExampleControlsNoTouching" class="carousel slide" data-bs-touch="false"
                     data-bs-interval="false">
                     <div class="carousel-inner">
                            <div *ngFor="let preg of preguntas index as p"
                                   [ngClass]="p === 0 ? 'carousel-item active' : 'carousel-item'">
                                   <div class="margenuni animate__animated animate__fadeIn animate__fast">
                                          <table class="table mt-8">
                                                 <thead>
                                                        <tr>
                                                               <th colspan="2" scope="col">{{p+1}}. {{preg.texto}}</th>
                                                        </tr>
                                                 </thead>
                                                 <tbody>
                                                        <tr *ngFor="let respuesta of preg.respuestas index as t">
                                                               <td style="width: 30px;"><input
                                                                             class="form-check-input mt-0"
                                                                             ng-model="respuesta.seleccionada"
                                                                             value="respuesta.respuesta"
                                                                             name="respuesta_{{preg.id}}" type="radio"
                                                                             (change)="radiosRespuesta($event,t)" />
                                                               </td>
                                                               <td>{{ respuesta.respuesta }}</td>
                                                        </tr>
                                                 </tbody>
                                          </table>
                                   </div>
                            </div>
                     </div>
                     <div class="modal-footer">
                            <button *ngIf="actual > 0" type="submit" class="btn btn-secondary"
                                   data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev"
                                   (click)="anteriorPregunta()">
                                   Anterior
                            </button>
                            <button *ngIf="actual < preguntas.length -1" type="submit" class="btn btn-primary"
                                   data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next"
                                   (click)="siguientePregunta()" [disabled]="disableSiguiente">
                                   Siguiente
                            </button>
                            <button *ngIf="actual === preguntas.length -1" type="submit" class="btn btn-primary"
                                   data-bs-target="#carouselExampleControlsNoTouching" (click)="enviarRespuestas()"
                                   [disabled]="disableSiguiente">
                                   Enviar respuestas
                            </button>
                     </div>
              </div>
       </div>
</div>

<div class="animate__animated animate__fadeIn animate__fast" *ngIf="tipoValidacion === 'FACIAL' && !inicioFacial">
       <form [formGroup]="formaForm" (ngSubmit)="ngsubmitFacial()" id="form1">
              <div class="margen2">
                     <div class="mb-3">
                            <label class="form-label"><b>Número de solicitud: {{solicitudId}}</b></label>
                            <div style="padding: 5px; background-color: silver;">
                                   <i class="fas fa-info-circle icon-primary"></i>&nbsp;Completa los siguientes campos
                                   con la
                                   información solicitada.
                            </div>
                     </div>
                     <div class="mb-3">
                            <label for="tipoIdentificacion" class="form-label">Tipo de identificación *</label>
                            <select [(ngModel)]="usuarioValida.tipo" id="tipoIdentificacion" class="form-select" formControlName="tipo"
                                   [class.is-invalid]="camposvalidos('tipo')">
                                   <option class="form-control" value="CC">Cédula de Ciudadanía</option>
                                   <option class="form-control" value="CE">Cédula de Extranjería</option>
                                   <option class="form-control" value="PP">Pasaporte</option>
                                   <option class="form-control" value="PT">Permiso Permanencia Temporal P.P.T</option>
                            </select>
                            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                   *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de documento</div>
                     </div>

                     <div class="mb-3">
                            <div class="row">
                                   <div class="col">
                                          <label class="form-label">¡Número de identificación! *</label>
                                          <input [(ngModel)]="usuarioValida.numero" type="text" class="form-control"
                                                 placeholder="Número de identificación" formControlName="numero"
                                                 [class.is-invalid]="camposvalidos('numero')" #inputRefNumero>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('numero')">¡Identificación no válido!</div>
                                   </div>
                                   <div class="col">
                                          <label class="form-label">Fecha de expedición *</label>
                                          <div class="input-group">
                                                 <input class="form-control" placeholder="dd/mm/aaaa"
                                                        formControlName="fechaExpDocumento"
                                                        [(ngModel)]="fechaExpDocumento" mask="99/99/9999" appInputMask
                                                        [class.is-invalid]="camposvalidos('fechaExpDocumento')"
                                                        [maxDate]="maxDate" [minDate]="minDate" ngbDatepicker
                                                        #d="ngbDatepicker"
                                                        (keyup)="fechas(fechaExpDocumento, 'fechaExpDocumento')" />
                                                 <button class="btn btn-primary  bi bi-calendar3" (click)="d.toggle()"
                                                        type="button">
                                                        <i class="far fa-calendar-alt"></i>
                                                 </button>
                                          </div>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="statusFechaExpDocumento || camposvalidos('fechaExpDocumento')">
                                                 Fecha de expedición documento no
                                                 válida</div>
                                   </div>
                            </div>
                     </div>
                     <div class="row mb-4" style="display: none;">
                            <div class="col">
                                   <label class="form-label">Departamento expedición documento *</label>
                                   <select [(ngModel)]="usuarioValida.departamento" class="form-select"
                                          formControlName="departamento" [class.is-invalid]="camposvalidos('municipio')"
                                          placeholder="selecione un departamento">
                                          <option *ngFor="let muni of depar" [value]="muni.codigo_dep">
                                                 {{muni.Departamento}}</option>
                                   </select>
                                   <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                          *ngIf="camposvalidos('departamento')">Departamento no válido, selecciona una
                                          opción del listado</div>
                            </div>
                            <div class="col">
                                   <label class="form-label">Municipio expedición documento *</label>
                                   <select [(ngModel)]="usuarioValida.municipio" class="form-select"
                                          formControlName="municipio" [class.is-invalid]="camposvalidos('municipio')"
                                          placeholder="selecione un municipio">
                                          <option class="form-control" *ngFor="let muni of munifi"
                                                 [value]="muni.Codigo_mu">{{muni.Municipio}}
                                          </option>
                                   </select>
                                   <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                          *ngIf="camposvalidos('municipio')">Municipio no válido, selecciona una opción
                                          del listado</div>
                            </div>
                     </div>
                     <div class="mb-3">
                            <div class="row">
                                   <div class="col">
                                          <label class="form-label">Nombres *</label>
                                          <input [(ngModel)]="usuarioValida.nombres" type="text" class="form-control"
                                                 placeholder="Nombres completos" formControlName="nombres"
                                                 [class.is-invalid]="camposvalidos('nombres')" #inputRefNombres>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('nombres')">
                                                 ¡Los nombres no son válidos!</div>
                                   </div>
                                   <div class="col">
                                          <label class="form-label">Apellidos *</label>
                                          <input [(ngModel)]="usuarioValida.apellidos" type="text" class="form-control"
                                                 placeholder="Apellidos completos" formControlName="apellidos"
                                                 [class.is-invalid]="camposvalidos('apellidos')" #inputRefApellidos>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('apellidos')">¡Los apellidos no son válidos!</div>
                                   </div>

                            </div>
                     </div>
              </div>
              <div class="margen2" style="padding-bottom:0px ;">
                     <div class="mb-3" style="line-height: 10px; margin-bottom: 5px !important;">
                            <label for="label" class="form-label">* Campos obligatorios</label>
                     </div>
              </div>
              <div class="modal-footer">
                     <button type="button" class="btn btn-secondary" (click)="homeNavigate()">Continuar después</button>
                     <button type="submit" class="btn btn-primary" [disabled]="formaForm.invalid">Validación
                            facial</button>
              </div>
       </form>
</div>
<div class="animate__animated animate__fadeIn animate__fast" *ngIf="tipoValidacion === 'FACIAL' && inicioFacial">
       <span class="close" (click)="cerrarModalFacial()">X</span>
       <div id="ebm"></div>
</div>

<ng-template [ngIf]="tipoValidacion === ''">
       <div class="alert alert-light text-center animate__animated animate__fadeIn animate__faster"
              style="z-index: 10000;">
              <h3>Validación de identidad</h3>
              <p style="text-align: justify;">Para garantizar la seguridad de tus datos, es fundamental que elijas el
                     método que prefieras para
                     confirmar tu identidad. Esta verificación nos ayuda a proteger tu información personal y asegurar
                     que solo tu tengas acceso a tu certificado de firma digital. Por favor, selecciona el método
                     mediante el cual deseas realizar este proceso de validación de identidad</p>
              <div class="mb-4">
                     <button style="width: 200px;" type="button" class="btn btn-primary "
                            (click)="seleccionarTipoValidacion('FACIAL')">Validación facial</button>
              </div>
              <div class="mb-4">
                     <button style="width: 200px;" type="button" class="btn btn-primary "
                            (click)="seleccionarTipoValidacion('PREGUNTAS')">Preguntas de seguridad</button>
              </div>
       </div>
</ng-template>