
<div class="modal-header">
    <h4 class="titulos" style="margin-left: 25%;" >{{titulo}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body  text-center ms-4 me-4">    
    <p style="font-size: 16pt;" [innerHTML]="mensaje"></p>
 <button type="submit" class="btn btn-primary" (click)="close()">{{tituloBoton}}</button>
</div>
<div class="modal-footer">
  
</div>