<div class="modal-header">

  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<form [formGroup]="formaForm" (ngSubmit)="ngsubmit()">
  <div class="modal-body  ms-4 me-4">
    <div class="contiene">

      <h4 class="titulos">Estás a un clic de disfrutar los beneficios </h4>
      <p class="texto">

        Queremos brindarte una solución que se ajuste perfectamente a los objetivos de tu empresa. Compártenos por favor
        tus datos personales para que uno de nuestros consultores especializados pueda contactarse contigo durante las
        próximas 24 horas.
      </p>
      <div class="margencon">
        <div class="margen"></div>
        <div class="row">
          <div class="col">
            <div class="mb-3" *ngIf="tipoCliente !== 'natural'">
              <label class="form-label">Tipo de identificación</label>
              <select [(ngModel)]="usuario.tipo" class="form-select" formControlName="tipo"
                [class.is-invalid]="camposvalidos('tipo')">
                <option class="form-control" value="NI">NIT</option>
              </select>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de documento</div>
            </div>
            <div class="mb-3" *ngIf="tipoCliente === 'natural'">
              <label class="form-label">Tipo de identificación</label>
              <select [(ngModel)]="usuario.tipo" class="form-select" formControlName="tipo"
                [class.is-invalid]="camposvalidos('tipo')">
                <option class="form-control" value="CC">Cédula de Ciudadanía</option>
                <option class="form-control" value="CE">Cédula de Extranjería</option>
                <option class="form-control" value="PP">Pasaporte</option>
              </select>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de documento</div>
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <label class="form-label">Número de identificación</label>
              <input [(ngModel)]="usuario.numero" type="text" class="form-control"
                placeholder="Número de identificación" formControlName="numero"
                [class.is-invalid]="camposvalidos('numero')" #inputRefNumero>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('numero')">¡Identificación no válido!</div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="row">
            <ng-template [ngIf]="tipoCliente == 'natural'">
              <div class="col">
                <label class="form-label">Nombres completos</label>
                <input type="text" class="form-control" [(ngModel)]="usuario.primerNombre" placeholder="nombres completos"
                  formControlName="nombre" [class.is-invalid]="camposvalidos('nombre')" #inputRefNombres>
                <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                  *ngIf="camposvalidos('nombre')">Nombres no son válidos, ingrese solo letras</div>
              </div>
              <div class="col">
                <label class="form-label">Apellidos Completos</label>
                <input type="text" [(ngModel)]="usuario.primerApellido" class="form-control"
                  placeholder=" apellidos completos" formControlName="apellido"
                  [class.is-invalid]="camposvalidos('apellido')" #inputRefApellidos>
                <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                  *ngIf="camposvalidos('apellido')">Apellidos no son válidos, ingrese solo letras</div>
              </div>
            </ng-template>
          </div>
        </div>


        <ng-template [ngIf]="tipoCliente == 'juridica'">

          <div class="mb-3">

            <label class="form-label">Entidad / empresa u organización</label>
            <input type="text" [(ngModel)]="usuario.razon" class="form-control" placeholder="entidad"
              formControlName="empresa" [class.is-invalid]="camposvalidos('empresa')" #inputRefRazonSocial>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('empresa')">Empresa no válida</div>
          </div>
        </ng-template>

        <div class="mb-3">

          <div class="row">
            <div class="col">
              <label class="form-label">Correo electrónico:</label>
              <input type="text" [(ngModel)]="usuario.correo" class="form-control" placeholder="correo electrónico"
                formControlName="correo" [class.is-invalid]="camposvalidos('correo')" #inputRefCorreo>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('correo')">Correo no válida</div>

            </div>
            <div class="col">
              <label class="form-label">Celular de contacto:</label>
              <input type="number" class="form-control" placeholder="celular" formControlName="celular"
                [class.is-invalid]="camposvalidos('celular')" #datos [(ngModel)]="usuario.celular"
                value="{datos.toString()}" (keyup)="noPuntoComa($event)" (keyup)="quitarceros(datos.value)">
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('celular')">Celular no válido, ingrese  número de 10 dígitos</div>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comas">El campo
                no
                puede tener comas</div>
            </div>
          </div>

        </div>
        <div class="mb-3">
          <div class="row">
            <div class="col">
              <label class="form-label">Disponibilidad</label>
              <input type="text" [(ngModel)]="usuario.disponibilidad" class="form-control"
                placeholder="Lunes-viernes 8am-2pm" formControlName="disponibilidad"
                [class.is-invalid]="camposvalidos('disponibilidad')" #inputRefDisponibilidad>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('disponibilidad')">Disponibilidad no puede ser vacia y más de 5 carácteres</div>

            </div>

          </div>

        </div>
        <br>
        <div class="check">
          <div class="mb-1 form-check">
            <input type="checkbox" class="form-check-input" formControlName="terminosp"
              [class.is-invalid]="camposvalidos('terminosp')">
            <label class="form-check-label">He leído y acepto <a class="descargas"
                href="../../../assets/Documentos/documentos.pdf" download="documentos.pdf"><span class="politicas">
                  Política términos y
                  condiciones.</span></a>
            </label>
          </div>

          <div class="mb-1 form-check">
            <input type="checkbox" class="form-check-input" formControlName="terminost"
              [class.is-invalid]="camposvalidos('terminost')">
            <label class="form-check-label">He leído y acepto <a class="descargas"
                href="../../../assets/Documentos/PC_-_Política_de_certificación_-_Servicios_asociados_a_sistemas_de_información.pdf"
                download="politica  (ver.1.0).pdf"><span class="politicas">Política de
                  Certificación.</span></a> </label>
          </div>

          <div class="mb-1 form-check">
            <input type="checkbox" class="form-check-input" formControlName="terminostpro"
              [class.is-invalid]="camposvalidos('terminostpro')" id="exampleCheck2">
            <label class="form-check-label" for="exampleCheck2">He leído y acepto <a class="descargas"
                href="../../../assets/Documentos/ANEXO_SAGRILAFT.pdf" download="ANEXO_SAGRILAFT.pdf"><span
                  class="politicas"> Anexo
                  Sagrilaf</span></a></label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <!--<button type="submit" class="btn btn-primary">Enviar</button>-->
    <button type="button" class="btn btn-secondary" (click)="close()">Cancelar</button>
    <button type="submit" class="btn btn-primary">Enviar</button>
  </div>

</form>