import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '../../domain/servicios/log.service'
import { urlRequest } from 'src/assets/config/app.conf';
import { Observable } from 'rxjs';
import { SuccessResponse } from '../../domain/interfaces/response-base.interface';

@Injectable({
  providedIn: 'root'
})
export class PreInvoiceAdapterService {

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  urlRequest = urlRequest;

  constructor(private http: HttpClient, private logger: LogService) { }

  validateLink(url: string): Observable<SuccessResponse>  {
    this.logger.log("validar-link", url);
    return this.http.get<SuccessResponse>(this.urlRequest.VALIDARLINKPREFACTURA, this.httpOptions);
  }

  acceptPreInvoice(body: any): Observable<SuccessResponse>  {
    this.logger.log("aprobar", body);
    return this.http.post<SuccessResponse>(this.urlRequest.APROBARPREFACTURA, body, this.httpOptions);
  }

  declinePreInvoice(body: any): Observable<SuccessResponse>  {
    this.logger.log("rechazar", body);
    return this.http.post<SuccessResponse>(this.urlRequest.RECHAZARPREFACTURA, body, this.httpOptions);
  }
}
