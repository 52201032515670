import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-persona-j',
  templateUrl: './persona-j.component.html',
  styleUrls: ['./persona-j.component.css']
})
export class PersonaJComponent implements OnInit, AfterViewInit {
  @ViewChild('inputRefRazonSocial')
  inputRefRazonSocial!: { nativeElement: any; };

  formaForm!: FormGroup;
  estados: boolean = false;
  estados2: boolean = false;
  listas: boolean = true;
  mostrar: boolean = false;
  rutasActivas: string = '';
  comas: boolean = false;
  quitar: any;
  digito: any = ""

  /*validacion de campos y validastors */
  constructor(private fb: FormBuilder,
    private componentesService: ComponentesService,
    private ssps: SspsService,
    private changeDetector: ChangeDetectorRef,
    private alert: AlertsService,
    private el: ElementRef,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.formaForm = this.fb.group({
      tipo: ['', [Validators.required]],
      numero: ['', [//Validators.required,
        // Validators.min(1),
        //Validators.max(9999999999),

      ]],
      codigo: ['', [
        Validators.required,
        Validators.min(0),
        Validators.max(9),
        , Validators.pattern("^[0-9]*$")
      ]
      ],
      razon: ['', [Validators.required,
      Validators.minLength(3),
      Validators.maxLength(60)]],
      terminosp: [false, Validators.requiredTrue],
      terminost: [false, Validators.requiredTrue],
      terminostpro: [false, Validators.requiredTrue],

    }, {
      validators: [this.componentesService.minimos('numero'),
      this.componentesService.digitoChequeo('numero', 'codigo')]
    });
    this.setOnChangesInputs()
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.ssps.compras = [];
    this.ssps.typePerson = "";
    this.ssps.obtenerIpValor()
    this.formaForm.controls["codigo"]?.disable({ onlySelf: true })
  }

  setOnChangesInputs() {
    this.formaForm.get("razon")!.valueChanges.subscribe(val => {
      const elRef = this.inputRefRazonSocial.nativeElement;
      const orVal = elRef.getAttribute('data-model-value') || '';
      val = val.replace(orVal.toUpperCase(), orVal);
      elRef.setAttribute('data-model-value', val);
      elRef.value = val.toUpperCase();
      this.formaForm.get("razon")!.setValue(val.toUpperCase(), {
        emitEvent: false,
        emitModelToViewChange: false
      });
    });
    this.formaForm.get("numero")!.valueChanges.subscribe(val => {
      if (val !== null && val !== undefined && val.length > 6) {
        this.digito = this.componentesService.calcularDigitoChequeo(val)
      }
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }


  ngAfterViewInit() {

  }
  /*funcion para validacion de campos formularios*/

  camposvalidos(campo: any) {
    return this.formaForm.controls[campo].errors && this.formaForm.controls[campo].touched;
  }

  //implementacion de validacion de comas
  noPuntoComa(event: any) {

    var e = event || window.event;
    var key = e.keyCode || e.which;


    if (key === 110 || key === 190 || key === 188) {

      this.comas = true;
    } else {
      this.comas = false;
    }
  }

  /*quitar numeros ceros a la izquierda*/
  quitarceros(numero: string) {

    this.quitar = numero.replace(/^0+/, '');
  }


  /*implementacion eventos modal */
  abrirmodal() {
  }


  /**envio de datos y validacion de boton */
  ngsubmit() {
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          return;
        }
      });
      return;
    }

    this.alert.showSpiner("No olvides tener tu documento de identificación a la mano para poder continuar con el proceso");
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token: string) => {
        this.ssps.validatedCapcha(token)
          .subscribe({
            next: (res: any) => {
              if (res.code == 200) {
                this.estados = true;
                let { numero, codigo, razon, terminosp, terminost, terminostpro } = this.formaForm.getRawValue();
                
                this.ssps.AseptPJ(numero, codigo, razon, terminosp, terminost, terminostpro)
                  .subscribe({
                    next: (res: any) => {
                      if (res) {
                        this.ShowReporterPJ()
                      }
                      else {
                        this.alert.closesSpiner()
                      }
                    },
                    error: (error) => {
                      this.alert.closesSpiner()
                      console.error('Error en subscriber aceptacion PN', error)
                      let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
                        error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                          error.error.message ? error.error.message :
                            (error.message ? error.message : "No fue posible realizar la petición")) :
                        (error.message ? error.message : "No fue posible realizar la petición")
                      this.alert.showError(mensaje, false, null, false, null, null)
                    }
                  })
              }
            },
            error: (error) => {
              this.alert.closesSpiner()
              console.error('Error en la funion de capcha', error);
              let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
                error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                  error.error.message ? error.error.message :
                    (error.message ? error.message : "No fue posible realizar la petición")) :
                (error.message ? error.message : "No fue posible realizar la petición")
              this.alert.showError(mensaje, false, null, false, null, null)
            },
          })
      });
  }

  //TODO:
  ShowReporterPJ() {
    let { numero, razon, codigo } = this.formaForm.getRawValue();
    this.ssps.ReportPersonPJ(numero, codigo, razon)
      .subscribe({
        next: (res: any) => {
          let cliente: object = { cliente: 'juridica' };
          let valores = this.formaForm.getRawValue();
          let uid: object = { uid: this.componentesService.myGuid };
          let valoresfi = Object.assign(valores, cliente, uid);

          /*validaciones  yredioreaciones a componentes*/
          if (res.result === false) {
            this.ssps.saveLocalStorage("identificacion", numero);
            this.alert.closesFiels();
            this.ssps.typePerson = 'juridica';
            this.ssps.numero = numero;
            this.ssps.person = valoresfi;
            this.alert.closesSpiner()
            this.alert.closesFiels()
            this.alert.showSolicitante();
            //Pedir los datos del solicitante y validar en listas negras, ahí si consultar historico
            //this.gethistorial(tipo, numero);
          } else {
            this.ssps.typePerson = 'juridica';
            this.ssps.numero = numero;
            this.ssps.person = valoresfi;
            this.alert.closesSpiner();
            this.alert.showReporter(false);
          }
          this.ssps.saveLocalStorage('tipocliente', 'juridica');
          this.ssps.saveLocalStorage('identificacion', numero);
          this.ssps.saveLocalStorage('usuario', JSON.stringify(valoresfi));
        },
        error: (error) => {
          this.alert.closesSpiner()
          console.error('Error en subscriber aceptacion PJ', error)
          let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
            error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
              error.error.message ? error.error.message :
                (error.message ? error.message : "No fue posible realizar la petición")) :
            (error.message ? error.message : "No fue posible realizar la petición")
          this.alert.showError(mensaje, false, null, false, null, null)
        }
      })

  }

  //TODO:cierra modal;
  close() {
    this.alert.closesSpiner();
  }

  /*alertas animaciones */
  validar() {
    return (this.estados) ? 'alert alert-light animate__animated animate__fadeIn animate__faster text-center ' : 'animate__animated animate__fadeOut';
  }
}
