<div class=" animate__animated animate__fadeInLeftBig animate__faster">

  <div class="row ms-1 mt-3">

    <h5 class="titulosintu2">Datos de contacto</h5>
    <br>

    <div class="mb-3">
      <div class="checkbox-wrapper-19">
        <input type="checkbox" class="form-check-input" (change)="contactoOtroChange($event)" id="contactoOtro"
          [(ngModel)]="contactoOtro">
        <label for="contactoOtro" class="check-box"></label>
        <label class="form-check-label" for="contactoOtro">
          <p>¿Deseas que los datos de la persona vinculada sean diferentes?</p>
        </label>
      </div>
    </div>

    <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()">

      <app-identificacion [formaForm]="formaForm" [cols]="2"></app-identificacion>

      <app-nombre-apellido [formaForm]="formaForm"></app-nombre-apellido>

      <div class="mb-3">
        <label class="form-label mt-3">Teléfono contacto</label>
        <div class="row">

          <div class="col">
            <input class="form-control" list="browsers" formControlName="indicativo" placeholder="indicativo"
              [class.is-invalid]="camposvalidos('indicativo')"
              (keyup)="noPuntoComa($event)">
            <datalist id="browsers">
              <option *ngFor="let d of indicativos" [value]="d.indicativo">{{d.nomDepartamento}}</option>
            </datalist>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('indicativo')">Indicativo no válido, selecciona una opción de la lista</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comas">El campo
              no
              puede tener decimales</div>
          </div>


          <div class="col">

            <input type="number" class="form-control" placeholder="telefono" formControlName="telefono"
              [class.is-invalid]="camposvalidos('telefono')" #datos2
              value="{datos.toString()}" (keyup)="noPunto($event, 'comastel2')">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('telefono')">Teléfono no válido, debe ser un número de 7 dígitos, debe ser un número
              de 6 dígitos</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastel2">El
              campo
              no puede tener comas</div>

          </div>
          <div class="col">
            <input type="number" class="form-control" placeholder="extensión" formControlName="extension"
              [class.is-invalid]="camposvalidos('extension')" #datos3
              value="{datos3.toString()}" (keyup)="noPunto($event, 'comastex')">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('extension')">Extension no válido</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastex">El campo
              no puede tener comas</div>
          </div>

        </div>

      </div>

      <div class="mb-3">

        <label class="form-label">Celular *</label>
        <input type="number" class="form-control" placeholder="celular" formControlName="celular"
          [class.is-invalid]="camposvalidos('celular')" #datos4
          value="{datos4.toString()}" (keyup)="noPunto($event, 'comastel')">
        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
          *ngIf="camposvalidos('celular')">Celular no válido, ingresar número de 10 dígitos</div>

        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastel">El campo no
          puede tener comas</div>
      </div>

      <div class="mb-3">
        <div class="col">
          <label for="correoElectronico" class="form-label">Correo electrónico *</label>
          <input type="text" id="correoElectronico" class="form-control" placeholder="correo electrónico" formControlName="correo"
            [class.is-invalid]="camposvalidos('correo')" #inputRefCorreo>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('correo')">Correo no válido, debe tener la estructura correo&#64;dominio.com(.co,.es..)
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="row">
          <div class="col">
            <label class="form-label">Confirmación correo electrónico *</label>
            <input type="text" class="form-control" placeholder="correo electrónico" formControlName="correo1"
              [class.is-invalid]="camposvalidos('correo1')" appNocopiado
              #inputRefCorreo1>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('correo1')">Correo no válido, debe tener la estructura correo&#64;dominio.com(.co,.es..)
            </div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="correo2NoValido">
              Los correos no son iguales</div>
          </div>
        </div>
      </div>
      <div class="margen2_1" style="padding-bottom:0px ;">
        <div class="mb-3" style="line-height: 10px; margin-bottom: 5px !important;">
          <label for="label" class="form-label">* Campos obligatorios</label>
        </div>
      </div>
      <div class="mb-4">
        <button type="button" class="btn btn-secondary" style="margin: 15px;" (click)="redireciona()">Anterior</button>
        <button type="submit" class="btn btn-primary">Siguiente</button>
      </div>
    </form>
  </div>