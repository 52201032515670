<div class="animate__animated animate__fadeIn animate__fast">
       <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()">
              <div class="margen2">
                     <div class="mb-3">
                            <label class="form-label">Tipo de identificación</label>
                            <select class="form-select" formControlName="tipo"
                                   [class.is-invalid]="camposvalidos('tipo')">
                                   <option class="form-control" value="NI">NIT</option>

                            </select>
                            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                   *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de documento</div>
                     </div>
                     <div class="mb-3">
                            <div class="row">
                                   <div class="col">
                                          <label class="form-label">Número de identificación</label>
                                          <input type="number" class="form-control"
                                                 placeholder="número de identificación" formControlName="numero"
                                                 [class.is-invalid]="camposvalidos('numero')" #datos
                                                 [(ngModel)]="quitar" value="{datos.toString()}"
                                                 (keyup)="noPuntoComa($event)" (keyup)="quitarceros(datos.value)">

                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('numero')">Número no válido</div>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="comas">El campo no puede tener comas</div>
                                   </div>

                                   <div class="col">
                                          <label class="form-label">Número de verificación</label>
                                          <input type="text" class="form-control" placeholder="Número de verificación"
                                                 formControlName="codigo" [class.is-invalid]="camposvalidos('codigo')"
                                                 appDigito maxLength="1" [(ngModel)]="digito">
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('codigo')">Número de verificación no válido</div>
                                   </div>

                            </div>
                     </div>

                     <div class="col">
                            <label class="form-label">Razón social</label>
                            <input type="text" class="form-control" placeholder="Razón social" formControlName="razon"
                                   [class.is-invalid]="camposvalidos('razon')" #inputRefRazonSocial>
                            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                   *ngIf="camposvalidos('razon')">Razón social no puede ser vacio, ni puede tener más de 60 caracteres</div>
                     </div>
              </div>

              <!--[class.is.invalid]="camposvalidos('nombres')">-->
              <div class="check">
                     <div class="mb-1 form-check">
                            <input type="checkbox" class="form-check-input" formControlName="terminosp"
                                   [class.is-invalid]="camposvalidos('terminosp')">
                            <label class="form-check-label">He leído y acepto los <a class="descargas"
                                          href="../../../assets/Documentos/PYP-L-005_Términos_Condiciones_del Servicio_de_Certificación_de_Firma_Digital_v1.pdf"
                                          download="PYP-L-005_Términos_Condiciones_del Servicio_de_Certificación_de_Firma_Digital_v1.pdf"><span
                                                 class="politicas"> Términos y condiciones</span></a>
                            </label>
                     </div>

                     <div class="mb-1 form-check">
                            <input type="checkbox" class="form-check-input" formControlName="terminost"
                                   [class.is-invalid]="camposvalidos('terminost')">
                            <label class="form-check-label">He leído y acepto <a class="descargas"
                                          href="../../../assets/Documentos/Aviso_de_Privacidad_SSPS.pdf"
                                          download="Aviso_de_Privacidad_SSPS.pdf"><span class="politicas">Aviso de
                                                 privacidad</span></a> </label>
                     </div>

                     <div class="mb-1 form-check" style="font-size: 11pt;">
                            <input type="checkbox" class="form-check-input" formControlName="terminostpro"
                                   [class.is-invalid]="camposvalidos('terminostpro')" id="exampleCheck2">
                            <label class="form-check-label" for="exampleCheck2">He leído y acepto <a class="descargas"
                                          href="../../../assets/Documentos/AGR-L-004_Declaraciones_y_Compromisos_en_Materia_de_Prevención_de_LA_FT_FPADM_Y_C_ST_v1.pdf"
                                          download="AGR-L-004_Declaraciones_y_Compromisos_en_Materia_de_Prevención_de_LA_FT_FPADM_Y_C_ST_v1.pdf"><span
                                                 class="politicas">Declaración y compromisos LA/FT/FPADM Y
                                                 C/ST</span></a></label>
                     </div>
              </div>

              <div class="modal-footer">
                     <button type="button" class="btn btn-secondary" (click)="close()">Cancelar</button>
                     <button type="submit" class="btn btn-primary">Siguiente</button>
              </div>
       </form>
</div>


<!--politicas-->