import { UpperCasePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonPn } from 'src/app/domain/interfaces/personpn.interface';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { environment as env } from "src/environments/environment"
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-persona-solicitante',
  templateUrl: './persona-solicitante.component.html',
  styleUrls: ['./persona-solicitante.component.css']
})
export class PersonaSolicitanteComponent implements OnInit {
  @ViewChild('inputRefPrimerNombre')
  inputRefPrimerNombre!: { nativeElement: any; };

  @ViewChild('inputRefSegundoNombre')
  inputRefSegundoNombre!: { nativeElement: any; };

  @ViewChild('inputRefPrimerApellido')
  inputRefPrimerApellido!: { nativeElement: any; };

  @ViewChild('inputRefSegundoApellido')
  inputRefSegundoApellido!: { nativeElement: any; };

  @ViewChild('inputRefNumero')
  inputRefNumero!: { nativeElement: any; };

  formaForm!: FormGroup;
  estados: boolean = false;
  estados2: boolean = false;
  listas: boolean = true;
  rutasActivas: string = '';
  reportes: any;
  pasaportes: boolean = false;
  cedulas: boolean = true;
  cheksPolis: boolean = false;
  solicitante: any = {}
  reCAPTCHAToken: string = "";
  constructor(private fb: FormBuilder,
    private router: Router,
    private componentesService: ComponentesService,
    private activateRoute: ActivatedRoute,
    private ssps: SspsService,
    private alert: AlertsService,
    private el: ElementRef,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    /*validacion de campos validators*/
    this.formaForm = this.fb.group({
      tipo: ['', [Validators.required]],
      numero: ['', [Validators.required,
        // Validators.pattern('([A-Za-z]+[0-9]|[0-9]+[A-Za-z])[A-Za-z0-9]*')
      ]],
      primerNombre: ['', [Validators.pattern("^[a-zA-ZÑ ]{3,60}$"),
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(60)
      ]
      ],
      segundoNombre: ['', [Validators.pattern("^[a-zA-ZÑ ]{3,60}$"),
      Validators.minLength(3),
      Validators.maxLength(60)
      ]
      ],
      primerApellido: ['', [Validators.required,
      Validators.minLength(3),
      Validators.maxLength(60),
      Validators.pattern('[a-zA-ZÑ ]{3,60}')]],
      segundoApellido: ['', [
        Validators.minLength(3),
        Validators.maxLength(60),
        Validators.pattern('[a-zA-ZÑ ]{3,60}')]],
      terminosp: [false, Validators.requiredTrue],
      terminost: [false, Validators.requiredTrue],
      terminostpro: [false, Validators.requiredTrue]
    }, { validators: [this.componentesService.codigonit('tipo', 'numero')] });
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.ssps.compras = [];
    this.ssps.typePerson = this.ssps.getLocalStorage("tipocliente")!
    this.ssps.obtenerIpValor()
    if (this.ssps.getLocalStorage("solicitante")) {
      this.solicitante = JSON.parse(this.ssps.getLocalStorage("solicitante")!)
    }
    this.setOnChangesInputs()
  }

  setOnChangesInputs() {
    this.formaForm.get("primerNombre")!.valueChanges.subscribe(val => {
      const elRef = this.inputRefPrimerNombre.nativeElement;
      const orVal = elRef.getAttribute('data-model-value') || '';
      val = val.replace(orVal.toUpperCase(), orVal);
      elRef.setAttribute('data-model-value', val);
      elRef.value = val.toUpperCase();
      this.formaForm.get("primerNombre")!.setValue(val.toUpperCase(), {
        emitEvent: false,
        emitModelToViewChange: false
      });
    });

    this.formaForm.get("segundoNombre")!.valueChanges.subscribe(val => {
      const elRef = this.inputRefSegundoNombre.nativeElement;
      const orVal = elRef.getAttribute('data-model-value') || '';
      val = val.replace(orVal.toUpperCase(), orVal);
      elRef.setAttribute('data-model-value', val);
      elRef.value = val.toUpperCase();
      this.formaForm.get("segundoNombre")!.setValue(val.toUpperCase(), {
        emitEvent: false,
        emitModelToViewChange: false
      });
    });

    this.formaForm.get("primerApellido")!.valueChanges.subscribe(val => {
      const elRef = this.inputRefPrimerApellido.nativeElement;
      const orVal = elRef.getAttribute('data-model-value') || '';
      val = val.replace(orVal.toUpperCase(), orVal);
      elRef.setAttribute('data-model-value', val);
      elRef.value = val.toUpperCase();
      this.formaForm.get("primerApellido")!.setValue(val.toUpperCase(), {
        emitEvent: false,
        emitModelToViewChange: false
      });
    });

    this.formaForm.get("segundoApellido")!.valueChanges.subscribe(val => {
      const elRef = this.inputRefSegundoApellido.nativeElement;
      const orVal = elRef.getAttribute('data-model-value') || '';
      val = val.replace(orVal.toUpperCase(), orVal);
      elRef.setAttribute('data-model-value', val);
      elRef.value = val.toUpperCase();
      this.formaForm.get("segundoApellido")!.setValue(val.toUpperCase(), {
        emitEvent: false,
        emitModelToViewChange: false
      });
    });

    this.formaForm.get("numero")!.valueChanges.subscribe(val => {
      if (this.inputRefNumero) {
        const elRef = this.inputRefNumero?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numero")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });
  }

  /* funcion para validacion de campos*/
  camposvalidos(campo: any) {
    return this.formaForm.get(campo)!.invalid &&
      (this.formaForm.get(campo)!.touched || this.formaForm.get(campo)!.dirty);
  }

  //TODO:validiaciones de status de formualrio
  //Validar cuando el tipo de identificación cambie
  onchanges() {
    this.formaForm.valueChanges.subscribe({
      next: (res: any) => {
        this.formaForm.controls['numero'].clearValidators()
      },
      error: (error) => {
        console.error('error en el subscriber de formulario');
      },
    });
  }

  //TODO:cerrar el modal de activo digital
  close() {
    this.alert.closesSpiner();
  }

  /*ingreso y validacion de datos de formulario*/
  ngsubmit() {
    this.checks();
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl?.focus();
          return;
        }
      });
      return;
    }
    else {
      this.alert.showSpiner("No olvides tener tu documento de identificación a la mano para poder continuar con el proceso");
      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token: string) => {
          this.ssps.validatedCapcha(token)
            .subscribe({
              next: (res: any) => {
                if (res.code == 200) {
                  //this.estados= true;
                  let { primerNombre, segundoNombre, primerApellido, segundoApellido, numero, tipo, terminosp, terminost, terminostpro } = this.formaForm.value;
                  this.ssps.AseptN(primerNombre, segundoNombre, primerApellido, segundoApellido, tipo, numero, terminosp, terminost, terminostpro)
                    .subscribe({
                      next: (res: any) => {
                        if (res) {
                          this.showReporter();
                        }
                        else {
                          this.alert.closesSpiner()
                          this.alert.closesFiels()
                          this.alert.showFiels()
                        }
                      },
                      error: (error) => {
                        this.alert.closesSpiner()
                        this.alert.closesFiels()
                        this.alert.showFiels()
                        console.error('Error en subscriber aceptacion PN', error)
                        let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
                          error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                            error.error.message ? error.error.message :
                              (error.message ? error.message : "No fue posible realizar la petición")) :
                          (error.message ? error.message : "No fue posible realizar la petición")
                        this.alert.showError(mensaje, false, null, false, null, null)
                      }
                    })
                }
              },
              error: (error) => {
                this.alert.closesSpiner()
                console.error('Error en la funion de capcha', error);
                let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
                  error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                    error.error.message ? error.error.message :
                      (error.message ? error.message : "No fue posible realizar la petición")) :
                  (error.message ? error.message : "No fue posible realizar la petición")
                this.alert.showError(mensaje, false, null, false, null, null)
              },
            })
        });
    }
  }

  //TODO:implementacion de listas negras 
  showReporter() {
    let { primerNombre, segundoNombre, primerApellido, segundoApellido, numero, tipo } = this.formaForm.value;
    this.ssps.reportados(primerNombre, segundoNombre, primerApellido, segundoApellido, numero, tipo)
      .subscribe({
        next: (res: any) => {
          let valores: PersonPn = this.formaForm.value;
          valores.primerNombre = valores.primerNombre.toUpperCase();
          valores.segundoNombre = valores.segundoNombre.toUpperCase();
          valores.primerApellido = valores.primerApellido.toUpperCase();
          valores.segundoApellido = valores.segundoApellido.toUpperCase();

          /*validaciones  yredioreaciones a componentes*/
          if (res.result === false) {
            this.ssps.saveLocalStorage("solicitante", JSON.stringify(valores))
            /*implementacion inyeccion de roll a objeto*/
            this.gethistorial();
          } else {
            this.ssps.saveLocalStorage("solicitante", JSON.stringify(valores))
            this.alert.closesSpiner();
            this.alert.closesFiels()
            this.alert.showFiels()
            this.alert.showReporter(false);
          }
        },
        error: (error) => {
          this.alert.closesSpiner()
          this.alert.closesFiels()
          this.alert.showFiels()
          console.error("reportados error solicitante", error);
          let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
            error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
              error.error.message ? error.error.message :
                (error.message ? error.message : "No fue posible realizar la petición")) :
            (error.message ? error.message : "No fue posible realizar la petición")
          this.alert.showError(mensaje, false, null, false, null, null)
        },
      })
  }

  /* estilos alertas */
  validar() {
    return (this.estados) ? 'alert alert-light animate__animated animate__fadeIn animate__faster text-center ' : 'animate__animated animate__fadeOut';
  }

  negras() {
    return (this.estados2) ? 'alert-malo animate__animated animate__fadeIn animate__faster text-center' : 'animate__animated animate__fadeOut';
  }

  //TODO:redireciona al home de certicamara
  cancelar() {
    this.alert.closesFiels();
    this.alert.showFiels()
  }

  //TODO:implementacion de validaciones de checks
  checks() {
    let { terminosp, terminost, terminostpro } = this.formaForm.value;
    if (terminosp == false || terminost == false || terminostpro == false) {
      this.cheksPolis = true;
    }
    else {
      this.cheksPolis = false;
    }
  }

  //TODO:implementacion de historial
  gethistorial() {
    this.ssps.historial(this.solicitante.tipo, this.solicitante.numero, this.ssps.person.numero + this.ssps.person.codigo, this.ssps.typePerson)
      .subscribe({
        next: (res: any) => {
          this.ssps.compras = res.codigoEstado === 200 ? res.compras : [];
          this.alert.closesSpiner();
          this.alert.closesFiels();
          this.alert.showSssps();
        },
        error: (error) => {
          this.alert.closesSpiner()
          this.alert.closesFiels()
          this.alert.showFiels()
          console.error('Error en subscriber historial PJ', error)
          let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
            error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
              error.error.message ? error.error.message :
                (error.message ? error.message : "No fue posible realizar la petición")) :
            (error.message ? error.message : "No fue posible realizar la petición")
          this.alert.showError(mensaje, false, null, false, null, null)
        }
      })
  }
}