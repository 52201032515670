import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-identificacion',
  templateUrl: './identificacion.component.html',
  styleUrl: './identificacion.component.css',
  standalone: false
})
export class IdentificacionComponent {
  @Input() formaForm!: FormGroup;
  @Input() solicitante: any;
  @Input() cols: number = 1;

  @ViewChild('inputRefNumero') inputRefNumero!: { nativeElement: any; };

  ngOnInit() {
    this.setOnChangesInputs();
  }

  setOnChangesInputs() {
    this.formaForm.get("numero")!.valueChanges.subscribe(val => {
      const elRef = this.inputRefNumero.nativeElement;
      const orVal = elRef.getAttribute('data-model-value') || '';
      val = val.replace(orVal.toUpperCase(), orVal);
      elRef.setAttribute('data-model-value', val);
      elRef.value = val.toUpperCase();
      this.formaForm.get("numero")!.setValue(val.toUpperCase(), {
        emitEvent: false,
        emitModelToViewChange: false
      });
    });
  }

  camposvalidos(campo: any) {
    return this.formaForm.get(campo)!.invalid &&
      (this.formaForm.get(campo)!.touched || this.formaForm.get(campo)!.dirty);
  }
}
