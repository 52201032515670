<div class="animate__animated animate__fadeIn animate__fast">
       <div class="modal-header">
              <h4 style="text-align: center;" *ngIf="listadoSolicitudes.length === 0"> ESTADO DEL TRAMITE DE SU
                     CERTIFICADO
                     DE FIRMA DIGITAL</h4>
              <h4 style="text-align: center; text-transform: uppercase;" *ngIf="listadoSolicitudes.length > 0">
                     {{tituloConsulta}}
              </h4>
              <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
       </div>
       <div class="margen2" style="max-height: 90vh;">
              <div class="mb-3" *ngIf="listadoSolicitudes.length === 0">
                     <span>Para conocer más información acerca del estado de su solicitud, ingrese el tipo y número de
                            identificación del solicitante y luego haga click en el botón Consultar. Allí podrá obtener
                            información clave, acerca del proceso de emisión, entrega y vigencia de su certificado de
                            firma digital.</span>
                     <br>
                     <br>
                     <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()" id="form1">
                            <div class="mb-3" style="width: 70%;">
                                   <div class="row">
                                          <div class="col-3">
                                                 <label class="form-label">Tipo de identificación</label>
                                                 <select [(ngModel)]="usuarioConsulta.tipo" class="form-select"
                                                        formControlName="tipo"
                                                        [class.is-invalid]="camposvalidos('tipo')">
                                                        <option class="form-control" value="CC">Cédula de Ciudadanía
                                                        </option>
                                                        <option class="form-control" value="CE">Cédula de Extranjería
                                                        </option>
                                                        <option class="form-control" value="PP">Pasaporte</option>
                                                 </select>
                                                 <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                        *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de
                                                        documento
                                                 </div>
                                          </div>
                                          <div class="col">
                                                 <label class="form-label">¡Identificación!</label>
                                                 <input [(ngModel)]="usuarioConsulta.numero" type="text"
                                                        class="form-control" placeholder="Número de identificación"
                                                        formControlName="numero"
                                                        [class.is-invalid]="camposvalidos('numero')"
                                                        (keyup)="noPuntoComa($event)"
                                                        (keyup)="quitarceros(inputRefNumero.value)" #inputRefNumero>
                                                 <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                        *ngIf="camposvalidos('numero')">¡Identificación no válido!</div>
                                                 <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                        *ngIf="comas">El campo no puede tener comas</div>
                                          </div>
                                   </div>
                            </div>
                            <div class="modal-footer">
                                   <button type="submit" class="btn btn-primary"
                                          [disabled]="formaForm.invalid">Consultar</button>
                            </div>
                     </form>
              </div>
              <div class="mb-3" *ngIf="listadoSolicitudes.length > 0">
                     <span>Nota: Para conocer más información del estado de su solicitud, haga click en <svg
                                   xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                   <path d="M0 0h24v24H0V0z" fill="none" />
                                   <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                            </svg> donde se presenta información clave, acerca del proceso de emisión, entrega
                            y vigencia de su certificado de firma digital.</span>
                     <br>
                     <br>
                     <!-- <table class="table table-striped table-bordered table-hover" style="margin: 0 auto;">
                            <thead>
                                   <tr>
                                          <th scope="col" class="text-center listas">
                                                 Orden Pedido No.
                                          </th>
                                          <th scope="col" class="text-center listas">
                                                 Nº Solicitud
                                          </th>
                                          <th scope="col" class="text-center listas">
                                                 Nombre del titular
                                          </th>
                                          <th scope="col" class="text-center listas">
                                                 Mecanismo
                                          </th>
                                          <th scope="col" class="text-center listas">
                                                 Politica
                                          </th>
                                          <th class="text-center listas"></th>
                                   </tr>
                            </thead>
                            <tbody>
                                   <tr class="table-light" *ngFor="let item of listadoSolicitudes">
                                          <td class="text-center animate__animated animate__fadeIn">
                                                 <span>{{ item.ordenPedidoId }}</span>
                                          </td>
                                          <td class="text-center animate__animated animate__fadeIn">
                                                 <span>{{ item.solicitudId }}</span>
                                          </td>
                                          <td class="text-center animate__animated animate__fadeIn">
                                                 <span>{{ item.nombreTitular }}</span>
                                          </td>
                                          <td class="text-center animate__animated animate__fadeIn">
                                                 <span>{{ item.mecanismo }}</span>
                                          </td>
                                          <td class="text-center animate__animated animate__fadeIn">
                                                 <span>{{ item.politica }}</span>
                                          </td>
                                          <td class="text-center">
                                                 <span class="btn btn-primary"
                                                        style="border: none !important; opacity: 100 !important;">
                                                        VER DETALLES
                                                 </span>
                                          </td>
                                   </tr>
                            </tbody>
                     </table> -->
                     <div class="example-table-container">
                            <table mat-table [dataSource]="listadoSolicitudes" multiTemplateDataRows
                                   class="mat-elevation-z8">
                                   <ng-container matColumnDef="{{column}}"
                                          *ngFor="let column of columnsToDisplay; let i = index">
                                          <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                                          <td mat-cell *matCellDef="let element">
                                                 {{element[columnsToDisplayValores[i]]}} </td>
                                   </ng-container>

                                   <ng-container matColumnDef="expand">
                                          <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                                          <td mat-cell *matCellDef="let element">
                                                 <button mat-icon-button aria-label="expand row"
                                                        (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">

                                                        <svg *ngIf="expandedElement === element"
                                                               xmlns="http://www.w3.org/2000/svg" height="24"
                                                               viewBox="0 0 24 24" width="24">
                                                               <path d="M0 0h24v24H0z" fill="none" />
                                                               <path
                                                                      d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
                                                        </svg>

                                                        <svg *ngIf="expandedElement !== element"
                                                               xmlns="http://www.w3.org/2000/svg" height="24"
                                                               viewBox="0 0 24 24" width="24">
                                                               <path d="M0 0h24v24H0V0z" fill="none" />
                                                               <path
                                                                      d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                                                        </svg>
                                                 </button>
                                          </td>
                                   </ng-container>

                                   <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                                   <ng-container matColumnDef="expandedDetail">
                                          <td mat-cell *matCellDef="let element"
                                                 [attr.colspan]="columnsToDisplayWithExpand.length">
                                                 <div class="example-element-detail"
                                                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                                                        <div _ngcontent-vsn-c4="" class="stepper-header"
                                                               style="width: 100%; margin-top: 25px; height: 120px;">
                                                               <ol _ngcontent-vsn-c4="" class="steps">
                                                                      <li _ngcontent-vsn-c4=""
                                                                             [class]="validarEstadoClase(paso)"
                                                                             ng-reflect-klass="step"
                                                                             *ngFor="let paso of element.pasos; let i = index">
                                                                             <div _ngcontent-vsn-c3=""
                                                                                    class="step-bullet ng-star-inserted">
                                                                                    {{i + 1}}</div>
                                                                             <div _ngcontent-vsn-c3=""
                                                                                    class="step-title ng-star-inserted"
                                                                                    style="cursor: pointer;"
                                                                                    [ngStyle]="{'bottom': (i+1) === 2?'-50px':'-26px'}"
                                                                                    (click)="mostrarComentarios(paso.comentarios)"
                                                                                    title="Haga click para más detalles">
                                                                                    {{paso.paso}}
                                                                                    <i *ngIf="paso.comentarios !== ''"
                                                                                           class="fas fa-info-circle"></i>
                                                                             </div>
                                                                      </li>
                                                               </ol>
                                                        </div>
                                                 </div>
                                          </td>
                                   </ng-container>

                                   <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
                                   <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                                          class="example-element-row"
                                          [class.example-expanded-row]="expandedElement === element"
                                          (click)="expandedElement = expandedElement === element ? null : element">
                                   </tr>
                                   <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                                          class="example-detail-row"></tr>
                            </table>
                     </div>

                     <div class="mb-4" style="text-align: center;">
                            <button type="button" class="btn btn-secondary" style="margin: 15px;"
                                   (click)="cancelarSeleccion()">Regresar</button>
                     </div>
              </div>
       </div>
</div>