import { Component, Input, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-show-open-tabs',
    templateUrl: './show-open-tabs.component.html',
    styleUrls: ['./show-open-tabs.component.scss'],
    standalone: false
})
export class ShowOpenTabsComponent implements OnInit {

  @Input() data: any;
  menssage: string = '';
  closemodal: boolean = false;
  closetap: boolean = false;

  constructor(private alert: AlertsService) { 
  }

  ngOnInit(): void {
    this.menssage = this.data;

    if (this.menssage == "Se acaba de abrir otra pestaña de este sitio") {
      this.closemodal = true;
    }

    if (this.menssage == "Ya se está ejecutando una instancia de este sitio") {
      this.closetap = true;
    }
  }


  //TODO:se cierra modal deacuerdo a acciones
  cloSeptaps() {
    this.alert.closeTabs();
  }


  closedExplorer() {
    this.alert.closeTabs();
    let externalUrl = environment.urlWebCerti;
    window.location.replace(externalUrl);

  }


}
