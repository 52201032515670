<div class="modal-header">
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <div class="modal-body">
    <div id="carouselExampleControlsNoTouching" class="carousel slide" data-bs-touch="false" data-bs-interval="false">
      <div class="carousel-inner">
        <!-- implementacion vista preguntas 0-->
        <div class="carousel-item active">
          <div class="margenuni animate__animated animate__fadeIn animate__fast">
            <h4 class="lh-1 text-center textomodal2">
              Te ayudamos a elegir, selecciona la mejor opción para ti.
            </h4>
            <table class="table mt-4">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col">SI</th>
                  <th scope="col">NO</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let pre of  questionsPolices index as t">
                  <th scope="row" *ngIf="pre.status">
                    <i class="fa-solid fa-angle-right icon-primary"></i>
                  </th>
                  <td *ngIf="pre.status">{{ pre.pregunta }}</td>

                  <td *ngIf="pre.status">
                    <div class="checkbox-wrapper-19">
                      <input class="form-check-input mt-0" type="radio" [(ngModel)]="pre.respuesta" value="si"
                        name="ques_{{t}}" (change)="radios($event,t)" id="cbtest_{{t}}" />
                      <label for="cbtest_{{t}}" class="check-box"></label>
                    </div>
                  </td>
                  <td *ngIf="pre.status">
                    <div class="checkbox-wrapper-19">
                      <input class="form-check-input mt-0" value="no" [(ngModel)]="pre.respuesta" name="ques_{{t}}"
                        type="radio" (change)="radios($event,t)" id="cbtestno_{{t}}" />
                      <label for="cbtestno_{{t}}" class="check-box"></label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>

        <!-- implementacion vista historial 1-->
        <div class="carousel-item">
          <div class="margenuni animate__animated animate__fadeIn animate__fast overwidth">
            <h4 class="textomodal lh-1" style="color: #999696"
              *ngIf="!newstatus && !mostrarReposiciones && compras.length > 0">¡A
              continuación encuentras los
              productos que has comprado
              en CERTI!</h4>
            <h4 class="textomodal lh-1" style="color: #999696"
              *ngIf="!newstatus && mostrarReposiciones && compras.length > 0">¡A continuación
              encuentras los
              productos que puedes solicitar reposición!</h4>
            <h3 class="textomodal lh-1 text-center" style="color: black" *ngIf="!newstatus && !mostrarReposiciones">
              Historial de compra
            </h3>
            <h3 class="textomodal lh-1 text-center" style="color: black" *ngIf="!newstatus && mostrarReposiciones">
              Reposición de certificados
            </h3>
            <table class="table mt-4" *ngIf="compras.length > 0  && !newstatus">
              <thead>
                <tr>
                  <th scope="col">Fecha de entrega</th>
                  <th scope="col">Política</th>
                  <th scope="col" colspan="3">Mecanismo de entrega</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let history of compras; index as i">
                  <td>{{ history.fecha }}</td>
                  <td>{{(history.politica | titlecase).replace("Pkcs10", "PKCS10") }}</td>
                  <td>{{ history.tipo.replace("FISICO", "FÍSICO").replace("Fisico", "Físico").replace("fisico",
                    "físico").replace("REUSO", "reúso").replace("Reuso", "Reúso").replace("reuso", "reúso") | uppercase
                    }}{{ history.tipo.toUpperCase() === "PKCS#10" &&
                    history.uso !== undefined && history.uso !== "" ? ' (' + history.uso +')' : ''}}
                  </td>
                  <td>
                    <span *ngIf="!mostrarReposiciones" class="badge rounded-pill text-bg-primary ms-3"
                      (click)="showCart(i)" (mouseDown)="showCart(i)">Volver
                      a
                      adquirir</span>
                    <span *ngIf="mostrarReposiciones" class="badge rounded-pill text-bg-primary ms-3"
                      (click)="showCartReposicion(i)" (mouseDown)="showCartReposicion(i)">Pedir reposición</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <table class="table mt-4" *ngIf="compras.length === 0  && !newstatus"> -->
            <table class="table mt-4" *ngIf="false">
              <thead>
                <tr>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>No se encontraron compras prevías como {{tipoCliente.toLowerCase() === 'natural' ? 'Persona ' +
                    tipoCliente : tipoCliente }},
                    puedes verificar realizando el proceso como {{tipoClienteSugerido.toLowerCase() === 'natural' ?
                    'Persona ' + tipoClienteSugerido : tipoClienteSugerido }}</td>
                  <td>
                    <span class="badge rounded-pill text-bg-primary ms-3" (click)="intentarPersonaSugerida()"
                      (mouseDown)="intentarPersonaSugerida()">Reintentar</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div style="text-align: left; float: left;" *ngIf="aplicaReposicion && !newstatus">
              <span *ngIf="!mostrarReposiciones" class="badge rounded-pill text-bg-primary ms-3"
                (click)="showReposiciones()">Quiero pedir reposición de un certificado digital</span>
              <span *ngIf="mostrarReposiciones" class="badge rounded-pill text-bg-primary ms-3"
                (click)="showReposiciones()">Quiero volver a adquirir un certificado digital</span>
            </div>

            <div style="text-align: right; float: right" *ngIf="compras.length > 0 && !newstatus">
              <span class="badge rounded-pill text-bg-primary ms-3" (click)="showNewProccess()">Quiero adquirir otro
                tipo de certificado digital</span>
            </div>

            <h4 class="textomodal lh-1 text-center" style="color: #999696" *ngIf="newstatus">
              Políticas sugeridas
            </h4>
            <br />
            <br />
            <form [formGroup]="formaForm" *ngIf="newstatus">
              <div class="row">
                <div class="col-lg-6" style="width: 100% !important;" *ngFor="let poli of directivesActive">
                  <div class="mb-1 form-check">
                    <div class="checkbox-wrapper-19">
                      <input id="exampleCheck2" type="radio" class="form-check-input" [value]="poli.id"
                        formControlName="checks" (change)="politica(poli)" [checked]="poli.id === usuario.idpoli" />
                        <label for="exampleCheck2" class="check-box"></label>
                      <span (click)="politica(poli)">{{poli.politica}} &nbsp;<i class="fas fa-info-circle icon-primary"
                          ngbTooltip="{{poli.desciption}}"></i></span>
                    </div>
                  </div>
                </div>

                <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                  *ngIf="camposvalidos('checks')">
                  <p><small>seleciona un solo elemento</small></p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- implementacion vista mecanismo 2-->
        <div class="carousel-item">
          <div class="margenuni animate__animated animate__fadeIn animate__fast">
            <p class="lh-1">
              <small>La opción de persona natural permite solicitar Certificados de
                Firma Digital a título personal, aquí podrás solicitar
                certificados para personas natural o solicitar el certificado
                profesional para solicitantes.
              </small>
            </p>

            <h4 class="textomodal lh-1 text-center" style="color: #999696">
              Mecanismo de Entrega
            </h4>
            <br />
            <form [formGroup]="formaForm2" (ngSubmit)="ngsubmit()">
              <div class="centrado">
                <table cellpadding="5">
                  <thead style="display: none;"><tr><th></th></tr></thead>
                  <tr>
                    <td colspan="3">

                      <div class="row" style="display: flex; justify-content: space-between; min-height: 65px;" 
                      *ngFor="let pre of preciosd; index as i">
                        <div class="col-2 col-sm-2 d-flex justify-content-start align-items-start">
                          <img class="projcard-img" [src]="pre.img" style="width: 50px; height: auto;" />
                        </div>
                      
                        <div class="col-5 col-sm-5 d-flex justify-content-center align-items-center" style="text-align: center;">
                          <h4 class="textomodal lh-1" *ngIf="pre.nombre.toLowerCase() !== 'pkcs#10'">
                            {{ pre.nombre | titlecase }}
                          </h4>
                          <h4 class="textomodal lh-1" *ngIf="pre.nombre.toLowerCase() === 'pkcs#10'">
                            {{ pre.nombre | titlecase }}
                          </h4>
                        </div>
                      
                        <div class="col-2 col-sm-2 d-flex justify-content-end align-items-center">
                          <div class="checkbox-wrapper-19" style="position: relative; display: flex; align-items: center;">
                            <input type="radio" name="chkMecanismo" id="cbtest-21_{{i}}" (click)="mecanisEntregaChange({target: true}, i)" />
                            <label for="cbtest-21_{{i}}" class="check-box" style="margin-left: 10px;"></label>
                          </div>
                        </div>
                      
                        <div class="col-3 col-sm-3 d-flex justify-content-end align-items-center">
                          <span *ngIf="pre.nombre.toLowerCase() === 'reúso token físico'">
                            <i class="fas fa-info-circle icon-primary"></i>&nbsp;&nbsp;
                            <span style="text-decoration: underline; font-size: 12px; cursor: pointer;" (click)="habilitarVideoReuso(2)">Ver más</span>
                          </span>
                        </div>
                      </div>                      
                                      
                    </td>
                  </tr>
                  <!-- <tr *ngFor="let pre of preciosd; index as i">
                     <td>
                      <input type="radio" class="form-check-input" value="{{ pre.id }}"
                        (change)="mecanisEntregaChange($event, i)" formControlName="dispositivo" />
                    </td>
                    <td><img [src]="pre.img" /></td>
                    <td *ngIf="pre.nombre.toLowerCase() !== 'pkcs#10'">{{ pre.nombre | titlecase }} <span
                        *ngIf="pre.nombre.toLowerCase()=== 'reúso token físico'"><i
                          class="fas fa-info-circle"></i>&nbsp;&nbsp;<span
                          style="text-decoration: underline; font-weight: 600; cursor: pointer;"
                          (click)="habilitarVideoReuso(2)">Ver
                          más</span></span></td>
                    <td *ngIf="pre.nombre.toLowerCase() === 'pkcs#10'">{{ pre.nombre | uppercase }}</td>
                  </tr> -->

                  <tr *ngIf="tipodis.dispo !== undefined && tipodis.dispo !== null && tipodis.dispo !== ''">
                    <td style="width: 10%;">
                      <h4 class="textomodal lh-1">
                        Vigencia
                      </h4>
                    </td>
                    <td colspan="2">
                      <select class="form-select" formControlName="anos" #costvalue
                        (change)="ChagePrice(costvalue.value)">
                        <option class="form-control" *ngFor="let vige of vigencias" [value]="vige.anos">
                          {{ vige.texto }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr *ngIf="tipodis.dispo.toLowerCase() === 'pkcs#10' && tipodis.anos > 0">
                    <td>
                      <h4 class="textomodal lh-1">
                        Uso
                      </h4>
                    </td>
                    <td>
                      <select class="form-select" formControlName="uso" (change)="cambioUso($event.target)">
                        <option class="form-control" *ngFor="let uso of usosCertificados" [value]="uso.id">
                          {{ uso.descripcion }}
                        </option>
                      </select>
                    </td>
                    <td *ngIf="usoCertificado.descripcion.toLowerCase() === 'otros'">
                      <input type="text" class="form-control" placeholder="Especifica otro uso"
                        formControlName="otroUso" [class.is-invalid]="camposvalidos2('otroUso')" maxlength="100"
                        #inputRefOtroUso>
                      <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                        *ngIf="camposvalidos2('otroUso')">Campo no válido</div>
                    </td>
                  </tr>
                </table>
              </div>
              <!--implementacion de politicas-->
              <!--implementacion errores-->
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comas">
                El campo no puede tener comas
              </div>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="check">
                <p><small>Solo se puede ingresar dos dígitos</small></p>
              </div>
              <div class="row" style="margin-top: 25px;">
                <div class="col">
                  <h4 class="textomodal lh-1" style="color: #999696">
                    <p data-bs-toggle="tooltip" data-bs-placement="top">
                      Políticas sugeridas:
                      &nbsp; <strong><span data-bs-toggle="tooltip" data-bs-placement="top"
                          title="{{ elementose?.desciption }}">{{ elementose?.politica }}</span></strong>
                    </p>
                  </h4>

                </div>

                <div class="row" style="margin-top: 10px;">
                  <br />
                  <div class="col">
                    <h4 class="textomodal lh-1" style="color: #999696">
                      <p data-bs-toggle="tooltip" data-bs-placement="top"
                        title="Tener en cuenta que este valor corresponde al subtotal más IVA del bien o servicio adquirido. Se aplicarán descuentos tributarios de acuerdo con la calidad tributaria">
                        Valor subtotal más IVA
                        &nbsp; <strong style="color: #ef9308">${{ resultado | currency: 'COP': '' : '1.0-0' }}</strong>
                      </p>
                    </h4>
                  </div>
                </div>
                <div class="row" *ngIf="tipodis.dispo.toLowerCase() === 'reúso token físico'">
                  <div class="col-lg-12">
                    <div class="checkbox-wrapper-19">
                      <input type="checkbox" class="form-check-input" [(ngModel)]="usuario.terminosReuso"
                        (change)="aceptaTerminosReuso($event)" id="cbtest-38" formControlName="terminosReuso">
                        <label for="cbtest-38" class="check-box"></label>
                        <label for="terminosReuso" class="form-check-label">He leído y acepto los <a class="descargas"
                          id="terminosReuso"
                          style="display: inline;"
                          href="../../../assets/Documentos/Terminos_Condiciones_Reuso_token_fisico_v1.pdf"
                          download="Terminos_Condiciones_Reuso_token_fisico_v1.pdf"><span class="politicas">
                            Términos y
                            condiciones</span></a> para reúso de token físico
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- implementacion vista de resumen 3-->
        <div class="carousel-item #enviar">
          <div class="margenuni animate__animated animate__fadeIn animate__fast">
            <p class="lh-1">
              <small>La opción de persona natural permite solicitar Certificados de
                Firma Digital a título personal , aquí podrás solicitar
                certificados para personas natural o solicitar el certificado
                profesional para solicitantes.
              </small>
            </p>
            <h4 class="textomodal lh-1 text-center" style="color: #999696">
              Detalles de la solicitud
            </h4>
            <div class="row">
              <div>
                <table *ngIf="show" style="margin: 0 auto; width: 70%" cellpadding="7px">
                  <thead style="display: none;"><tr><th></th></tr></thead>
                  <tr>
                    <td style="width: 190px"><strong>Mecanismo de Entrega</strong></td>
                    <td style="width: 210px" *ngIf="usuario.dispo.toLowerCase() !== 'pkcs#10'">{{ usuario.dispo |
                      titlecase }}</td>
                    <td style="width: 210px" *ngIf="usuario.dispo.toLowerCase() === 'pkcs#10'">{{ usuario.dispo |
                      uppercase }}</td>
                    <td *ngIf="!usuario.esReposicion" (click)="showresum()"><i
                        class="fa-solid fa-pen-to-square estilos"></i></td>
                    <td *ngIf="showresumeA">
                      <select [(ngModel)]="usuario.idispo" (change)="changePrices($event)"
                        class="form-select form-select-sm animate__animated animate__fadeIn">
                        <option *ngFor="let preci of preciosd" [value]="preci.id"><span
                            *ngIf="usuario.dispo.toLowerCase() !== 'pkcs#10'">{{preci.nombre | titlecase}}</span>
                          <span *ngIf="usuario.dispo.toLowerCase() === 'pkcs#10'">{{preci.nombre | uppercase}}</span>
                        </option>
                      </select>
                    </td>
                  </tr>

                  <tr></tr>
                  <tr>
                    <td><strong>Política</strong></td>
                    <td>{{ usuario.politica | titlecase }}</td>
                    <td *ngIf="!usuario.esReposicion" (click)="showNewProccess()"><i
                        class="fa-solid fa-pen-to-square estilos"></i></td>
                  </tr>

                  <tr></tr>

                  <!-- <tr *ngIf="usuario.cantidad > 0">
                    <td><strong>Cantidad</strong></td>
                    <td><i class="fa-solid fa-circle-check estilos"></i></td>
                    <td>{{ usuario.cantidad }}</td>
                    <td *ngIf="!usuario.esReposicion"><i class="fa-solid fa-pen-to-square estilos"></i></td>
                    <td>
                      <select class="form-select" #costdata (change)="ChagePrice(costdata.value)">
                        <option class="form-control" *ngFor="let vige of vigencias" [value]="vige.anos">
                          {{ vige.anos }}
                        </option>
                      </select>

                    </td>
                  </tr>

                  <tr></tr> -->

                  <tr>
                    <td><strong>Vigencia</strong></td>
                    <td>{{ usuario.anos }} {{ tiempo }}</td>
                    <td *ngIf="!usuario.esReposicion"><i class="fa-solid fa-pen-to-square estilos"
                        (click)="showresumB()"></i></td>
                    <td *ngIf="showresumeB">
                      <select [(ngModel)]="usuario.anos"
                        class="form-select form-select-sm animate__animated animate__fadeIn" #costdatab
                        (change)="ChagePrice(costdatab.value)">
                        <option class="form-control" *ngFor="let vige of vigencias" [value]="vige.anos">
                          {{ vige.texto }}
                        </option>
                      </select>
                    </td>
                  </tr>

                  <tr></tr>
                  <tr>
                    <td>
                      <strong>
                        <p data-bs-toggle="tooltip" data-bs-placement="top"
                          title="Tener en cuenta que este valor corresponde al subtotal más IVA del bien o servicio adquirido. Se aplicarán descuentos tributarios de acuerdo con la calidad tributaria">
                          Valor
                          subtotal más IVA
                        </p>
                      </strong>
                    </td>
                    <td style="vertical-align: top;">${{ resultado | currency: 'COP': '' : '1.0-0' }} &nbsp;<i
                        class="fas fa-info-circle icon-primary"
                        ngbTooltip="Tener en cuenta que este valor corresponde al subtotal más IVA del bien o servicio adquirido. Se aplicarán descuentos tributarios de acuerdo con la calidad tributaria"></i>
                    </td>
                  </tr>

                  <tr></tr>
                  <tr>
                    <td colspan="3">
                      <div class="row" *ngIf="tipodis.dispo.toLowerCase() === 'reúso token físico'">
                        <div class="col-lg-12">
                          <div class="checkbox-wrapper-19">
                            <input type="checkbox" class="form-check-input" [(ngModel)]="usuario.terminosReuso"
                              (change)="aceptaTerminosReuso($event)" id="cbtest-37">
                            <label for="cbtest-37" class="check-box"></label>
                            <label for="terminosReuso" class="form-check-label">He leído y acepto los <a class="descargas"
                                id="terminosReuso"
                                style="display: inline;"
                                href="../../../assets/Documentos/Terminos_Condiciones_Reuso_token_fisico_v1.pdf"
                                download="Terminos_Condiciones_Reuso_token_fisico_v1.pdf"><span class="politicas">
                                  Términos y
                                  condiciones</span></a> para reúso de token físico
                            </label>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- implementacion vista de seleccionar reuso token fisico 4-->
        <div class="carousel-item #enviar">
          <div class="margenuni animate__animated animate__fadeIn animate__fast">
            <h2 class="textomodal lh-1 text-center" style="color: #999696">
              ¿Deseas reusar el token físico?
            </h2>
            <p class="lh-1">
              <small>Si aún conservas el dispositivo Token de tu última compra, te ofrecemos el servicio de
                reutilización del token físico. De esta manera, podrás continuar utilizando el mismo dispositivo, lo que
                agiliza los tiempos de entrega, reducirá el valor del certificado y lo más importante, contribuye al
                cuidado del medio ambiente.
              </small>
            </p>
            <div class="centrado">
              <table>
                <thead style="display: none;"><tr><th></th></tr></thead>
                <tr>
                  <td>
                    <div class="checkbox-wrapper-19">
                      <input type="radio" class="form-check-input" value="3" name="checkReuso"
                        (change)="mecanisEntregaChange($event, 2)" id="cbtest-31" />
                      <label for="cbtest-31" class="check-box"></label>
                      <label class="form-check-label-normal" for="exampleCheck2">&nbsp;&nbsp;Reusar token&nbsp;<span><i
                            class="fas fa-info-circle icon-primary"></i>&nbsp;&nbsp;<span
                            style="text-decoration: underline; font-weight: 600; cursor: pointer;"
                            (click)="habilitarVideoReuso(4)">Ver
                            más</span></span></label>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox-wrapper-19">
                      <input type="radio" class="form-check-input" value="0" name="checkReuso"
                        (change)="mecanisEntregaChange($event, 0)" id="cbtest-32" />
                      <label for="cbtest-32" class="check-box"></label>
                      <label class="form-check-label-normal" for="exampleCheck2">&nbsp;&nbsp;Nuevo token</label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="text-align: center;"><label class="form-label">Vigencia</label>
                    &nbsp;&nbsp;<select [(ngModel)]="anosReusoSeleccion" class="form-select"
                      style="width: 25%; display: inline-block;" #costRvalue (change)="ChagePrice(costRvalue.value)">
                      <option class="form-control" value="" disabled selected>Seleccionar</option>
                      <option class="form-control" *ngFor="let vige of vigencias" [value]="vige.anos">
                        {{ vige.texto }}
                      </option>
                    </select></td>
                </tr>
              </table>
            </div>
            <div class="row">
              <br />
              <div class="col">
                <h4 class="textomodal lh-1" style="color: #999696">
                  <p data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Tener en cuenta que este valor corresponde al subtotal más IVA del bien o servicio adquirido. Se aplicarán descuentos tributarios de acuerdo con la calidad tributaria">
                    Valor subtotal más IVA
                    &nbsp; <strong style="color: #ef9308">${{ resultado | currency: 'COP': '' : '1.0-0' }}</strong>
                  </p>
                </h4>
              </div>
            </div>
            <div class="row" *ngIf="tipodis.dispo.toLowerCase() === 'reúso token físico'">
              <div class="col-lg-12">
                <div class="checkbox-wrapper-19">
                  <input type="checkbox" class="form-check-input" [(ngModel)]="usuario.terminosReuso"
                    (change)="aceptaTerminosReuso($event)" id="cbtest-33">
                  <label for="cbtest-33" class="check-box"></label>
                  <label for="terminosReuso" class="form-check-label">He leído y acepto los <a class="descargas" style="display: inline;"
                      id="terminosReuso"
                      href="../../../assets/Documentos/Terminos_Condiciones_Reuso_token_fisico_v1.pdf"
                      download="Terminos_Condiciones_Reuso_token_fisico_v1.pdf"><span class="politicas"> Términos y
                        condiciones</span></a> para reúso de token físico
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- implementacion vista de video reuso 5-->
        <div class="carousel-item #enviar">
          <div class="margenuni animate__animated animate__fadeIn animate__fast">
            <h4 class="textomodal lh-1 text-center" style="color: #999696">
              Reúso token físico
            </h4>
            <div class="row">
              <iframe width="420" height="315" [src]="urlVideoReuso">
              </iframe>
            </div>
            <div class="row" style="margin: 10px 30%;">
              <button class="btn btn-secondary" (click)="cancelarVideoReuso()">
                Volver
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--cierre de modal body-->
      <div class="modal-footer" *ngIf="mostrarBotones === true">
        <button type="submit" class="btn btn-secondary" data-bs-target="#carouselExampleControlsNoTouching"
          (click)="anteriorFlujo()" [disabled]="anterior">
          Anterior
        </button>
        <button type="submit" class="btn btn-primary" data-bs-target="#carouselExampleControlsNoTouching"
          (click)="estados();verifica()" [disabled]="desactiva">
          Siguiente
        </button>
      </div>
      <!--(click)="estados()" -->
    </div>
  </div>

  <!--<div class="modal-footer">
  
</div>-->
</div>