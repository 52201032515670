<div class="animate__animated animate__fadeIn animate__fast">
       <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()">
              <div class="margen2">
                     <div class="mb-3">
                            <label for="tipoIdentificacion" class="form-label">Tipo de identificación *</label>
                            <select class="form-select" formControlName="tipo"
                                   id="tipoIdentificacion"
                                   [class.is-invalid]="camposvalidos('tipo')">
                                   <option class="form-control" value="NI">NIT</option>

                            </select>
                            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                   *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de documento</div>
                     </div>
                     <div class="mb-3">
                            <div class="row">
                                   <div class="col">
                                          <label for="numeroIdentificacion" class="form-label">Número de identificación *</label>
                                          <input type="number" id="numeroIdentificacion" class="form-control"
                                                 placeholder="número de identificación" formControlName="numero"
                                                 [class.is-invalid]="camposvalidos('numero')" #datos
                                                 [(ngModel)]="quitar" value="{datos.toString()}"
                                                 (keyup)="noPuntoComa($event)" (keyup)="quitarceros(datos.value)">

                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('numero')">Número no válido</div>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="comas">El campo no puede tener comas</div>
                                   </div>

                                   <div class="col">
                                          <label class="form-label">Número de verificación</label>
                                          <input type="text" class="form-control" placeholder="Número de verificación"
                                                 formControlName="codigo" [class.is-invalid]="camposvalidos('codigo')"
                                                 appDigito maxLength="1" [(ngModel)]="digito">
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('codigo')">Número de verificación no válido</div>
                                   </div>

                            </div>
                     </div>

                     <div class="col">
                            <label class="form-label">Razón social *</label>
                            <input type="text" class="form-control" placeholder="Razón social" formControlName="razon"
                                   [class.is-invalid]="camposvalidos('razon')" #inputRefRazonSocial>
                            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                   *ngIf="camposvalidos('razon')">Razón social no puede ser vacio, ni puede tener más de
                                   60 caracteres</div>
                     </div>
              </div>

              <app-politicas [formaForm]="formaForm"></app-politicas>
       
              <div class="margen2" style="padding-bottom:0px ;">
                     <div class="mb-3" style="line-height: 10px; margin-bottom: 5px !important;">
                            <label for="label" class="form-label">* Campos obligatorios</label>
                     </div>
              </div>
              <div class="modal-footer">
                     <button type="button" class="btn btn-secondary" (click)="close()">Cancelar</button>
                     <button type="submit" class="btn btn-primary">Siguiente</button>
              </div>
       </form>
</div>


<!--politicas-->