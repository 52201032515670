<div [formGroup]="formaForm">
    <div class="mb-3">
      <div class="row">
        <div class="col">
          <label class="form-label">Primer nombre *</label>
          <input type="text" class="form-control" placeholder="Primer nombre"
                 formControlName="primerNombre"
                 [class.is-invalid]="camposvalidos('primerNombre')"
                 #inputRefPrimerNombre>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
               *ngIf="camposvalidos('primerNombre')">
            Primer nombre no es válido
          </div>
        </div>
        <div class="col">
          <label class="form-label">Segundo nombre</label>
          <input type="text" class="form-control" placeholder="Segundo nombre"
                 formControlName="segundoNombre"
                 [class.is-invalid]="camposvalidos('segundoNombre')"
                 #inputRefSegundoNombre>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
               *ngIf="camposvalidos('segundoNombre')">
            Segundo nombre no es válido
          </div>
        </div>
      </div>
    </div>
  
    <div class="mb-3">
      <div class="row">
        <div class="col">
          <label class="form-label">Primer apellido *</label>
          <input type="text" class="form-control" placeholder="Primer apellido"
                 formControlName="primerApellido"
                 [class.is-invalid]="camposvalidos('primerApellido')"
                 #inputRefPrimerApellido>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
               *ngIf="camposvalidos('primerApellido')">
            Primer apellido no es válido
          </div>
        </div>
        <div class="col">
          <label class="form-label">Segundo apellido</label>
          <input type="text" class="form-control" placeholder="Segundo apellido"
                 formControlName="segundoApellido"
                 [class.is-invalid]="camposvalidos('segundoApellido')"
                 #inputRefSegundoApellido>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
               *ngIf="camposvalidos('segundoApellido')">
            Segundo apellido no es válido
          </div>
        </div>
      </div>
    </div>
  </div>