<div class="animate__animated animate__fadeIn animate__fast">
       <div class="modal-header">
              <h4>DESCARGA DE CERTIFICADO DIGITAL</h4>
              <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
       </div>
       <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()" id="form1">
              <div class="margen2">
                     <div class="row mb-4" style="text-align: justify;">
                            <p>Tenga en cuenta que el archivo a descargar
                                   corresponde al certificado digital con el cual podrá realizar la firma de sus
                                   documentos.</p>
                     </div>
                     <div formArrayName="documentos">
                            <div *ngFor="let favorite of documentsDocuments.controls; let i = index">
                                   <div class="form-check">
                                          <input class="form-check-input" type="checkbox" id="{{buttons[i].id}}"
                                                 value="true" [checked]="true" [disabled]="true"
                                                 style="opacity: 1 !important; font-weight: 600; font-size: 13pt;">
                                          <label class="form-check-label"
                                                 style="opacity: 1 !important; font-weight: 600; font-size: 13pt;">
                                                  {{buttons[i].name}}
                                          </label>
                                   </div>
                                   <div class="animate__animated animate__fadeIn animate__fast ms-5">
                                          <div class="d-flex justify-content-center">
                                                 <div id="div_file" class="text-center">
                                                        <div [class]="validar()" *ngIf="cambiologo">
                                                               <span class="fa-stack fa-1x">
                                                                      <i class="fa-solid fa-circle fa-stack-2x"
                                                                             [ngStyle]="{'color':buttons[i].fondo }"></i>
                                                                      <i class="fa-solid fa-upload fa-stack-1x fa-inverse"
                                                                             [ngStyle]="{'color':buttons[i].colores }"></i>
                                                               </span>

                                                               <p id="texto">{{buttons[i].title}}
                                                                      <br>
                                                                      <small>{{buttons[i].subtitle}}</small>
                                                               </p>
                                                        </div>
                                                        <input type="file" class="enviar" #enviar [formControlName]="i"
                                                               (change)="getFileDetails(enviar.files,i)"
                                                               accept="{{buttons[i].accept}}">
                                                 </div>
                                          </div>
                                   </div>
                                   <div style="text-align: center; font-weight: 400;"
                                          class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                          *ngIf="validateformats[i] || isValidFieldInArray(documentsDocuments,i)">
                                          {{error[i]}}</div>
                            </div>
                     </div>
                     <div class="row mb-4">
                            <div class="col">
                                   <label class="form-label">Contraseña</label>
                                   <input type="password" class="form-control" placeholder="Contraseña"
                                          formControlName="contrasena" appNocopiado
                                          [class.is-invalid]="camposvalidos('contrasena')">
                                   <div class="text-red-400 text-sm">
                                          <span class="block"
                                                 [ngClass]="{'text-success': passwordFormField?.value?.match('^(?=.*[A-Z])'), 'text-danger':!passwordFormField?.value?.match('^(?=.*[A-Z])')}">
                                                 Mínimo una mayuscula.</span><br>
                                          <span class="block"
                                                 [ngClass]="{'text-success': passwordFormField?.value?.match('(?=.*[a-z])'), 'text-danger':!passwordFormField?.value?.match('(?=.*[a-z])')}">
                                                 Mínimo una minuscula.</span><br>
                                          <span class="block"
                                                 [ngClass]="{'text-success': passwordFormField?.value?.match('(.*[0-9].*)'), 'text-danger':!passwordFormField?.value?.match('(.*[0-9].*)')}">
                                                 Incluya mínimo un número.</span><br>
                                          <span class="block"
                                                 [ngClass]="{'text-success': passwordFormField?.value?.match('(?=.*[@$!%*?&_-])'), 'text-danger':!passwordFormField?.value?.match('(?=.*[$@$!%*?&_-])')}">
                                                 Un carácter como $@$!%*?&_-.</span><br>
                                          <span class="block"
                                                 [ngClass]="{'text-success': passwordMinMaxLengthValid, 'text-danger':!passwordMinMaxLengthValid}">
                                                 De 8 a 12 caracteres.</span>
                                   </div>

                            </div>
                            <div class="col">
                                   <label class="form-label">Confirmar contraseña</label>
                                   <input type="password" class="form-control" placeholder="Confirmar contraseña"
                                          formControlName="contrasenaConfirmacion"
                                          [class.is-invalid]="camposvalidos('contrasenaConfirmacion')" appNocopiado>
                                   <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                          *ngIf="!contrasenaIguales">
                                          La confirmación de la contraseña no coincide</div>
                            </div>
                     </div>
              </div>
              <div class="modal-footer">
                     <button type="submit" class="btn btn-primary"
                            [disabled]="formaForm.invalid || !contrasenaIguales || totalCantidad !== cantidad">Descargar
                            PFX</button>
              </div>
       </form>
</div>