import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LogService } from '../../../../domain/servicios/log.service'
import { Router } from '@angular/router';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { datosPasarela } from 'src/assets/config/app.conf';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';

@Component({
  selector: 'app-pse',
  templateUrl: './pse.component.html',
  styleUrls: ['./pse.component.css']
})
export class PSEComponent implements OnInit {
  formaForm!: FormGroup;
  noradicado: string = '';
  mostrarModal: boolean = false;
  pago: any;
  mensajeEstado = ''
  usuario: any
  facturacion: any
  constructor(private fb: FormBuilder,
    private router: Router,
    private componentesService: ComponentesService,
    private ssps: SspsService, private logger: LogService,
    private alert: AlertsService,
    private el: ElementRef
  ) {

    this.formaForm = this.fb.group({

      referencia: ['', [
        Validators.max(9999999)]],
      valorTotal: [],
      valorNeto: [],
      impuestos: [],
      basedevolucion: [],
      nombreComprador: [],
      correoComprador: [],
    });
  }



  ngOnInit(): void {
    //Colocar validacion de que si ya tiene  estadoCompra en cache, vaya y valide la etapa(Iniciado)
    //* Debo ir a validar el estado con los servicios de P2P(Consumir lambda), si la respuesta no es aprobado, mostrar boton de reintento
    //* Si la etapa no es ABIERTO y estado != aprobado, Mostrar boton de reintentar pago, ya teniendo la url(Solo se actualiza el objeto a etapa iniciado)
    //Luego de validar el estado aprobado, se debe retornar a la pagina de pago donde se valide el estado de compra y habilite
    //boton de continuar a aprobación solicitud
    this.usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
    this.facturacion = JSON.parse(this.ssps.getLocalStorage('facturacion')!);
    this.pago = JSON.parse(this.ssps.getLocalStorage('pago')!);
    this.ssps.obtenerIpValor()
  }


  /* funcion para los errores indicados*/
  camposvalidos(campo: any) {
    return this.formaForm.controls[campo].errors && this.formaForm.controls[campo].touched;
  }


  /*funcion para el envio de datos*/
  ngsubmit() {
    this.alert.showSpiner("Estimado usuario, recuerda que para completar tu proceso, se realizará proceso de validación de identidad");
    //Ir y consumir servicio para armar url, enviando ordenId(Referencia), url de retorno, login p2p. valor total, impuestos, base  devolucion
    //Guardar los datos de respuesta para futura consulta de estado
    //Consumir lambda de guarda PagosenlineaPSE
    //Redireccionar a url
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      this.alert.closesSpiner()
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          return;
        }
      });
      return;
    }

    if (this.pago.cuponAplicado !== undefined && this.pago.cuponAplicado !== null &&
      this.pago.cuponAplicado !== "") {
      let body = {
        ordenPedidoId: this.pago.ordenPedidoId,
        cupon: this.pago.cuponAplicado
      }
      this.ssps.validarCodigoDescuento(body)
        .subscribe({
          next: (res: any) => {
            this.logger.log("respuesta PSE pagoVirtual", res)
            if (res !== null && +res.codigo === 200) {
              this.crearUrlPago()
            }
            else {
              this.pago.cuponAplicado = ""
              this.pago.esDescuentoCupon = false
              this.pago.cuponAplicadoExitosamente = false
              this.recalcularValores(res.valoresOrdenDescuento)
              this.alert.closesSpiner()
              this.alert.showError(res.mensaje, false, null, false, null, null);
            }
          },
          error: (error: any) => {
            this.pago.cuponAplicado = ""
            this.alert.closesSpiner()
            console.error('Error en subscriber PSE validarCodigoDescuento', error)
            let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
              error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                error.error.message ? error.error.message :
                  (error.message ? error.message : "No fue posible realizar la petición")) :
              (error.message ? error.message : "No fue posible realizar la petición")
            this.alert.showError(mensaje, false, null, false, null, null)
          }
        });
    }
    else {
      this.crearUrlPago()
    }
  }

  crearUrlPago() {
    let datosPse = datosPasarela
    datosPse.ipUsuario = this.ssps.ip
    datosPse.navegador = this.ssps.getBrowserName()
    datosPse.urlRetorno = `${location.origin}?id=${JSON.parse(this.ssps.getLocalStorage("solicitudId")!)}&redirect=pago`
    let body = {
      datosCompra: {
        ordenPedidoId: this.pago.ordenPedidoId,
        valorCompra: this.pago.valorTotal,
        impuestos: this.pago.impuestos ?? 0,
        valorBase: this.pago.costoBase,
        compradorNombre: this.facturacion.nombres,
        compradorApellido: this.facturacion.apellidos === "" ? this.facturacion.nombres : this.facturacion.apellidos,
        compradorEmail: this.facturacion.correo
      },
      datosPasarela: datosPse
    }
    this.ssps.consultarUrlPSE(body)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta consultarUrlPSE", res)
          if (res !== null) {
            this.pago.url = res.urlRedireccion
            this.pago.estado = res.estado
            this.pago.mensaje = res.mensaje
            this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
            this.alert.closesSpiner()
            //Redirigir a la url
            //localStorage.clear()
            window.location.replace(res.urlRedireccion);
          }
          else {
            this.alert.closesSpiner()
            //Mostrar alerta error
            console.error('Error en servicio consultarUrlPSE', res)
            this.alert.showError("No fue posible realizar la petición", false, null, false, null, null)
          }
        },
        error: (error: any) => {
          //Mostrar alerta error
          this.alert.closesSpiner()
          console.error('Error en subscriber consultarUrlPSE', error)
          let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
            error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
              error.error.message ? error.error.message :
                (error.message ? error.message : "No fue posible realizar la petición")) :
            (error.message ? error.message : "No fue posible realizar la petición")
          this.alert.showError(mensaje, false, null, false, null, null)
        }
      });
  }

  consultarPago() {
    this.alert.showSpiner("Estimado usuario, recuerda que para completar tu proceso, se realizará proceso de validación de identidad");
    this.logger.log("servicioConsultarPago PSE", "Llamar servicio")
    let datosPse = datosPasarela
    datosPse.ipUsuario = this.ssps.ip
    datosPse.urlRetorno = `${location.origin}?id=${JSON.parse(this.ssps.getLocalStorage("solicitudId")!)}&redirect=pago`
    datosPse.navegador = this.ssps.getBrowserName()
    //Si el estado es aprobado, mostrar alerta de transacción exitosa y ya terminar proceso
    let body = {
      ordenPedidoId: this.pago.ordenPedidoId,
      cupon: this.pago.cuponAplicado ?? '',
      datosPasarela: datosPse
    }
    this.ssps.consultarEstadoPagoPSE(body)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta consultarEstadoPagoPSE", res)
          if (res !== null) {
            this.alert.closesSpiner()
            this.pago.estado = res.estado
            this.pago.mensaje = res.mensaje
            this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
            this.alert.closesSpiner()
            if (this.pago.estado === "APROBADO") {
              this.logger.log("Estado Pago", "PROBADO")
              //Mostrar alerta de aprobado
              //Termina flujo borrando cache
              this.alert.showFinalizado("pagado")
            }
            else {
              //Redirige a PSE y allá muestra el estado de la transacción
              console.error('Error en servicio consultarEstadoPagoPSE', res)
              this.alert.showError(this.pago.mensaje, false, null, false, null, null)
            }
          }
          else {
            this.alert.closesSpiner()
            //Mostrar alerta error
            console.error('Error en servicio consultarEstadoPagoPSE', res)
            this.alert.showError("No fue posible realizar la petición", false, null, false, null, null)
            this.pago.estado = "PENDIENTE"
          }
        },
        error: (error: any) => {
          //Mostrar alerta error
          this.alert.closesSpiner()
          console.error('Error en subscriber consultarEstadoPagoPSE', error)
          let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
            error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
              error.error.message ? error.error.message :
                (error.message ? error.message : "No fue posible realizar la petición")) :
            (error.message ? error.message : "No fue posible realizar la petición")
          
          this.alert.showError(mensaje, false, null, false, null, null)
          this.pago.estado = "PENDIENTE"
        }
      });
  }

  /*rediociona al usuario si se equivova*/
  redireciona() {
    this.router.navigate(['/flujo/pago']);
  }

  cerrar() {
    this.mostrarModal = false;
    //Ver si hay que redirigir
  }

  recalcularValores(valoresOrdenDescuento: any) {
    this.pago.costoBase = valoresOrdenDescuento.valorBaseLiquidacion
    this.pago.icaValor = valoresOrdenDescuento.valorIca
    this.pago.ivaValor = valoresOrdenDescuento.valorIva
    this.pago.reteIvaValor = valoresOrdenDescuento.valorReteIva
    this.usuario.costoBase = valoresOrdenDescuento.valorBaseLiquidacion
    this.pago.descuentoCupon = valoresOrdenDescuento.valorDescuentoCupon
    this.usuario.costo = valoresOrdenDescuento.valorNeto
    this.usuario.descuentoCupon = valoresOrdenDescuento.valorDescuentoCupon
    this.pago.valorTotal = valoresOrdenDescuento.valorNeto
    this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
    this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
  }

}


