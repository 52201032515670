<div class="animate__animated animate__fadeIn animate__fast" *ngIf="otpValidado">
       <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()" id="form1">
              <div class="margen2">
                     <div class="modal-header" style="padding-left: 0px !important;">
                            <label for="label" class="form-label" style="text-align: left;"><b>Número de solicitud:
                                          {{usuarioDescarga.solicitudId}}</b></label>
                            <!-- <div style="padding: 5px; background-color: silver;">
                                   <i class="fas fa-info-circle"></i>&nbsp;DESCARGA DEL CERTIFICADO DE FIRMA DIGITAL
                            </div> -->
                            <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
                     </div>
                     <div class="row mb-4">
                            <div class="col">
                                   <label for="label" class="form-label">Tipo de identificación</label>
                            </div>
                            <div class="col">
                                   <label for="label">{{usuarioDescarga.tipo}}</label>
                            </div>
                     </div>

                     <div class="row mb-4">
                            <div class="col">
                                   <label class="form-label">Número de identificación</label>
                            </div>
                            <div class="col">
                                   <label>{{usuarioDescarga.numero}}</label>
                            </div>
                     </div>
                     <div class="row mb-4">
                            <div class="col">
                                   <label class="form-label">Nombre</label>
                            </div>
                            <div class="col">
                                   <label>{{usuarioDescarga.nombreCompleto}}</label>
                            </div>
                     </div>
                     <br>
                     <div class="row mb-4"
                            *ngIf="(certificado.estado === '' || certificado.estado === undefined || certificado.estado === null) && usuarioDescarga.mecanismo !== 'PKCS#10'">
                            <p><strong>CONTRASEÑA DE ACCESO A LOS CERTIFICADOS DIGITALES EMITIDOS EN TOKEN
                                          VIRTUAL</strong></p>
                            <p>Apreciado suscriptor, si ya has asignado tu contraseña anteriormente, esta te permitirá
                                   acceder a los certificados de firma digital vigentes, almacenados en Token virtual.
                            </p>
                     </div>
                     <div class="row mb-4" style="text-align: justify;"
                            *ngIf="usuarioDescarga.mecanismo == 'PKCS#10' && usuarioDescarga.existeCSR">
                            <p>Ten en cuenta que el archivo a descargar
                                   corresponde a la llave pública del certificado emitido con extensión
                                   .cer, una vez descargado el certificado deberás utilizarlo en la
                                   aplicación/máquina donde fue generado el request para completar la
                                   instalación del certificado digital.</p>
                            <p>
                                   La llave privada asociada a tu certificado fue generada y enviada al correo
                                   electrónico registrado en la solicitud.</p>
                     </div>
                     <div class="row mb-4" style="text-align: justify;"
                            *ngIf="usuarioDescarga.mecanismo == 'PKCS#10' && !usuarioDescarga.existeCSR">
                            <p>Ten en cuenta que el archivo a generar
                                   corresponde a la llave privada del certificado emitido con extensión
                                   .key y será enviado al correo electrónico registrado en la solicitud, una vez
                                   realizado el proceso exitosamente, deberás generar y descargar el
                                   archivo .CER que corresponde a la llave pública del certificado</p>
                     </div>
                     <div class="row mb-4" *ngIf="certificado.estado == 'CONTRASENA'">
                            <p><strong>CONTRASEÑA DE ACCESO A LOS CERTIFICADOS DIGITALES EMITIDOS EN TOKEN
                                          VIRTUAL</strong></p>
                            <p>Apreciado suscriptor, la contraseña que ingreses a continuación te permitirá acceder a los
                                   certificados de firma digital vigentes, almacenados en Token virtual.</p>
                            <span *ngIf="false"><strong>Importante:</strong> La contraseña debe contener:</span>
                            <ul style="padding-left:30px" *ngIf="false">
                                   <li style="list-style-type:disc;">
                                          Mínimo ocho (8) caracteres
                                   </li>
                                   <li style="list-style-type:disc;">
                                          Máximo doce (12) caracteres
                                   </li>
                                   <li style="list-style-type:disc;">
                                          Mezclar mayúsculas, minúsculas y números. NO EMPLEE CARACTERES
                                          ESPECIALES
                                   </li>
                            </ul>
                            <span> * Asegúrate de recordar tu contraseña solo así podrá firmar tus documentos y/o
                                   transacciones. </span>
                     </div>
                     <br>
                     <div class="row mb-4" *ngIf="certificado.estado == 'CONTRASENA'">
                            <div class="col">
                                   <label class="form-label">Contraseña</label>
                                   <input type="password" class="form-control" placeholder="Contraseña"
                                          formControlName="contrasena" appNocopiado
                                          [class.is-invalid]="camposvalidos('contrasena')">
                                   <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                          *ngIf="camposvalidos('contrasena')">Contraseña no cumple criterio de
                                          aceptación</div>
                                   <div class="text-red-400 text-sm">
                                          <span class="block"
                                                 [ngClass]="{'text-success': passwordFormField?.value?.match('^(?=.*[A-Z])'), 'text-danger':!passwordFormField?.value?.match('^(?=.*[A-Z])')}">
                                                 Mínimo una mayuscula.</span><br>
                                          <span class="block"
                                                 [ngClass]="{'text-success': passwordFormField?.value?.match('(?=.*[a-z])'), 'text-danger':!passwordFormField?.value?.match('(?=.*[a-z])')}">
                                                 Mínimo una minuscula.</span><br>
                                          <span class="block"
                                                 [ngClass]="{'text-success': passwordFormField?.value?.match('(.*[0-9].*)'), 'text-danger':!passwordFormField?.value?.match('(.*[0-9].*)')}">
                                                 Incluye mínimo un número.</span><br>
                                          <span class="block"
                                                 [ngClass]="{'text-success': !passwordFormField?.value?.match('(?=.*[@$!%*?&_-])') && passwordFormField?.value?.length > 0, 'text-danger':passwordFormField?.value?.match('(?=.*[$@$!%*?&_-])') || passwordFormField?.value?.length === 0}">
                                                 No usar caracteres especiales</span><br>
                                          <span class="block"
                                                 [ngClass]="{'text-success': passwordMinMaxLengthValid, 'text-danger':!passwordMinMaxLengthValid}">
                                                 De 8 a 12 caracteres.</span>
                                   </div>

                            </div>
                            <div class="col">
                                   <label class="form-label">Confirmar contraseña</label>
                                   <input type="password" class="form-control" placeholder="Confirmar contraseña"
                                          formControlName="contrasenaConfirmacion"
                                          [class.is-invalid]="camposvalidos('contrasenaConfirmacion')" appNocopiado>
                                   <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                          *ngIf="!contrasenaIguales">
                                          La confirmación de la contraseña no coincide</div>
                            </div>
                     </div>
              </div>
              <div class="modal-footer" *ngIf="usuarioDescarga.existeCSR">
                     <button type="submit" class="btn btn-primary"
                            [disabled]="formaForm.invalid || !contrasenaIguales">{{usuarioDescarga.mecanismo ===
                            'PKCS#10' ? 'Descargar .cer'
                            : 'Obtener certificado'}}</button>
              </div>
       </form>
       <div class="modal-footer" *ngIf="!usuarioDescarga.existeCSR">
              <div class="row mb-4" *ngIf="!usoGuardado">
                     <p style="text-align: center;"><strong>ASIGNACIÓN USO DEL CERTIFICADO</strong></p>
                     <table>
                            <thead style="visibility: hidden; position: absolute; width: 0; height: 0;">
                              <tr>
                                <th scope="col" >Uso</th>
                                <th scope="col">Seleccione Uso</th>
                                <th scope="col">Otro Uso</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><label class="form-label">Uso</label></td>
                                <td>
                                  <select class="form-select" (change)="cambioUso($event.target)"
                                          [(ngModel)]="usuarioDescarga.usoCertificado">
                                    <option class="form-control" *ngFor="let uso of usosCertificados"
                                            [value]="uso.id">
                                      {{ uso.descripcion }}
                                    </option>
                                  </select>
                                </td>
                                <td *ngIf="usuarioDescarga.uso.toLowerCase() === 'otros'">
                                  <input type="text" class="form-control" [(ngModel)]="usuarioDescarga.otroUso"
                                         placeholder="Especifique otro uso" maxlength="100"
                                         style="text-transform: uppercase">
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <div class="modal-footer">
                                    <button class="btn btn-primary"
                                            data-bs-target="#carouselExampleControlsNoTouching"
                                            data-bs-slide="next" (click)="guardarUsoCertificado()"
                                            [disabled]="(usuarioDescarga.uso.toLowerCase() === 'otros' && usuarioDescarga.otroUso === '') || usuarioDescarga.usoCertificado < 0">
                                      Guardar
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                     </table>                          
              </div>
              <button (click)="generarLlave()" class="btn btn-primary" *ngIf="usoGuardado">Generar llave
                     privada</button>
       </div>
</div>
<div class="animate__animated animate__fadeIn animate__fast" *ngIf="!otpValidado">
       <div class="modal-header">
              <h4>Estimado usuario</h4>
              <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
       </div>
       <div class="margen2">
              <div class="row mb-4" style="text-align: justify;">
                     <p>
                            Para poder continuar con el proceso de descarga de tu certificado digital, se debe realizar
                            la
                            verificación de titularidad mediante la confirmación de un código OTP que será enviado al
                            correo
                            electrónico <b>{{correoTitularOfuscado}}</b> que aparece registrado en la solicitud.</p>
              </div>
              <div class="content" *ngIf="otpEnviado">
                     <div class="o-c" style="text-align: center;">
                            <h4>ingresar CÓDIGO</h4>
                            <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
                     </div>
                     <br>
                     <div style="text-align: center;" *ngIf="!reenviarOtp">Reenviar código OTP en <span id="timer"
                                   style="font-weight: 700;">{{ display }}</span> segundos</div>
              </div>
              <div class="modal-footer" *ngIf="!otpEnviado">
                     <button (click)="enviarOTP()" class="btn btn-primary">Enviar código</button>
              </div>
              <div class="modal-footer" *ngIf="reenviarOtp">
                     <button (click)="enviarOTP()" class="btn btn-primary">Reenviar código</button>
              </div>
       </div>
</div>