import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { IdentificacionComponent } from './identificacion/identificacion.component';
import { NombreApellidoComponent } from './nombre-apellido/nombre-apellido.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PoliticasComponent } from './politicas/politicas.component';




@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    IdentificacionComponent,
    NombreApellidoComponent,
    PoliticasComponent
  ],exports:[
    NavbarComponent,
    FooterComponent,
    IdentificacionComponent,
    NombreApellidoComponent,
    PoliticasComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    ReactiveFormsModule
  ]
})
export class SharedModule { }
