<div class="animate__animated animate__fadeIn animate__fast">
       <div class="modal-header">
              <h4 style="text-align: center;" *ngIf="listadoFacturas.length === 0">DESCARGUE EN LINEA SU FACTURA DE
                     COMPRA</h4>
              <h4 style="text-align: center;" *ngIf="listadoFacturas.length > 0 && !consulta">LISTADO DE FACTURAS
                     ELECTRÓNICAS</h4>
              <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
       </div>
       <div class="margen2" style="max-height: 90vh;">
              <div class="mb-3" *ngIf="listadoFacturas.length === 0">
                     <span>Podrá realizar la consulta de su factura de compra mediante alguo de los siguientes filtros:
                            Número de orden pedido, número de identificación ó número de factura, usted podrá efectuar
                            la descarga de su factura de compra en formato PDF.</span>
                     <br>
                     <span><b>Nota: </b>Se podrán visualizar las ultimas 5 facturas de compras realizadas de certificados digitales.</span>
                     <br>
                     <br>
                     <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()" id="form1">
                            <div class="mb-3">
                                   <div class="row">
                                          <div class="col-3">
                                                 <label class="form-label">Filtro</label>
                                                 <select (change)="changeFiltro($event)"
                                                        [(ngModel)]="usuarioFactura.filtro" class="form-select"
                                                        formControlName="filtro"
                                                        [class.is-invalid]="camposvalidos('filtro')">
                                                        <option class="form-control" value="DOCUMENTO">Número de
                                                               identificación
                                                        </option>
                                                        <option class="form-control" value="ORDEN">Número de orden
                                                               pedido
                                                        </option>
                                                        <option class="form-control" value="FACTURA">Número de factura
                                                        </option>
                                                 </select>
                                                 <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                        *ngIf="camposvalidos('filtro')">Debe seleccionar un filtro
                                                 </div>
                                          </div>
                                          <div class="col-3" *ngIf="usuarioFactura.filtro === 'DOCUMENTO'">
                                                 <label class="form-label">Tipo de identificación</label>
                                                 <select (change)="changeTipoDocumento($event)"
                                                        [(ngModel)]="usuarioFactura.tipo" class="form-select"
                                                        formControlName="tipo"
                                                        [class.is-invalid]="camposvalidos('tipo')">
                                                        <option class="form-control" value="CC">Cédula de Ciudadanía
                                                        </option>
                                                        <option class="form-control" value="CE">Cédula de Extranjería
                                                        </option>
                                                        <option class="form-control" value="PP">Pasaporte</option>
                                                        <option class="form-control" value="NIT">Nit</option>
                                                 </select>
                                                 <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                        *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de
                                                        documento
                                                 </div>
                                          </div>
                                          <div class="col" *ngIf="usuarioFactura.filtro === 'DOCUMENTO'">
                                                 <label class="form-label">¡Identificación!</label>
                                                 <input [(ngModel)]="usuarioFactura.numero" type="text"
                                                        class="form-control" placeholder="Número de identificación"
                                                        formControlName="numero"
                                                        [class.is-invalid]="camposvalidos('numero')"
                                                        (keyup)="noPuntoComa($event)"
                                                        (keyup)="quitarceros(inputRefNumero.value)" #inputRefNumero>
                                                 <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                        *ngIf="camposvalidos('numero')">¡Identificación no válido!</div>
                                                 <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                        *ngIf="comas">El campo no puede tener comas</div>
                                          </div>
                                          <div class="col-3"
                                                 *ngIf="usuarioFactura.tipo === 'NIT' && usuarioFactura.filtro === 'DOCUMENTO'">
                                                 <label class="form-label">Verificación</label>
                                                 <input type="text" class="form-control"
                                                        placeholder="Número de verificación" formControlName="codigo"
                                                        [class.is-invalid]="camposvalidos('codigo')" appDigito
                                                        maxLength="1" [(ngModel)]="usuarioFactura.codigo">
                                                 <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                        *ngIf="camposvalidos('codigo')">Número de verificación no válido
                                                 </div>
                                          </div>
                                          <div class="col" *ngIf="usuarioFactura.filtro === 'ORDEN'">
                                                 <label class="form-label">¡Número de orden pedido!</label>
                                                 <input [(ngModel)]="usuarioFactura.ordenPedidoId" type="number"
                                                        class="form-control" placeholder="Número de orden pedido"
                                                        formControlName="ordenPedidoId"
                                                        [class.is-invalid]="camposvalidos('ordenPedidoId')">
                                                 <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                        *ngIf="camposvalidos('ordenPedidoId')">¡Número de orden pedido
                                                        no válido!</div>
                                          </div>
                                          <div class="col" *ngIf="usuarioFactura.filtro === 'FACTURA'">
                                                 <label class="form-label">¡Número de factura!</label>
                                                 <input [(ngModel)]="usuarioFactura.factura" type="text"
                                                        class="form-control" placeholder="Número de  factura"
                                                        formControlName="factura"
                                                        [class.is-invalid]="camposvalidos('factura')" #inputRefFactura>
                                                 <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                        *ngIf="camposvalidos('factura')">¡Número de factura no válido!
                                                 </div>
                                          </div>
                                   </div>
                            </div>
                            <div class="modal-footer">
                                   <button type="submit" class="btn btn-primary"
                                          [disabled]="formaForm.invalid">Consultar factura</button>
                            </div>
                     </form>
              </div>
              <div class="mb-3" *ngIf="listadoFacturas.length > 0">
                     <div class="mb-3" *ngIf="!consulta">
                            <span>A continuación podrá encontrar el listado de facturas que tiene disponibles, para
                                   visualizar la representación gráfica de una factura puede hacerlo dando click en
                                   el botón <b>VER FACTURA</b> de la cual desea visualizar.</span>
                            <br>
                            <br>
                            <table class="table table-striped table-bordered table-hover" style="margin: 0 auto;"
                                   *ngIf="!consulta">
                                   <thead>
                                          <tr>
                                                 <th scope="col" class="text-center listas">
                                                        Orden pedido
                                                 </th>
                                                 <th scope="col" class="text-center listas">
                                                        Factura
                                                 </th>
                                                 <th class="text-center listas"></th>
                                          </tr>
                                   </thead>
                                   <tbody>
                                          <!--   <tr *ngFor="let data of datos | slice: (page-1) * pageSize : page * pageSize">-->
                                          <tr class="table-light" *ngFor="let item of listadoFacturas">
                                                 <td class="text-center animate__animated animate__fadeIn">
                                                        <span>{{ item.ordenPedidoId }}</span>
                                                 </td>
                                                 <td class="text-center animate__animated animate__fadeIn">
                                                        <span>{{ item.numeroFactura }}</span>
                                                 </td>
                                                 <td class="text-center">
                                                        <span class="btn btn-primary" (click)="verFactura(item)"
                                                               style="border: none !important; opacity: 100 !important;">
                                                               VER FACTURA
                                                        </span>
                                                 </td>
                                          </tr>
                                   </tbody>
                            </table>
                            <div class="mb-4" style="text-align: center;">
                                   <button type="button" class="btn btn-secondary" style="margin: 15px;"
                                          (click)="cancelarSeleccion()">Regresar</button>
                            </div>
                     </div>
                     <div class="mb-3" *ngIf="consulta">
                            <div id="pdfContainer"></div>
                            <!-- <div style="overflow: auto;">
                                    <pdf-viewer [src]="documento" [rotation]="0" [original-size]="true" [show-all]="true"
                                          [fit-to-page]="true" [zoom]="1" [zoom-scale]="'page-width'" [stick-to-page]="true"
                                          [render-text]="false" [external-link-target]="'blank'" [autoresize]="false"
                                          [show-borders]="false" style="width: 100%; height: 70vh;"></pdf-viewer> 
                                  
                            </div>-->
                            <div class="mb-4" style="text-align: center;">
                                   <button type="button" class="btn btn-secondary" style="margin: 15px;"
                                          (click)="cancelarSeleccionDocumento()">Cerrar</button>
                            </div>
                     </div>
              </div>
       </div>
</div>