import { Component, OnInit } from '@angular/core';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { LogService } from 'src/app/domain/servicios/log.service'
import { SspsService } from 'src/app/domain/servicios/ssps.service';

@Component({
  selector: 'app-barra',
  templateUrl: './barra.component.html',
  styleUrls: ['./barra.component.css']
})
export class BarraComponent implements OnInit {
  compras: any = {};
  pago: any = {};
  constructor(private componente: ComponentesService, private logger: LogService,
    private ssps: SspsService
  ) {
  }

  ngOnInit(): void {
    if(this.ssps.getLocalStorage('usuario')){
      this.compras = JSON.parse(this.ssps.getLocalStorage('usuario')!);
    }  
    if(this.ssps.getLocalStorage('pago')){
      this.pago = JSON.parse(this.ssps.getLocalStorage('pago')!);
    }    
  }

  documentos() {
    return this.compras.proceso >= 0 ? 'iconos2' : 'bordes';
  }

  pagos() {
    return this.compras.proceso >= 1 ? 'iconos2' : 'bordes';
  }
}
