<div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
  <form [formGroup]="declineForm" (ngSubmit)="ngsubmit()">
    <div class="modal-body  ms-4 me-4">
      <div class="contiene">
  
        <h4 class="titulos">Formulario de rechazo </h4>
        <p class="texto">
            Has seleccionado Rechazar la prefactura del periodo [periodo]. 
            Para poder realizar los ajustes necesarios y generar una nueva prefactura, por favor completa la siguiente información:
        </p>
        <div class="margencon">
          <div class="margen"></div>
          <div class="row">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="mb-3">
                    <label class="form-label">Tu nombre completo:</label>
                    <input type="text" class="form-control" [(ngModel)]="usuario.nombre" placeholder="Nombre completo"
                      formControlName="nombre" [class.is-invalid]="camposvalidos('usuario.nombre')">
                    <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                      *ngIf="camposvalidos('usuario.nombre')">Nombres no son válidos, ingrese solo letras
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <label class="form-label">Tipo de identificación</label>
                    <select [(ngModel)]="usuario.tipo" class="form-select" formControlName="tipo"
                      [class.is-invalid]="camposvalidos('tipo')">
                      <option class="form-control" value="CC">Cédula de Ciudadanía</option>
                      <option class="form-control" value="CE">Cédula de Extranjería</option>
                      <option class="form-control" value="PP">Pasaporte</option>
                    </select>
                    <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                      *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de documento
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <label class="form-label">Número de identificación</label>
                    <input [(ngModel)]="usuario.numero" type="text" class="form-control"
                      placeholder="Número de identificación" formControlName="numero"
                      [class.is-invalid]="camposvalidos('numero')" #inputRefNumero>
                    <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                      *ngIf="camposvalidos('numero')">¡Identificación no válida!
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="mb-3">
                    <label class="form-label">Cargo:</label>
                    <input type="text" class="form-control" [(ngModel)]="usuario.cargo" placeholder="Cargo en la empresa"
                      formControlName="cargo" [class.is-invalid]="camposvalidos('cargo')">
                    <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                      *ngIf="camposvalidos('cargo')">Nombres no son válidos, ingrese solo letras
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="mb-3">
                  <label class="form-label">Motivo del rechazo:</label>
                  <textarea class="form-control" [(ngModel)]="usuario.motivoRechazo" 
                    placeholder="Escriba el motivo por el cual rechaza la pre factura..." 
                    formControlName="motivoRechazo" 
                    [class.is-invalid]="camposvalidos('motivoRechazo')" 
                    rows="3">
                  </textarea>
                  <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" 
                    *ngIf="camposvalidos('motivoRechazo')"> 
                    Debe escribir un motivo de rechazo por lo menos de 8 caracteres
                  </div>
                </div>
            </div>

          </div>
          <br>
        </div>
      </div>
    </div>
  
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="close()">Cancelar</button>
      <button type="submit" class="btn btn-primary">Aceptar</button>
    </div>
  
  </form>