import { Component, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';

@Component({
    selector: 'app-descargar-factura',
    templateUrl: './descargar-factura.component.html',
    styleUrls: ['./descargar-factura.component.css'],
    standalone: false
})
export class DescargarFacturaComponent implements OnInit {

  constructor(private alert: AlertsService, private ssps: SspsService) { }

  ngOnInit(): void {
    this.alert.closesFiels()
    this.alert.showFieldsDescargarFactura();
  }
}
