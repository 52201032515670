<div class="animate__animated animate__fadeIn">
    <section class="content-body margen2">
        <div class="row">
            <div class="col">
              <h4 class="">{{title}}</h4>
                <div [innerHTML]="content" class="margencon">
                </div>
                
                <div class="modal-footer d-flex justify-content-center align-items-center mt-3">
                  <div *ngIf="rechazar">
                    <button type="button" class="btn btn-primary" (click)="declinePreInvoice()">Rechazar</button>
                  </div>
                  <button type="button" class="btn btn-primary" (click)="acceptPreInvoice()">Aceptar</button>
                </div>
            </div>
        </div> 
    </section>
</div>