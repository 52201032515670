import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';

@Component({
  selector: 'app-prefactura',
  templateUrl: './prefactura.component.html',
  styleUrls: ['./prefactura.component.css']
})
export class PrefacturaComponent implements OnInit {

  preInvoice: any = {
    idPrefactura: 0,
    periodo: ''
  };

  data: any;

  constructor(private alert: AlertsService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.preInvoice.idPrefactura = params.get('id');
      this.preInvoice.periodo = params.get('periodo');
    });

    this.data = {
      mode: 'accept',
      title: 'Solicitud confirmación del contenido',
      content: `Gracias por revisar la prefactura correspondiente al periodo ${this.preInvoice.periodo}. Elige una opción para continuar:
      <br><strong>Aceptar</strong>: Si estás de acuerdo y podemos proceder con la emisión de la factura.
      <br><strong>Rechazar</strong>: Si necesitas hacer ajustes. Indícanos los cambios para realizar las modificaciones necesarias.
      <br><strong>Nota:</strong> Tu decisión se registrará automáticamente y notificaremos al equipo correspondiente.`
    };

    this.alert.showAceptarPrefactura(this.preInvoice, this.data);
  }
}
