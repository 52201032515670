<div [formGroup]="formaForm">
    <div class="check">
        <div *ngFor="let politica of politicas">
          <div class="checkbox-wrapper-19">
            <input type="checkbox" [id]="politica.id" [formControlName]="politica.formControl"
              [class.is-invalid]="camposvalidos(politica.formControl)">
            <label [for]="politica.id" class="check-box"></label>
            <label class="form-check-label">
              He leído y acepto 
              <a class="descargas" [href]="politica.link" download>
                <span class="politicas">{{ politica.texto }} *</span>
              </a>
            </label>
          </div>
        </div>
        <div class="form-text text-danger" *ngIf="cheksPolis">¡Debe aceptar las tres políticas</div>
    </div>
</div>