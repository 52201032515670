<div [formGroup]="formaForm" [ngClass]="{'row': cols === 2}">
    <div class="mb-3" [ngClass]="{'col-md-6': cols === 2, 'col-12': cols === 1}">
        <label for="tipoIdentificacion" class="form-label">Tipo de identificación *</label>
        <select class="form-select" formControlName="tipo"
                id="tipoIdentificacion"
                [class.is-invalid]="camposvalidos('tipo')">
            <option class="form-control" value="CC">Cédula de Ciudadanía</option>
            <option class="form-control" value="CE">Cédula de Extranjería</option>
            <option class="form-control" value="PP">Pasaporte</option>
            <option class="form-control" value="TI">Tarjeta de Identidad T.I.</option>
            <option class="form-control" value="PT">Permiso Permanencia Temporal P.P.T</option>
        </select>
        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
             *ngIf="camposvalidos('tipo')">
            Debe seleccionar un tipo de documento
        </div>
    </div>

    <div class="mb-3" [ngClass]="{'col-md-6': cols === 2, 'col-12': cols === 1}">
        <label for="numeroIdentificacion" class="form-label">Número de identificación *</label>
        <input type="text" class="form-control" placeholder="Número de identificación"
               formControlName="numero"
               [class.is-invalid]="camposvalidos('numero')"
               #inputRefNumero>
        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
             *ngIf="camposvalidos('numero')">
            ¡Identificación no válida!
        </div>
    </div>
</div>