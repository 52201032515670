<div class="modal-header">
    <h4 class="titulos" style="text-align: center;">{{titulo}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body  text-center ms-4 me-4">
    <p style="font-size: 16pt;">{{mensaje}}
    </p>
    <div class="mb-4" style="text-align: center;">
        <button type="button" class="btn btn-secondary" style="margin: 15px; min-width: 50px;"
            (click)="cancelar()">{{tituloBoton2}}</button>
        <button (click)="confirmar()" class="btn btn-primary" style=" min-width: 50px">{{tituloBoton}}</button>
    </div>
</div>
<div class="modal-footer">

</div>