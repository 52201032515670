import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    standalone: false
})
export class NavbarComponent implements OnInit {

 // @ViewChild('margen', { read: ElementRef, static:false }) margen: ElementRef;

  constructor(private compoente:ComponentesService,
              private alert:AlertsService) { }


  ngOnInit(): void {

    
  }

  /*mostrar modal manualmente desde servicio*/ 
mostrar(){
this.compoente.estados = true;

}

//TODO:muestra activo Digital
showActive(){
this.alert.showFiels();
}

}
