!function (e, t) {
  "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define([], t) : "object" == typeof exports ? exports.EBiometrics = t() : e.EBiometrics = t();
}(self, () => (() => {
  "use strict";

  var e = {
      1: (e, t, r) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.startFlow = void 0;
        const o = r(37),
          i = r(999);
        t.startFlow = async function (e) {
          e.onError = e.onError ?? o.defaultOnError;
          try {
            const t = new i.ValidatorService(),
              r = await t.getState(e);
            new i.WebsocketService().connect(r), new i.RenderService().createEmbed(r);
          } catch (t) {
            e.onError({
              message: t.message
            });
          }
        };
      },
      722: (e, t) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.defaultOnSuccess = t.defaultOnError = t.defaultOnRetry = void 0, t.defaultOnRetry = function () {
          console.log("eBiometrics retry");
        }, t.defaultOnError = function (e) {
          console.error(e);
        }, t.defaultOnSuccess = function (e) {
          console.log(e);
        };
      },
      37: function (e, t, r) {
        var o = this && this.__createBinding || (Object.create ? function (e, t, r, o) {
            void 0 === o && (o = r);
            var i = Object.getOwnPropertyDescriptor(t, r);
            i && !("get" in i ? !t.__esModule : i.writable || i.configurable) || (i = {
              enumerable: !0,
              get: function () {
                return t[r];
              }
            }), Object.defineProperty(e, o, i);
          } : function (e, t, r, o) {
            void 0 === o && (o = r), e[o] = t[r];
          }),
          i = this && this.__exportStar || function (e, t) {
            for (var r in e) "default" === r || Object.prototype.hasOwnProperty.call(t, r) || o(t, e, r);
          };
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), i(r(722), t), i(r(140), t);
      },
      140: (e, t) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.EBM_WEBSOCKET_API_ENDPOINT_DEVELOPMENT = t.EBM_WEBSOCKET_API_ENDPOINT_PRODUCTION = t.EBM_TRANSACTIONS_API_ENDPOINT_DEVELOPMENT = t.EBM_TRANSACTIONS_API_ENDPOINT_PRODUCTION = t.EBM_URL_DEVELOPMENT = t.EBM_URL_PRODUCTION = void 0, t.EBM_URL_PRODUCTION = "https://www.ebiometrics.co/recognition", t.EBM_URL_DEVELOPMENT = "https://www.ebiometrics.dev/recognition", t.EBM_TRANSACTIONS_API_ENDPOINT_PRODUCTION = "https://api.ebiometrics.co/v1/business/transactions", t.EBM_TRANSACTIONS_API_ENDPOINT_DEVELOPMENT = "https://api.ebiometrics.dev/v1/business/transactions", t.EBM_WEBSOCKET_API_ENDPOINT_PRODUCTION = "wss://xhw1jdkvyh.execute-api.us-east-1.amazonaws.com/prod", t.EBM_WEBSOCKET_API_ENDPOINT_DEVELOPMENT = "wss://eksa30bjp4.execute-api.us-east-1.amazonaws.com/dev";
      },
      607: function (e, t, r) {
        var o = this && this.__createBinding || (Object.create ? function (e, t, r, o) {
            void 0 === o && (o = r);
            var i = Object.getOwnPropertyDescriptor(t, r);
            i && !("get" in i ? !t.__esModule : i.writable || i.configurable) || (i = {
              enumerable: !0,
              get: function () {
                return t[r];
              }
            }), Object.defineProperty(e, o, i);
          } : function (e, t, r, o) {
            void 0 === o && (o = r), e[o] = t[r];
          }),
          i = this && this.__exportStar || function (e, t) {
            for (var r in e) "default" === r || Object.prototype.hasOwnProperty.call(t, r) || o(t, e, r);
          };
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), i(r(554), t), i(r(1), t);
      },
      147: (e, t) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        });
      },
      554: function (e, t, r) {
        var o = this && this.__createBinding || (Object.create ? function (e, t, r, o) {
            void 0 === o && (o = r);
            var i = Object.getOwnPropertyDescriptor(t, r);
            i && !("get" in i ? !t.__esModule : i.writable || i.configurable) || (i = {
              enumerable: !0,
              get: function () {
                return t[r];
              }
            }), Object.defineProperty(e, o, i);
          } : function (e, t, r, o) {
            void 0 === o && (o = r), e[o] = t[r];
          }),
          i = this && this.__exportStar || function (e, t) {
            for (var r in e) "default" === r || Object.prototype.hasOwnProperty.call(t, r) || o(t, e, r);
          };
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), i(r(147), t), i(r(113), t), i(r(815), t), i(r(497), t), i(r(820), t), i(r(469), t);
      },
      113: (e, t) => {
        var r, o, i, n;
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.EBiometricsQueue = t.EBiometricsProvider = t.EBiometricsTheme = t.EBiometricsEnvironment = void 0, (n = t.EBiometricsEnvironment || (t.EBiometricsEnvironment = {})).development = "development", n.production = "production", (i = t.EBiometricsTheme || (t.EBiometricsTheme = {})).light = "light", i.dark = "dark", (o = t.EBiometricsProvider || (t.EBiometricsProvider = {})).idemia = "idemia", o.softcol = "softcol", (r = t.EBiometricsQueue || (t.EBiometricsQueue = {})).simple = "simple", r.extended = "extended";
      },
      815: (e, t) => {
        var r;
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.MessageCode = void 0, (r = t.MessageCode || (t.MessageCode = {})).BIOMETRICS_COMPLETED = "BIOMETRICS_COMPLETED", r.BIOMETRICS_RETRY = "BIOMETRICS_RETRY", r.BIOMETRICS_ERROR = "BIOMETRICS_ERROR";
      },
      497: (e, t) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        });
      },
      820: (e, t) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        });
      },
      469: (e, t) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        });
      },
      999: function (e, t, r) {
        var o = this && this.__createBinding || (Object.create ? function (e, t, r, o) {
            void 0 === o && (o = r);
            var i = Object.getOwnPropertyDescriptor(t, r);
            i && !("get" in i ? !t.__esModule : i.writable || i.configurable) || (i = {
              enumerable: !0,
              get: function () {
                return t[r];
              }
            }), Object.defineProperty(e, o, i);
          } : function (e, t, r, o) {
            void 0 === o && (o = r), e[o] = t[r];
          }),
          i = this && this.__exportStar || function (e, t) {
            for (var r in e) "default" === r || Object.prototype.hasOwnProperty.call(t, r) || o(t, e, r);
          };
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), i(r(597), t), i(r(705), t), i(r(743), t), i(r(155), t);
      },
      597: (e, t, r) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.RenderService = void 0;
        const o = r(554),
          i = r(37);
        t.RenderService = class {
          constructor() {
            this._url = i.EBM_URL_DEVELOPMENT;
          }
          _configure(e) {
            e.environment === o.EBiometricsEnvironment.production && (this._url = i.EBM_URL_PRODUCTION);
          }
          createEmbed(e) {
            this._configure(e);
            const {
              targetEl: t
            } = e;
            for (t.style.overflow = "hidden"; t.firstChild;) t.lastChild && t.removeChild(t.lastChild);
            const r = document.createElement("iframe");
            r.src = `${this._url}/${e.account}/${e.provider}?id=${e.id}`, r.style.width = "100%", r.style.height = "100%", r.style.minHeight = "inherit", r.style.border = "none", r.allow = "camera *;microphone *", t.appendChild(r);
          }
        };
      },
      705: (e, t, r) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.TransactionsService = void 0;
        const o = r(554),
          i = r(37);
        t.TransactionsService = class {
          constructor() {
            this._url = i.EBM_TRANSACTIONS_API_ENDPOINT_DEVELOPMENT;
          }
          _configure(e) {
            e.environment === o.EBiometricsEnvironment.production && (this._url = i.EBM_TRANSACTIONS_API_ENDPOINT_PRODUCTION);
          }
          async create(e) {
            return this._configure(e), new Promise((t, r) => {
              const i = {
                provider: e.provider,
                ...e.params
              };
              e.provider === o.EBiometricsProvider.softcol && (i.queue = e.queue);
              const n = new XMLHttpRequest();
              n.open("POST", this._url, !0), n.setRequestHeader("X-Ebm-Token", e.account), n.setRequestHeader("Content-Type", "application/json"), n.send(JSON.stringify(i)), n.onreadystatechange = function () {
                this.readyState === XMLHttpRequest.DONE && (0 === this.status || this.status >= 200 && this.status < 400 ? t(JSON.parse(this.responseText)) : r());
              };
            });
          }
        };
      },
      743: (e, t, r) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.ValidatorService = void 0;
        const o = r(705),
          i = r(37),
          n = r(554);
        t.ValidatorService = class {
          constructor() {
            this._transactionsService = new o.TransactionsService();
          }
          _error(e) {
            throw new Error(e);
          }
          async getState(e) {
            e.target || this._error("You must provide the target element ID"), e.account || this._error("You must provide the account UUID"), e.params || this._error("You must provide the params"), e.theme = e.theme ?? n.EBiometricsTheme.light, e.provider = e.provider ?? n.EBiometricsProvider.softcol, e.queue = e.queue ?? n.EBiometricsQueue.simple, e.environment = e.environment ?? n.EBiometricsEnvironment.development, e.onRetry = e.onRetry ?? i.defaultOnRetry, e.onSuccess = e.onSuccess ?? i.defaultOnSuccess;
            const t = e,
              r = await this._transactionsService.create(t);
            t.id = r.id;
            const o = document.getElementById(t.target);
            return o || this._error(`Could not find HTML element with ID: ${t.target}`), t.targetEl = o, t;
          }
        };
      },
      155: (e, t, r) => {
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.WebsocketService = void 0;
        const o = r(554),
          i = r(37);
        t.WebsocketService = class {
          constructor() {
            this._url = i.EBM_WEBSOCKET_API_ENDPOINT_DEVELOPMENT;
          }
          _configure(e) {
            this._room = e.id, e.environment === o.EBiometricsEnvironment.production && (this._url = i.EBM_WEBSOCKET_API_ENDPOINT_PRODUCTION);
          }
          _processMessage(e, t) {
            const r = JSON.parse(e);
            r.code === o.MessageCode.BIOMETRICS_RETRY && t.onRetry(), r.code === o.MessageCode.BIOMETRICS_ERROR && t.onError({
              message: r.content
            }), r.code === o.MessageCode.BIOMETRICS_COMPLETED && t.onSuccess({
              id: r.content
            });
          }
          connect(e) {
            this._configure(e), this._websocket = new WebSocket(`${this._url}?room=${this._room}`), this._websocket.onerror = () => {
              this.connect(e);
            }, this._websocket.onclose = () => {
              this.connect(e);
            }, this._websocket.onmessage = t => {
              t && t.data && this._processMessage(t.data, e);
            };
          }
        };
      }
    },
    t = {};
  return function r(o) {
    var i = t[o];
    if (void 0 !== i) return i.exports;
    var n = t[o] = {
      exports: {}
    };
    return e[o].call(n.exports, n, n.exports, r), n.exports;
  }(607);
})());