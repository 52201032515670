<div class=" animate__animated animate__fadeInLeftBig animate__faster">

  <div class="row ms-1 mt-3">
    <h5 class="titulosintu2">Información básica</h5>
    <br>
    <br>
    <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()">
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label class="form-label">Tipo de identificación</label>
            <select [(ngModel)]="usuario.tipo" class="form-select" formControlName="tipo">
              <option class="form-control" value="CC">Cédula de Ciudadanía</option>
              <option class="form-control" value="CE">Cédula de Extranjería</option>
              <option class="form-control" value="PP">Pasaporte</option>
              <option class="form-control" value="TI">Tarjeta de Identidad T.I.</option>
              <option class="form-control" value="NI">NIT</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label class="form-label">Número de identificación</label>
            <input [(ngModel)]="usuario.numero" type="text" class="form-control" placeholder="Número de identificación"
              formControlName="numero">
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <label class="form-label">Primer nombre</label>
          <input type="text" class="form-control" placeholder="Primer nombre" formControlName="primerNombre"
            [class.is-invalid]="camposvalidos('primerNombre')" [(ngModel)]="usuario.primerNombre" #inputRefPrimerNombre>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('primerNombre')">
            Primer nombre no es válido</div>
        </div>
        <div class="col">
          <label class="form-label">Segundo nombre</label>
          <input type="text" class="form-control" placeholder="Segundo nombre" formControlName="segundoNombre"
            [class.is-invalid]="camposvalidos('segundoNombre')" [(ngModel)]="usuario.segundoNombre" #inputRefSegundoNombre>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('segundoNombre')">
            Segundo nombre no es válido</div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <label class="form-label">Primer apellido</label>
          <input type="text" class="form-control" placeholder="Primer apellido" formControlName="primerApellido"
            [class.is-invalid]="camposvalidos('primerApellido')" [(ngModel)]="usuario.primerApellido" #inputRefPrimerApellido>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('primerApellido')">Primer apellido no es válido</div>
        </div>
        <div class="col">
          <label class="form-label">Segundo apellido</label>
          <input type="text" class="form-control" placeholder="Segundo apellido" formControlName="segundoApellido"
            [class.is-invalid]="camposvalidos('segundoApellido')"  [(ngModel)]="usuario.segundoApellido" #inputRefSegundoApellido>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('segundoApellido')">Segundo apellido no es válido</div>
        </div>
      </div>
      <!-- <div class="mb-3">
          <div class="row">
            <div class="col">
              <label class="form-label">Fecha de expedición documento</label>
              <div class="input-group">
                <input class="form-control" placeholder="dd/mm/aaaa" formControlName="fechaExpDocumento"
                  [(ngModel)]="fechaExpDocumento" [class.is-invalid]="camposvalidos('fechaExpDocumento')" ngbDatepicker
                  #d="ngbDatepicker" (keyup)="fechas(fechaExpDocumento, 'fechaExpDocumento')" />
                <button class="btn btn-primary  bi bi-calendar3" (click)="d.toggle()" type="button">
                  <i class="far fa-calendar-alt"></i>
                </button>
              </div>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="statusFechaExpDocumento || camposvalidos('fechaExpDocumento')">Fecha de expedición documento no
                válida</div>
            </div>
          </div>
        </div> -->
      <div class="row mb-4">
        <div class="col">
          <label class="form-label">Correo electrónico:</label>
          <input type="text" class="form-control" placeholder="correo electrónico" formControlName="correo"
            [(ngModel)]="usuario.correo" [class.is-invalid]="camposvalidos('correo')" #inputRefCorreo>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('correo')">Correo no válido, debe tener la estructura correo@dominio.com(.co,.es..)
          </div>

        </div>
        <div class="col">
          <label class="form-label">Confirmación correo electrónico:</label>
          <input type="text" class="form-control" placeholder="correo electrónico" formControlName="correo1"
            [(ngModel)]="usuario.correo1" [class.is-invalid]="camposvalidos('correo1')" appNocopiado #inputRefCorreo1>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('correo1')">Correo no válido, debe tener la estructura correo@dominio.com(.co,.es..)
          </div>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="correo2NoValido">
            Los correos no son iguales</div>
        </div>
      </div>

      <div class="mb-3">
        <div class="mb-3">
          <div class="row">
            <div class="col">
              <label class="form-label">Tipo dirección</label>
              <select (change)="changeTipoDireccion($event)" class="form-select" formControlName="tipoDireccion"
                [class.is-invalid]="camposvalidos('tipoDireccion')" [(ngModel)]="direccion.tipo"
                placeholder="seleccione">
                <option *ngFor="let tipo of tipoDireccion" [value]="tipo.name">{{tipo.name}}</option>
              </select>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('tipoDireccion')">Tipo no válido</div>
            </div>
            <div class="col" *ngIf="direccion.tipo === 'Otro'">
              <label class="form-label">Dirección</label>
              <input type="text" class="form-control" placeholder="Ej: Finca Guayabita Kilometro 4 vía Chía"
                formControlName="numeroOtroDireccion" [class.is-invalid]="camposvalidos('numeroOtroDireccion')"
                #inputRefNumeroOtroDireccion [(ngModel)]="direccion.tipoOtroNumero">
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('numeroOtroDireccion')">Campo no válido</div>
            </div>
            <div class="col" *ngIf="direccion.tipo !== 'Otro'">
              <label class="form-label">{{direccion.tipo}}&nbsp;</label>
              <input type="text" class="form-control" placeholder="Ej:18" formControlName="tipoNumeroDireccion"
                [class.is-invalid]="camposvalidos('tipoNumeroDireccion')" #inputRefTipoNumeroDireccion
                [(ngModel)]="direccion.tipoNumero">
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('tipoNumeroDireccion')">Campo no válido</div>
            </div>
            <div class="col" *ngIf="direccion.tipo !== 'Otro'">
              <label class="form-label">Número</label>
              <input type="text" class="form-control" placeholder="Ej:79A" formControlName="numeroDireccion"
                [class.is-invalid]="camposvalidos('numeroDireccion')" #inputRefNumeroDireccion
                [(ngModel)]="direccion.numero">
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('numeroDireccion')">Número no válido</div>
            </div>
            <div class="col" *ngIf="direccion.tipo !== 'Otro'">
              <label class="form-label">&nbsp;</label>
              <input type="text" class="form-control" placeholder="Ej:332" formControlName="numeroDireccion2"
                [class.is-invalid]="camposvalidos('numeroDireccion2')" #inputRefNumeroDireccion2
                [(ngModel)]="direccion.numero2">
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('numeroDireccion2')">Número no válido</div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="mb-3">
          <div class="row">
            <div class="col">
              <label class="form-label">{{direccion.tipo === "Otro" ? "Barrio/Vereda/Corregimiento" : "Barrio"}}</label>
              <input type="text" class="form-control" placeholder="Ej: Sevilla" formControlName="barrioDireccion"
                [class.is-invalid]="camposvalidos('barrioDireccion')" [(ngModel)]="direccion.barrio"
                #inputRefBarrioDireccion>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('barrioDireccion')">Barrio no válido, no puede ser vacio</div>
            </div>
            <div class="col">
              <label class="form-label">Piso/Apartamento/Torre/Edificio/Casa</label>
              <input type="text" class="form-control" placeholder="Ej: torre 5 apto 302"
                formControlName="adicionalDireccion" [class.is-invalid]="camposvalidos('adicionalDireccion')"
                [(ngModel)]="direccion.adicional" #inputRefAdicionalDireccion>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('adicionalDireccion')">Direccion inválida</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <label class="form-label">Departamento</label>
          <select class="form-select" formControlName="departamento" [class.is-invalid]="camposvalidos('departamento')"
            (change)="changeDepartamento($event)" [(ngModel)]="usuario.departamento"
            placeholder="selecione un departamento">
            <option *ngFor="let muni of depar" [value]="muni.codigo_dep">{{muni.Departamento}}</option>
          </select>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('departamento')">Departamento no válido, seleccione una opción del listado</div>
        </div>
        <div class="col">
          <label class="form-label">Municipio</label>
          <select class="form-select" formControlName="municipio" [class.is-invalid]="camposvalidos('municipio')"
            [(ngModel)]="usuario.municipio" placeholder="selecione un municipio">
            <option class="form-control" *ngFor="let muni of munifi" [value]="muni.Codigo_mu">{{muni.Municipio}}
            </option>
          </select>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('municipio')">Municipio no válido, seleccione una opción del listado</div>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label mt-3">Teléfono contacto:</label>
        <div class="row">
          <div class="col">
            <input class="form-control" list="browsers" formControlName="indicativo" placeholder="indicativo"
              [(ngModel)]="usuario.indicativo" [class.is-invalid]="camposvalidos('indicativo')"
              (keyup)="noPuntoComa($event)">
            <datalist id="browsers">
              <option *ngFor="let d of indicativos" [value]="d.indicativo">{{d.nomDepartamento}}</option>
            </datalist>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('indicativo')">Indicativo no válido, seleccione una opción de la lista</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comas">El campo
              no
              puede tener decimales</div>
          </div>

          <div class="col">
            <input type="number" class="form-control" placeholder="telefono" formControlName="telefono"
              [class.is-invalid]="camposvalidos('telefono')" #datos2 [(ngModel)]="usuario.telefono"
              value="{datos.toString()}" (keyup)="quitarceros2(datos2.value)" (keyup)="noPuntote($event)">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('telefono')">Teléfono no válido, debe ser un número de 7 dígitos</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastel2">El
              campo
              no puede tener comas</div>

          </div>
          <div class="col">
            <input type="number" class="form-control" placeholder="extension" formControlName="extension"
              [class.is-invalid]="camposvalidos('extension')" #datos3 [(ngModel)]="usuario.extension"
              value="{datos3.toString()}" (keyup)="quitarceros3(datos3.value)" (keyup)="noPuntoex($event)">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('extension')">Extension no válido</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastex">El campo
              no puede tener comas</div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="row">
          <div class="col">
            <label class="form-label">Celular:</label>
            <input type="number" class="form-control" placeholder="celular" formControlName="celular"
              [class.is-invalid]="camposvalidos('celular')" #datos4 [(ngModel)]="usuario.celular"
              value="{datos4.toString()}" (keyup)="quitarceros4(datos4.value)" (keyup)="noPuntotel($event)">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('celular')">Celular no válido, ingrese número de 10 dígitos</div>

            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastel">El campo
              no
              puede tener comas</div>
          </div>
          <div class="col">
            <label class="form-label">Régimen Tributario</label>
            <select class="form-select" formControlName="regimenTributario"
              [class.is-invalid]="camposvalidos('regimenTributario')" [(ngModel)]="usuario.regimenTributario">
              <option class="form-control" value="" disabled></option>
              <option *ngFor="let regimen of regimenTributario" [value]="regimen">{{regimen}}</option>
            </select>

            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('regimenTributario')">Regimen tributario no válido</div>
          </div>
        </div>
      </div>

      <div class="row mb-4" *ngIf="profesional">
        <div *ngIf="!otraProfesion" class="col">
          <label class="form-label">Institución que concedió el grado:</label>
          <input type="text" class="form-control" placeholder="Institución donde se concedió el grado"
            formControlName="institucion" [class.is-invalid]="camposvalidos('institucion')"
            [(ngModel)]="usuario.institucionGrado" #inputRefInstitucion>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('institucion')">Institución no válida</div>
        </div>
        <div class="col">
          <label class="form-label">Profesión:</label>
          <select [(ngModel)]="profesionLista" (change)="validarProfesion($event)" class="form-select"
            formControlName="profesionLista" [class.is-invalid]="camposvalidos('profesionLista')">
            <option class="form-control" value="" disabled></option>
            <option *ngFor="let profesion of profesiones" [value]="profesion">{{profesion}}</option>
          </select>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('profesionLista')">Profesion no válido</div>
        </div>
        <div *ngIf="otraProfesion" class="col">
          <label class="form-label">&nbsp;</label>
          <input type="text" class="form-control" placeholder="Profesion" formControlName="profesion"
            placeholder="Ej. Ingeniero" [class.is-invalid]="camposvalidos('profesion')" [(ngModel)]="usuario.profesion"
            #inputRefProfesion>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('profesion')">Profesion no válido</div>
        </div>
      </div>

      <div class="row mb-4" *ngIf="profesional">
        <div *ngIf="otraProfesion" class="col">
          <label class="form-label">Institución que concedió el grado:</label>
          <input type="text" class="form-control" placeholder="Institución donde se concedió el grado"
            formControlName="institucion" [class.is-invalid]="camposvalidos('institucion')"
            [(ngModel)]="usuario.institucionGrado" #inputRefInstitucion>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('institucion')">Institución no válida</div>
        </div>
        <div class="col">
          <label class="form-label">Número de tarjeta profesional</label>
          <input type="text" class="form-control" placeholder="Número de tarjeta profesiona"
            formControlName="profesional" placeholder="Ej. A1234" [class.is-invalid]="camposvalidos('profesional')"
            [(ngModel)]="usuario.tarjetaProfesional" #inputRefProfesional>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('profesional')">Número de tarjeta profesional no válido</div>
        </div>
        <div class="col">
          <label class="form-label">Fecha de expedición tarjeta profesional</label>
          <div class="input-group">
            <input class="form-control" placeholder="dd/mm/aaaa" mask="99/99/9999" appInputMask
              formControlName="fechaExpTarjeta" [(ngModel)]="fechaExpTarjeta"
              [class.is-invalid]="camposvalidos('fechaExpTarjeta')" ngbDatepicker #d="ngbDatepicker" [maxDate]="maxDate"
              [minDate]="minDate" (keyup)="fechas(fechaExpTarjeta, 'fechaExpTarjeta')" />
            <button class="btn btn-primary  bi bi-calendar3" (click)="d.toggle()" type="button">
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="statusFechaExpTarjeta || camposvalidos('fechaExpTarjeta')">Fecha de expedición de tarjeta profesional
            no válida</div>
        </div>
      </div>

      <div class="mb-3">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="cedula"
            [checked]="ExisteDocumento('cedula') || ExisteDocumentoCargado('cedula')" value="true"
            (change)="CreateDocument('Copia de cédula', 'cedula')"
            [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
          <label class="form-check-label">
             Adjuntar copia de cédula
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="rut"
            [checked]="ExisteDocumento('rut') || ExisteDocumentoCargado('rut')" value="true"
            (change)="CreateDocument('Copia de rut', 'rut')"
            [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
          <label class="form-check-label">
             Adjuntar copia RUT
          </label>
        </div>

        <div class="form-check" *ngIf="profesional">
          <input class="form-check-input" type="checkbox" id="tarjeta"
            [checked]="ExisteDocumento('tarjeta') || ExisteDocumentoCargado('tarjeta')" value="true"
            (change)="CreateDocument('Tarjeta profesional', 'tarjeta')"
            [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
          <label class="form-check-label">
             Adjuntar copia de tarjeta profesional
          </label>

        </div>
      </div>

      <div formArrayName="documentos">
        <div *ngFor="let favorite of documentsDocuments.controls; let i = index">

          <div class="animate__animated animate__fadeIn animate__fast ms-5">
            <label class="form-check-label">
              Documento <strong style="color:#44494d ;">{{buttons[i].name}}</strong> ({{i + 1}} de {{totalCantidad}})
            </label>
            <div class="d-flex justify-content-center">
              <div id="div_file" class="text-center">
                <div [class]="validar()" *ngIf="cambiologo">
                  <span class="fa-stack fa-1x">
                    <i class="fa-solid fa-circle fa-stack-2x" [ngStyle]="{'color':buttons[i].fondo }"></i>
                    <i class="fa-solid fa-upload fa-stack-1x fa-inverse" [ngStyle]="{'color':buttons[i].colores }"></i>
                  </span>

                  <p id="texto">{{buttons[i].title}}
                    <br>
                    <small>{{buttons[i].subtitle}}</small>
                  </p>
                </div>
                <input type="file" class="enviar" #enviar [formControlName]="i"
                  (change)="getFileDetails(enviar.files,i)" accept="{{buttons[i].accept}}">
              </div>
            </div>
          </div>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="validateformats[i] || isValidFieldInArray(documentsDocuments,i)">{{error}}</div>
        </div>
        <div class="mb-4">
          <button type="button" class="btn btn-secondary" style="margin: 15px;" (click)="regresarResumen()"
            [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">Resumen</button>
          <button *ngIf="usuario.solicitudFinalizada !== 'ASIGNADO'" type="submit"
            class="btn btn-primary">{{actualizacion ? "Guardar" : "Siguiente"}}</button>
          <button *ngIf="usuario.solicitudFinalizada === 'ASIGNADO'" type="submit"
            class="btn btn-primary">Continuar</button>
        </div>
      </div>
    </form>
  </div>

</div>