import { Component, Input, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { environment as env } from "src/environments/environment"
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { urlRequest } from 'src/assets/config/app.conf';

@Component({
  selector: 'app-finalizado',
  templateUrl: './finalizado.component.html',
  styleUrls: ['./finalizado.component.css']
})
export class FinalizadoComponent implements OnInit {
  @Input() public  pagoComprobante: any
  validaIdentidad = false
  urlRequest = urlRequest
  constructor(private alert: AlertsService, private ssps: SspsService) { }

  ngOnInit(): void {
    if (this.ssps.getLocalStorage("cargarPago")) {
      this.pagoComprobante = this.ssps.getLocalStorage("cargarPago")!
    }
    if (this.ssps.getLocalStorage("usuario")) {
      var usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
      this.validaIdentidad = usuario.identidadValidada
    }
  }

  //TODO:cerrar modal
  close() {
    this.alert.closeAlert();
    let externalUrl = `${env.urlWebCerti}`;
    localStorage.clear()
    window.location.replace(externalUrl);
  }

  validarIdentidad(){
    this.alert.closeAlert();
    this.alert.showAlertaValidarIdentidad(true)
  }
}
