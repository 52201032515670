import { Component, OnInit } from '@angular/core';
import { ComponentesService } from './domain/servicios/componentes.service';
import { SspsService } from './domain/servicios/ssps.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent implements OnInit {
  title = 'certicámara';
  subscription!: Subscription;
  formsInit = true
  constructor(private components: ComponentesService,
    private ssps: SspsService, private router: Router, 
    private gtmService: GoogleTagManagerService
  ) {
    router.events.subscribe((val: any) => {
      if (val.url !== undefined && val.url.includes("/flujo"))
        this.formsInit = false
      if (val.url !== undefined && val.url === "/")
        this.formsInit = true
    });

  }
  ngOnInit(): void {

    this.initializeGTM();

    this.ssps.checkIfFirstTab();

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
          const gtmTag = {
              event: 'page',
              pageName: item.url
          };
          this.gtmService.pushTag(gtmTag);
      }
  });
  
  }

  private async initializeGTM(): Promise<void> {
    try {
      await this.gtmService.addGtmToDom();
    } catch (error) {
      console.error('Error al agregar GTM al DOM:', error);
    }
  }

}
