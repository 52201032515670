<div class="row fondo mb-4">
</div>

<div class="bs-stepper linear">
  <div class="bs-stepper-header" role="tablist">
    <div class="step active carrusel">
      <button class="carrusel">
        <span [class]="documentos()">
          <i class="fa-regular fa-folder-open fa-xl"></i>
        </span>
        <p class="textope mt-2">Radicación de documentos
          <br>
          <span class="fechas">{{compras.fechaCreacion}}</span>
        </p>
      </button>
    </div>
    <div class="bs-stepper-line"></div>
    <div class="step">
      <button class="carrusel">
        <span  [class]="pagos()">
          <i class="fa-regular fa-credit-card fa-xl"></i>
        </span>
        <p class="textope mt-2">Verificación de pago
          <br>
          <span class="fechas">{{pago.fechaPago}}</span>
        </p>
      </button>
    </div>
    <div class="bs-stepper-line"></div>
    <div class="step">
      <button class="carrusel">
        <span class="bordes">
          <i class="fa-solid fa-bars fa-xl"></i>
        </span>
        <br>
        <p class="textope mt-2">Aprobación de solicitud
          <br>
          <span class="fechas">&nbsp;</span>
        </p>
      </button>

    </div>
    <div class="bs-stepper-line"></div>
    <div class="step">
      <button class="carrusel">
        <span class="bordes">
          <i class="fa-regular fa-envelope-open fa-xl"></i>
        </span>
        <br>
        <p class="textope mt-2">Entrega de certificado
          <br>
          <span class="fechas">&nbsp;</span>
        </p>
      </button>

    </div>
  </div>
</div>