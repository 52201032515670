import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { LogService } from '../../../../domain/servicios/log.service'
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { datosPasarela } from 'src/assets/config/app.conf';

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.css']
})
export class PagoComponent implements OnInit {
  usuario: any = null;
  facturacion: any = null;
  pago: any = {};
  codigoDescuento: any = false;
  validacionCupon: boolean = false;
  descarga: boolean = false;
  carga: boolean = false;
  formaForm!: FormGroup;
  buttons: any = [] = [];
  validateformats: any[] = [];
  cambiologo: boolean = true;
  error: string = 'Formato no válido';
  cargarSoloPago = false
  documentoCargado = false;
  @ViewChild('codigo')
  codigo!: { nativeElement: any; };

  constructor(private router: Router,
    private componentesService: ComponentesService,
    private ssps: SspsService, private logger: LogService,
    private changeDetector: ChangeDetectorRef,
    private alert: AlertsService, private fb: FormBuilder) {
    this.formaForm = this.fb.group({
      documentos: this.fb.array([])
    }
    );
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.ssps.obtenerIpValor()
    //Consultar storage pago, si el estado es aprobado y la etapa es finalizado, habilitar boton para ir 
    // a aprobación solicitud
    //Si la etapa es iniciado, se debe redirigir a la pagina place to play para que realice el pago
    this.componentesService.emitircambio("pagosFull");
    this.usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
    this.facturacion = JSON.parse(this.ssps.getLocalStorage('facturacion')!);

    if (this.ssps.getLocalStorage('cargarSoloPago')) {
      this.cargarSoloPago = JSON.parse(this.ssps.getLocalStorage('cargarSoloPago')!);
    }
    this.logger.log("documentosCargados usuario", this.usuario.documentosCargados)
    if (this.ssps.getLocalStorage('pago')) {
      this.pago = JSON.parse(this.ssps.getLocalStorage('pago')!);
      if (!this.pago.ordenPedidoId) {
        this.pago.ordenPedidoId = JSON.parse(this.ssps.getLocalStorage("ordenPedidoId")!)
      }
      if (!this.pago.solicitudId) {
        this.pago.solicitudId = JSON.parse(this.ssps.getLocalStorage("solicitudId")!)
      }
      this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago))
    }
    else {
      let valorIva = (this.usuario.costoBase * (this.usuario.iva / 100))
      let valorReteIva = (this.usuario.costoBase * ((this.usuario.reteiva ?? 0) / 100))
      this.pago = {
        ordenPedidoId: JSON.parse(this.ssps.getLocalStorage("ordenPedidoId")!),
        solicitudId: JSON.parse(this.ssps.getLocalStorage("solicitudId")!),
        costoBase: this.usuario.costoBase,
        impuestos: valorIva - valorReteIva,
        valorTotal: this.usuario.costo - (this.usuario.descuentoCupon ?? 0),
        mensaje: '',
        estado: 'SIN_PAGO',
        url: '',
        iva: this.usuario.iva,
        ica: this.usuario.ica,
        reteiva: this.usuario.reteiva ?? 0,
        ivaValor: valorIva,
        icaValor: this.usuario.costoBase * (this.usuario.ica / 100),
        documentos: [],
        pagoPSE: false,
        descuentoCupon: this.usuario.descuentoCupon ?? 0,
        realizoPago: "N",
        fechaPago: "",
        referenciaPago: '',
        usuarioRegistraPago: 'N',
        estampillas: this.usuario.estampillas,
        reteIvaValor: valorReteIva,
        esDescuentoCupon: false
      }
      if (!this.pago.ordenPedidoId) {
        this.pago.ordenPedidoId = JSON.parse(this.ssps.getLocalStorage("ordenPedidoId")!)
      }
      if (!this.pago.solicitudId) {
        this.pago.solicitudId = JSON.parse(this.ssps.getLocalStorage("solicitudId")!)
      }
      this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago))
    }

    //Mapear para mostrar si ya tiene un cupon aplicado, 
    //campo esDescuentoCupon va en true, mostrar cupon y habilitar boton eliminar cupon

    if (this.pago !== null && this.pago.realizoPago === "S" &&
      this.pago.fechaPago !== "" && this.pago.fechaPago !== undefined &&
      this.pago.referenciaPago !== undefined && this.pago.referenciaPago !== "" &&
      this.pago.usuarioRegistraPago === "S") {
      this.alert.closesSpiner();
      this.alert.showFinalizado("pagado")
    }
    else if (this.pago !== null && this.pago.pagoPSE === true && !this.cargarSoloPago) {
      this.consultarPago()
    }
    else {
      if (this.cargarSoloPago) {
        this.fisicos()
      }
      this.alert.closesSpiner();
      this.pago.estado = "SIN_PAGO"
    }
    // if (this.pago.realizoPago !== "S") {
    //   this.habilitarCarguePago()
    // }

    if (this.pago.cuponAplicado !== undefined && this.pago.cuponAplicado !== null &&
      this.pago.cuponAplicado !== "") {
      this.codigoDescuento = true
      this.pago.cuponAplicadoExitosamente = true
    }
  }

  consultarPago() {
    //this.alert.showSpiner("Estimado usuario, recuerda que para completar tu proceso, se realizará proceso de validación de identidad");
    this.logger.log("servicioConsultarPago", "Llamar servicio")
    let datosPse = datosPasarela
    datosPse.ipUsuario = this.ssps.ip
    datosPse.urlRetorno = `${location.origin}?id=${JSON.parse(this.ssps.getLocalStorage("solicitudId")!)}&redirect=pago`
    datosPse.navegador = this.ssps.getBrowserName()
    //Si el estado es aprobado, mostrar alerta de transacción exitosa y ya terminar proceso
    let body = {
      ordenPedidoId: this.pago.ordenPedidoId,
      cupon: this.pago.cuponAplicado ?? '',
      datosPasarela: datosPse
    }
    this.ssps.consultarEstadoPagoPSE(body)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta consultarEstadoPago", res)
          if (res !== null) {
            this.pago.estado = res.estado
            this.pago.mensaje = res.mensaje
            this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
            if (this.pago.estado === "APROBADO") {
              this.logger.log("Estado Pago", "APROBADO")
              //Mostrar alerta de aprobado
              //Termina flujo borrando cache
              this.alert.showFinalizado("pagado")
            }
            this.alert.closesSpiner()
            // else {
            //   //Redirige a PSE y allá muestra el estado de la transacción
            //   this.router.navigate(['/flujo/pasarela']);
            // }
          }
          else {
            this.pago.estado = "PENDIENTE"
            this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
            this.alert.closesSpiner()
            //this.router.navigate(['/flujo/pasarela']);
          }
        },
        error: (error: any) => {
          this.logger.log("error suscriber consultarEstadoPago", error)
          this.pago.estado = "PENDIENTE"
          this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
          this.alert.closesSpiner()
          //this.router.navigate(['/flujo/pasarela']);
        }
      });
  }

  /*rediociona al usuario si se equivova*/
  redireciona() {
    this.router.navigate(['/flujo/facturacion']);
    this.componentesService.emitircambio("reverfactu");
  }

  descargarPagoFisico() {
    this.alert.showSpiner("Estimado usuario, recuerda que para completar tu proceso, se realizará proceso de validación de identidad");
    this.logger.log("Descarga pdf fisico", "llamar metodo")
    this.ssps.descargarOrdenPago(this.pago.solicitudId)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta descargarOrdenPago", res)
          if (res !== null) {
            this.alert.closesSpiner()
            this.descargarArchivo(res.archivoBase64, res.nombre, res.tipo)
            this.alert.showFinalizado("sin_comprobante")
          }
          else {
            this.alert.closesSpiner()
            this.logger.error('Error en servicio descargarOrdenPago', res)
            this.alert.showError(this.pago.mensaje, false, null, false, null, null)
          }
        },
        error: (error: any) => {
          this.alert.closesSpiner()
          this.logger.error('Error en subscriber descargarOrdenPago', error)
          let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
            error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
              error.error.message ? error.error.message :
                (error.message ? error.message : "No fue posible realizar la petición")) :
            (error.message ? error.message : "No fue posible realizar la petición")
          this.alert.showError(mensaje, false, null, false, null, null)
        }
      });
  }

  descargarArchivo(base64: any, nombreArchivo: any, contentType: any) {
    const src = `data:${contentType};base64,${base64}`;
    const link = document.createElement("a")
    link.href = src
    link.download = nombreArchivo
    link.click()
    link.remove()
  }

  tengoCodigoDescuento(event: any) {
    this.codigoDescuento = true;

    //Si es false, debe eliminar el cupon si ya lo había aplicado
  }

  /* busca el dodigo selecionado de la persona*/
  buscarcodigo(codigo: string) {
    this.logger.log("aplicar codigo descuento", codigo)
    //Validar el codigo ingresado
    //Consumir servicio de aplicar codigo
    //Mapear respuesta, recalculando valores de pago y usuario con lo que llega del servicio y mostrar alerta con el mensaje
    //Mostrar moensaje de respuesta
    if (codigo === undefined || codigo === null || codigo === "" || codigo.length < 9) {
      this.validacionCupon = true
      return
    }
    this.validacionCupon = false
    this.alert.showSpiner("Estimado usuario, recuerda que para completar tu proceso, se realizará proceso de validación de identidad");
    let body = {
      ordenPedidoId: this.pago.ordenPedidoId,
      cupon: codigo
    }
    this.ssps.aplicarCodigoDescuento(body)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta aplicarCodigoDescuento", res)
          this.pago.estado = "SIN_PAGO"
          let tituloModal = null
          if (res !== null && +res.codigo === 200) {
            tituloModal = "¡Exitoso!"
            this.pago.cuponAplicado = codigo + " "
            this.pago.esDescuentoCupon = true
            this.pago.cuponAplicadoExitosamente = true

            setTimeout(() => {
              this.pago.cuponAplicado = codigo
            }, 1000);
          }
          else {
            this.pago.cuponAplicado = ""
          }
          this.recalcularValores(res.valoresOrdenDescuento)
          this.alert.closesSpiner()
          this.alert.showError(res.mensaje, false, tituloModal, false, null, null);
          this.changeDetector.detectChanges();
        },
        error: (error: any) => {
          this.pago.cuponAplicado = ""
          this.alert.closesSpiner()
          console.error('Error en subscriber aplicarCodigoDescuento', error)
          let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
            error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
              error.error.message ? error.error.message :
                (error.message ? error.message : "No fue posible realizar la petición")) :
            (error.message ? error.message : "No fue posible realizar la petición")
          this.alert.showError(mensaje, false, null, false, null, null)
        }
      });

  }

  eliminarCodigo() {
    this.logger.log("eliminarCodigo codigo descuento", "")
    this.validacionCupon = false
    this.alert.showSpiner("Estimado usuario, recuerda que para completar tu proceso, se realizará proceso de validación de identidad");
    this.ssps.eliminarCodigoDescuento(this.pago.ordenPedidoId, this.pago.cuponAplicado)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta eliminarCodigoDescuento", res)
          this.pago.estado = "SIN_PAGO"
          let tituloModal = null
          if (res !== null && +res.codigo === 200) {
            tituloModal = "¡Exitoso!"
            this.pago.cuponAplicado = ""
            this.pago.esDescuentoCupon = false
            this.pago.cuponAplicadoExitosamente = false
          }
          this.recalcularValores(res.valoresOrdenDescuento)
          this.alert.closesSpiner()
          this.alert.showError(res.mensaje, false, tituloModal, false, null, null)
          this.changeDetector.detectChanges();
        },
        error: (error: any) => {
          this.pago.cuponAplicado = ""
          this.alert.closesSpiner()
          console.error('Error en subscriber eliminarCodigoDescuento', error)
          let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
            error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
              error.error.message ? error.error.message :
                (error.message ? error.message : "No fue posible realizar la petición")) :
            (error.message ? error.message : "No fue posible realizar la petición")
          this.alert.showError(mensaje, false, null, false, null, null)
        }
      });
  }

  pagoVirtual() {
    if (this.pago.cuponAplicado !== undefined && this.pago.cuponAplicado !== null &&
      this.pago.cuponAplicado !== "") {
      this.alert.showSpiner("Estimado usuario, recuerda que para completar tu proceso, se realizará proceso de validación de identidad");
      let body = {
        ordenPedidoId: this.pago.ordenPedidoId,
        cupon: this.pago.cuponAplicado
      }
      this.ssps.validarCodigoDescuento(body)
        .subscribe({
          next: (res: any) => {
            this.logger.log("respuesta pagoVirtual", res)
            if (res !== null && +res.codigo === 200) {
              this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago))
              this.alert.closesSpiner()
              this.router.navigate(['/flujo/pasarela']);
            }
            else {
              this.pago.cuponAplicado = ""
              this.pago.esDescuentoCupon = false
              this.pago.cuponAplicadoExitosamente = false
              this.recalcularValores(res.valoresOrdenDescuento)
              this.alert.closesSpiner()
              this.alert.showError(res.mensaje, false, null, false, null, null);
              this.changeDetector.detectChanges();
            }
          },
          error: (error: any) => {
            this.pago.cuponAplicado = ""
            this.alert.closesSpiner()
            console.error('Error en subscriber validarCodigoDescuento', error)
            let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
              error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                error.error.message ? error.error.message :
                  (error.message ? error.message : "No fue posible realizar la petición")) :
              (error.message ? error.message : "No fue posible realizar la petición")
            this.alert.showError(mensaje, false, null, false, null, null)
          }
        });
    }
    else {
      this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago))
      this.router.navigate(['/flujo/pasarela']);
    }
  }

  fisicos() {
    this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago))    
    this.descarga = true;
    if (!this.carga) {
      this.documentoCargado = this.ExisteDocumentoCargado("pago")
      this.habilitarCarguePago()
    }
  }

  habilitarCarguePago() {
    this.carga = true;
    this.CreateDocument('Comprobante pago', 'pago')
  }

  get documentsDocuments() {
    return this.formaForm.get('documentos') as FormArray;
  }

  CreateDocument(documentName: string, id: String) {
    const estilos = {
      fondo: '#ff4d4d', colores: '#fff', title: 'Seleccionar archivo',
      subtitle: 'El formato de documento permitido para cargar es .PDF',
      name: documentName,
      id: id,
      accept: ".pdf"
    }
    this.buttons.push(estilos)
    this.addControlDocument();
  }

  addControlDocument() {
    this.documentsDocuments.push(this.fb.control(null));
  }

  ExisteDocumento(id: String) {
    return this.pago.documentos.filter((f: { id: String; }) => f.id === id).length > 0
  }

  getFileDetails(file: any, index: number) {
    let fileToUpload = <File>file[0];
    const botonActualizado = { ...this.buttons[index] };
    let tamañoEnBytes = fileToUpload.size;
    let tamañoEnMegabytes = tamañoEnBytes / (1024 * 1024);

    if (fileToUpload.type == "application/pdf") {
      if (tamañoEnMegabytes > 10) {
        botonActualizado.fondo = '#ff4d4d';
        botonActualizado.colores = '#fff';
        botonActualizado.title = 'Seleccionar archivo';
        botonActualizado.subtitle = 'Formato de archivo no válido';
        this.buttons[index] = botonActualizado;
        this.validateformats[index] = true;
      }
      if (tamañoEnMegabytes < 10) {
        const reader = new FileReader()
        reader.onload = () => {
          const b64 = reader.result as string
          botonActualizado.file = b64
        }

        if (fileToUpload) {
          reader.readAsDataURL(fileToUpload)
        }

        botonActualizado.fondo = '#33f0b7';
        botonActualizado.colores = '#2d3d3d';
        botonActualizado.title = 'Documento cargado con éxito';
        botonActualizado.subtitle = fileToUpload.name;

        this.buttons[index] = botonActualizado;
        this.validateformats[index] = false;
      }
    }
    else {
      botonActualizado.fondo = '#ff4d4d';
      botonActualizado.colores = '#fff';
      botonActualizado.title = 'Seleccionar archivo';
      botonActualizado.subtitle = 'El formato de documento permitido para cargar es .PDF';
      this.buttons[index] = botonActualizado;
      this.validateformats[index] = true;

    }
  }

  isValidFieldInArray(formArray: FormArray, index: number) {
    return formArray.controls[index].errors
      && formArray.controls[index].touched;
  }
  validar() {
    return (this.cambiologo = true) ? ' animate__animated animate__fadeIn animate__fast' : 'animate__animated animate__fadeOut animate__fast';
  }

  cargarPago() {
    if (this.validateformats.length === 0 || this.validateformats.filter(f => f === false).length === 0) {
      this.formaForm.markAllAsTouched();
      return;
    }

    let documento = this.buttons.filter((f: { file: any; }) => f.file)[0]
    this.logger.log("documento pago", documento)
    let body = {
      tipo: documento.id,
      contenido: documento.file.split(",", 2)[1]
    }

    this.logger.log("documento pago", body)


    this.alert.showSpiner("Estimado usuario, recuerda que para completar tu proceso, se realizará proceso de validación de identidad");
    this.logger.log("Descarga pdf fisico", "llamar metodo")
    this.ssps.cargarComprobantePago(this.pago.solicitudId, body)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta cargarComprobantePago", res)
          if (res !== null) {
            this.alert.closesSpiner()
            //Llamar al servicio
            this.alert.showFinalizado("comprobante")
          }
          else {
            this.alert.closesSpiner()
            console.error('Error en servicio cargarComprobantePago', res)
            this.alert.showError(res.mensaje, false, null, false, null, null)
          }
        },
        error: (error: any) => {
          this.alert.closesSpiner()
          console.error('Error en subscriber cargarComprobantePago', error)
          let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
            error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
              error.error.message ? error.error.message :
                (error.message ? error.message : "No fue posible realizar la petición")) :
            (error.message ? error.message : "No fue posible realizar la petición")
          this.alert.showError(mensaje, false, null, false, null, null)
        }
      });
  }

  recalcularValores(valoresOrdenDescuento: any) {
    this.pago.costoBase = valoresOrdenDescuento.valorBaseLiquidacion
    this.pago.icaValor = valoresOrdenDescuento.valorIca
    this.pago.ivaValor = valoresOrdenDescuento.valorIva
    this.pago.reteIvaValor = valoresOrdenDescuento.valorReteIva
    this.usuario.costoBase = valoresOrdenDescuento.valorBaseLiquidacion
    this.pago.descuentoCupon = valoresOrdenDescuento.valorDescuentoCupon
    this.usuario.costo = valoresOrdenDescuento.valorNeto
    this.usuario.descuentoCupon = valoresOrdenDescuento.valorDescuentoCupon
    this.pago.valorTotal = valoresOrdenDescuento.valorNeto
    this.pago.estampillas = valoresOrdenDescuento.estampillas
    this.usuario.estampillas = valoresOrdenDescuento.estampillas
    this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
    this.ssps.saveLocalStorage("pago", JSON.stringify(this.pago));
  }

  ExisteDocumentoCargado(id: String) {
    return this.usuario.documentosCargados && this.usuario.documentosCargados.filter((f: { id: String; }) => f.id === id).length > 0
  }
  habilitarDocumentoPagoCargue() {
    this.carga = true
    this.documentoCargado = false
  }
}

