import { Component, Input, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { Router } from '@angular/router';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-alerta-valida-identidad',
    templateUrl: './alerta-valida-identidad.component.html',
    styleUrls: ['./alerta-valida-identidad.component.css'],
    standalone: false
})
export class AlertaValidaIdentidadComponent implements OnInit {
  @Input() public redirigirValidaIdentidad: any
  constructor(private alert: AlertsService,
    private router: Router,
    private ssps: SspsService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    if (this.redirigirValidaIdentidad) {
      this.validarIdentidad()
    }
  }

  //TODO:cerrar modal
  noValidar() {
    this.activeModal.close(false);
    this.alert.showAlertaSalirValidarIdentidad("", false, null, "alerta", this.router.url, null)
  }
  validarIdentidad() {
    this.activeModal.close(false);
    this.router.navigate(['validacion-identidad'], {
      queryParams: {
        'origen': "/?id=" + JSON.parse(this.ssps.getLocalStorage("solicitudId")!),
        'boton': "Regresar"
      },
      queryParamsHandling: 'merge'
    })
    
    //this.router.navigate(['validacion-identidad']);
  }
}
