import { Component, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';

@Component({
    selector: 'app-estado-tramite',
    templateUrl: './estado-tramite.component.html',
    styleUrls: ['./estado-tramite.component.css'],
    standalone: false
})
export class EstadoTramiteComponent implements OnInit {

  constructor(private alert: AlertsService, private ssps: SspsService) { }

  ngOnInit(): void {
    this.alert.closesFiels()
    this.alert.showFieldsEstadoTramite();
  }
}
