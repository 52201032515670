import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()

export class LogService {
    log(title: any, msg: any) {
        if (environment.logs)
            console.log(title, msg);
    }
    error(title: any, msg: any) {
        if (environment.logs)
            console.error(title, msg);
    }
}