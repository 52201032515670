import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { DatePipe } from '@angular/common'
import { LogService } from '../../../domain/servicios/log.service'
import { urlRequest } from 'src/assets/config/app.conf';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-revocar-certificado',
    templateUrl: './revocar-certificado.component.html',
    styleUrls: ['./revocar-certificado.component.css'],
    standalone: false
})
export class RevocarCertificadoComponent implements OnInit {
  @ViewChild('inputRefNombres')
  inputRefNombres!: { nativeElement: any; };

  @ViewChild('inputRefApellidos')
  inputRefApellidos!: { nativeElement: any; };

  @ViewChild('inputRefNumero')
  inputRefNumero!: { nativeElement: any; };
  urlRequest = urlRequest
  formaForm!: FormGroup;
  solicitudId: any = ""
  fechaExpDocumento!: any;
  statusFechaExpDocumento: boolean = false;
  usuarioValida: any = {
    ip: '',
    idTitular: 0,
    tipo: '',
    numero: '',
    fechaExpDocumento: null
  };
  maxDate: NgbDateStruct
  minDate: NgbDateStruct
  identidadValidada = false
  motivosRechazo: any = []
  certificados: any = []
  listadoHeaders = [
    { title: "Empresa", code: "organizacion" },
    { title: "Nombre", code: "nombreSolicitante" },
    { title: "Username", code: "username" },
    { title: "Estado", code: "status" },
    { title: "Fecha emisión", code: "fechaEmision" },
    { title: "Fecha Vencimiento", code: "fechaVencimiento" }]
  listadoDetalle = [
    { title: "Username", code: "username" },
    { title: "Solicitud", code: "serialDispositivo" },
    { title: "Política del Certificado", code: "politicaCertificado" },
    { title: "Nombre Solicitante", code: "nombreSolicitante" },
    { title: "Cédula", code: "cedula" },
    { title: "Correo Electrónico", code: "correoElectronicoSolicitante" },
    { title: "Fecha emisión", code: "fechaEmision" },
    { title: "Fecha Vencimiento", code: "fechaVencimiento" },
    { title: "Organización", code: "organizacion" },
    { title: "Nit", code: "nit" },
    { title: "Dirección", code: "direccionFisica" },
    { title: "Ciudad", code: "localidad" }]

  certificadoSeleccionado: any = null
  motivoSeleccionadoId: any = null
  motivoSeleccionado: any = null
  urlManualRevocacion = ""
  constructor(private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private componentesService: ComponentesService,
    private ssps: SspsService,
    private alert: AlertsService,
    public datepipe: DatePipe,
    private logger: LogService,
    private el: ElementRef,
    private router: Router,
  ) {
    /*validacion de campos validators*/
    this.formaForm = this.fb.group({
      tipo: ['', [Validators.required]],
      numero: ['', [Validators.required
      ]],
      fechaExpDocumento: ['', [Validators.required]]
    }, { validators: [this.componentesService.codigonit('tipo', 'numero')] });
    this.setOnChangesInputs()
    var actualFecha = new Date();
    this.maxDate = { year: actualFecha.getFullYear(), month: actualFecha.getMonth() + 1, day: actualFecha.getDate() }
    this.minDate = { year: actualFecha.getFullYear() - 100, month: actualFecha.getMonth() + 1, day: actualFecha.getDate() }
  }

  async ngOnInit(): Promise<void> { //NOSONAR
    this.obtenerParametros()
    this.ssps.obtenerIpValor()
    if (this.ssps.getLocalStorage("usuarioValida")) {
      this.usuarioValida = JSON.parse(this.ssps.getLocalStorage("usuarioValida")!)
      this.fechaExpDocumento = this.usuarioValida.fechaExpDocumento
      this.consultarMotivosRechazo()
    }

    if (this.ssps.getLocalStorage("validaIdentidad")) {
      this.identidadValidada = JSON.parse(this.ssps.getLocalStorage("validaIdentidad")!)
      localStorage.removeItem("validaIdentidad")
      if (this.identidadValidada) {
        this.consultarCertificadosRevocacion()
      }
    }
  }
  
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  setOnChangesInputs() {
    this.formaForm.get("numero")!.valueChanges.subscribe(val => {
      if (this.inputRefNumero) {
        const elRef = this.inputRefNumero?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numero")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });
  }

  /* funcion para validacion de campos*/
  camposvalidos(campo: any) {
    return this.formaForm.get(campo)!.invalid &&
      (this.formaForm.get(campo)!.touched || this.formaForm.get(campo)!.dirty);
  }

  //TODO:cerrar el modal de activo digital
  close() {
    this.alert.closesSpiner();
  }

  /*ingreso y validacion de datos de formulario*/
  ngsubmit() {
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl?.focus();
          return;
        }
      });
      return;
    }
    else {
      //this.estados= true;
      this.alert.showSpiner("Procesando petición, por favor espera un momento");
      let { fechaExpDocumento } = this.formaForm.value;
      this.usuarioValida.fechaExpDocumento = fechaExpDocumento
      this.consultarDatsSolicitante()
      //Llamar servicio para consultar datos
    }
  }

  fechas(dato: any, campo: any) {
    if (dato === undefined || dato === null) return;
    let valida = /^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/-](?:0?[1-9]|1[0-2])|(?:29|30)[/-](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/-](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/-]0?2[/-](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/;
    if (campo === "fechaExpDocumento") {
      if (valida.test(dato) == false) {
        this.statusFechaExpDocumento = true;
      } else {
        this.statusFechaExpDocumento = false;
      }
    }
  }

  consultarDatsSolicitante() {
    let data = {
      tipo: this.usuarioValida.tipo,
      numero: this.usuarioValida.numero
    }
    this.ssps.consultarDatosSolicitanteRevocacion(data)
      .subscribe({
        next: (res: any) => {
          this.logger.log("consultarDatosSolicitanteRevocacion", res)
          this.usuarioValida.ip = this.ssps.ip
          this.usuarioValida.nombres = (res.primerNombre ?? '') + (res.segundoNombre !== null &&
            res.segundoNombre !== undefined && res.segundoNombre !== "" ?
            " " + res.segundoNombre : "")
          this.usuarioValida.apellidos = (res.primerApellido ?? '') + (res.segundoApellido !== null &&
            res.segundoApellido !== undefined && res.segundoApellido !== "" ?
            " " + res.segundoApellido : "")
          this.usuarioValida.idTitular = res.solicitudId
          this.usuarioValida.correo = res.correo
          this.usuarioValida.celular = res.celular
          this.usuarioValida.primerNombre = res.primerNombre
          this.usuarioValida.segundoNombre = res.segundoNombre
          this.usuarioValida.primerApellido = res.primerApellido
          this.usuarioValida.segundoApellido = res.segundoApellido
          this.logger.log("this.usuarioValida", this.usuarioValida)
          this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuarioValida))
          this.ssps.saveLocalStorage("usuarioValida", JSON.stringify(this.usuarioValida))
          this.ssps.saveLocalStorage("solicitudId", JSON.stringify(res.solicitudId.toString()))
          this.alert.closesSpiner()
          this.alert.closesFiels()
          this.router.navigate(['validacion-identidad'], {
            queryParams: {
              'origen': "revocar-certificado",
              'boton': "Volver a revocaciones"
            },
            queryParamsHandling: 'merge'
          })
        },
        error: (error) => {
          console.error('Error en subscriber consultarDatosSolicitanteRevocacion', error)
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.consultarDatsSolicitante();
          }
          else {
            this.alert.closesSpiner()
            console.error('Error en subscriber consultarDatosSolicitanteRevocacion', error)
            let mensaje = this.ssps.getErrorMessage(error);
            this.alert.showError(mensaje, false, null, false, null, null, false)
          }
        }
      })
  }

  consultarMotivosRechazo() {
    this.ssps.consultarMotivosRechazo()
      .subscribe({
        next: (res: any) => {
          this.logger.log("consultarMotivosRechazo", res)
          this.motivosRechazo = res
        },
        error: (error) => {
          console.error('Error en subscriber consultarMotivosRechazo', error)
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.consultarMotivosRechazo();
          }
          else {
            console.error('Error en subscriber consultarMotivosRechazo', error)
          }
        }
      })
  }

  obtenerParametros() {
    this.ssps.obtenerParametros()
      .subscribe({
        next: (res: any) => {
          this.logger.log("obtenerParametros", res)
          this.urlManualRevocacion = res.manualRevocacion
        },
        error: (error) => {
          console.error('Error en subscriber obtenerParametros', error)
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.obtenerParametros();
          }
          else {
            console.error('Error en subscriber obtenerParametros', error)
          }
        }
      })
  }

  consultarCertificadosRevocacion() {
    this.alert.showSpiner("Estamos cargando tus solicitudes, este proceso puede tardar un momento");
    this.ssps.consultarCertificadosRevocacion(this.usuarioValida.numero)
      .subscribe({
        next: (res: any) => {
          this.logger.log("consultarCertificadosRevocacion", res)
          this.certificados = res
          this.alert.closesSpiner()
          if (this.certificados.length === 0) {
            this.alert.showError("No se encontraron certificados vigentes asociados a tu número de identificacion",
              false, null, false, null, null, false)
          }
        },
        error: (error) => {
          console.error('Error en subscriber consultarCertificadosRevocacion', error)
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.consultarCertificadosRevocacion();
          }
          else {
            this.alert.closesSpiner()
            console.error('Error en subscriber consultarCertificadosRevocacion', error)
            let mensaje = this.ssps.getErrorMessage(error);
            this.alert.showError(mensaje, false, null, false, null, null, false)
          }
        }
      })
  }

  detalleCertificado(certificado: any) {
    this.logger.log("detalleCertificado", certificado)
    this.certificadoSeleccionado = certificado
  }

  //TODO:redireciona al home de certicamara
  homeNavigate() {
    this.alert.closesFiels();
    this.alert.closesFiels()
  }

  cancelarSeleccion() {
    this.certificadoSeleccionado = null
    this.motivoSeleccionado = null
    this.motivoSeleccionadoId = null
    localStorage.removeItem("tituloBoton")
  }

  revocarCertificado() {
    this.logger.log("motivoSeleccionado", this.motivoSeleccionado)
    if (this.motivoSeleccionado === null) {
      this.alert.closesSpiner()
      this.alert.showError("El motivo de revocación es obligatorio para continuar",
        false, null, false, null, null, false)
      return;
    }

    this.certificadoSeleccionado.status = "REVOCADO"
    this.certificadoSeleccionado.motivoRevocacionId = this.motivoSeleccionado.motivoRevocacionId
    this.certificadoSeleccionado.motivoRevocacion = this.motivoSeleccionado.descCortaMotivo
    let alertaConfirmacion = this.alert.showConfirmacion(`¿Está seguro de que deseas revocar el certificado ${this.certificadoSeleccionado.username}?`,
      'Confirmar revocación certificado', 'SI', 'NO')
    alertaConfirmacion.result.then((confirmacion: any) => {
      this.logger.log("alertaConfirmacion", confirmacion);
      if (confirmacion) {
        this.alert.showSpiner("Procesando petición, por favor espera un momento");
        this.revocarCertificadoServicio()
      }
    });
  }

  revocarCertificadoServicio() {
    this.ssps.revocarCertificado(this.certificadoSeleccionado)
      .subscribe({
        next: (res: any) => {
          this.alert.closesSpiner()
          this.logger.log("revocarCertificado", res)
          let alerta = this.alert.showError(res.mensaje, false, "Revocación exitosa", false, null, null, false)
          this.cancelarSeleccion()
          alerta.result.then((confirmacion: any) => {
            this.logger.log("revocarCertificado errorAlert result", confirmacion);
            this.consultarCertificadosRevocacion()
          });
        },
        error: (error) => {
          console.error('Error en subscriber revocarCertificado', error)
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.revocarCertificadoServicio();
          }
          else {
            this.alert.closesSpiner()
            console.error('Error en subscriber revocarCertificado', error)
            let mensaje = this.ssps.getErrorMessage(error);
            this.alert.showError(mensaje, false, null, false, null, null, false)
          }
        }
      })
  }

  changeMotivo($event: any) {
    let motivo = $event.target.value;
    this.motivoSeleccionado = this.motivosRechazo.filter((i: any) => i.motivoRevocacionId === +motivo)[0]
  }

  volverFormulario() {
    this.certificadoSeleccionado = null
    this.certificados = []
    this.identidadValidada = false
    this.formaForm.reset()
    this.usuarioValida = {
      ip: '',
      idTitular: 0,
      tipo: '',
      numero: '',
      fechaExpDocumento: null
    };
  }

  closeModal() {
    let externalUrl = `${environment.urlWebCerti}`;
    this.alert.closesFiels();
    localStorage.clear()
    window.location.replace(externalUrl);
  }
}