<table border="0" cellspacing="0" cellpadding="0" style="width: 100%;">
  <!-- Fila de encabezado, semántico para accesibilidad -->
  <thead>
    <tr>
      <th style="display: none;"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style="padding: 5px; vertical-align: top;">
        <img src="../../../assets/logo_certicamara.png" class="rounded mx-auto d-block" style="width: 130px;" alt="Logo Certicámara">
        <img src="../../../assets/ssl_identidad.png" class="rounded mx-auto d-block" style="width: 150px; margin-top: -30px;" alt="Validación de identidad">
      </td>
      <td style="vertical-align: top; padding-top: 45px;">
        <h3 class="modal-title">Validación de identidad</h3>
        <div class="modal-body">
          <p class="textor">
            Apreciado usuario, por tu seguridad y la del proceso, queremos validar tu identidad.
          </p>
        </div>
        <div class="modal-footer" style="justify-content: space-between;">
          <button style="font-size: 11pt;" type="button" class="btn btn-secondary" (click)="noValidar()">Continuar después</button>
          <button style="font-size: 11pt;" type="button" class="btn btn-primary" (click)="validarIdentidad()">Validar mi identidad</button>
        </div>
      </td>
    </tr>
  </tbody>
</table>