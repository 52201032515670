<div class=" animate__animated animate__fadeInLeftBig animate__faster">

  <h5 class="titulosintu2">Información del pago a realizar</h5>
  <br>
  <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()">
    <table style="border-collapse:collapse; width: 100%" cellspacing="0">
      <tr style="height:15pt;">
        <td style="width:50%;">
          <p class="s11"
            style="font-weight: bold; padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">SUBTOTAL:
          </p>
        </td>
        <td style="width:50pt;">
          &nbsp;
        </td>
        <td style="width:100pt;">
          <p class="s9" style="text-indent: 0pt;text-align: right;">
            {{pago.costoBase | currency:'COP'}}</p>

        </td>
      </tr>
      <tr *ngIf="pago.descuentoCupon !== undefined && pago.descuentoCupon !== null && pago.descuentoCupon > 0" style="height:15pt;">
        <td style="width:50pt;">
          <p class="s11"
            style="font-weight: bold; padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">DESCUENTO COMERCIAL:
          </p>
        </td>
        <td style="width:100pt;">
          <p class="s11" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: center;">
            &nbsp;</p>
        </td>
        <td style="width:100pt;">
          <p class="s9" style="text-indent: 0pt;text-align: right;">{{(pago.descuentoCupon * -1)| currency:'COP'}}</p>
        </td>
      </tr>
      <tr *ngIf="pago.descuentoCupon !== undefined && pago.descuentoCupon !== null && pago.descuentoCupon > 0" style="height:15pt;">
        <td style="width:50pt;">
          <p class="s11"
            style="font-weight: bold; padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">SUBTOTAL CON DESCUENTO COMERCIAL:
          </p>
        </td>
        <td style="width:100pt;">
          <p class="s11" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: center;">
            &nbsp;</p>
        </td>
        <td style="width:100pt;">
          <p class="s9" style="text-indent: 0pt;text-align: right;">{{pago.costoBase - pago.descuentoCupon | currency:'COP'}}</p>
        </td>
      </tr>
      <tr style="height:15pt;">
        <td style="width:50pt;">
          <p class="s11"
            style="font-weight: bold; padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">IVA:</p>
        </td>
        <td style="width:50pt;">
          <p class="s11" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: center;">
            {{pago.iva}}%</p>
        </td>
        <td>
          <p class="s9" style="text-indent: 0pt;text-align: right;">{{pago.ivaValor | currency:'COP'}}</p>
        </td>
      </tr>
      <tr *ngIf="pago.reteiva !== undefined && pago.reteiva !== null" style="height:15pt;">
        <td style="width:50pt;">
          <p class="s11"
            style="font-weight: bold; padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">RETEIVA:
          </p>
        </td>
        <td style="width:100pt;">
          <p class="s11" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: center;">
            {{pago.reteiva}}%</p>
        </td>
        <td style="width:100pt;">
          <p class="s9" style="text-indent: 0pt;text-align: right;">{{(pago.reteIvaValor * -1) | currency:'COP'}}</p>
        </td>
      </tr>
      <tr *ngIf="pago.ica !== undefined && pago.ica !== null && pago.ica > 0" style="height:15pt;">
        <td style="width:50pt;">
          <p class="s11"
            style="font-weight: bold; padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">RETEICA:
          </p>
        </td>
        <td style="width:100pt;">
          <p class="s11" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: center;">
            {{pago.ica}}%</p>
        </td>
        <td style="width:100pt;">
          <p class="s9" style="text-indent: 0pt;text-align: right;">{{(pago.icaValor * -1)| currency:'COP'}}</p>
        </td>
      </tr>      
      <tr *ngIf="pago.estampillas !== undefined && pago.estampillas !== null && pago.estampillas.length > 0" style="height:15pt;">
        <td style="width:50pt;">
          <p class="s11"
            style="font-weight: bold; padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">DESCUENTOS POR ESTAMPILLAS:
          </p>
        </td>
        <td style="width:100pt;">
          <p class="s11" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: center;"></p>
        </td>
        <td style="width:100pt;">
          <p class="s9" style="text-indent: 0pt;text-align: right;"></p>
        </td>
      </tr>
      <tr *ngFor="let estampilla of pago.estampillas index as t">
        <td style="width:50pt;">
          <p class="s11"
            style="font-weight: 500; padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
            <li style="margin-left: 5pt;">{{estampilla.descripcion}} </li>
          </p>
        </td>
        <td style="width:100pt;">
          <p class="s11" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: center;">
            {{estampilla.porcentaje}}% </p>
        </td>
        <td style="width:100pt;">
          <p class="s9" style="text-indent: 0pt;text-align: right;">{{(estampilla.valor * -1) | currency:'COP'}} </p>
        </td>
      </tr>
      <tr style="height:15pt;">
        <td style="width:50pt;">
          <p class="s11"
            style="font-weight: bold; padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">NETO A
            PAGAR:</p>
        </td>
        <td style="width:100pt;">
          <p class="s11" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: center;"></p>
        </td>
        <td style="width:100pt;">
          <p class="s9" style="text-indent: 0pt;text-align: right;">{{pago.valorTotal | currency:'COP'}}</p>
        </td>
      </tr>
    </table>
    <br>
    <div class="mb-3">
      <a style="margin-right: 5px;" class="btn btn-secondary" (click)="redireciona()">Anterior</a>
      <button type="submit" class="btn btn-primary"
        *ngIf="pago.estado === 'SIN_PAGO' || pago.estado === 'DECLINADO'">Realizar pago electrónico</button>
      <button type="submit" class="btn btn-primary"
        *ngIf="pago.estado === 'ERROR' || pago.estado === 'ABIERTO' || pago.estado === 'FALLIDO'">Reintentar pago
        electrónico</button>
      <a class="btn btn-primary" (click)="consultarPago()" *ngIf="pago.estado === 'PENDIENTE'">Consultar pago
        electrónico</a>
    </div>

  </form>

  <ng-template [ngIf]="mostrarModal">
    <div role="alert" class="alert alert-light text-center animate__animated animate__fadeIn animate__faster"
      style="top: 100px;">
      <br>
      <h3>No de radicado {{noradicado}}</h3>
      <p>mensajeEstado</p>
      <button type="submit" class="btn btn-primary " (click)="cerrar()">Aceptar</button>
    </div>

  </ng-template>
</div>