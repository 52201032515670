import { Injectable, ViewChild, ElementRef } from '@angular/core';
import {
  AbstractControl, FormGroup, ValidationErrors, FormArray, ValidatorFn, FormControl,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { of } from 'rxjs';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '/';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : '';
  }
}



@Injectable({
  providedIn: 'root',
})

export class ComponentesService {
  private datos: any | undefined;
  formModal: any | undefined;
  estados: boolean = false;
  rutas: string = '';
  myGuid = uuidv4();
  enviodata: any = false;
  //public show: boolean = false;

  // declaracion de sujeto para multiples observables  Fuentes de cadenas observables
  private envios = new Subject<any>();
  // Flujos de cadenas observables
  // Observable de tipo string
  eventos$ = this.envios.asObservable();
  // Comandos de mensajes de servicio

  constructor() {
  }

  /*implementacion de validaciond e modals */
  abrirmodal() {
    this.formModal.show();
  }

  cerrarModal() {
    this.formModal.hide();
  }

  //TODO:emicion de eventos flujo de compra
  emitircambio(change: any) {
    this.envios.next(change);
  }

  /*validaciones de formularios listas negras campo personalizado validacion de cedula*/

  codigonit(tipo: string, numero: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const tipo1 = formGroup.get(tipo)!.value;
      const numero1 = formGroup.get(numero)!.value;

      //let palabras = alfanumertico(numero1);
      let numerosolo = /^[0-9]+$/;
      const str = String(numero);
      let numeros = /^0[0-9].*$/;
      let regex = /^[a-zA-Z0-9]{7,9}$/;
      let valida = new RegExp('([A-Za-z]+[0-9]|[0-9]+[A-Za-z])[A-Za-z0-9]*');

      switch (tipo1) {
        case "CC":
          if (!numero1 || numero1.length <= 3 || numero1.length > 12 || numero1 == '') {
            formGroup.get(numero)!.setErrors({ sincodigo: true });
            return { sincodigo: true };
          }
          break;

        case "CE":
          if (!numero1 || numero1.length <= 3 || numero1.length > 12 || numero1 == '') {
            formGroup.get(numero)!.setErrors({ sincodigo: true });
            return { sincodigo: true };
          }

          break;

        case "PP":
          if (!numero1 || !regex.test(numero1) || numero1.length <= 3 || numero1.length > 9 || numero1 == '') {
            formGroup.get(numero)!.setErrors({ sincodigo: true });
            return { sincodigo: true };
          }
          break;

        case "":

          if (!numero1 || numero1 == '') {
            formGroup.get(numero)!.setErrors({ sincodigo: true });
            return { sincodigo: true };
          }
          break;
      }

      if (
        (tipo1 == 'CC' && !numero1.match(numerosolo)) ||
        numeros.test(str)
      ) {
        formGroup.get(numero)!.setErrors({ sincodigo: true });
        return { sincodigo: true };
      }

      if (
        tipo1 == 'CE' && valida.test(str)
      ) {
        formGroup.get(numero)!.setErrors({ sincodigo: true });
        return { sincodigo: true };
      }

      formGroup.get(numero)!.setErrors(null);
      return null;
    };
  }

  /*implementacion de validaciones de minimo una politica debe estar selecionada en el slide de politcas a selecionar modulos ssps*/

  /*validador si el check es mayor a uno*/
  validar(min = 1) {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      if (formArray instanceof FormArray) {
        const totalSelected = formArray.controls
          .map((control) => control.value)
          .reduce((prev, next) => (next ? prev + next : prev), 0);
        return totalSelected <= 1 ? null : { required: true };
      }

      throw new Error('formArray no es una instancia  de FormArray');
    };

    return validator;
  }

  /*funcion de checks minimos uno*/
  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      if (formArray instanceof FormArray) {
        const totalSelected = formArray.controls
          .map((control) => control.value)
          .reduce((prev, next) => (next ? prev + next : prev), 0);
        return totalSelected >= min ? null : { required: true };
      }

      throw new Error('formArray no es una instancia  de FormArray');
    };

    return validator;
  }

  /*validar si los corrreos son iguales modulo info basica ssps*/

  soniguales(campo1: string, campo2: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const correo = formGroup.get(campo1)!.value;
      const correo1 = formGroup.get(campo2)!.value;

      if (correo !== correo1 || correo1 == '') {
        formGroup.get(campo2)!.setErrors({ noiguales: true });
        return { noiguales: true };
      }

      formGroup.get(campo2)!.setErrors(null);
      return null;
    };

    /* Validador de datalist*/
  }

  validalist(indicativo: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const indi = formGroup.get(indicativo)!.value;
      let numerosolo = /^[0-9]+$/;
      let valida = new RegExp('[a-zA-Z][a-zA-Z ]+');
      var numlet = new RegExp('([A-Za-z]+[0-9]|[0-9]+[A-Za-z])[A-Za-z0-9]*');
      let comas = /^[0-9,]*$/g;

      if (
        indi && numerosolo.test(indi) == false
      ) {
        formGroup.get(indicativo)!.setErrors({ vacioindi: true });
        return { vacioindi: true };
      }

      formGroup.get(indicativo)!.setErrors(null);
      return null;
    };
  }

  validadigito(indicativo: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const indi = formGroup.get(indicativo)!.value;

      function getNumberLength(num: any) {
        return Math.ceil(Math.log10(num + 1));
      }

      const str = String(indi);
      var numeros = /^0[0-9].*$/;

      if (indi > 9 || indi == '' || indi == null) {
        formGroup.get(indicativo)!.setErrors({ vacioindi: true });
        return { vacioindi: true };
      }

      formGroup.get(indicativo)!.setErrors(null);
      return null;
    };
  }

  minimos(numero: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      let numerosolo = /^[0-9]+$/;
      const indi = formGroup.get(numero)!.value;
      const numeros = String(indi);

      let comas = /[^.,]/;

      function getNumberLength(num: any) {
        return Math.ceil(Math.log10(num + 1));
      }

      let valor = getNumberLength(indi);

      if (
        valor < 4 ||
        valor > 11 ||
        indi == '' ||
        indi == null ||
        !numeros.match(indi)
      ) {
        formGroup.get(numero)!.setErrors({ vacioindi: true });
        return { vacioindi: true };
      }

      formGroup.get(numero)!.setErrors(null);
      return null;
    };
  }

  digitoChequeo(numero: string, digito: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      var nit = formGroup.get(numero)!.value;
      var digitoForm = formGroup.get(digito)!.value;
      if (isNaN(nit) || nit === "") {
        formGroup.get(numero)!.setErrors({ novalido: true });
        return { novalido: true };
      };

      if (isNaN(digitoForm) || digitoForm === "") {
        formGroup.get(digito)!.setErrors({ novalido: true });
        return { novalido: true };
      };

      var digitoChequeo = this.calcularDigitoChequeo(nit)
      if (+digitoChequeo !== +digitoForm) {
        formGroup.get(digito)!.setErrors({ digitonovalido: true });
        return { digitonovalido: true };
      }

      formGroup.get(numero)!.setErrors(null);
      formGroup.get(digito)!.setErrors(null);
      return null;
    };
  }

  calcularDigitoChequeo(nit: string) {
    if(nit === undefined || nit === null || nit === ""){
      return ""
    }
    var vpri: any,
      x: any,
      y: any,
      z: any;

    // Procedimiento
    vpri = new Array(16);
    z = nit.length;
    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    y = 0;
    for (var i = 0; i < z; i++) {
      y = (nit.substr(i, 1));

      x += (y * vpri[z - i]);
    }

    y = x % 11;
    return (y > 1) ? 11 - y : y;
  }

  /*validacion de direcciones*/

  minimosd(numero: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      let numerosolo = /^[0-9]+$/;
      const indi = formGroup.get(numero)!.value;
      const numeros = String(indi);

      let valida = new RegExp('^[^,]+$');

      function getNumberLength(num: any) {
        return Math.ceil(Math.log10(num + 1));
      }

      let valor = getNumberLength(indi);

      if (
        valor < 1 ||
        valor > 3 ||
        indi == '' ||
        indi == null ||
        !numeros.match(indi)
      ) {
        formGroup.get(numero)!.setErrors({ vacioindi: true });
        return { vacioindi: true };
      }

      formGroup.get(numero)!.setErrors(null);
      return null;
    };
  }

  //minismos calles direciones
  minimosc(numero: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      let numerosolo = /^[0-9]+$/;
      const indi = formGroup.get(numero)!.value;
      const numeros = String(indi);

      let comas = /[^.,]/;

      function getNumberLength(num: any) {
        return Math.ceil(Math.log10(num + 1));
      }

      let valor = getNumberLength(indi);

      if (
        valor < 1 ||
        valor > 10 ||
        indi == '' ||
        indi == null ||
        !numeros.match(indi)
      ) {
        formGroup.get(numero)!.setErrors({ vacioindi: true });
        return { vacioindi: true };
      }

      formGroup.get(numero)!.setErrors(null);
      return null;
    };
  }

  //validaciones de commas
  commas(event: any) {
    var e = event || window.event;
    var key = e.keyCode || e.which;

    let respuesta;

    if (key === 110 || key === 190 || key === 188) {
      //    this.comasnit = true;
      return of(true);
    } else {
      //  this.comas = false;
      return of(false);
    }
  }

  //validaciones de codigo actividad economica

  economica(numero: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      let numerosolo = /^[0-9]+$/;
      const indi = formGroup.get(numero)!.value;
      const numeros = String(indi);

      function getNumberLength(num: any) {
        return Math.ceil(Math.log10(num + 1));
      }

      let valor = getNumberLength(indi);

      if (
        valor < 1 ||
        valor > 5 ||
        indi == '' ||
        indi == null ||
        !numeros.match(indi)
      ) {
        formGroup.get(numero)!.setErrors({ vacioindi: true });
        return { vacioindi: true };
      }

      formGroup.get(numero)!.setErrors(null);
      return null;
    };
  }

  /*validacion de objetos servicio*/

  comparacion(usu: any, datos: any) {
    const shallowComparison =
      Object.keys(usu).length === Object.keys(datos).length &&
      (Object.keys(usu) as (keyof typeof usu)[]).every((key) => {
        return (
          Object.prototype.hasOwnProperty.call(datos, key) &&
          usu[key] === datos[key]
        );
      });

    return shallowComparison;
  }
}
