import { JsonpInterceptor } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { environment as env } from "src/environments/environment"

@Component({
    selector: 'app-confirmacion-alerta',
    templateUrl: './confirmacion-alerta.component.html',
    styleUrls: ['./confirmacion-alerta.component.css'],
    standalone: false
})
export class ConfirmacionAlertaComponent implements OnInit {
  @Input() public mensaje: any
  @Input() public titulo: any
  @Input() public tituloBoton: any
  @Input() public tituloBoton2: any

  constructor(private alert: AlertsService,
    private router: Router,
    private componets: ComponentesService,
    private ssps: SspsService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  //TODO:cerrar modal
  close() {
    this.activeModal.close(false);
  }

  confirmar() {
    this.activeModal.close(true);
  }

  cancelar() {
    this.activeModal.close(false);
  }
}
