<div class=" animate__animated animate__fadeInLeftBig animate__faster">

  <h5 class="titulosintu2 mt-3">Facturación electrónica</h5>
  <br>
  <div class="mb-3">
    <div class="mb-1 checkbox-wrapper-19">
      <input type="checkbox" class="form-check-input" (change)="facturaOtroChange($event)" id="facturaOtro"
        [(ngModel)]="facturaOtro"
        [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
      <label for="facturaOtro" class="check-box"></label>
      <label class="form-check-label" for="exampleCheck1">
        <p>¿Deseas que la factura se genere a nombre de otra persona/Empresa?</p>
      </label>
    </div>
  </div>
  <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="mb-3" *ngIf="tipoCliente !== 'natural'">
          <label for="tipoIdentificacion" class="form-label">Tipo de identificación *</label>
          <select [(ngModel)]="facturacion.tipo" class="form-select" (change)="changeTipoIdentificacion($event)"
            id="tipoIdentificacion"
            formControlName="tipo" [class.is-invalid]="camposvalidos('tipo')">
            <option class="form-control" value="NI">NIT</option>
          </select>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de documento</div>
        </div>
        <div class="mb-3" *ngIf="tipoCliente === 'natural'">
          <label for="tipoIdentificacion" class="form-label">Tipo de identificación *</label>
          <select [(ngModel)]="facturacion.tipo" class="form-select" formControlName="tipo"
            id="tipoIdentificacion"
            [class.is-invalid]="camposvalidos('tipo')" (change)="changeTipoIdentificacion($event)">
            <option class="form-control" value="CC">Cédula de Ciudadanía</option>
            <option class="form-control" value="CE">Cédula de Extranjería</option>
            <option class="form-control" value="PP">Pasaporte</option>
            <option class="form-control" value="TI">Tarjeta de Identidad T.I.</option>
            <option class="form-control" value="NI">NIT</option>
            <option class="form-control" value="PT">Permiso Permanencia Temporal P.P.T</option>
          </select>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de documento</div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="mb-3">
          <label for="numeroIdentificacion" class="form-label">Número de identificación *</label>
          <input [(ngModel)]="facturacion.numero" type="text" class="form-control"
            placeholder="Número de identificación" formControlName="numero" [class.is-invalid]="camposvalidos('numero')"
            #inputRefNumero>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('numero')">¡Identificación no válido!</div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-12" *ngIf="facturacion.tipo === 'NI'">
        <div class="mb-3">
          <label class="form-label">Número de verificación</label>
          <input [(ngModel)]="facturacion.codigo" type="text" class="form-control" placeholder="Número de verificación"
            formControlName="codigo" [class.is-invalid]="camposvalidos('codigo')" appDigito maxLength="1">
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('codigo')">Número de verificación no válido</div>
        </div>
      </div>
    </div>
    <div class="mb-3" *ngIf="facturacion.tipo !== 'NI'">
      <div class="row">
        <div class="col">
          <label class="form-label">Nombres *</label>
          <input [(ngModel)]="facturacion.nombres" type="text" class="form-control" placeholder="Nombres completos"
            formControlName="nombres" [class.is-invalid]="camposvalidos('nombres')" #inputRefNombres>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('nombres')">
            Nombres no son válidos</div>
        </div>
        <div class="col">
          <label class="form-label">Apellidos *</label>
          <input type="text" [(ngModel)]="facturacion.apellidos" class="form-control" placeholder="Apellidos completos"
            formControlName="apellidos" [class.is-invalid]="camposvalidos('apellidos')" #inputRefApellidos>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('apellidos')">Apellidos no son válidos</div>
        </div>
      </div>
    </div>
    <div class="mb-3" *ngIf="facturacion.tipo === 'NI'">
      <div class="row">
        <div class="col">
          <label class="form-label">Razón social *</label>
          <input [(ngModel)]="facturacion.razon" type="text" class="form-control" placeholder="Razón social"
            formControlName="razon" [class.is-invalid]="camposvalidos('razon')" #inputRefRazon>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('razon')">
            ¡La razón social no es válida!</div>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <div class="mb-3">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12 col-6">
            <label class="form-label">Tipo dirección *</label>
            <select (change)="changeTipoDireccion($event)" class="form-select" formControlName="tipoDireccion"
              [class.is-invalid]="camposvalidos('tipoDireccion')" [(ngModel)]="direccion.tipo"
              placeholder="seleccionar">
              <option *ngFor="let tipo of tipoDireccion" [value]="tipo.name">{{tipo.name}}</option>
            </select>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('tipoDireccion')">Tipo no válido</div>
          </div>
          <div class="col-lg-9 col-md-6 col-sm-12 col-6" *ngIf="direccion.tipo === 'Otro'">
            <label class="form-label">Dirección *</label>
            <input type="text" class="form-control" placeholder="Ej: Finca Guayabita Kilometro 4 vía Chía"
              formControlName="numeroOtroDireccion" [class.is-invalid]="camposvalidos('numeroOtroDireccion')"
              #inputRefNumeroOtroDireccion [(ngModel)]="direccion.tipoOtroNumero">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('numeroOtroDireccion')">Campo no válido</div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-6" *ngIf="direccion.tipo !== 'Otro'">
            <label class="form-label">{{direccion.tipo}}&nbsp; *</label>
            <input type="text" class="form-control" placeholder="Ej:18" formControlName="tipoNumeroDireccion"
              [class.is-invalid]="camposvalidos('tipoNumeroDireccion')" #inputRefTipoNumeroDireccion
              [(ngModel)]="direccion.tipoNumero">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('tipoNumeroDireccion')">Campo no válido</div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-6 mb-3" *ngIf="direccion.tipo !== 'Otro'">
            <label class="form-label">Número *</label>
            <input type="text" class="form-control" placeholder="Ej:79A" formControlName="numeroDireccion"
              [class.is-invalid]="camposvalidos('numeroDireccion')" #inputRefNumeroDireccion
              [(ngModel)]="direccion.numero">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('numeroDireccion')">Número no válido</div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-6" *ngIf="direccion.tipo !== 'Otro'">
            <label class="form-label">&nbsp;</label>
            <input type="text" class="form-control" placeholder="Ej:332" formControlName="numeroDireccion2"
              [class.is-invalid]="camposvalidos('numeroDireccion2')" #inputRefNumeroDireccion2
              [(ngModel)]="direccion.numero2">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('numeroDireccion2')">Número no válido</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <div class="mb-3">
        <div class="row">
          <div class="col">
            <label class="form-label">{{direccion.tipo === "Otro" ? "Barrio/Vereda/Corregimiento" : "Barrio"}}</label>
            <input type="text" class="form-control" placeholder="Ej: Sevilla" formControlName="barrioDireccion"
              [class.is-invalid]="camposvalidos('barrioDireccion')" [(ngModel)]="direccion.barrio"
              #inputRefBarrioDireccion>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('barrioDireccion')">Barrio no válido.</div>
          </div>
          <div class="col">
            <label class="form-label">Piso/Apartamento/Torre/Edificio/Casa</label>
            <input type="text" class="form-control" placeholder="Ej: torre 5 apto 302"
              formControlName="adicionalDireccion" [class.is-invalid]="camposvalidos('adicionalDireccion')"
              [(ngModel)]="direccion.adicional" #inputRefAdicionalDireccion>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('adicionalDireccion')">Direccion inválida</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col">
        <label class="form-label">Departamento *</label>
        <select class="form-select" formControlName="departamento" [class.is-invalid]="camposvalidos('departamento')"
          (change)="changeDepartamento($event)" [(ngModel)]="facturacion.departamento"
          placeholder="selecione un departamento">
          <option *ngFor="let muni of depar" [value]="muni.codigo_dep">{{muni.Departamento}}</option>
        </select>
        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
          *ngIf="camposvalidos('departamento')">Departamento no válido, selecciona una opción del listado</div>
      </div>
      <div class="col">
        <label class="form-label">Municipio *</label>
        <select class="form-select" formControlName="municipio" [class.is-invalid]="camposvalidos('municipio')"
          [(ngModel)]="facturacion.municipio" placeholder="selecione un municipio">
          <option class="form-control" *ngFor="let muni of munifi" [value]="muni.Codigo_mu">{{muni.Municipio}}
          </option>
        </select>
        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
          *ngIf="camposvalidos('municipio')">Municipio no válido, selecciona una opción del listado</div>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label mt-3">Teléfono contacto</label>
      <div class="row">

        <div class="col">
          <input class="form-control" list="browsers" formControlName="indicativo" placeholder="indicativo"
            [(ngModel)]="facturacion.indicativo" [class.is-invalid]="camposvalidos('indicativo')"
            (keyup)="noPuntoComa($event)">
          <datalist id="browsers">
            <option *ngFor="let d of indicativos" [value]="d.indicativo">{{d.nomDepartamento}}</option>
          </datalist>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('indicativo')">Indicativo no válido, selecciona una opción de la lista</div>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comas">El campo
            no
            puede tener decimales</div>
        </div>

        <div class="col">
          <input type="number" class="form-control" placeholder="teléfono" formControlName="telefono"
            [class.is-invalid]="camposvalidos('telefono')" #datos2 [(ngModel)]="facturacion.telefono"
            value="{datos.toString()}" (keyup)="quitarceros2(datos2.value)" (keyup)="noPunto($event, 'comastel2')">
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('telefono')">Teléfono no válido, debe ser un número de 7 dígitos</div>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastel2">El campo
            no puede tener comas</div>

        </div>
        <div class="col">
          <input type="number" class="form-control" placeholder="extensión" formControlName="extension"
            [class.is-invalid]="camposvalidos('extension')" #datos3 [(ngModel)]="facturacion.extension"
            value="{datos3.toString()}" (keyup)="quitarceros3(datos3.value)" (keyup)="noPunto($event, 'comastex')">
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('extension')">Extension no válido</div>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastex">El campo
            no puede tener comas</div>
        </div>

      </div>

    </div>

    <div class="mb-3">

      <label class="form-label">Celular *</label>
      <input type="number" class="form-control" placeholder="celular" formControlName="celular"
        [class.is-invalid]="camposvalidos('celular')" #datos4 [(ngModel)]="facturacion.celular"
        value="{datos4.toString()}" (keyup)="quitarceros4(datos4.value)" (keyup)="noPunto($event, 'comastel')">
      <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
        *ngIf="camposvalidos('celular')">Celular no válido, ingresar número de 10 dígitos</div>

      <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastel">El campo no
        puede tener comas</div>
    </div>

    <div class="mb-3">

      <div class="col">
        <label class="form-label">Correo electrónico para recepción de factura electrónica *</label>
        <input type="text" class="form-control" placeholder="correo electrónico" formControlName="correo"
          [(ngModel)]="facturacion.correo" [class.is-invalid]="camposvalidos('correo')" #inputRefCorreo>
        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
          *ngIf="camposvalidos('correo')">Correo no válido, debe tener la estructura correo&#64;dominio.com(.co,.es..)</div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col" *ngIf="facturacion.tipo === 'NI'">
        <label class="form-label">Tipo de Capital *</label>
        <select [(ngModel)]="facturacion.tipoCapital" (change)="filtrarTipoRegimen($event, true)" class="form-select"
          formControlName="tipoCapital" [class.is-invalid]="camposvalidos('tipoCapital')">
          <option class="form-control" value="" disabled></option>
          <option *ngFor="let tipoCapital of tiposCapital" [value]="tipoCapital">{{tipoCapital}}</option>
        </select>
        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
          *ngIf="camposvalidos('tipoCapital')">Capital no válido</div>
      </div>
      <div class="col">
        <label class="form-label">Régimen Tributario *</label>
        <select class="form-select" formControlName="regimenTributario"
          [class.is-invalid]="camposvalidos('regimenTributario')" [(ngModel)]="facturacion.regimenTributario">
          <option class="form-control" value="" disabled></option>
          <option *ngFor="let regimen of regimenTributario" [value]="regimen">{{regimen}}</option>
        </select>

        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
          *ngIf="camposvalidos('regimenTributario')">Regimen tributario no válido</div>
      </div>
    </div>
    <div class="mb-3">
      <div class="mb-1 checkbox-wrapper-19">
        <input type="checkbox" class="form-check-input" (change)="mostrarCampoAgente($event)"
          formControlName="ayudaAgente" id="ayudaAgente" [(ngModel)]="ayudaAgente">
        <label for="ayudaAgente" class="check-box"></label>
        <label class="form-check-label">
          <p>¿Recibiste ayuda de parte de un asesor de Certicámara?</p>
        </label>
      </div>
      <div class="col" *ngIf="ayudaAgente">
        <label class="form-label">Código agente *<i class="fas fa-info-circle icon-primary"
            ngbTooltip="Código del asesor de Certicamara que te ayudó a realizar la solicitud"></i></label>
        <input type="text" class="form-control" placeholder="Código agente" formControlName="agente"
          [(ngModel)]="facturacion.agente" [class.is-invalid]="camposvalidos('agente')" #inputRefAgente>
        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
          *ngIf="camposvalidos('agente')">Código de agente no valido, formato debe ser XX000. Ejemplo AA000</div>
      </div>
    </div>
    <div class="margen2_1" style="padding-bottom:0px ;">
      <div class="mb-3" style="line-height: 10px; margin-bottom: 5px !important;">
        <label for="label" class="form-label">* Campos obligatorios</label>
      </div>
    </div>
    <div class="mb-4">
      <button type="button" class="btn btn-secondary" style="margin: 15px;" (click)="redireciona()">Anterior</button>
      <button *ngIf="usuario.solicitudFinalizada !== 'ASIGNADO'" type="submit" class="btn btn-primary">{{actualizacion ?
        "Guardar" : "Siguiente"}}</button>
      <button *ngIf="usuario.solicitudFinalizada === 'ASIGNADO'" type="submit"
        class="btn btn-primary">Continuar</button>
    </div>
  </form>

</div>