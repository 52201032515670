import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentesService } from '../../../domain/servicios/componentes.service';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
declare var window: any;

@Component({
    selector: 'app-certimail-home',
    templateUrl: './certimail-home.component.html',
    styleUrls: ['./certimail-home.component.css'],
    standalone: false
})
export class CertimailHomeComponent implements OnInit {

  constructor(private router:Router,
              private componentesService:ComponentesService,
              private alert:AlertsService
              ) { }

  ngOnInit(): void {
    this.alert.showFiels();
   //this.alert.showSssps();
  }



  /*cerra Modal*/ 
  abrirmodal() {
  
    this.alert.showFiels();
 // this.componentesService.abrirmodal();  
  }

}
