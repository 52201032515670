import { Component, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';

@Component({
  selector: 'app-revocar-certificado',
  templateUrl: './revocar-certificado.component.html',
  styleUrls: ['./revocar-certificado.component.css']
})
export class RevocarCertificadoComponent implements OnInit {

  constructor(private alert: AlertsService, private ssps: SspsService) { }

  ngOnInit(): void {
    this.alert.closesFiels()
    this.alert.showFieldsRevocarCertificado();
  }
}
