<div class="animate__animated animate__fadeIn animate__fast">
       <div class="modal-header">
              <h4 style="text-align: center;" *ngIf="certificadoSeleccionado === null">REVOCACIÓN EN LINEA</h4>
              <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
       </div>
       <div class="margen2">
              <div class="mb-3" *ngIf="certificadoSeleccionado === null">
                     <span>La revocación es el medio por el cual se cancelará e inhabilitará el Certificado
                            Digital y se dará por terminado su periodo de confiabilidad, al presentarse alguna de
                            las causas establecidas en la Declaración de Prácticas de Certificación. </span>
              </div>
              <div class="mb-3" *ngIf="false">
                     <span>Conozca como generar el proceso de revocación en línea &nbsp;</span>
                     <span class="btn btn-secondary" style="border: none !important; opacity: 100 !important;">
                            <a style="color: white; text-decoration: none; border: none !important; outline: none !important;"
                                   href={{urlManualRevocacion}} target="_blank">
                                   <i class="fa-solid fa-download fa-xl"></i>
                                   Descargar manual
                            </a>
                     </span>
              </div>
              <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()" id="form1" *ngIf="!identidadValidada">
                     <div class="mb-3">
                            Recuerde que el proceso de revocación en línea lo debe realizar única y exclusivamente el
                            titular del certificado de firma digital.
                            <br>
                            <br>
                            <span style="font-weight: 600;">
                                   Revoque su certificado en línea así:
                            </span>
                            <ol>
                                   <li>
                                          Ingrese su tipo identificación, número de identificación y fecha de
                                          expedición.
                                   </li>
                                   <li>Oprima el botón, "Validar identidad"</li>
                                   <li>Seleccione como desea validar su identidad, mediante preguntas de historial
                                          crediticio o verificación facial</li>
                                   <li>Conteste las preguntas del cuestionario de historial crediticio y/o verifique su
                                          identidad mediante reconocimiento facial.
                                   </li>
                                   <li>Seleccione el certificado que desea revocar y de clic en el botón Revocar.</li>
                            </ol>
                     </div>

                     <div class="mb-3">
                            <div class="row">
                                   <div class="col">
                                          <label class="form-label">Tipo de identificación</label>
                                          <select [(ngModel)]="usuarioValida.tipo" class="form-select"
                                                 formControlName="tipo" [class.is-invalid]="camposvalidos('tipo')">
                                                 <option class="form-control" value="CC">Cédula de Ciudadanía</option>
                                                 <option class="form-control" value="CE">Cédula de Extranjería</option>
                                                 <option class="form-control" value="PP">Pasaporte</option>
                                          </select>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de documento
                                          </div>
                                   </div>
                                   <div class="col">
                                          <label class="form-label">¡Número de identificación!</label>
                                          <input [(ngModel)]="usuarioValida.numero" type="text" class="form-control"
                                                 placeholder="Número de identificación" formControlName="numero"
                                                 [class.is-invalid]="camposvalidos('numero')" #inputRefNumero>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('numero')">¡Identificación no válido!</div>
                                   </div>
                                   <div class="col">
                                          <label class="form-label">Fecha de expedición</label>
                                          <div class="input-group">
                                                 <input class="form-control" placeholder="dd/mm/aaaa"
                                                        formControlName="fechaExpDocumento"
                                                        [(ngModel)]="fechaExpDocumento" mask="99/99/9999" appInputMask
                                                        [class.is-invalid]="camposvalidos('fechaExpDocumento')"
                                                        [maxDate]="maxDate" [minDate]="minDate" ngbDatepicker
                                                        #d="ngbDatepicker"
                                                        (keyup)="fechas(fechaExpDocumento, 'fechaExpDocumento')" />
                                                 <button class="btn btn-primary  bi bi-calendar3" (click)="d.toggle()"
                                                        type="button">
                                                        <i class="far fa-calendar-alt"></i>
                                                 </button>
                                          </div>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="statusFechaExpDocumento || camposvalidos('fechaExpDocumento')">
                                                 Fecha de expedición documento no
                                                 válida</div>
                                   </div>
                            </div>
                     </div>
                     <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" [disabled]="formaForm.invalid">Validar
                                   identidad</button>
                     </div>
              </form>
              <div class="mb-3" *ngIf="identidadValidada">
                     <h4 style="text-align: center;" *ngIf="certificadoSeleccionado === null">LISTADO CERTIFICADOS</h4>
                     <h4 style="text-align: center;" *ngIf="certificadoSeleccionado !== null">DATOS DEL CERTIFICADO
                            <b>{{certificadoSeleccionado.username | uppercase }}</b>
                     </h4>
                     <div class="modal-footer" *ngIf="certificados.length === 0">
                            <button type="button" style="margin: 0 auto;" class="btn btn-primary"
                                   (click)="consultarCertificadosRevocacion()">Consultar certificados</button>
                     </div>
                     <div style="overflow: auto; max-height: 58vh;">
                            <table class="table table-striped table-bordered table-hover" style="margin: 0 auto;"
                                   *ngIf="certificados.length > 0 && certificadoSeleccionado === null">
                                   <thead>
                                          <tr>
                                                 <th scope="col" class="text-center listas"
                                                        *ngFor="let item of listadoHeaders">
                                                        {{ item.title | uppercase }}
                                                 </th>
                                                 <th class="text-center listas"></th>
                                          </tr>
                                   </thead>
                                   <tbody>
                                          <!--   <tr *ngFor="let data of datos | slice: (page-1) * pageSize : page * pageSize">-->
                                          <tr class="table-light" *ngFor="let item of certificados">
                                                 <td class="text-center animate__animated animate__fadeIn"
                                                        *ngFor="let header of listadoHeaders">
                                                        <span>{{ item[header.code] }}</span>
                                                 </td>
                                                 <td class="text-center">
                                                        <span class="btn btn-primary" (click)="detalleCertificado(item)"
                                                               style="border: none !important; opacity: 100 !important;">
                                                               REVOCAR
                                                        </span>
                                                 </td>
                                          </tr>
                                   </tbody>
                            </table>
                            <table class="table table-striped table-bordered table-hover" style="margin: 0 auto;"
                                   *ngIf="certificadoSeleccionado !== null">
                                   <tbody>
                                          <tr class="table-light" *ngFor="let item of listadoDetalle">
                                                 <td class="text-center animate__animated animate__fadeIn"
                                                        *ngIf="certificadoSeleccionado[item.code] !== null && certificadoSeleccionado[item.code] !== undefined">
                                                        <span>{{ item.title }}</span>
                                                 </td>
                                                 <td class="text-center animate__animated animate__fadeIn"
                                                        *ngIf="certificadoSeleccionado[item.code] !== null && certificadoSeleccionado[item.code] !== undefined">
                                                        <span>{{ certificadoSeleccionado[item.code] }}</span>
                                                 </td>
                                          </tr>
                                          <tr class="table-light">
                                                 <td class="text-center animate__animated animate__fadeIn">
                                                        <span>Motivo revocación</span>
                                                 </td>
                                                 <td class="text-center">
                                                        <select (change)="changeMotivo($event)" class="form-select"
                                                               [(ngModel)]="motivoSeleccionadoId">
                                                               <option class="form-control" value="" disabled></option>
                                                               <option *ngFor="let motivo of motivosRechazo"
                                                                      [value]="motivo.motivoRevocacionId">
                                                                      {{motivo.descCortaMotivo}}</option>
                                                        </select>

                                                 </td>
                                          </tr>
                                          <tr class="table-light" *ngIf="motivoSeleccionadoId !== null">
                                                 <td class="text-center animate__animated animate__fadeIn">
                                                        <span>Descripción motivo revocación</span>
                                                 </td>
                                                 <td class="text-center">
                                                        <span>{{ motivoSeleccionado.descripcionMotivo }}</span>
                                                 </td>
                                          </tr>
                                   </tbody>
                            </table>
                     </div>
                     <div class="modal-footer" *ngIf="certificados.length > 0 && certificadoSeleccionado === null ">
                            <button type="button" style="margin: 0 auto;" class="btn btn-primary"
                                   (click)="volverFormulario()">Realizar otra consulta</button>
                     </div>
                     <div class="mb-4" *ngIf="certificadoSeleccionado !== null" style="text-align: center;">
                            <button type="button" class="btn btn-secondary" style="margin: 15px;"
                                   (click)="cancelarSeleccion()">Cancelar</button>
                            <button (click)="revocarCertificado()" class="btn btn-primary">Revocar
                                   certificado</button>
                     </div>
              </div>
       </div>
</div>