import { Component, OnInit } from '@angular/core';
import { ComponentesService } from './domain/servicios/componentes.service';
import { SspsService } from './domain/servicios/ssps.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'certicámara';
  subscription!: Subscription;

  constructor(private components: ComponentesService,
    private ssps: SspsService
  ) {
    //TODO:muestra elementos del header y ffoter
    //  this.components.show = false;

  }
  ngOnInit(): void {
    this.ssps.checkIfFirstTab();
  }
}
