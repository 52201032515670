import { JsonpInterceptor } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { environment as env } from "src/environments/environment"

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  @Input() public mensaje: any
  @Input() public redirigir: any
  @Input() public titulo: any
  @Input() public identidadValidada: boolean | undefined
  @Input() public urlRedireccion: any
  @Input() public tituloBoton: any
  solicitud = ""
  constructor(private alert: AlertsService,
    private router: Router,
    private componets: ComponentesService,
    private ssps: SspsService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.solicitud = JSON.parse(this.ssps.getLocalStorage("solicitudId")!)

    if (this.mensaje?.includes("Http failure response for")) {
      this.mensaje = "No fue posible conectar a los servicios, verifique su conexión a internet."
      this.titulo = "¡Sin conexión a internet!"
    }
  }

  //TODO:cerrar modal
  close() {
    this.activeModal.close(false);
    if (this.redirigir) {
      if (this.urlRedireccion !== "") {
        this.router.navigate([this.urlRedireccion]);
      }
      else {
        localStorage.clear()
        let externalUrl = this.identidadValidada ? `${env.urlVentaDigital}?id=` + this.solicitud : `${env.urlWebCerti}`;
        window.location.replace(externalUrl);
      }
    }
  }
}
