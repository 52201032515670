<div class=" animate__animated animate__fadeInLeftBig animate__faster">
  <h5 *ngIf="usuario.dispo.toLowerCase().includes('token físico')" class="titulosintu2 mt-3">Datos de Entrega </h5>
  <h5 *ngIf="!usuario.dispo.toLowerCase().includes('token físico')" class="titulosintu2 mt-3">Datos de Envío </h5>
  <br>
  <div class="mb-3" *ngIf="validacionReusoToken">
    <p class="form-label">
      Estimado suscriptor, te recordamos que te encuentras en el proceso de compra mediante el reúso de token físico.
      Esto significa que continuarás utilizando el token que ya posees y no se realizará la entrega de un nuevo
      dispositivo físico.
    </p>
    <div class="mb-1 form-check">
      <div class="centrado">
        <table style="width: 100%;">
          <thead style="visibility: hidden;">
            <tr>
              <th>
              </th>
            </tr>
          </thead>
          <tr>
            <td>
              <div class="checkbox-wrapper-19">
                <input type="radio" class="form-check-input" value="3" name="checkReuso"
                  (change)="mecanisEntregaChange($event, 2)"
                  [checked]="usuario.dispo.toLowerCase() === 'reúso token físico'" [disabled]="pago.realizoPago === 'S'"
                  id="tokenFisico1" />
                <label for="tokenFisico1" class="check-box"></label>
                <label class="form-check-label" for="exampleCheck2">Si, tengo el token físico</label>
              </div>
              <!-- &nbsp;<span><i
                  class="fas fa-info-circle icon-primary"></i>&nbsp;&nbsp;<span
                  style="text-decoration: underline; font-weight: 600; cursor: pointer;"
                  (click)="habilitarVideoReuso(4)">Ver
                  más</span></span> -->
            </td>
            <td>
              <div class="checkbox-wrapper-19">
                <input type="radio" class="form-check-input" value="0" name="checkReuso"
                  (change)="mecanisEntregaChange($event, 0)" [checked]="usuario.dispo.toLowerCase() === 'token físico'"
                  [disabled]="pago.realizoPago === 'S'" id="tokenFisico2" />
                <label for="tokenFisico2" class="check-box"></label>
                <label class="form-check-label" for="exampleCheck2">No, necesito un token nuevo</label>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="mb-3" *ngIf="!entregaVirtual && usuario.dispo.toLowerCase() === 'token físico'">
    <div class="mb-1 checkbox-wrapper-19">
      <input type="checkbox" class="form-check-input" (change)="instalaciones($event)" id="exampleCheck1"
        [(ngModel)]="entregaCerti"
        [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
      <label for="exampleCheck1" class="check-box"></label>
      <label class="form-check-label">
        <p>¿Entrega en instalaciones de certicámara ?</p>
      </label>
    </div>
  </div>

  <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()">
    <div *ngIf="entregaCerti && usuario.dispo.toLowerCase() === 'token físico'">
      <div class="mb-3">
        <div class="col">
          <label class="text-danger">Compra y recoje</label>
        </div>
        <p class="form-label">
          Acercate a las instalaciones de Certicámara: Dirección: Carrera 7 # 26-20 Piso 18 - 19, Edificio Seguros
          Tequendama. 
          Horario de atención 8:00 a.m. a 5:00 p.m. de lunes a viernes.
        </p>
      </div>
    </div>
    <div *ngIf="entregaVirtual">
      <div class="mb-3">
        <div class="col">
          <label for="correoElectronico" class="form-label">Correo electrónico *</label>
          <input type="text" id="correoElectronico" class="form-control" placeholder="correo electrónico" formControlName="correo"
            [class.is-invalid]="camposvalidos('correo')" [(ngModel)]="correoEntrega" #inputRefCorreo>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('correo')">Correo no válido, debe tener la estructura correo&#64;dominio.com(.co,.es..)
          </div>
        </div>
      </div>
      <p style="color: #767676; font-size: 11pt;">
        *Al registrar tu correo electrónico, confirmas que es de tu propiedad y comprendes que este se usará para todas
        las
        notificaciones y fines relacionados con la emisión de tu firma digital. Por favor, asegúrate de que sea válido y
        accesible, pues modificar tu cuenta de correo en nuestros sistemas implicará costos adicionales. ¡Gracias por tu
        colaboración!
      </p>
    </div>
    <div class="mb-3" *ngIf="!entregaCerti && !entregaVirtual && usuario.dispo.toLowerCase() === 'token físico'">
      <div class="mb-3">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-6">
            <label class="form-label">Tipo dirección *</label>
            <select (change)="changeTipoDireccion($event)" class="form-select" formControlName="tipoDireccion"
              [class.is-invalid]="camposvalidos('tipoDireccion')" [(ngModel)]="direccion.tipo"
              placeholder="seleccionar">
              <option *ngFor="let tipo of tipoDireccion" [value]="tipo.name">{{tipo.name}}</option>
            </select>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('tipoDireccion')">Tipo no válido</div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-6" *ngIf="direccion.tipo === 'Otro'">
            <label class="form-label">Dirección *</label>
            <input type="text" class="form-control" placeholder="Ej: Finca Guayabita Kilometro 4 vía Chía"
              formControlName="numeroOtroDireccion" [class.is-invalid]="camposvalidos('numeroOtroDireccion')"
              #inputRefNumeroOtroDireccion [(ngModel)]="direccion.tipoOtroNumero">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('numeroOtroDireccion')">Campo no válido</div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-6" *ngIf="direccion.tipo !== 'Otro'">
            <label class="form-label">{{direccion.tipo}}&nbsp; *</label>
            <input type="text" class="form-control" placeholder="Ej:18" formControlName="tipoNumeroDireccion"
              [class.is-invalid]="camposvalidos('tipoNumeroDireccion')" #inputRefTipoNumeroDireccion
              [(ngModel)]="direccion.tipoNumero">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('tipoNumeroDireccion')">Campo no válido</div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-6" *ngIf="direccion.tipo !== 'Otro'">
            <label class="form-label">Número *</label>
            <input type="text" class="form-control" placeholder="Ej:79A" formControlName="numeroDireccion"
              [class.is-invalid]="camposvalidos('numeroDireccion')" #inputRefNumeroDireccion
              [(ngModel)]="direccion.numero">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('numeroDireccion')">Número no válido</div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-6" *ngIf="direccion.tipo !== 'Otro'">
            <label class="form-label">&nbsp;</label>
            <input type="text" class="form-control" placeholder="Ej:332" formControlName="numeroDireccion2"
              [class.is-invalid]="camposvalidos('numeroDireccion2')" #inputRefNumeroDireccion2
              [(ngModel)]="direccion.numero2">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('numeroDireccion2')">Número no válido</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3" *ngIf="!entregaCerti && !entregaVirtual && usuario.dispo.toLowerCase() === 'token físico'">
      <div class="mb-3">
        <div class="row">
          <div class="col">
            <label class="form-label">{{direccion.tipo === "Otro" ? "Barrio/Vereda/Corregimiento" : "Barrio"}}</label>
            <input type="text" class="form-control" placeholder="Ej: Sevilla" formControlName="barrioDireccion"
              [class.is-invalid]="camposvalidos('barrioDireccion')" [(ngModel)]="direccion.barrio"
              #inputRefBarrioDireccion>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('barrioDireccion')">Barrio no válido</div>
          </div>
          <div class="col">
            <label class="form-label">Piso/Apartamento/Torre/Edificio/Casa</label>
            <input type="text" class="form-control" placeholder="Ej: torre 5 apto 302"
              formControlName="adicionalDireccion" [class.is-invalid]="camposvalidos('adicionalDireccion')"
              [(ngModel)]="direccion.adicional" #inputRefAdicionalDireccion>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('adicionalDireccion')">Complemento dirección no válido</div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
      *ngIf="campoValidacionDireccion()">La dirección no puede contener el carácter especial +</div>
    <div class="row mb-4" *ngIf="!entregaCerti && !entregaVirtual && usuario.dispo.toLowerCase() === 'token físico'">
      <div class="col">
        <label class="form-label">Departamento *</label>
        <select class="form-select" formControlName="departamento" [class.is-invalid]="camposvalidos('departamento')"
          (change)="changeDepartamento($event)" [(ngModel)]="entrega.departamento"
          placeholder="selecione un departamento">
          <option *ngFor="let muni of depar" [value]="muni.codigo_dep">{{muni.Departamento}}</option>
        </select>
        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
          *ngIf="camposvalidos('departamento')">Departamento no válido, selecciona una opción del listado</div>
      </div>
      <div class="col">
        <label class="form-label">Municipio *</label>
        <select class="form-select" formControlName="municipio" [class.is-invalid]="camposvalidos('municipio')"
          [(ngModel)]="entrega.municipio" placeholder="selecione un municipio">
          <option class="form-control" *ngFor="let muni of munifi" [value]="muni.Codigo_mu">{{muni.Municipio}}
          </option>
        </select>
        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
          *ngIf="camposvalidos('municipio')">Municipio no válido, selecciona una opción del listado</div>
      </div>
    </div>
    <div class="mb-3" *ngIf="!entregaCerti && !entregaVirtual && usuario.dispo.toLowerCase() === 'token físico'">
      <div class="mb-3">
        <label class="form-label mt-3">Teléfono contacto</label>
        <div class="row">
          <div class="col">
            <input class="form-control" list="browsers" formControlName="indicativo" placeholder="indicativo"
              [(ngModel)]="entrega.indicativo" (change)="verficacion($event)"
              [class.is-invalid]="camposvalidos('indicativo')" (keyup)="noPuntoComa($event)">
            <datalist id="browsers">
              <option *ngFor="let d of indicativos" [value]="d.indicativo">{{d.nomDepartamento}}</option>
            </datalist>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('indicativo')">Indicativo no válido, selecciona una opción de la lista</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comas">El campo
              no
              puede tener decimales</div>
          </div>
          <div class="col">

            <input type="number" class="form-control" placeholder="teléfono" formControlName="telefono"
              [class.is-invalid]="camposvalidos('telefono')" #datos2 [(ngModel)]="entrega.telefono"
              value="{datos2.toString()}" (keyup)="quitarceros3(datos2.value)">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('telefono')">Teléfono no válido, debe ser un número de 7 dígitos</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastel2">El
              campo no puede tener comas</div>

          </div>
          <div class="col">
            <input type="number" class="form-control" placeholder="extensión" formControlName="extension"
              [class.is-invalid]="camposvalidos('extension')" #datos [(ngModel)]="entrega.extension"
              value="{datos.toString()}" (keyup)="quitarceros2(datos.value)">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('extension')">Extension no válido</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastex">El
              campo
              no puede tener comas</div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label">Celular *</label>
        <input type="number" class="form-control" placeholder="celular" formControlName="celular"
          [class.is-invalid]="camposvalidos('celular')" #datos3 [(ngModel)]="entrega.celular"
          value="{datos3.toString()}" (keyup)="quitarceros(datos3.value)" (keyup)="noPuntotel($event)">
        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
          *ngIf="camposvalidos('celular')">Celular no válido, debe ser número de 10 dígitos</div>

        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastel">El campo
          no
          puede tener comas</div>
      </div>
    </div>
    <div class="margen2_1" style="padding-bottom:0px ;">
      <div class="mb-3" style="line-height: 10px; margin-bottom: 5px !important;">
        <label for="label" class="form-label">* Campos obligatorios</label>
      </div>
    </div>
    <div class="mb-4">
      <button type="button" class="btn btn-secondary" style="margin: 15px;" (click)="redireciona()">Anterior</button>
      <button *ngIf="usuario.solicitudFinalizada !== 'ASIGNADO'" type="submit" class="btn btn-primary">{{ entregaVirtual
        ? 'Aceptar' : actualizacion ?
        "Guardar" : "Siguiente"}}</button>
      <button *ngIf="usuario.solicitudFinalizada === 'ASIGNADO'" type="submit" class="btn btn-primary">{{entregaVirtual
        ? 'Aceptar' : 'Continuar'}}</button>
    </div>
  </form>
  <div *ngIf="entregaCertiHistorico && !entregaVirtual && usuario.dispo.toLowerCase() === 'token físico'"
    style="z-index: 10000;background: white; width: 170%; right: -35%;height: 130%; top: -12%;  position: absolute;  margin-top: 0px;">
    <div class="alert alert-light text-center animate__animated animate__fadeIn animate__faster"
      style="margin-top: 0px; z-index: 10000;">
      <br>
      <h3>Forma de entrega</h3>
      <p>En tu última solicitud seleccionaste entrega en las instalaciones de Certicámara. ¿Deseas cambiar la forma de
        entrega y que la enviemos a
        tu domicilio?</p>
      <div class="mb-4">
        <button type="button" style="margin: 15px; width: 80px;" class="btn btn-secondary "
          (click)="instalaciones({target: {checked: true}})">No</button>
        <button type="button" class="btn btn-primary" style="margin: 15px; width: 80px;"
          (click)="instalaciones({target: {checked: false}})">Si</button>
      </div>
    </div>
  </div>
</div>