import { Injectable } from '@angular/core';
import { PreInvoiceAdapterService } from 'src/app/infraestucture/driven-adapter/pre-invoice-adapter.service';
import { Observable } from 'rxjs';
import { SuccessResponse } from '../../domain/interfaces/response-base.interface';
import { SspsService } from './ssps.service';
import { GeneratePreInvoiceRequest, PreInvoiceDocument, PreInvoiceRequest, UpdatePreInvoiceRequest } from '../interfaces/pre-invoice.interface';

@Injectable({
  providedIn: 'root'
})
export class PreInvoiceService {
    constructor(private preInvoiceAdapter : PreInvoiceAdapterService
    ){}

    validarLinkPrefactura(preInvoiceId: any): Observable<SuccessResponse>{
      return this.preInvoiceAdapter.validateLink(preInvoiceId);
    }

    obtenerPrefactura(idPrefactura: string): Observable<SuccessResponse> {
      return this.preInvoiceAdapter.getPreInvoice(idPrefactura);
    }

    aceptarPrefactura(data: any): Observable<SuccessResponse>{
      return this.preInvoiceAdapter.acceptPreInvoice(data);
    }

    rechazarPrefactura(body: any): Observable<SuccessResponse>{
      return this.preInvoiceAdapter.declinePreInvoice(body);
    }

    crearPrefactura(body: PreInvoiceRequest): Observable<SuccessResponse> {
      return this.preInvoiceAdapter.createPreInvoice(body);
    }

    actualizarPrefactura(body: UpdatePreInvoiceRequest): Observable<SuccessResponse> {
      return this.preInvoiceAdapter.updatePreInvoice(body);
    }

    regenerarPrefactura(body: GeneratePreInvoiceRequest): Observable<SuccessResponse> {
      return this.preInvoiceAdapter.generatePreInvoice(body);
    }

    generarPdfPrefactura(body: PreInvoiceDocument) : Observable<SuccessResponse> {
      return this.preInvoiceAdapter.generatePreInvoiceDocument(body);
    }

    visualizarDocumentoPreFactura(path: string): Observable<Blob>{
      return this.preInvoiceAdapter.downloadPreInvoiceDocument(path);
    }
}