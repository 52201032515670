<table border="0" cellspacing="0" cellpadding="0">
  <tr>
    <td style="vertical-align: top; padding: 20px;">
      <h2 class="modal-title" id="exampleModalLabel">Señor usuario</h2>
    </td>
  </tr>
</table>
<div class="modal-body" *ngIf="pagoComprobante === 'sin_comprobante'">
  <p class="textoc">
    El proceso finaliza de manera exitosa, una vez Certicámara verifique el pago. Recuerde: Para pagar en cualquiera de
    las sucursales del banco BBVA, usted debe llevar impresa la orden de pedido que incluye el código de barras, con
    esto evitará inconvenientes en el momento del pago y disminuirá los tiempos de emisión.
  </p>
</div>
<div class="modal-body" *ngIf="pagoComprobante === 'comprobante'">
  <p class="textoc">
    Estimado usuario, el proceso finaliza de manera exitosa, una vez Certicámara verifique el pago.
  </p>
  <span class="textoc" *ngIf="validaIdentidad === false">
    Apreciado usuario, por tu seguridad y la del proceso, queremos validar tu identidad. Por favor ingresa para
    poder continuar
  </span>
</div>
<div class="modal-body" *ngIf="pagoComprobante === 'pagado'">
  <p class="textoc">
    El pago de la compra fue realizado correctamente.
  </p>
  <p class="textoc" *ngIf="validaIdentidad === false" style="padding-top: 0px;">
    Por tu seguridad y la del proceso, queremos validar tu identidad. Por favor ingresa para
    poder continuar
  </p>
</div>
<div class="modal-footer" *ngIf="validaIdentidad === false && pagoComprobante !== 'sin_comprobante'">
  <button type="button" class="btn btn-primary" (click)="validarIdentidad()">Validar identidad</button>
</div>
<div class="modal-footer" *ngIf="validaIdentidad === true || pagoComprobante === 'sin_comprobante'">
  <button type="button" class="btn btn-secondary" (click)="close()">Finalizar solicitud</button>
</div>