<div class="modal-header">

  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<form [formGroup]="formaForm" (ngSubmit)="ngsubmit()">
  <div class="modal-body  ms-4 me-4">
    <div class="contiene">

      <h4 class="titulos">Estás a un clic de disfrutar los beneficios </h4>
      <p class="texto">

        Queremos brindarte una solución que se ajuste perfectamente a los objetivos de tu empresa. Compártenos por favor
        tus datos personales para que uno de nuestros consultores especializados pueda contactarse contigo durante las
        próximas 24 horas.
      </p>
      <div class="margencon">
        <div class="margen"></div>
        <div class="row">
          <div class="col">
            <div class="mb-3" *ngIf="tipoCliente !== 'natural'">
              <label for="tipoIdentificacion" class="form-label">Tipo de identificación</label>
              <select [(ngModel)]="usuario.tipo" id="tipoIdentificacion" class="form-select" formControlName="tipo"
                [class.is-invalid]="camposvalidos('tipo')">
                <option class="form-control" value="NI">NIT</option>
              </select>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de documento</div>
            </div>
            <div class="mb-3" *ngIf="tipoCliente === 'natural'">
              <label for="tipoIdentificacion" class="form-label">Tipo de identificación *</label>
              <select [(ngModel)]="usuario.tipo" id="tipoIdentificacion" class="form-select" formControlName="tipo"
                [class.is-invalid]="camposvalidos('tipo')">
                <option class="form-control" value="CC">Cédula de Ciudadanía</option>
                <option class="form-control" value="CE">Cédula de Extranjería</option>
                <option class="form-control" value="PP">Pasaporte</option>
                <option class="form-control" value="PT">Permiso Permanencia Temporal P.P.T</option>
              </select>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" 
                *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de documento</div>
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <label for="numeroIdentificacion" class="form-label">Número de identificación *</label>
              <input [(ngModel)]="usuario.numero" type="text" class="form-control"
                id="numeroIdentificacion"
                placeholder="Número de identificación" formControlName="numero"
                [class.is-invalid]="camposvalidos('numero')" #inputRefNumero>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('numero')">¡Identificación no válido!</div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="row">
            <ng-template [ngIf]="tipoCliente == 'natural'">
              <div class="col">
                <label for="nombresCompletos" class="form-label">Nombres completos *</label>
                <input type="text" class="form-control" [(ngModel)]="usuario.primerNombre"
                  id="nombresCompletos"
                  placeholder="nombres completos" formControlName="nombre" [class.is-invalid]="camposvalidos('nombre')"
                  #inputRefNombres>
                <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                  *ngIf="camposvalidos('nombre')">Nombres no son válidos, ingresar solo letras</div>
              </div>
              <div class="col">
                <label for="apellidosCompletos" class="form-label">Apellidos Completos *</label>
                <input type="text" [(ngModel)]="usuario.primerApellido" class="form-control"
                  id="apellidosCompletos"
                  placeholder=" apellidos completos" formControlName="apellido"
                  [class.is-invalid]="camposvalidos('apellido')" #inputRefApellidos>
                <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                  *ngIf="camposvalidos('apellido')">Apellidos no son válidos, ingresar solo letras</div>
              </div>
            </ng-template>
          </div>
        </div>


        <ng-template [ngIf]="tipoCliente == 'juridica'">

          <div class="mb-3">

            <label for="entidadEmpresa" class="form-label">Entidad / empresa u organización *</label>
            <input type="text" [(ngModel)]="usuario.razon" class="form-control" placeholder="entidad"
              id="entidadEmpresa"
              formControlName="empresa" [class.is-invalid]="camposvalidos('empresa')" #inputRefRazonSocial>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('empresa')">Empresa no válida</div>
          </div>
        </ng-template>

        <div class="mb-3">

          <div class="row">
            <div class="col">
              <label for="correoElectronico" class="form-label">Correo electrónico *</label>
              <input type="text" [(ngModel)]="usuario.correo" class="form-control" placeholder="correo electrónico"
                id="correoElectronico"
                formControlName="correo" [class.is-invalid]="camposvalidos('correo')" #inputRefCorreo>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('correo')">Correo no válida</div>

            </div>
            <div class="col">
              <label for="celularContacto" class="form-label">Celular de contacto *</label>
              <input type="number" class="form-control" placeholder="celular" formControlName="celular"
                id="celularContacto"
                [class.is-invalid]="camposvalidos('celular')" #datos [(ngModel)]="usuario.celular"
                value="{datos.toString()}" (keyup)="noPuntoComa($event)" (keyup)="quitarceros(datos.value)">
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('celular')">Celular no válido, ingresar número de 10 dígitos</div>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comas">El campo
                no
                puede tener comas</div>
            </div>
          </div>

        </div>
        <div class="mb-3">
          <div class="row">
            <div class="col">
              <label for="disponibilidad" class="form-label">Disponibilidad *</label>
              <input type="text" [(ngModel)]="usuario.disponibilidad" class="form-control"
                id="disponibilidad"
                placeholder="Lunes-viernes 8am-2pm" formControlName="disponibilidad"
                [class.is-invalid]="camposvalidos('disponibilidad')" #inputRefDisponibilidad>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('disponibilidad')">Disponibilidad no puede ser vacia y más de 5 carácteres</div>

            </div>

          </div>

        </div>
        <br>
        <app-politicas [formaForm]="formaForm"></app-politicas>
      </div>
    </div>
  </div>
  <div class="margen2" style="padding-bottom:0px ;">
    <div class="mb-3" style="line-height: 10px; margin-bottom: 5px !important;">
      <label for="label" class="form-label">* Campos obligatorios</label>
    </div>
  </div>
  <div class="modal-footer">
    <!--<button type="submit" class="btn btn-primary">Enviar</button>-->
    <button type="button" class="btn btn-secondary" (click)="close()">Cancelar</button>
    <button type="submit" class="btn btn-primary">Enviar</button>
  </div>

</form>