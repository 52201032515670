import { Component, OnInit, OnChanges, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';


@Component({
  selector: 'app-entrega',
  templateUrl: './entrega.component.html',
  styleUrls: ['./entrega.component.css']
})
export class EntregaComponent implements OnInit {
  @ViewChild('inputRefCorreo')
  inputRefCorreo!: { nativeElement: any; };
  @ViewChild('inputRefDireccion')
  inputRefDireccion!: { nativeElement: any; };
  @ViewChild('inputRefTipoNumeroDireccion')
  inputRefTipoNumeroDireccion!: { nativeElement: any; };
  @ViewChild('inputRefNumeroDireccion')
  inputRefNumeroDireccion!: { nativeElement: any; };
  @ViewChild('inputRefNumeroDireccion2')
  inputRefNumeroDireccion2!: { nativeElement: any; };
  @ViewChild('inputRefBarrioDireccion')
  inputRefBarrioDireccion!: { nativeElement: any; };
  @ViewChild('inputRefAdicionalDireccion')
  inputRefAdicionalDireccion!: { nativeElement: any; };
  @ViewChild('inputRefNumeroOtroDireccion')
  inputRefNumeroOtroDireccion!: { nativeElement: any; };
  formaForm!: FormGroup;
  indicativos: any[] = [];
  codindi: any;
  codigo: any;
  depar: any[] = [];
  tipoDireccion: any[] = [];
  muni: any[] = [];
  munifi: any[] = [];
  comas: boolean = false;
  comastel: boolean = false;
  comastel2: boolean = false;
  comastex: boolean = false;
  entrega: any = {};
  entregaCerti: boolean = false
  entregaCertiHistorico: boolean = false
  entregaVirtual: boolean = false
  usuario: any;
  direccion: any = {
    tipo: "",
    tipoNumero: "",
    numero: "",
    numero2: "",
    barrio: "",
    adicional: "",
    tipoOtroNumero: ""
  }
  actualizacion = false
  correoEntrega = ""

  constructor(private router: Router,
    private fb: FormBuilder,
    private componentesService: ComponentesService,
    private changeDetector: ChangeDetectorRef,
    private ssps: SspsService, 
    private el: ElementRef
  ) {

    this.formaForm = this.fb.group({
      departamento: ['', [Validators.required]],
      municipio: ['', [Validators.required]],
      celular: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      indicativo: [],
      telefono: ['', [
        Validators.maxLength(7),
        Validators.minLength(7)
      ]],
      extension: ["", [
        Validators.maxLength(5)
      ]],
      tipoDireccion: ['', [
        Validators.required
      ]],
      tipoNumeroDireccion: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10)
      ]],
      numeroDireccion: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10)
      ]],
      numeroDireccion2: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10)
      ]],
      barrioDireccion: ['', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(200)
      ]],
      adicionalDireccion: ['', [
        Validators.maxLength(200)
      ]],
      numeroOtroDireccion: ['', [
        Validators.minLength(4),
        Validators.maxLength(200)
      ]],
      correo: ['', []]
    }, { validators: [this.componentesService.validalist('indicativo')] });
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.indicativo();
    this.tiposDireccion();
    this.actualizacion = this.ssps.getLocalStorage("actualizacion") ?
      JSON.parse(this.ssps.getLocalStorage("actualizacion")!) : false
    this.usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
    var solicitante = JSON.parse(this.ssps.getLocalStorage('solicitante')!);
    if (this.ssps.getLocalStorage('entrega')) {
      this.entrega = JSON.parse(this.ssps.getLocalStorage('entrega')!);
      this.entregaCerti = this.entrega.tipo === "OFICINA"
      this.entregaCertiHistorico = !this.actualizacion && this.entrega.tipo === "OFICINA" && this.usuario.dispo.toLowerCase() !== 'pkcs#10'
      this.direccion = this.entrega.objetoDireccion !== undefined && this.entrega.objetoDireccion !== null ?
        this.entrega.objetoDireccion : {
          tipo: "",
          tipoNumero: "",
          numero: "",
          numero2: "",
          barrio: "",
          adicional: "",
          tipoOtroNumero: ""
        }
      if (this.entrega.tipo === "OFICINA") {
        this.clearValidators()
      }
      else {
        setTimeout(() => {
          this.deshabilitarCmaposFinalizados()
        }, 1000);
      }
      if (this.direccion.tipo === "Otro") {
        this.clearValidatorsDireccion()
      }
    }
    else {
      this.entrega = {
        direccionCompleta: "",
        departamento: "",
        municipio: "",
        celular: "",
        indicativo: "",
        telefono: "",
        extension: "",
        correo: "",
        tipo: "",
      }
      this.entrega.objetoDireccion = {
        tipo: "",
        tipoNumero: "",
        numero: "",
        numero2: "",
        barrio: "",
        adicional: "",
        tipoOtroNumero: ""
      }
    }
    this.correoEntrega = this.usuario.cliente == "natural" ? this.usuario.correo : solicitante.correo
    if (this.usuario.dispo.toLowerCase() !== "token físico") {
      this.entregaVirtual = true
      this.entregaCerti = false
      this.clearValidators()
    }
    this.departamentos();
    this.setOnChangesInputs()
  }

  deshabilitarCmaposFinalizados() {
    Object.keys(this.formaForm.controls).forEach(key => {
      if (key !== "correo" && (this.usuario.solicitudFinalizada === "APROBADO" || this.usuario.solicitudFinalizada === "FINALIZADO")) {
        this.formaForm.controls[key]?.disable({ onlySelf: true })
      }
      else if(this.usuario.solicitudFinalizada === "ASIGNADO"){
        this.formaForm.controls[key]?.disable({ onlySelf: true })
      }
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }


  setOnChangesInputs() {
    this.formaForm.get("tipoNumeroDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefTipoNumeroDireccion) {
        const elRef = this.inputRefTipoNumeroDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("tipoNumeroDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numeroDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefNumeroDireccion) {
        const elRef = this.inputRefNumeroDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numeroDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numeroDireccion2")!.valueChanges.subscribe(val => {
      if (this.inputRefNumeroDireccion2) {
        const elRef = this.inputRefNumeroDireccion2.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numeroDireccion2")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("barrioDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefBarrioDireccion) {
        const elRef = this.inputRefBarrioDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("barrioDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("adicionalDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefAdicionalDireccion) {
        const elRef = this.inputRefAdicionalDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("adicionalDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numeroOtroDireccion")!.valueChanges.subscribe(val => {
      if (this.inputRefNumeroOtroDireccion) {
        const elRef = this.inputRefNumeroOtroDireccion.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numeroOtroDireccion")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("correo")!.valueChanges.subscribe(val => {
      if (this.inputRefCorreo) {
        const elRef = this.inputRefCorreo.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("correo")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });
  }

  departamentos() {
    this.ssps.departamentos()
      .subscribe((res: any) => {
        this.muni = res.sort((a: any,b: any)=> (a.Departamento < b.Departamento ? -1 : 1));
        this.depar = [...new Map(res.map((item: { [x: string]: any; }) => [item['Departamento'], item])).values()].sort((a: any,b: any)=> (a.Departamento < b.Departamento ? -1 : 1));
        setTimeout(() => {
          let departamento = this.entrega.departamento
          this.formaForm.get("departamento")!.setValue(this.entrega.departamento, {
            emitEvent: true,
            emitModelToViewChange: true
          });
          this.munifi = this.muni.filter(function (obj: any, index) {
            return obj.codigo_dep == +departamento;
          }).sort((a,b)=> (a.Municipio < b.Municipio ? -1 : 1))
        }, 1000);
      });
  }

  tiposDireccion() {
    this.ssps.tiposDireccion()
      .subscribe((res: any) => {
        this.tipoDireccion = res;
      });
  }

  changeDepartamento($event: any) {
    let departamento = $event.target.value;
    this.munifi = this.muni.filter(function (obj: any, index) {
      return obj.codigo_dep == +departamento;
    }).sort((a,b)=> (a.Municipio < b.Municipio ? -1 : 1))
    setTimeout(() => {
      this.usuario.municipio = undefined
      this.formaForm.get("municipio")!.setValue(undefined, {
        emitEvent: true,
        emitModelToViewChange: true
      });
    }, 500);    
  }


  redireciona() {
    if (this.usuario.cliente == "natural" && this.usuario.dispo.toUpperCase() !== "PKCS#10") {
      this.componentesService.emitircambio("reveinfobasi");
      this.router.navigate(['/flujo/infobasi']);
    }
    else if (this.usuario.cliente == "juridica" && this.usuario.dispo.toUpperCase() !== "PKCS#10") {
      this.componentesService.emitircambio("revesolicitante");
      this.router.navigate(['/flujo/solicitante']);
    }
    else {
      this.router.navigate(['/flujo/contacto']);
      this.componentesService.emitircambio("revecontacto");
    }
  }

  /* funcion para los errores indicados*/
  camposvalidos(campo: any) {
    return this.formaForm.controls[campo].errors && this.formaForm.controls[campo].touched;
  }
  /*funcion que hace el filtro de los elementos selecionados del select*/
  verficacion(event: any) {
    this.codindi = this.indicativos.find(x => x?.indicativo === +event.target.value);
    this.entrega.indicativo = this.codindi?.indicativo;
  }

  /*Implementacion de validaciones de coma*/


  noPuntoComa(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comas = res;
      })
  }


  noPuntotel(event: any) {
    this.componentesService.commas(event)
      .subscribe((res: any) => {
        this.comastel = res;
      })
  }



  /*quitar numeros ceros a la izquierda*/
  quitarceros(numero: string) {
    this.entrega.celular = numero.replace(/^0+/, '');
  }

  quitarceros2(numero: string) {
    this.entrega.extension = numero.replace(/^0+/, '');
  }

  quitarceros3(numero: string) {
    this.entrega.telefono = numero.replace(/^0+/, '');
  }


  /*funcion para el envio de datos*/
  ngsubmit() {
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl?.focus();
          return;
        }
      });
      return;
    }
    if (!this.entregaCerti && !this.entregaVirtual) {
      let { departamento, municipio, celular, indicativo, telefono, extension,
        tipoDireccion, tipoNumeroDireccion, numeroDireccion, numeroDireccion2,
        barrioDireccion, adicionalDireccion, numeroOtroDireccion } = this.formaForm.getRawValue();

      let objetoDireccion = {
        tipo: tipoDireccion,
        tipoNumero: tipoNumeroDireccion,
        numero: numeroDireccion,
        numero2: numeroDireccion2,
        barrio: barrioDireccion,
        adicional: adicionalDireccion ?? "",
        tipoOtroNumero: numeroOtroDireccion
      }

      let valoresfi = {
        departamento,
        municipio,
        celular,
        indicativo,
        telefono,
        extension,
        objetoDireccion: objetoDireccion,
        direccionCompleta: (objetoDireccion.tipo !== "Otro" ? (objetoDireccion.tipo + " " + objetoDireccion.tipoNumero + " #" + objetoDireccion.numero + "-" +
          objetoDireccion.numero2) : objetoDireccion.tipoOtroNumero) +
          " BARRIO " + objetoDireccion.barrio + (objetoDireccion.adicional !== "" ? " ADICIONAL " + objetoDireccion.adicional : ""),
        tipo: "ENVIO"
      }
      Object.assign(this.entrega, valoresfi);
      if (this.ssps.getLocalStorage('facturacion')!) {
        let facturacion = JSON.parse(this.ssps.getLocalStorage('facturacion')!)
        facturacion.direccion = valoresfi.direccionCompleta
        this.ssps.saveLocalStorage("facturacion", JSON.stringify(facturacion));
      }
    }
    else if (this.entregaVirtual) {
      let { correo } = this.formaForm.value;
      this.entrega.correo = correo
      this.entrega.tipo = "VIRTUAL"
    }
    else {
      this.entrega.tipo = "OFICINA"
    }
    this.ssps.saveLocalStorage("entrega", JSON.stringify(this.entrega));
    if (this.ssps.getLocalStorage("solicitudId")) {
      this.componentesService.emitircambio("actualizarOrden");
    }
    this.router.navigateByUrl('/flujo/facturacion');
    this.componentesService.emitircambio("facturacionf");
  }

  /*carga datos select*/
  indicativo() {
    this.ssps.indicativos()
      .subscribe((res: any) => {
        this.indicativos = res;
      });

  }

  instalaciones($event: any) {
    this.entregaCertiHistorico = false
    let isCheckeado = $event.target.checked;
    if (isCheckeado) {
      this.entrega.tipo = "OFICINA"
      this.entregaCerti = true
      //Quitar validaciones de los demas campos formularios
      this.clearValidators()
      setTimeout(() => {
        this.deshabilitarCmaposFinalizados()
      }, 2000);
    }
    else {
      this.entregaCerti = false
      this.entrega.tipo = "ENVIO"
      this.direccion = {
        tipo: "",
        tipoNumero: "",
        numero: "",
        numero2: "",
        barrio: "",
        adicional: "",
        tipoOtroNumero: ""
      }
      this.addValidators()
      setTimeout(() => {
        this.habilitarCmaposFinalizados()
      }, 2000);
    }
  }

  habilitarCmaposFinalizados() {
    if (this.usuario.solicitudFinalizada === "PENDIENTE") {
      Object.keys(this.formaForm.controls).forEach(key => {
        this.formaForm.controls[key]?.enable()
      });
    }
  }
  changeTipoDireccion($event: any) {
    let tipoDireccion = $event.target.value;
    if (tipoDireccion === "Otro") {
      this.direccion = {
        tipo: tipoDireccion,
        tipoNumero: "",
        numero: "",
        numero2: "",
        barrio: "",
        adicional: "",
        tipoOtroNumero: ""
      }
      this.clearValidatorsDireccion()
    }
    else {
      this.addValidatorsDireccion()
    }
  }

  clearValidatorsDireccion() {
    const controlesAEliminar = ['tipoNumeroDireccion', 'numeroDireccion',
      'numeroDireccion2', 'numeroOtroDireccion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        if (controlName === "numeroOtroDireccion") {
          control.addValidators([
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(200)])
        }
        else {
          control.clearValidators();
        }
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  addValidatorsDireccion() {
    const controlesAEliminar = ['tipoNumeroDireccion', 'numeroDireccion',
      'numeroDireccion2', 'numeroOtroDireccion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        if (controlName === "numeroOtroDireccion") {
          control.clearValidators();
        }
        else {
          control.addValidators([
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(10)
          ])
        }
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  clearValidators() {
    const controlesAEliminar = ['departamento', 'municipio', 'celular', 'indicativo',
      'telefono', 'extension', 'tipoDireccion', 'tipoNumeroDireccion', 'numeroDireccion',
      'numeroDireccion2', 'barrioDireccion', 'adicionalDireccion', 'numeroOtroDireccion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores

    this.formaForm.clearValidators()
    this.formaForm.updateValueAndValidity()

    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        if (controlName === "correo") {
          control.addValidators([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(60),
            Validators.pattern('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$')])
        }
        else {
          control.clearValidators();
        }
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  addValidators() {
    const controlesAEliminar = ['departamento', 'municipio', 'celular', 'indicativo',
      'telefono', 'extension', 'tipoDireccion', 'tipoNumeroDireccion', 'numeroDireccion',
      'numeroDireccion2', 'barrioDireccion', 'adicionalDireccion', 'numeroOtroDireccion'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    this.formaForm.addValidators([this.componentesService.validalist('indicativo')])
    this.formaForm.updateValueAndValidity()
    for (const controlName of controlesAEliminar) {
      const control = this.formaForm.get(controlName);
      if (control) {
        if (controlName === "direccion") {
          control.addValidators([
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(200),])
        }
        else if (controlName === "departamento" || controlName === "municipio") {
          control.addValidators([
            Validators.required])
        }
        else if (controlName === "celular") {
          control.addValidators([
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10)
          ])
        }
        else if (controlName === "telefono") {
          control.addValidators([
            Validators.minLength(7),
            Validators.maxLength(7)
          ])
        }
        else if (controlName === "extension") {
          control.addValidators([
            Validators.maxLength(5)
          ])
        }
        else if (controlName === "adicionalDireccion") {
          control.addValidators([
            Validators.maxLength(200)
          ])
        }
        else if (controlName === "correo") {
          control.clearValidators();
        }
        else {
          control.addValidators([
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(200)
          ])
        }
        control.setValue("")
        control.updateValueAndValidity();
      }
    }
  }

  prueba() {
    this.router.navigateByUrl('/flujo/instalaciones');
  }
}
