import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-politicas',
  templateUrl: './politicas.component.html',
  styleUrl: './politicas.component.css',
  standalone: false
})
export class PoliticasComponent {
  @Input() formaForm!: FormGroup;
  @Input() cheksPolis!: boolean;

  politicas = [
    { id: 'cbtest-19', formControl: 'terminosp', texto: 'Términos y condiciones', link: '../../../assets/Documentos/PYP-L-005_Términos_Condiciones_del Servicio_de_Certificación_de_Firma_Digital_v1.pdf' },
    { id: 'cbtest-20', formControl: 'terminost', texto: 'Aviso de privacidad', link: '../../../assets/Documentos/Aviso_de_Privacidad_SSPS.pdf' },
    { id: 'cbtest-21', formControl: 'terminostpro', texto: 'Declaración y compromisos LA/FT/FPADM Y C/ST', link: '../../../assets/Documentos/AGR-L-004_Declaraciones_y_Compromisos_en_Materia_de_Prevención_de_LA_FT_FPADM_Y_C_ST_v1.pdf' }
  ];

  camposvalidos(campo: any) {
    return this.formaForm.get(campo)!.invalid &&
      (this.formaForm.get(campo)!.touched || this.formaForm.get(campo)!.dirty);
  }
}
