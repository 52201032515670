import { Injectable } from '@angular/core';
import { PreInvoiceAdapterService } from 'src/app/infraestucture/driven-adapter/pre-invoice-adapter.service';
import { Observable } from 'rxjs';
import { SuccessResponse } from '../../domain/interfaces/response-base.interface';
import { SspsService } from './ssps.service';

@Injectable({
  providedIn: 'root'
})
export class PreInvoiceService {
    constructor(private preInvoiceAdapter : PreInvoiceAdapterService
    ){}

    validarLinkPrefactura(preInvoice: any): Observable<SuccessResponse>{
        return this.preInvoiceAdapter.validateLink(preInvoice);
    }

    aceptarPrefactura(data: any): Observable<SuccessResponse>{
      return this.preInvoiceAdapter.acceptPreInvoice(data);
    }

    rechazarPrefactura(body: any): Observable<SuccessResponse>{
      return this.preInvoiceAdapter.declinePreInvoice(body);
    }
}