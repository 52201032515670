import { Component, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';

@Component({
    selector: 'app-descarga-certificado-pfx',
    templateUrl: './descarga-certificado-pfx.component.html',
    styleUrls: ['./descarga-certificado-pfx.component.css'],
    standalone: false
})
export class DescargaCertificadoPfxComponent implements OnInit {

  constructor(private alert: AlertsService) { }

  ngOnInit(): void {
    this.alert.showFieldsDescargaPfx();
  }
}
