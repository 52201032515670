<div class="animate__animated animate__fadeIn animate__fast">
       <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()" id="form1">
              <!--listas negras alert -->
              <div class="margen2">
                     <app-identificacion [formaForm]="formaForm"></app-identificacion>

                     <app-nombre-apellido [formaForm]="formaForm"></app-nombre-apellido>
              </div>
                     <app-politicas [formaForm]="formaForm"></app-politicas>
              <div class="margen2" style="padding-bottom:0px ;">
                     <div class="mb-3" style="line-height: 10px; margin-bottom: 5px !important;">
                            <label for="label" class="form-label">* Campos obligatorios</label>
                     </div>
              </div>
              <div class="modal-footer">
                     <button type="button" class="btn btn-secondary" (click)="homeNavigate()">Cancelar</button>
                     <button type="submit" class="btn btn-primary" [disabled]="formaForm.invalid">Siguiente</button>
              </div>
       </form>
</div>