<table border="0" cellspacing="0" cellpadding="0">
  <thead style="display: none;">
    <tr>
      <th>Encabezado</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style="vertical-align: top; padding: 20px;">
        <h2 class="modal-title" id="exampleModalLabel">Señor usuario</h2>
      </td>
    </tr>
  </tbody>
</table>
<div class="modal-body" *ngIf="pagoComprobante === 'sin_comprobante'">
  <p class="textoc">
    ¡Atención! Tu proceso finalizará con éxito una vez Certicámara verifique el pago. 
    Imprime tu orden de pedido y cancela en cualquier corresponsal bancario Pagatodo o Supergiros, 
    indicando el código 2286 al momento de pagar. Gracias por confiar en Certicámara.
  </p>
</div>
<div class="modal-body" *ngIf="pagoComprobante === 'comprobante'">
  <p class="textoc">
    Estimado usuario, el proceso finaliza de manera exitosa, una vez Certicámara verifique el pago.
  </p>
  <p class="textoc" *ngIf="validaIdentidad === false">
    Apreciado usuario, por tu seguridad y la del proceso, queremos validar tu identidad. Por favor ingresa para
    poder continuar
  </p>
</div>
<div class="modal-body" *ngIf="pagoComprobante === 'pagado'">
  <p class="textoc">
    El pago de la compra fue realizado correctamente.
  </p>
  <p class="textoc" *ngIf="validaIdentidad === false" style="padding-top: 0px;">
    Por tu seguridad y la del proceso, queremos validar tu identidad. Por favor ingresa para
    poder continuar
  </p>
</div>
<div class="modal-footer" *ngIf="validaIdentidad === false && pagoComprobante !== 'sin_comprobante'">
  <button type="button" class="btn btn-primary" (click)="validarIdentidad()">Validar identidad</button>
</div>
<div class="modal-footer" *ngIf="validaIdentidad || pagoComprobante === 'sin_comprobante' || (pagoComprobante === 'comprobante' && validaIdentidad)">
  <button type="button" class="btn btn-secondary" (click)="close()">Finalizar solicitud</button>
</div>