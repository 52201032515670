import { Component, ElementRef, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { PreInvoiceService } from 'src/app/domain/servicios/pre-invoice.service';
import { LogService } from '../../../../domain/servicios/log.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';

@Component({
  selector: 'app-rechazar-prefactura',
  templateUrl: './rechazar-prefactura.component.html',
  styleUrls: ['./rechazar-prefactura.component.css']
})
export class RechazarPrefacturaComponent implements OnInit {

  declineForm!: FormGroup;
  usuario: any;
  preInvoice: any;

  constructor(private fb: FormBuilder,
    private preInvoiceService: PreInvoiceService,
    private ssps: SspsService,
    private logger: LogService,
    private alert: AlertsService,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.declineForm = this.fb.group({
      nombre: ['', Validators.required],
      tipo: ['', Validators.required],
      numero: ['', Validators.required],
      motivoRechazo: ['', Validators.required]
    });

  }

  /*ingreso y validacion de datos de formulario*/
  ngsubmit() {
    /*if (this.declineForm.invalid) {
      this.declineForm.markAllAsTouched();
      Object.keys(this.declineForm.controls).forEach(key => {
        if (this.declineForm.controls[key].errors) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          return;
        }
      });
      return;
    }
    //Llamar servicio de formularioContacto
    this.alert.showSpiner("Estimado usuario, recuerda que para completar tu proceso, se realizará proceso de validación de identidad");
    let { nombre, tipo, numero, motivoRechazo } = this.declineForm.value;
    let request = {
      nombreCompleto: nombre,
      tipoIdentificacion: tipo,
      numeroIdentificacion: numero,
      motivoRechazo: motivoRechazo
    }
    this.preInvoiceService.rechazarPrefactura(request)
      .subscribe({
        next: (res: any) => {
          this.alert.closesSpiner()
          this.logger.log("respuesta formularioRechazo", res)
          this.alert.closeContact();
          this.alert.showAlert(false);
        },
        error: (error: any) => {
          this.alert.closesSpiner()
          console.error('Error en subscriber formularioRechazo', error)
          this.alert.showError(error.mensaje, true, null, false, null, null)
        }
      })*/

        this.alert.showAlert(true);
  }

  camposvalidos(campo: any) {
    return this.declineForm.controls[campo].errors && this.declineForm.controls[campo].touched;
  }

  close(){
    this.alert.closesFiels();
  }
}
