<div class=" animate__animated animate__fadeInLeftBig animate__faster">

  <div class="row ms-1 mt-3">
    <h5 class="titulosintu2">Información básica empresa</h5>
    <br>
    <br>
    <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="mb-3">
            <label for="tipoIdentificacion" class="form-label">Tipo de identificación *</label>
            <select [(ngModel)]="usuario.tipo" id="tipoIdentificacion" class="form-select" formControlName="tipo">
              <option class="form-control" value="CC">Cédula de Ciudadanía</option>
              <option class="form-control" value="CE">Cédula de Extranjería</option>
              <option class="form-control" value="PP">Pasaporte</option>
              <option class="form-control" value="NI">NIT</option>
              <option class="form-control" value="PT">Permiso Permanencia Temporal P.P.T</option>
            </select>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="mb-3">
            <label for="numeroIdentificacion" class="form-label">Número de identificación *</label>
            <input [(ngModel)]="usuario.numero" id="numeroIdentificacion" type="text" class="form-control" placeholder="Número de identificación"
              formControlName="numero">
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <label class="form-label">Razón social *</label>
          <input type="text" class="form-control" placeholder="Razón social" formControlName="razon"
            [(ngModel)]="usuario.razon" [class.is-invalid]="camposvalidos('razon')" #inputRefRazon>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('razon')">Razón social no puede ser vacio, ni puede tener más de 60 caracteres</div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
          <label for="correoElectronico" class="form-label">Correo electrónico *</label>
          <input type="text" class="form-control" placeholder="correo electrónico" formControlName="correo"
            [(ngModel)]="usuario.correo" [class.is-invalid]="camposvalidos('correo')" #inputRefCorreo>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('correo')">Correo no válido, debe tener la estructura correo&#64;dominio.com(.co,.es..)
          </div>

        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label class="form-label">Confirmación correo electrónico *</label>
          <input type="text" class="form-control" placeholder="correo electrónico" formControlName="correo1"
            [(ngModel)]="usuario.correo1" [class.is-invalid]="camposvalidos('correo1')" appNocopiado #inputRefCorreo1>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('correo1')">Correo no válido, debe tener la estructura correo&#64;dominio.com(.co,.es..)
          </div>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="correo2NoValido">
            Los correos no son iguales</div>
        </div>
      </div>

      <div class="mb-3">
        <div class="mb-3">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
              <label class="form-label">Tipo dirección *</label>
              <select (change)="changeTipoDireccion($event)" class="form-select" formControlName="tipoDireccion"
                [class.is-invalid]="camposvalidos('tipoDireccion')" [(ngModel)]="direccion.tipo"
                placeholder="seleccionar">
                <option *ngFor="let tipo of tipoDireccion" [value]="tipo.name">{{tipo.name}}</option>
              </select>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('tipoDireccion')">Tipo no válido</div>
            </div>
            <div class="col-lg-9 col-md-6 col-sm-12 col-12" *ngIf="direccion.tipo === 'Otro'">
              <label class="form-label">Dirección *</label>
              <input type="text" class="form-control" placeholder="Ej: Finca Guayabita Kilometro 4 vía Chía"
                formControlName="numeroOtroDireccion" [class.is-invalid]="camposvalidos('numeroOtroDireccion')"
                #inputRefNumeroOtroDireccion [(ngModel)]="direccion.tipoOtroNumero">
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('numeroOtroDireccion')">Campo no válido</div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-6" *ngIf="direccion.tipo !== 'Otro'">
              <label class="form-label">{{direccion.tipo}}&nbsp; *</label>
              <input type="text" class="form-control" placeholder="Ej:18" formControlName="tipoNumeroDireccion"
                [class.is-invalid]="camposvalidos('tipoNumeroDireccion')" #inputRefTipoNumeroDireccion
                [(ngModel)]="direccion.tipoNumero">
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('tipoNumeroDireccion')">Campo no válido</div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-6" *ngIf="direccion.tipo !== 'Otro'">
              <label class="form-label">Número *</label>
              <input type="text" class="form-control" placeholder="Ej:79A" formControlName="numeroDireccion"
                [class.is-invalid]="camposvalidos('numeroDireccion')" #inputRefNumeroDireccion
                [(ngModel)]="direccion.numero">
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('numeroDireccion')">Número no válido</div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-6" *ngIf="direccion.tipo !== 'Otro'">
              <label class="form-label">&nbsp;</label>
              <input type="text" class="form-control" placeholder="Ej:332" formControlName="numeroDireccion2"
                [class.is-invalid]="camposvalidos('numeroDireccion2')" #inputRefNumeroDireccion2
                [(ngModel)]="direccion.numero2">
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('numeroDireccion2')">Número no válido</div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="mb-3">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              <label class="form-label">{{direccion.tipo === "Otro" ? "Barrio/Vereda/Corregimiento" : "Barrio"}}</label>
              <input type="text" class="form-control" placeholder="Ej: Sevilla" formControlName="barrioDireccion"
                [class.is-invalid]="camposvalidos('barrioDireccion')" [(ngModel)]="direccion.barrio"
                #inputRefBarrioDireccion>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('barrioDireccion')">Barrio no válido</div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <label class="form-label">Piso/Apartamento/Torre/Edificio/Casa</label>
              <input type="text" class="form-control" placeholder="Ej: torre 5 apto 302"
                formControlName="adicionalDireccion" [class.is-invalid]="camposvalidos('adicionalDireccion')"
                [(ngModel)]="direccion.adicional" #inputRefAdicionalDireccion>
              <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                *ngIf="camposvalidos('adicionalDireccion')">Complemento dirección no válido</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
        *ngIf="campoValidacionDireccion()">La dirección no puede contener el carácter especial +</div>
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">Departamento *</label>
          <select class="form-select" formControlName="departamento" [class.is-invalid]="camposvalidos('departamento')"
            (change)="changeDepartamento($event)" [(ngModel)]="usuario.departamento"
            placeholder="selecione un departamento">
            <option *ngFor="let muni of depar" [value]="muni.codigo_dep">{{muni.Departamento}}</option>
          </select>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('departamento')">Departamento no válido, selecciona una opción del listado</div>
        </div>
        <div class="col">
          <label class="form-label">Municipio *</label>
          <select class="form-select" formControlName="municipio" [class.is-invalid]="camposvalidos('municipio')"
            [(ngModel)]="usuario.municipio" placeholder="selecione un municipio">
            <option class="form-control" *ngFor="let muni of munifi" [value]="muni.Codigo_mu">{{muni.Municipio}}
            </option>
          </select>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('municipio')">Municipio no válido, selecciona una opción del listado</div>
        </div>
      </div>
      <div *ngIf="departamentoExcluidoIva !== ''" class="row mb-3">
        <div style="font-weight: 600;" class="col">
          <small>{{departamentoExcluidoIva}}</small>
        </div>
      </div>

      <div class="row mb-4">
        <label class="form-label mt-3">Teléfono contacto</label>
        <div class="row">
          <div class="col">
            <input class="form-control" list="browsers" formControlName="indicativo" placeholder="indicativo"
              [(ngModel)]="usuario.indicativo" [class.is-invalid]="camposvalidos('indicativo')"
              (keyup)="noPuntoComa($event)">
            <datalist id="browsers">
              <option *ngFor="let d of indicativos" [value]="d.indicativo">{{d.nomDepartamento}}</option>
            </datalist>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('indicativo')">Indicativo no válido, selecciona una opción de la lista</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comas">El campo
              no
              puede tener decimales</div>
          </div>

          <div class="col">
            <input type="number" class="form-control" placeholder="teléfono" formControlName="telefono"
              [class.is-invalid]="camposvalidos('telefono')" #datos2 [(ngModel)]="usuario.telefono"
              value="{datos.toString()}" (keyup)="quitarceros2(datos2.value)" (keyup)="noPunto($event, 'comastel2')">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('telefono')">Teléfono no válido, debe ser un número de 7 dígitos</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastel2">El
              campo
              no puede tener comas</div>

          </div>
          <div class="col">
            <input type="number" class="form-control" placeholder="extensión" formControlName="extension"
              [class.is-invalid]="camposvalidos('extension')" #datos3 [(ngModel)]="usuario.extension"
              value="{datos3.toString()}" (keyup)="quitarceros3(datos3.value)" (keyup)="noPunto($event, 'comastex')">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('extension')">Extension no válido</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastex">El campo
              no puede tener comas</div>
          </div>
        </div>
      </div>

      <div class="mb-3">

        <label class="form-label">Celular *</label>
        <input type="number" class="form-control" placeholder="celular" formControlName="celular"
          [class.is-invalid]="camposvalidos('celular')" #datos4 [(ngModel)]="usuario.celular"
          value="{datos4.toString()}" (keyup)="quitarceros4(datos4.value)" (keyup)="noPunto($event, 'comastel')">
        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
          *ngIf="camposvalidos('celular')">Celular no válido, ingresar número de 10 dígitos</div>

        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastel">El campo no
          puede tener comas</div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <label for="tipoCapital" class="form-label">Tipo de Capital</label>
          <select [(ngModel)]="usuario.tipoCapital" (change)="filtrarTipoRegimen($event, true)" class="form-select"
            id="tipoCapital"
            formControlName="tipoCapital" [class.is-invalid]="camposvalidos('tipoCapital')">
            <option class="form-control" value="" disabled></option>
            <option *ngFor="let tipoCapital of tiposCapital" [value]="tipoCapital">{{tipoCapital}}</option>
          </select>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('tipoCapital')">Capital no válido</div>
        </div>
        <div class="col">
          <label class="form-label">Régimen Tributario *</label>
          <select class="form-select" formControlName="regimenTributario"
            [class.is-invalid]="camposvalidos('regimenTributario')" [(ngModel)]="usuario.regimenTributario">
            <option class="form-control" value="" disabled></option>
            <option *ngFor="let regimen of regimenTributario" [value]="regimen">{{regimen}}</option>
          </select>

          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('regimenTributario')">Regimen tributario no válido</div>
        </div>
      </div>
      <div *ngIf="usuario.regimenTributario === 'Entidad del Estado'" class="row">
        <div class="col">
          <label class="form-label">¿La entidad realiza descuentos por concepto de estampillas? *</label>
        </div>
        <div class="checkbox-wrapper-19">
          <input type="radio" class="form-check-input" id="aplicaEstampillas" formControlName="aplicaEstampillas"
            [(ngModel)]="usuario.aplicaEstampillas" value="si" name="aplicaEstampillas"
            (click)="habilitarAplicaEstampillas($event)"
            [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
          <label for="aplicaEstampillas" class="check-box"></label>
          <label style="margin-right: 25pt;margin-left: 5pt" class="form-check-label" for="aplicaEstampillad">
            <p>SI</p>
          </label>
          <input type="radio" class="form-check-input" id="noAplicaEstampillas" formControlName="aplicaEstampillas"
            [(ngModel)]="usuario.aplicaEstampillas" value="no" name="aplicaEstampillas"
            (click)="habilitarAplicaEstampillas($event)"
            [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
          <label for="noAplicaEstampillas" class="check-box"></label>
          <label style="margin-left: 5pt" class="form-check-label" for="noAplicaEstampillad">
            <p>NO</p>
          </label>
        </div>
        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
          *ngIf="camposvalidos('aplicaEstampillas')">Campo requerido</div>
      </div>
      <div *ngIf="usuario.aplicaEstampillas === 'si'" class="row mb-4">
        <label *ngIf="agregarNuevaEstampilla" class="form-label mt-3">Ingresar los datos del recaudo a realizar</label>
        <div *ngIf="agregarNuevaEstampilla" class="row">
          <div class="col">
            <label class="form-label mt-3">Por concepto de *</label>
          </div>
          <div class="col">
            <label class="form-label mt-3">Tipo de recaudo *</label>
          </div>
          <div class="col">
            <label class="form-label mt-3">Valor *</label>
          </div>
        </div>
        <div *ngIf="agregarNuevaEstampilla" class="row">
          <div class="col">
            <input class="form-control" formControlName="descripcionEstampilla" placeholder="Concepto de recaudo"
              [(ngModel)]="nuevaEstampilla.descripcion" [class.is-invalid]="camposvalidos('descripcionEstampilla')"
              #inputRefDescripcionEstampilla>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('descripcionEstampilla')">Campo no válido</div>
          </div>

          <div class="col checkbox-wrapper-19">
            <input type="radio" class="form-check-input" formControlName="tipoEstampilla" id="tipoEstampilla"
              [(ngModel)]="nuevaEstampilla.tipo" value="P" name="tipoEstampilla"
              [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
              <label for="tipoEstampilla" class="check-box"></label>
              <label style="margin-right: 25pt;margin-left: 5pt" class="form-check-label" for="tipoEstampilla">
              <p>%</p>
            </label>
            <input type="radio" class="form-check-input" formControlName="tipoEstampilla" id="tipoEstampillaV"
              [(ngModel)]="nuevaEstampilla.tipo" value="V" name="tipoEstampilla"
              [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
              <label for="tipoEstampillaV" class="check-box"></label>
              <label style="margin-left: 5pt" class="form-check-label" for="tipoEstampillaV">
              <p>VALOR FIJO</p>
            </label>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('tipoEstampilla')">Debe seleccionar una opción</div>
          </div>
          <div class="col">
            <input type="number" class="form-control" formControlName="valorEstampilla" placeholder="Valor"
              [class.is-invalid]="camposvalidos('valorEstampilla')" [(ngModel)]="nuevaEstampilla.valor">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('valorEstampilla')">Campo no válido</div>
          </div>
        </div>
        <div *ngIf="pago.realizoPago !== 'S'" class="row">
          <div class="col" style="text-align: center;">
            <button *ngIf="agregarNuevaEstampilla" type="button" class="btn btn-secondary" style="margin: 15px;"
              (click)="habilitarNuevaEstampilla(false)"
              [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">Cancelar</button>
            <button *ngIf="agregarNuevaEstampilla" type="button" class="btn btn-primary" style="margin: 15px;"
              (click)="agregarEstampilla()"
              [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">Guardar
              estampilla</button>
            <button *ngIf="!agregarNuevaEstampilla" type="button" class="btn btn-primary" style="margin: 15px;"
              (click)="habilitarNuevaEstampilla(true)"
              [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">Nueva
              estampilla</button>
          </div>
        </div>
        <div *ngIf="estampillas.length > 0" class="row" style="text-align: center;">
          <table border="0" cellspacing="0" cellpadding="2" style="width: 90%; margin: 0 auto">
            <thead style="display: none;">
              <tr style="background-color: #919191; border: solid 1px black;">
                <th style="border: solid 1px black; font-weight: bold;">DESCRIPCIÓN</th>
                <th style="border: solid 1px black; font-weight: bold;">PORCENTAJE</th>
                <th style="border: solid 1px black; font-weight: bold;">VALOR</th>
                <th *ngIf="pago.realizoPago !== 'S'" style="border: solid 1px black; font-weight: bold;"></th>
              </tr>
            </thead>
            <tbody>
              <tr style="background-color: #fbf6f6; border: solid 1px black;" *ngFor="let estampilla of estampillas index as t">
                <td style="border: solid 1px black">{{estampilla.descripcion}}</td>
                <td style="border: solid 1px black">{{estampilla.porcentaje}}%</td>
                <td style="border: solid 1px black">{{estampilla.valor | currency:'COP'}}</td>
                <td *ngIf="pago.realizoPago !== 'S'" style="vertical-align: middle; width: 80px; border: solid 1px black; text-align: center;">
                  <button [disabled]="pago.realizoPago === 'S'" (click)="eliminarEstampilla(t)" style="font-size:20px; border-style: none; background: none;">
                    <i class="fa fa-trash icon-primary" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>          
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <label class="form-label">Código de actividad Económica</label>
          <input type="number" class="form-control" placeholder="Codigo de actividad"
            formControlName="actividadEconomica" appNocopiado [class.is-invalid]="camposvalidos('actividadEconomica')"
            (keyup)="noPuntoComaeconomi($event)" [(ngModel)]="usuario.actividadEconomica">
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('actividadEconomica')">Codigo actividad economica no válido</div>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comasecnomi">El
            campo no puede tener decimales</div>
        </div>
        <a class="link-dark" target="_blank"
          href="https://www.dian.gov.co/normatividad/Normatividad/Resoluci%C3%B3n%20000114%20de%2021-12-2020.pdf">
          <small>Ver listado de código actividad económica</small></a>
      </div>
      <div class="mb-3">
        <div class="checkbox-wrapper-19">
          <input class="form-check-input" type="checkbox" id="rut"
            [checked]="ExisteDocumento('rut') || ExisteDocumentoCargado('rut')" value="true"
            (change)="CreateDocument('Copia de rut', 'rut')"
            [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">
          <label for="rut" class="check-box"></label>
          <label class="form-check-label">
             Adjuntar copia RUT
          </label>
        </div>
      </div>

      <div formArrayName="documentos">
        <div *ngFor="let favorite of documentsDocuments.controls; let i = index">

          <div class="animate__animated animate__fadeIn animate__fast ms-5">
            <label class="form-check-label">
              Documento <strong style="color:#44494d ;">{{buttons[i].name}}</strong> ({{i + 1}} de {{totalCantidad}})
            </label>
            <div class="d-flex justify-content-center">
              <div id="div_file" class="text-center">
                <div [class]="validar()" *ngIf="cambiologo">
                  <span class="fa-stack fa-1x">
                    <i class="fa-solid fa-circle fa-stack-2x" [ngStyle]="{'color':buttons[i].fondo }"></i>
                    <i class="fa-solid fa-upload fa-stack-1x fa-inverse" [ngStyle]="{'color':buttons[i].colores }"></i>
                  </span>

                  <p id="texto">{{buttons[i].title}}
                    <br>
                    <small>{{buttons[i].subtitle}}</small>
                  </p>
                </div>
                <input type="file" class="enviar" #enviar [formControlName]="i"
                  (change)="getFileDetails(enviar.files,i)" accept="{{buttons[i].accept}}">
              </div>
            </div>
          </div>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="validateformats[i] || isValidFieldInArray(documentsDocuments,i)">{{error}}</div>
        </div>
        <div class="margen2_1" style="padding-bottom:0px ;">
          <div class="mb-3" style="line-height: 10px; margin-bottom: 5px !important;">
            <label for="label" class="form-label">* Campos obligatorios</label>
          </div>
        </div>
        <div class="mb-4">
          <button type="button" class="btn btn-secondary" style="margin: 15px;" (click)="regresarResumen()"
            [disabled]="usuario.solicitudFinalizada === 'APROBADO' || usuario.solicitudFinalizada === 'ASIGNADO'">Resumen</button>
          <button *ngIf="usuario.solicitudFinalizada !== 'ASIGNADO'" type="submit"
            class="btn btn-primary">{{actualizacion ? "Guardar" : "Siguiente"}}</button>
          <button *ngIf="usuario.solicitudFinalizada === 'ASIGNADO'" type="submit"
            class="btn btn-primary">Continuar</button>
        </div>
      </div>
    </form>
  </div>

</div>