<div class=" animate__animated animate__fadeInLeftBig animate__faster">

  <div class="row ms-1 mt-3">

    <h5 class="titulosintu2">Datos de contacto</h5>
    <br>

    <div class="mb-3">
      <div class="mb-1 form-check">
        <input type="checkbox" class="form-check-input" (change)="contactoOtroChange($event)" id="contactoOtro"
          [(ngModel)]="contactoOtro">
        <label class="form-check-label" for="contactoOtro">
          <p>¿Desea que los datos de la persona vinculada sean diferentes?</p>
        </label>
      </div>
    </div>

    <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()">
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label class="form-label">Tipo de identificación</label>
            <select [(ngModel)]="contacto.tipo" class="form-select" formControlName="tipo"
              [class.is-invalid]="camposvalidos('tipo')">
              <option class="form-control" value="CC">Cédula de Ciudadanía</option>
              <option class="form-control" value="CE">Cédula de Extranjería</option>
              <option class="form-control" value="PP">Pasaporte</option>
              <option class="form-control" value="TI">Tarjeta de Identidad T.I.</option>
            </select>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de documento</div>
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label class="form-label">Número de identificación</label>
            <input [(ngModel)]="contacto.numero" type="text" class="form-control" placeholder="Número de identificación"
              formControlName="numero" [class.is-invalid]="camposvalidos('numero')" #inputRefNumero>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('numero')">¡Identificación no válido!</div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="row">
          <div class="col">
            <label class="form-label">Primer nombre</label>
            <input type="text" class="form-control" placeholder="Primer nombre" formControlName="primerNombre"
              [class.is-invalid]="camposvalidos('primerNombre')" [(ngModel)]="contacto.primerNombre" #inputRefPrimerNombre>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('primerNombre')">
              Primer nombre no es válido</div>
          </div>
          <div class="col">
            <label class="form-label">Segundo nombre</label>
            <input type="text" class="form-control" placeholder="Segundo nombre" formControlName="segundoNombre"
              [class.is-invalid]="camposvalidos('segundoNombre')" [(ngModel)]="contacto.segundoNombre" #inputRefSegundoNombre>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('segundoNombre')">
              Segundo nombre no es válido</div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="row">
          <div class="col">
            <label class="form-label">Primer apellido</label>
            <input type="text" class="form-control" placeholder="Primer apellido" formControlName="primerApellido"
              [class.is-invalid]="camposvalidos('primerApellido')" [(ngModel)]="contacto.primerApellido" #inputRefPrimerApellido>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('primerApellido')">Primer apellido no es válido</div>
          </div>
          <div class="col">
            <label class="form-label">Segundo apellido</label>
            <input type="text" class="form-control" placeholder="Segundo apellido" formControlName="segundoApellido"
              [class.is-invalid]="camposvalidos('segundoApellido')" [(ngModel)]="contacto.segundoApellido" #inputRefSegundoApellido>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('segundoApellido')">Segundo apellido no es válido</div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label mt-3">Teléfono contacto:</label>
        <div class="row">

          <div class="col">
            <input class="form-control" list="browsers" formControlName="indicativo" placeholder="indicativo"
              [(ngModel)]="contacto.indicativo" [class.is-invalid]="camposvalidos('indicativo')"
              (keyup)="noPuntoComa($event)">
            <datalist id="browsers">
              <option *ngFor="let d of indicativos" [value]="d.indicativo">{{d.nomDepartamento}}</option>
            </datalist>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('indicativo')">Indicativo no válido, seleccione una opción de la lista</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comas">El campo
              no
              puede tener decimales</div>
          </div>


          <div class="col">

            <input type="number" class="form-control" placeholder="telefono" formControlName="telefono"
              [class.is-invalid]="camposvalidos('telefono')" #datos2 [(ngModel)]="contacto.telefono"
              value="{datos.toString()}" (keyup)="quitarceros2(datos2.value)" (keyup)="noPuntote($event)">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('telefono')">Teléfono no válido, debe ser un número de 7 dígitos, debe ser un número
              de 6 dígitos</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastel2">El
              campo
              no puede tener comas</div>

          </div>
          <div class="col">
            <input type="number" class="form-control" placeholder="extension" formControlName="extension"
              [class.is-invalid]="camposvalidos('extension')" #datos3 [(ngModel)]="contacto.extension"
              value="{datos3.toString()}" (keyup)="quitarceros3(datos3.value)" (keyup)="noPuntoex($event)">
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('extension')">Extension no válido</div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastex">El campo
              no puede tener comas</div>
          </div>

        </div>

      </div>

      <div class="mb-3">

        <label class="form-label">Celular:</label>
        <input type="number" class="form-control" placeholder="celular" formControlName="celular"
          [class.is-invalid]="camposvalidos('celular')" #datos4 [(ngModel)]="contacto.celular"
          value="{datos4.toString()}" (keyup)="quitarceros4(datos4.value)" (keyup)="noPuntotel($event)">
        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
          *ngIf="camposvalidos('celular')">Celular no válido, ingrese número de 10 dígitos</div>

        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="comastel">El campo no
          puede tener comas</div>
      </div>

      <div class="mb-3">
        <div class="col">
          <label class="form-label">Correo electrónico:</label>
          <input type="text" class="form-control" placeholder="correo electrónico" formControlName="correo"
            [(ngModel)]="contacto.correo" [class.is-invalid]="camposvalidos('correo')" #inputRefCorreo>
          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
            *ngIf="camposvalidos('correo')">Correo no válido, debe tener la estructura correo@dominio.com(.co,.es..)
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="row">
          <div class="col">
            <label class="form-label">Confirmación correo electrónico:</label>
            <input type="text" class="form-control" placeholder="correo electrónico" formControlName="correo1"
              [(ngModel)]="contacto.correo1" [class.is-invalid]="camposvalidos('correo1')" appNocopiado
              #inputRefCorreo1>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
              *ngIf="camposvalidos('correo1')">Correo no válido, debe tener la estructura correo@dominio.com(.co,.es..)
            </div>
            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" *ngIf="correo2NoValido">
              Los correos no son iguales</div>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <button type="button" class="btn btn-secondary" style="margin: 15px;" (click)="redireciona()">Anterior</button>
        <button type="submit" class="btn btn-primary">Siguiente</button>
      </div>
    </form>
  </div>