import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-nombre-apellido',
  templateUrl: './nombre-apellido.component.html',
  styleUrl: './nombre-apellido.component.css',
  standalone: false
})
export class NombreApellidoComponent {
  @Input() formaForm!: FormGroup;

  @ViewChild('inputRefPrimerNombre')
  inputRefPrimerNombre!: { nativeElement: any; };

  @ViewChild('inputRefSegundoNombre')
  inputRefSegundoNombre!: { nativeElement: any; };

  @ViewChild('inputRefPrimerApellido')
  inputRefPrimerApellido!: { nativeElement: any; };

  @ViewChild('inputRefSegundoApellido')
  inputRefSegundoApellido!: { nativeElement: any; };
  
  ngOnInit() {
    this.setOnChangesInputs();
  }

  setOnChangesInputs() {
    this.formaForm.get("primerNombre")!.valueChanges.subscribe(val => {
      const elRef = this.inputRefPrimerNombre?.nativeElement;
      const orVal = elRef?.getAttribute('data-model-value') || '';
      val = val.replace(orVal?.toUpperCase(), orVal);
      elRef?.setAttribute('data-model-value', val);
      elRef.value = val?.toUpperCase();
      this.formaForm.get("primerNombre")!.setValue(val?.toUpperCase(), {
        emitEvent: false,
        emitModelToViewChange: false
      });
    });

    this.formaForm.get("segundoNombre")!.valueChanges.subscribe(val => {
      const elRef = this.inputRefSegundoNombre.nativeElement;
      const orVal = elRef.getAttribute('data-model-value') || '';
      val = val.replace(orVal.toUpperCase(), orVal);
      elRef.setAttribute('data-model-value', val);
      elRef.value = val.toUpperCase();
      this.formaForm.get("segundoNombre")!.setValue(val.toUpperCase(), {
        emitEvent: false,
        emitModelToViewChange: false
      });
    });

    this.formaForm.get("primerApellido")!.valueChanges.subscribe(val => {
      const elRef = this.inputRefPrimerApellido.nativeElement;
      const orVal = elRef.getAttribute('data-model-value') || '';
      val = val.replace(orVal.toUpperCase(), orVal);
      elRef.setAttribute('data-model-value', val);
      elRef.value = val.toUpperCase();
      this.formaForm.get("primerApellido")!.setValue(val.toUpperCase(), {
        emitEvent: false,
        emitModelToViewChange: false
      });
    });

    this.formaForm.get("segundoApellido")!.valueChanges.subscribe(val => {
      const elRef = this.inputRefSegundoApellido.nativeElement;
      const orVal = elRef.getAttribute('data-model-value') || '';
      val = val.replace(orVal.toUpperCase(), orVal);
      elRef.setAttribute('data-model-value', val);
      elRef.value = val.toUpperCase();
      this.formaForm.get("segundoApellido")!.setValue(val.toUpperCase(), {
        emitEvent: false,
        emitModelToViewChange: false
      });
    });
  }

  camposvalidos(campo: any) {
    return this.formaForm.get(campo)!.invalid &&
      (this.formaForm.get(campo)!.touched || this.formaForm.get(campo)!.dirty);
  }
}
