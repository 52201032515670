<div class="row fondo mb-4">
</div>

<div class="bs-stepper linear">
  <div class="bs-stepper-header" role="tablist">
    <div class="step">
      <button class="carrusel">
        <span [class]="documentos()">
          <img class="paso-img" src="../../../assets/img/paso_radicacion.png" alt="Paso radicación"/>
        </span>
        <p class="textope mt-2">Radicación de documentos
          <br>
          <span class="fechas">{{compras.fechaCreacion}}</span>
        </p>
      </button>
    </div>
    <div class="bs-stepper-line"></div>
    <div class="step">
      <button class="carrusel">
        <span [class]="pagos()">
          <img class="paso-img" src="../../../assets/img/paso_pago.png" alt="Paso de pago"/>
        </span>
        <p class="textope mt-2">Verificación de pago
          <br>
          <span class="fechas">{{pago.fechaPago}}</span>
        </p>
      </button>
    </div>
    <div class="bs-stepper-line"></div>
    <div class="step">
      <button class="carrusel">
        <span class="bordes">
          <img class="paso-img" src="../../../assets/img/paso_aprobacion.png" alt="Paso aprobación"/>
        </span>
        <br>
        <p class="textope mt-2">Aprobación de solicitud
          <br>
          <span class="fechas">&nbsp;</span>
        </p>
      </button>

    </div>
    <div class="bs-stepper-line"></div>
    <div class="step">
      <button class="carrusel">
        <span class="bordes">
          <img class="paso-img" src="../../../assets/img/paso_entrega.png" alt="Paso de entrega"/>
        </span>
        <br>
        <p class="textope mt-2">Entrega de certificado
          <br>
          <span class="fechas">&nbsp;</span>
        </p>
      </button>

    </div>
  </div>
</div>