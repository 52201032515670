<div class="animate__animated animate__fadeIn animate__fast">
       <form [formGroup]="formaForm" (ngSubmit)="ngsubmit()" id="form1">
              <!--listas negras alert -->
              <!--listas negras alert -->
              <div class="margen2">
                     <div class="mb-3">
                            <label class="form-label">Tipo de identificación</label>
                            <select class="form-select" formControlName="tipo"
                                   [class.is-invalid]="camposvalidos('tipo')">
                                   <option class="form-control" value="CC">Cédula de Ciudadanía</option>
                                   <option class="form-control" value="CE">Cédula de Extranjería</option>
                                   <option class="form-control" value="PP">Pasaporte</option>
                                   <option class="form-control" value="TI">Tarjeta de Identidad T.I.</option>
                            </select>
                            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                   *ngIf="camposvalidos('tipo')">Debe seleccionar un tipo de documento</div>
                     </div>

                     <div class="mb-3">
                            <label class="form-label">Número de identificación</label>
                            <input type="text" class="form-control" placeholder="Número de identificación"
                                   formControlName="numero" [class.is-invalid]="camposvalidos('numero')"
                                   #inputRefNumero>
                            <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                   *ngIf="camposvalidos('numero')">¡Identificación no válido!</div>
                     </div>

                     <div class="mb-3">
                            <div class="row">
                                   <div class="col">
                                          <label class="form-label">Primer nombre</label>
                                          <input type="text" class="form-control" placeholder="Primer nombre"
                                                 formControlName="primerNombre"
                                                 [class.is-invalid]="camposvalidos('primerNombre')"
                                                 #inputRefPrimerNombre>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('primerNombre')">
                                                 Primer nombre no es válido, debe ingresar solo letras</div>
                                   </div>
                                   <div class="col">
                                          <label class="form-label">Segundo nombre</label>
                                          <input type="text" class="form-control" placeholder="Segundo nombre"
                                                 formControlName="segundoNombre"
                                                 [class.is-invalid]="camposvalidos('segundoNombre')"
                                                 #inputRefSegundoNombre>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('segundoNombre')">
                                                 Segundo nombre no es válido, debe ingresar solo letras</div>
                                   </div>
                            </div>
                     </div>
                     <div class="mb-3">
                            <div class="row">
                                   <div class="col">
                                          <label class="form-label">Primer apellido</label>
                                          <input type="text" class="form-control" placeholder="Primer apellido"
                                                 formControlName="primerApellido"
                                                 [class.is-invalid]="camposvalidos('primerApellido')"
                                                 #inputRefPrimerApellido>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('primerApellido')">Primer apellido no es válido,
                                                 debe ingresar solo letras</div>
                                   </div>
                                   <div class="col">
                                          <label class="form-label">Segundo apellido</label>
                                          <input type="text" class="form-control" placeholder="Segundo apellido"
                                                 formControlName="segundoApellido"
                                                 [class.is-invalid]="camposvalidos('segundoApellido')"
                                                 #inputRefSegundoApellido>
                                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                                 *ngIf="camposvalidos('segundoApellido')">Segundo apellido no es válido,
                                                 debe ingresar solo letras</div>
                                   </div>
                            </div>
                     </div>
                     <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                                   *ngIf="cheksPolis">¡Debe aceptar las tres políticas</div>
              </div>
              <!--[class.is.invalid]="camposvalidos('nombres')">-->
              <div class="check">
                     <div class="mb-1 form-check">
                            <input type="checkbox" class="form-check-input" formControlName="terminosp"
                                   [class.is-invalid]="camposvalidos('terminosp')">
                            <label class="form-check-label">He leído y acepto los <a class="descargas"
                                          href="../../../assets/Documentos/PYP-L-005_Términos_Condiciones_del Servicio_de_Certificación_de_Firma_Digital_v1.pdf"
                                          download="PYP-L-005_Términos_Condiciones_del Servicio_de_Certificación_de_Firma_Digital_v1.pdf"><span
                                                 class="politicas"> Términos y condiciones</span></a>
                            </label>
                     </div>

                     <div class="mb-1 form-check">
                            <input type="checkbox" class="form-check-input" formControlName="terminost"
                                   [class.is-invalid]="camposvalidos('terminost')">
                            <label class="form-check-label">He leído y acepto <a class="descargas"
                                          href="../../../assets/Documentos/Aviso_de_Privacidad_SSPS.pdf"
                                          download="Aviso_de_Privacidad_SSPS.pdf"><span class="politicas">Aviso de
                                                 privacidad</span></a> </label>
                     </div>

                     <div class="mb-1 form-check" style="font-size: 11pt;">
                            <input type="checkbox" class="form-check-input" formControlName="terminostpro"
                                   [class.is-invalid]="camposvalidos('terminostpro')" id="exampleCheck2">
                            <label class="form-check-label" for="exampleCheck2">He leído y acepto <a class="descargas"
                                          href="../../../assets/Documentos/AGR-L-004_Declaraciones_y_Compromisos_en_Materia_de_Prevención_de_LA_FT_FPADM_Y_C_ST_v1.pdf"
                                          download="AGR-L-004_Declaraciones_y_Compromisos_en_Materia_de_Prevención_de_LA_FT_FPADM_Y_C_ST_v1.pdf"><span
                                                 class="politicas">Declaración y compromisos LA/FT/FPADM Y
                                                 C/ST</span></a></label>
                     </div>
              </div>

              <div class="modal-footer">
                     <button type="button" class="btn btn-secondary" (click)="homeNavigate()">Cancelar</button>
                     <button type="submit" class="btn btn-primary" [disabled]="formaForm.invalid">Siguiente</button>
              </div>
       </form>
</div>