<div class="modal-header text-center position-relative">
    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span>
    <h5 class="textomodal">Error</h5>

    <button type="button" class="btn-close" aria-label="Close" *ngIf="closetap" (click)="closedExplorer()"></button>
    <button type="button" class="btn-close" aria-label="Close" *ngIf="closemodal" (click)="cloSeptaps()"></button>

</div>
<form>
    <div class="modal-body">

        <div class="d-flex justify-content-center mt-3 animate__animated animate__bounceIn animate__faster">
            <table class="text-center tablamodal">
                <thead style="display: none;"><tr><th></th></tr></thead>

                <tr>
                    <td><img src="../../../../assets/img/reported.png" width="200px" height="200"></td>
                </tr>


                <tr>
                    <td>
                        <p>{{menssage}}</p>
                    </td>
                </tr>

                <tr>
                    <td>
                        <button type="button" class="btn btn-primary" *ngIf="closemodal"
                            (click)="cloSeptaps()">Aceptar</button>
                        <button type="button" class="btn btn-primary" *ngIf="closetap"
                            (click)="closedExplorer()">Aceptar</button>
                    </td>
                </tr>

            </table>
        </div>

    </div>
    <form>