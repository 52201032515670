import { ChangeDetectorRef, Component, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { NgForm, FormGroup, ValidatorFn, AbstractControl, FormArray, FormBuilder, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { SspsService } from '../../../domain/servicios/ssps.service';
import { ComponentesService } from '../../../domain/servicios/componentes.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ObjetosService } from '../../../domain/servicios/objetos.service';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { QuestionsService } from 'src/app/domain/servicios/questions.service';
import { LogService } from '../../../domain/servicios/log.service'
import { environment as env } from "src/environments/environment"
import { DatePipe } from '@angular/common'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var window: any;
declare var bootstrap: any;

@Component({
    selector: 'app-ssps',
    templateUrl: './ssps.component.html',
    styleUrls: ['./ssps.component.css'],
    standalone: false
})
export class SspsComponent implements OnInit {
  @ViewChild('inputRefOtroUso')
  inputRefOtroUso!: { nativeElement: any; };

  sspsh: any;
  valores: string = '';
  showresumeA: boolean = false;
  showresumeB: boolean = false;
  formaForm: FormGroup;
  formaForm2: FormGroup;
  cantidad: boolean = false;
  elementofi: any;
  desactiva = true;
  anterior: boolean = false;
  elementose: any;
  //muestreo de campos 
  muestreo: boolean = false;
  tipodis: any = { dispo: "" };
  check: boolean = false;
  resultado!: number;
  usuario: any = {
    idpoli: ""
  };
  show: boolean = false;
  preciosd: any[] = [];
  usosCertificados: any[] = [];
  preciosReposicion: any[] = [];
  redirecion: boolean = false;
  contador: number = 0;
  vista: boolean = true;
  vigencias: any[] = [];
  comas: boolean = false;
  quitar: any;
  tiempo: string = 'años';
  errorcantidad: boolean = false;
  carousel: any;
  newstatus: boolean = false;
  compras: any[] = []
  saveEmptyDataStorage: boolean = true
  tipoClienteSugerido = ''
  tipoCliente = ''
  aplicaReposicion = false
  mostrarReposiciones = false
  usoCertificado: any = { id: 0, descripcion: "", otro: "" }
  mostrarBotones = true
  idCarrouselSeleccionado = 0
  anosReusoSeleccion = 0
  validarReuso = false
  urlVideoReuso: SafeResourceUrl
  vistaVideo: boolean = false;
  aplicaReuso: boolean = false;

  constructor(public ssps: SspsService,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private objetosService: ObjetosService,
    private alert: AlertsService,
    private componentService: ComponentesService,
    private el: ElementRef,
    private questions: QuestionsService,
    private logger: LogService,
    public datepipe: DatePipe,
    public sanitizer: DomSanitizer
  ) {
    /*form array formulario1*/
    this.formaForm = this.fb.group({
      checks: ['', [Validators.required]],
    });

    /*form array formulario2*/
    this.formaForm2 = this.fb.group({
      checks2: ['', [
        //Validators.required
      ]],
      dispositivo: ['', [Validators.required]],
      cantidad: ['1', [Validators.min(1),
      Validators.max(99)
      ]],
      anos: [undefined, [Validators.required]],
      uso: ["", []],
      otroUso: ["", []],
      terminosReuso: [false, Validators.requiredTrue],
    });
    
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    // sonarignore:start
    // sonarcloud-ignore-next-line
    this.urlVideoReuso = this.sanitizer.bypassSecurityTrustResourceUrl(env.urlVideoReuso);// NOSONAR
    // sonarignore:end
  }

  /*implementacion de modal listas */
  ngOnInit(): void {
    this.alert.showSpiner("No olvides tener tu documento de identificación a la mano para poder continuar con el proceso")
    this.tipoCliente = this.ssps.getLocalStorage('tipocliente')!;
    this.ssps.typePerson = this.ssps.getLocalStorage("tipocliente")!
    let copiaCompras = this.ssps.compras
    this.historySummary()
    /*metodos de consultas servicios*/
    this.preguntas();
    // this.gethistorial();
    this.getpoliticas();
    this.codigov();
    this.OnChanges();
    this.precios();
    this.usos();
    this.setOnChangesInputs()
    this.cargarPreciosReposicion();
  
    if (this.ssps.getLocalStorage("regresaFormulario") === "true") {
      setTimeout(() => {
        this.usuario = JSON.parse(this.ssps.getLocalStorage("usuario")!)
        this.contador = 2
        this.ssps.person = this.usuario
        this.resultado = Math.trunc(this.usuario.costo)
        if (this.usuario.politica == "Persona Natural PKCS10" ||
          this.elementose?.politica == "Persona Jurídica PKCS10") {
          this.preciosd = this.preciosd.filter((res) => res.nombre === 'PKCS#10');
        }
        else {
          this.preciosd = this.preciosd.filter((res) => { return !(res.nombre == 'PKCS#10'); })
        }
  
        this.logger.log("precios", this.preciosd)
        let [mecEntrega] = this.preciosd.filter((res) => { return res.nombre.toLowerCase() == this.usuario.dispo.toLowerCase(); })
        this.vigencias = mecEntrega.vigencia;
        this.logger.log("regreso", mecEntrega)
        this.changePrices({ target: { value: this.preciosd.length === 1 ? 0 : mecEntrega.id } })
        this.alert.closesSpiner()
        this.carousel = new bootstrap.Carousel(this.el.nativeElement.querySelector('#carouselExampleControlsNoTouching'));
        this.showresumeA = false
        this.showResume()
        localStorage.removeItem("regresaFormulario")
      }, 3500);
    }
    else {
      this.carousel = new bootstrap.Carousel(this.el.nativeElement.querySelector('#carouselExampleControlsNoTouching'));
      this.alert.closesSpiner()
      if (this.ssps.compras.length > 0) {
        this.logger.log("compras más de 0", this.ssps.compras.length)
        this.newstatus = false;
        this.carousel.to(1);
        this.contador = this.contador + 1;
        this.mostrarBotones = false
      }
      this.logger.log("copia compras", copiaCompras.length)
      if (this.ssps.compras.length === 0 || copiaCompras.length === 0) {
        this.newstatus = true;
      }
      //Para cuando se le muestra el mensaje de sugerencia cambio tipo cliente
      // else {
      //   this.carousel.to(1);
      // }
    }
  }

  setOnChangesInputs() {
    this.formaForm2.get("otroUso")!.valueChanges.subscribe(val => {
      if (this.inputRefOtroUso) {
        const elRef = this.inputRefOtroUso.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm2.get("otroUso")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });
  }


  /* validacion de persona natutal para check pks*/
  codigov() {

    let { cliente } = JSON.parse(this.ssps.getLocalStorage('usuario')!);

    if (cliente == "juridica") {
      this.muestreo = true;
    }

  }

  //TODO:retoma los datos del historico 
  historySummary() {
    this.changeDetector.detach();
    if (this.tipoCliente === 'natural') {
      this.tipoClienteSugerido = "Entidad/Empresa"
      this.ssps.compras = this.ssps.compras.filter(historico => historico.solicitantes[0].detallesCertificado.nombrePolitica.toLowerCase() === 'profesional titulado' ||
        historico.solicitantes[0].detallesCertificado.nombrePolitica.toLowerCase() === 'persona natural' ||
        historico.solicitantes[0].detallesCertificado.nombrePolitica.toLowerCase() === 'persona natural pkcs10')

      this.ssps.reposiciones = this.ssps.compras.slice().filter(h => h.aplicaReposicion)
    }
    else {
      this.tipoClienteSugerido = "Natural"
      this.ssps.compras = this.ssps.compras.filter(historico => historico.solicitantes[0].detallesCertificado.nombrePolitica.toLowerCase() === 'profesional titulado' ||
        historico.solicitantes[0].detallesCertificado.nombrePolitica.toLowerCase() === 'representante legal' ||
        historico.solicitantes[0].detallesCertificado.nombrePolitica.toLowerCase() === 'pertenencia a empresa' ||
        historico.solicitantes[0].detallesCertificado.nombrePolitica.toLowerCase() === 'función publica' ||
        historico.solicitantes[0].detallesCertificado.nombrePolitica.toLowerCase() === 'funcion publica' ||
        historico.solicitantes[0].detallesCertificado.nombrePolitica.toLowerCase() === 'persona jurídica pkcs10' ||
        historico.solicitantes[0].detallesCertificado.nombrePolitica.toLowerCase() === 'persona juridica pkcs10')

      this.ssps.reposiciones = this.ssps.compras.filter(h => h.aplicaReposicion)
    }
    this.compras = this.ssps.compras.map((historico: any) => ({
      fecha: historico.ordenPedidoInfo.fechaEntrega,
      proyecto: historico.ordenPedidoInfo.nombreProyecto,
      tipo: historico.solicitantes[0].detallesCertificado.mecanismoEntrega,
      politica: historico.solicitantes[0].detallesCertificado.nombrePolitica,
      uso: historico.solicitantes[0].detallesCertificado.descripcionUso
    })).slice(0, 5);

    this.aplicaReposicion = this.ssps.reposiciones.length > 0
    this.changeDetector.detach();

    this.aplicaReuso = this.compras.find(item => item.proyecto === 'TOKEN FISICO' && item.fecha);

    this.logger.log("historico", this.ssps.compras)
    this.logger.log("reposicion", this.ssps.reposiciones)
    this.logger.log("this.compras", this.compras)
  }
  //TODO:listado de preguntas manteniedo el objeto original
  get questionsPolices() {
    return this.questions.listadop;
  }

  //TODO:listado de preguntas manteniedo el objeto original
  get directivesActive() {
    return this.questions.directiva;
  }

  politica(valor: any) {
    this.elementose = valor;
    this.vista = true;
    //debugger;

    if (this.elementose?.politica == "Persona Natural PKCS10" ||
      this.elementose?.politica == "Persona Jurídica PKCS10") {
      this.preciosd = this.preciosd.filter((res) => res.nombre === 'PKCS#10');
    }
    else {
      this.preciosd = this.preciosd.filter((res) => { return !(res.nombre == 'PKCS#10'); })
    }

    let datos = {
      idpoli: this.elementose.id,
      politica: this.elementose.politica,
      dispo: undefined,
      idispo: undefined,
      anos: 0
    }
    this.resultado = 0

    this.usuario = this.ssps.person;
    Object.assign(this.usuario, datos);
    this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
    //vaciameintos de campos de formulario seleccion de dispositivo
    if (this.resultado !== undefined) {
      this.formaForm2.get('cantidad')!.setValue(undefined);
      this.formaForm2.get('anos')!.setValue(undefined);
      //this.resultado != null;
    }

    this.desactiva = false;
  }

  /*muestro de campos cantidad*/
  mecanisEntregaChange($event: any, id: number) {
    this.logger.log("validacion mecanisEntregaChange", id)
    this.logger.log("validacion mecanisEntregaChange-precios", this.preciosd)
    let { cliente } = JSON.parse(this.ssps.getLocalStorage('usuario')!);
    this.resultado = 0;
    this.formaForm2.get('anos')!.setValue(1);
    this.formaForm2.get('anos')!.updateValueAndValidity()
    this.vigencias = this.preciosd[id].vigencia;
    //  debugger
    let isCheckeado2 = $event.target.checked;
    this.tipodis = {
      idispo: this.preciosd[id].id,
      dispo: this.preciosd[id].nombre,
      iva: this.preciosd[id].iva,
      ica: 0
    }
    this.logger.log("validacion mecanisEntregaChange", this.vigencias)
    this.usuario.terminosReuso = false
    this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
    for (var i = 0; i < this.preciosd.length; i++) {
      /*cambio de estado input*/
      if (this.preciosd[i].id == id && isCheckeado2 == true && cliente == 'juridica') {
        this.preciosd[id].estados = true;
      } else {
        this.preciosd[i].estados = false;
      }
      if (this.preciosd[i].id == id && isCheckeado2 == true && cliente == 'juridica' && this.preciosd[i].nombre == 'PKCS#10') {
        this.preciosd[i].estados = false;
      }
    }
    if (this.formaForm2.get('anos')!.value !== null && this.formaForm2.get('anos')!.value !== undefined) {
      this.ChagePrice(this.formaForm2.get('anos')!.value)
    }

    this.formaForm2.get('uso')!.setValue("")
    this.usoCertificado = { id: 0, descripcion: "", otro: "" }
    if (this.tipodis.dispo.toLowerCase() === 'pkcs#10') {
      this.formaForm2.get('uso')!.setValidators([Validators.required]);
    }
    else {
      this.formaForm2.get('uso')!.clearValidators()
      this.formaForm2.get('otroUso')!.clearValidators();
    }
    this.logger.log("validacion mecanisEntregaChange2", this.formaForm2.get('anos')!.value)
    this.logger.log("validacion mecanisEntregaChange2 dispo", this.tipodis)
    if (this.tipodis.dispo.toLowerCase() === "reúso token físico") {
      this.desactiva = true;
    }
  }

  /*lista de precios*/
  precios() {
    this.ssps.listaprecios().subscribe((res: any) => {
      this.preciosd = res;
    });
  }

  usos() {
    this.ssps.usos()
      .subscribe((res: any) => {
        this.usosCertificados = res.usos;
      })
  }

  cargarPreciosReposicion() {
    this.ssps.preciosReposicion()
      .subscribe((res: any) => {
        this.preciosReposicion = res.precios;
        
        for (var i = 0; i < this.preciosd.length; i++) {
          this.preciosReposicion[i].estados = false;
          if (this.preciosReposicion[i].nombre == "token virtual") {
            this.preciosReposicion[i].img = "../../../assets/img/token-virtual.png"
          }
          if (this.preciosReposicion[i].nombre == "token físico") {
            this.preciosReposicion[i].img = "../../../assets/img/usb.png"
          }
          if (this.preciosReposicion[i].nombre == "reúso token físico") {
            this.preciosReposicion[i].img = "../../../assets/img/usb.png"
          }

          if (this.preciosReposicion[i].nombre == "PKCS#10") {
            this.preciosReposicion[i].img = "../../../assets/img/kpcs10.png"
          }
        }
      })
  }

  //TODO:Calculos de precios en base al tipo de persona  
  ChagePrice(cost: string) {
    this.logger.log("validacion ChagePrice", this.formaForm2.get('anos')!)
    this.logger.log("validacion ChagePrice valor select", cost)
    let cantidad = 1;
    let anos: number = parseInt(cost);
    this.anosReusoSeleccion = anos
    //si es natural
    this.formaForm2.get('anos')!.setValue(parseInt(cost));
    if (this.ssps.typePerson == 'natural') {
      let [result] = this.vigencias.filter(res => res.id == anos);
      this.resultado = Math.trunc(result.precio + (result.precio * (this.tipodis.iva / 100)));

      this.logger.log("result", result)
      this.logger.log("resultado", this.resultado)

      if (this.formaForm2.get('anos')!.value > 0) {
        this.desactiva = false;
      }
      this.tipodis = Object.assign(this.tipodis, {
        anos: anos,
        costo: this.resultado,
        costoBase: Math.trunc(result.precio)
      })
      let usuario = this.ssps.person;
      Object.assign(usuario, this.tipodis);
      this.ssps.saveLocalStorage("usuario", JSON.stringify(usuario));
      this.formaForm2.get('anos')!.updateValueAndValidity()
    }
    //si es juridico
    if (this.ssps.typePerson == 'juridica') {
      let [result] = this.vigencias.filter(res => res.id == anos);
      let valorxcantidad: any = result.precio * cantidad
      this.resultado = Math.trunc(valorxcantidad + (valorxcantidad * (this.tipodis.iva / 100)));
      this.tipodis = Object.assign(this.tipodis, {
        anos: anos,
        costo: this.resultado,
        costoBase: Math.trunc(result.precio * cantidad)
      })
      if (this.formaForm2.get('anos')!.value > 0) {
        this.desactiva = false;
      }
      let usuario = this.ssps.person;
      Object.assign(usuario, this.tipodis);
      this.ssps.saveLocalStorage("usuario", JSON.stringify(usuario));
      this.formaForm2.get('anos')!.updateValueAndValidity()
    }
    if (this.show)
      this.showresumB()

    if (this.tipodis.dispo.toLowerCase() === 'pkcs#10' && this.usoCertificado.descripcion === "") {
      this.desactiva = true;
    }
    if (this.tipodis.dispo.toLowerCase() === 'pkcs#10' && this.usoCertificado.descripcion.toLowerCase() === "otros"
      && this.usoCertificado.otro === "") {
      this.desactiva = true;
    }
    if (this.tipodis.dispo.toLowerCase() === "reúso token físico") {
      this.desactiva = true;
    }
  }

  //TODO:implementacion cambio de precios
  changePrices(data: any) {
    this.logger.log("changePrices value", data.target.value)
    this.logger.log("changePrices selectedIndex", data.target.selectedIndex)
    let result = data.target?.selectedIndex;
    this.usuario.dispo = this.preciosd[result].nombre;
    this.usuario.idispo = this.preciosd[result].id;
    this.usuario.iva = this.preciosd[result].iva
    this.usuario.ica = 0
    this.vigencias = this.preciosd[result].vigencia;
    this.usuario.terminosReuso = false
    this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
    let [resultPrecio] = this.vigencias.filter(res => res.anos == this.usuario.anos);
    this.tipodis = {
      idispo: this.preciosd[result].id,
      dispo: this.preciosd[result].nombre,
      iva: this.preciosd[result].iva,
      ica: 0,
      costo: resultPrecio.precio + (resultPrecio.precio * (this.preciosd[result].iva / 100)),
      costoBase: resultPrecio.precio
    }
    let usuario = this.ssps.person;
    Object.assign(usuario, this.tipodis);
    this.ssps.saveLocalStorage("usuario", JSON.stringify(usuario));
    this.resumePrices(this.usuario.anos)
    this.showresum()
    if (this.tipodis.dispo.toLowerCase() === "reúso token físico") {
      this.desactiva = true;
    }
    else {
      this.desactiva = false;
    }
  }

  //TODO:vlidaciones de compras
  resumePrices(cost: any) {
    let anos: number = parseInt(cost);
    let [result] = this.vigencias.filter(res => res.id == anos);
    this.resultado = Math.trunc(result.precio + (result.precio * (this.tipodis.iva / 100)))
    this.usuario.costo = Math.trunc(this.resultado)
    this.usuario.costoBase = Math.trunc(result.precio)
    this.usuario.ica = 0
    this.usuario.reteiva = 0
    this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
  }

  OnChanges() {
    this.formaForm2.valueChanges.subscribe(valores => {
      /*las validaciones de persona juridica*/
      this.formaForm2.get('cantidad')!.setValidators([Validators.required,
      Validators.min(1),
      Validators.max(99)]);
      let anos = valores.anos;
      /*implementacion de validaciones numeros campo numero*/
      if (this.formaForm2.get('cantidad')!.value > 99 || this.formaForm2.get('cantidad')!.value === null) {
        this.desactiva = true;
        this.errorcantidad = true;

      } else { this.errorcantidad = false; }
      if (!anos || (typeof anos === 'number' && anos == 0)) {
        this.formaForm2.patchValue(
          {
            filtros: null,
          },
          { emitEvent: false }
        );
      }
      /*cambio de estado de boton siguiente*/
      if (this.formaForm2.valid) { this.desactiva = false; }

      if (anos > 1) {

        this.tiempo = 'años';

      } else if (anos == 1) {
        this.tiempo = 'año';
      }
      let otroUso = valores.otroUso
      if (this.usoCertificado.descripcion.toLowerCase() === "otros") {
        this.usoCertificado.otro = otroUso
        if (this.formaForm2.get('otroUso')!.invalid) {
          this.formaForm2.get('otroUso')!.markAllAsTouched();
          this.desactiva = true
        }
        else {
          this.desactiva = false
        }
      }
    });
  }

  /**
   *asignacion de valores vista compra 
   */
  verifica() {
    this.logger.log("logger resultado", this.resultado)
    this.logger.log("elementose resultado", this.elementose)
    if (this.resultado > 0) {
      let { cantidad, anos } = this.formaForm2.value;
      let datos = {
        cantidad: cantidad,
        anos: this.usuario.esReposicion ? this.usuario.anos : anos,
        politica: this.elementose ? this.elementose.politica : this.usuario.politica,
        costo: this.resultado,
        proceso: 0,
        fechaCreacion: this.datepipe.transform(new Date(), "dd/MM/yyyy")!,
        uso: this.usoCertificado
      }
      this.usuario = this.ssps.person;
      Object.assign(this.usuario, datos);
      this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
      this.desactiva = false;

      if (this.usoCertificado.descripcion.toLowerCase() === "otros" &&
        (this.usoCertificado.otro === "" || this.usoCertificado.otro === undefined)) {
        this.desactiva = true
      }

      if (this.usoCertificado.descripcion.toLowerCase() === "otros" &&
        (this.usoCertificado.otro === "" || this.usoCertificado.otro === undefined)) {
        this.desactiva = true
      }
      if (this.tipodis.dispo.toLowerCase() === 'pkcs#10') {
        if (this.usoCertificado === null || this.usoCertificado === undefined ||
          this.usoCertificado.id === 0 || this.usoCertificado.id === null ||
          this.usoCertificado.id === undefined) {
          this.desactiva = true
        }
      }
    }
  }

  /*deshabilita botton siguiente*/
  estados() {
    this.logger.log("estados", this.contador)
    if ((this.contador === 2 && !this.show) || this.contador == 4) {
      this.showResume()
    }
    else {
      this.desactiva = true;
      this.logger.log("this.directivesActive", this.directivesActive.length)
      if (this.contador === 0 && this.directivesActive.length === 0) {
        return;
      }
      if (this.contador === 0) {
        this.precios()
      }
      if (this.contador >= 0) {
        this.anterior = false;
      }
      this.contador = this.contador + 1;
      if (this.contador == 4) {
        this.alert.showSpiner("No olvides tener tu documento de identificación a la mano para poder continuar con el proceso")
        this.closeFinal();
        this.componentService.emitircambio("showmenu");
        this.componentService.emitircambio("infoperso");
        this.componentService.emitircambio("mostrarBarra");
        setTimeout(() => {
          this.alert.closesSpiner()
          /*ruteo deacuerdo al tipo de persona*/
          if (this.usuario.cliente == "natural") {
            this.router.navigate(['/flujo/infobasi']);
          } else if (this.usuario.cliente == "juridica") {
            this.router.navigate(['/flujo/empresa']);
          }
        }, 3000);
      }
      if (this.contador === 3) {
        this.formaForm2.get('dispositivo')!.setValue(null);
      }
      if (this.contador === 2) {
        this.bindFieldsTyped()
      }
      if (this.contador === 1 && this.usuario !== undefined &&
        this.formaForm.get('checks')!.value !== '') {
        var existPol = this.directivesActive.filter(d => d.id === this.usuario.idpoli)
        if (existPol.length > 0) {
          this.desactiva = false
        }
        else {
          this.formaForm.get('checks')!.setValue("")
        }
      }
      this.carousel.to(this.contador);
    }
    if (this.usoCertificado.descripcion.toLowerCase() === "otros" &&
      (this.usoCertificado.otro === "" || this.usoCertificado.otro === undefined)) {
      this.desactiva = true
    }
    if (this.tipodis.dispo.toLowerCase() === 'pkcs#10') {
      if (this.usoCertificado === null || this.usoCertificado === undefined ||
        this.usoCertificado.id === 0 || this.usoCertificado.id === null ||
        this.usoCertificado.id === undefined) {
        this.desactiva = true
      }
    }
  }

  /*valida estados del boton anterior*/
  anteriorFlujo() {
    this.logger.log("anteriorFlujo", this.contador)
    this.desactiva = true;
    this.contador = this.contador - 1;
    this.usoCertificado = { id: 0, descripcion: "", otro: "" }
    if (this.validarReuso && this.contador == 2) {
      this.contador = 4;
      this.carousel.to(4);
      this.desactiva = false;
    }
    else if (this.contador > 1 && this.ssps.compras.length > 0 && !this.newstatus) {
      this.newstatus = false;
      this.carousel.to(1);
      this.contador = 1;
      this.mostrarBotones = false
    }
    else {
      this.mostrarBotones = true
      if (this.contador == -1) {
        this.alert.closesSsps();
        if (this.tipoCliente === "natural") {
          this.alert.showFiels()
        }
        else {
          this.alert.showSolicitante();
        }
      }
      else if (this.contador == 0 && this.ssps.compras.length > 0 && !this.newstatus) {
        this.alert.closesSsps();
        if (this.tipoCliente === "natural") {
          this.alert.showFiels()
        }
        else {
          this.alert.showSolicitante();
        }
      }
      else if (this.contador == 0) {
        this.getpoliticas();
        this.desactiva = this.directivesActive.length === 0
      }
      this.bindFieldsTyped()
      this.carousel.to(this.contador);
    }
  }

  bindFieldsTyped() {
    if (this.usuario !== undefined && this.usuario.idispo !== undefined) {
      this.desactiva = false;
      if (this.usuario.idispo > this.preciosd.length) {
        this.desactiva = true;
        this.formaForm2.get('dispositivo')!.setValue(undefined);
        this.formaForm2.get('anos')!.setValue(undefined);
      }
      else {
        this.logger.log("set dispositivo", this.usuario.idispo.toString())
        this.formaForm2.get('dispositivo')!.setValue(this.usuario.idispo.toString());
        //this.formaForm2.get('anos')!.setValue(this.usuario.anos);
      }

      if (this.elementose === undefined || !this.elementose) {
        this.elementose = {
          id: -1,
          politica: ''
        }
      }
      this.elementose.id = this.usuario.idpoli
      this.elementose.politica = this.usuario.politica
      //this.usuario.idispo = this.usuario.idispo
      this.show = false
      this.changeDetector.detectChanges();
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  /*muestra alerta minimo dispositivo*/
  dispositivo() {
    if (this.formaForm2.get('dispositivo')!.value == "" && this.formaForm2.invalid) {
      this.check = true;
    } else {
      this.check = false;
    }
  }


  ngsubmit() {
    this.dispositivo();
  }

  preguntas() {
    /* trae datos de consulta de json de pruebas en un metodo get*/
    this.ssps.getpreguntas(this.ssps.typePerson)
      .subscribe((res: any) => {
        this.logger.log("preguntas", res)
        this.questions.listadop = res;
      });

    /*implementacion de desafce de tiempo para carga de alertas*/
    setTimeout(() => {
      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
      })

    }, 500);
  }

  /*consultas preguntas historial*/


  /*consultas preguntas historial*/
  getpoliticas() {
    this.ssps.politicas()
      .subscribe((res: any) => {
        this.questions.directivaListado = res;
      });

  }

  /*validacioned de los primeros radios*/
  radios($event: any, index: number) {
    this.logger.log("radios", $event.target.value)
    this.logger.log("radios-1", index)
    let option = $event.target.value;
    if (index === 0) {
      this.questions.listadop.forEach(m => m.respuesta = '');
    }
    this.questions.questionsPolices(index, option, this.ssps.typePerson)

    this.desactiva = this.directivesActive.length === 0
  }



  /* funcion para validacion de campos*/
  camposvalidos(campo: any) {
    return this.formaForm.controls[campo].errors && this.formaForm.controls[campo].touched;
  }
  camposvalidos2(campo: any) {
    return this.formaForm2.controls[campo].errors && this.formaForm2.controls[campo].touched;
  }

  //implementacion de validacion de comas
  noPuntoComa(event: any) {

    var e = event || window.event;
    var key = e.keyCode || e.which;


    if (key === 110 || key === 190 || key === 188) {

      this.comas = true;
    } else {
      this.comas = false;
    }
  }

  /*quitar numeros ceros a la izquierda*/
  quitarceros(numero: any) {

    this.quitar = numero.replace(/^0+/, '');
  }


  /*implementacion de modal ssps*/
  close() {
    if (this.vistaVideo) {
      this.cancelarVideoReuso()
    }
    else {
      this.alert.closesSsps();
      let externalUrl = `${env.urlWebCerti}`;
      this.alert.closesFiels();
      localStorage.clear()
      window.location.replace(externalUrl);
    }
  }

  //TODO:cierra modal en la parte final de la compra
  closeFinal() {
    this.alert.closesSsps();
  }

  //TODO:cambiar a la ultima pestaña del corrito de compra
  showCart(index: number) {
    this.mostrarBotones = true
    //Guardar datos de usuario, certificado, contacto tecnico
    if (this.tipoCliente === "natural") {
      this.ssps.saveBuyData(this.ssps.compras[index], false);
    }
    else {
      this.ssps.saveBuyDataPJ(this.ssps.compras[index], false);
      this.ssps.saveSolicitanteDataPJ(this.ssps.compras[index]);
    }

    this.ssps.saveEntregaData(this.ssps.compras[index]);
    this.ssps.saveFacturacionData(this.ssps.compras[index]);
    this.usuario = this.ssps.person;
    this.usuario.esReposicion = false;
    let [mecEntrega] = this.preciosd.filter((res) => { return res.nombre.toLowerCase() == this.usuario.dispo.toLowerCase(); })
    this.vigencias = this.preciosd[mecEntrega.id].vigencia;
    let [result] = this.vigencias.filter(res => res.anos == this.usuario.anos);
    this.tipodis = {
      idispo: mecEntrega.id,
      dispo: mecEntrega.nombre,
      iva: Math.trunc(mecEntrega.iva),
      ica: 0,
      costo: Math.trunc(result.precio + (result.precio * (mecEntrega.iva / 100))),
      costoBase: Math.trunc(result.precio)
    }
    Object.assign(this.usuario, this.tipodis);
    this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
    this.resultado = Math.trunc(this.ssps.person.costo);
    if (this.tipodis.dispo.toLowerCase() === 'pkcs#10') {
      this.logger.log("validacion uso " + this.tipodis.dispo, this.usuario.uso)
      this.preciosd = this.preciosd.filter((res) => res.nombre === 'PKCS#10');
      if (this.usuario.uso === null || this.usuario.uso === undefined ||
        this.usuario.uso.id === 0 || this.usuario.uso.id === null || this.usuario.uso.id === undefined) {
        this.logger.log("validacion uso no valido " + this.tipodis.dispo, this.usuario.uso)
        this.formaForm2.get('uso')!.setValue("")
        this.formaForm2.get('uso')!.setValidators([Validators.required]);
        this.formaForm2.get('uso')!.updateValueAndValidity()
        this.carousel.to(2);
        this.show = false;
        this.desactiva = true;
        this.contador = 2;
        this.newstatus = false;
      }
      else {
        this.carousel.to(3);
        this.show = true;
        this.desactiva = false;
        this.usoCertificado = this.usuario.uso
        this.contador = this.contador + 2;
      }
    }
    else {
      this.preciosd = this.preciosd.filter((res) => { return !(res.nombre == 'PKCS#10'); })
       if (this.tipodis.dispo.toLowerCase().includes("token físico")) {
         this.carousel.to(4);
         this.idCarrouselSeleccionado = 4
         this.contador = 4;
         this.resultado = 0;
         this.validarReuso = true
         this.anosReusoSeleccion = 0
       }
       else {
      this.carousel.to(3);
      this.show = true;
      this.desactiva = false;
      this.contador = this.contador + 2;
      }
    }

    this.newstatus = false;
    this.saveEmptyDataStorage = false
    this.elementose = {
      id: this.usuario.idpoli,
      politica: this.usuario.politica
    }

    this.formaForm2.get('cantidad')!.setValue(this.usuario.cantidad ?? '1');
    this.formaForm2.get('anos')!.setValue(this.usuario.anos);
  }


  showCartReposicion(index: number) {
    this.mostrarBotones = true
    //Guardar datos de usuario, certificado, contacto tecnico
    if (this.tipoCliente === "natural") {
      this.ssps.saveBuyData(this.ssps.compras[index], true);
    }
    else {
      this.ssps.saveBuyDataPJ(this.ssps.compras[index], true);
      this.ssps.saveSolicitanteDataPJ(this.ssps.compras[index]);
    }
    this.ssps.saveEntregaData(this.ssps.reposiciones[index]);
    this.ssps.saveFacturacionData(this.ssps.reposiciones[index]);
    this.usuario = this.ssps.person;
    this.usuario.esReposicion = true;
    let [mecEntrega] = this.preciosReposicion.filter((res) => { return res.nombre.toLowerCase() == this.usuario.dispo.toLowerCase(); })
    this.vigencias = this.preciosReposicion[mecEntrega.id].vigencia;
    let anosAprox = +this.usuario.anos <= 365 ? 1 : 2
    let [result] = this.vigencias.filter(res => res.anos == anosAprox);
    //Validar lo de los precios
    this.tipodis = {
      idispo: mecEntrega.id,
      dispo: mecEntrega.nombre,
      iva: mecEntrega.iva,
      ica: 0,
      costo: result.precio + (result.precio * (mecEntrega.iva / 100)),
      costoBase: result.precio
    }
    Object.assign(this.usuario, this.tipodis);
    this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
    this.formaForm2.get('cantidad')!.setValue(this.usuario.cantidad ?? '1');
    this.formaForm2.get('anos')!.setValue(anosAprox);
    this.resultado = Math.trunc(this.usuario.costo);

    if (this.tipodis.dispo.toLowerCase() === 'pkcs#10') {
      this.logger.log("validacion uso " + this.tipodis.dispo, this.usuario.uso)
      if (this.usuario.uso === null || this.usuario.uso === undefined ||
        this.usuario.uso.id === 0 || this.usuario.uso.id === null || this.usuario.uso.id === undefined) {
        this.logger.log("validacion uso no valido " + this.tipodis.dispo, this.usuario.uso)
        this.preciosd = this.preciosd.filter((res) => res.nombre === 'PKCS#10');
        this.formaForm2.get('uso')!.setValue("")
        this.formaForm2.get('uso')!.setValidators([Validators.required]);
        this.formaForm2.get('uso')!.updateValueAndValidity()
        this.carousel.to(2);
        this.show = false;
        this.desactiva = true;
        this.contador = 2;
        this.newstatus = false;
      }
      else {
        this.carousel.to(3);
        this.show = true;
        this.desactiva = false;
        this.usoCertificado = this.usuario.uso
        this.contador = this.contador + 2;
      }
    }
    else {
      this.preciosd = this.preciosd.filter((res) => { return !(res.nombre == 'PKCS#10'); })
       if (this.tipodis.dispo.toLowerCase().includes("token físico")) {
         this.carousel.to(4);
         this.idCarrouselSeleccionado = 4
         this.contador = 2;
         this.resultado = 0;
         this.anosReusoSeleccion = 0
       }
       else {
      this.carousel.to(3);
      this.show = true;
      this.desactiva = false;
      this.contador = this.contador + 2;
      }
    }

    this.newstatus = false;
    this.saveEmptyDataStorage = false
    this.elementose = {
      id: this.usuario.idpoli,
      politica: this.usuario.politica
    }
    this.usoCertificado = this.usuario.uso
    setTimeout(() => {
      this.tiempo = "días"
    }, 500);

  }

  showReposiciones() {
    if (this.mostrarReposiciones) {
      this.compras = this.ssps.compras.map((historico: any) => ({
        fecha: historico.ordenPedidoInfo.fechaEntrega,
        proyecto: historico.ordenPedidoInfo.nombreProyecto,
        tipo: historico.solicitantes[0].detallesCertificado.mecanismoEntrega,
        politica: historico.solicitantes[0].detallesCertificado.nombrePolitica
      })).slice(0, 5);
      this.mostrarReposiciones = false
    }
    else {
      this.compras = this.ssps.reposiciones.map((historico: any) => ({
        fecha: historico.ordenPedidoInfo.fechaEntrega,
        proyecto: historico.ordenPedidoInfo.nombreProyecto,
        tipo: historico.solicitantes[0].detallesCertificado.mecanismoEntrega,
        politica: historico.solicitantes[0].detallesCertificado.nombrePolitica
      })).slice(0, 5);
      this.mostrarReposiciones = true
    }
  }

  showResume() {
    this.carousel.to(3);
    this.show = true;
    this.desactiva = false;
    this.contador = 3;
  }

  //TODO:reversa al tap 0 cuendo quiere volver a las preguntas si quiere otro producto
  showNewProccess() {
    this.mostrarBotones = true
    this.carousel.to(0);
    this.newstatus = true;
    this.resultado = 0
    this.contador = 0;
    this.show = false;
    this.vigencias = []
  }


  //TODO:muestra elementosA
  showresum() {
    this.showresumeA = !this.showresumeA;
  }

  //TODO:muestra elementosB
  showresumB() {
    this.showresumeB = !this.showresumeB;
  }

  intentarPersonaSugerida() {
    this.anteriorFlujo()
    this.ssps.saveLocalStorage('tipoClienteSugerido', this.tipoClienteSugerido);
  }

  cambioUso(element: any) {
    this.logger.log("cambioUso", element)
    if (+element.value > 0) {
      this.usoCertificado = this.usosCertificados.find(f => +f.id === +element.value)
      const control = this.formaForm2.get('otroUso');
      this.logger.log("cambioUso1", this.usoCertificado)
      if (this.usoCertificado.descripcion.toLowerCase() === "otros") {
        control!.addValidators([Validators.required, Validators.min(5),
        Validators.max(100)]);
        control!.updateValueAndValidity();
        this.desactiva = true
        control!.markAsUntouched()
        this.logger.log("cambioUso2", this.usoCertificado)
      }
      else {
        control!.clearValidators();
        control!.updateValueAndValidity();
        this.desactiva = false
        this.logger.log("cambioUso3", this.usoCertificado)
      }
    }
  }

  habilitarVideoReuso(id: any) {
    this.vistaVideo = true
    this.carousel.to(5);
    this.mostrarBotones = false
    this.idCarrouselSeleccionado = id
  }

  cancelarVideoReuso() {
    this.vistaVideo = false
    this.carousel.to(this.idCarrouselSeleccionado);
    this.mostrarBotones = true
  }

  aceptaTerminosReuso($event: any) {
    this.desactiva = !$event.target.checked
    this.usuario.terminosReuso = $event.target.checked
    this.ssps.saveLocalStorage("usuario", JSON.stringify(this.usuario));
  }
}



