<div class="modal-body">
    <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; text-align: center;">
        <!-- Fila de encabezados semánticos pero ocultos visualmente -->
        <thead style="visibility: hidden; position: absolute; width: 0; height: 0;">
            <tr>
                <th>Imagen</th>
                <th>Mensaje</th>
                <th>Acción</th>
            </tr>
        </thead>
        
        <tbody>
            <tr>
                <td style="vertical-align: middle;">
                    <img src="../../../assets/searching.png" class="rounded mx-auto d-block" style="width: 150px;" alt="búsqueda">
                </td>
            </tr>
            <tr>
                <td style="vertical-align: middle;">
                    <p style="font-size: 13pt;" class="textoc">
                        {{mensaje}}
                    </p>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="vertical-align: middle;">
                    <div class="modal-footer" style="justify-content: center;">
                        <button type="button" class="btn btn-primary" (click)="cerrar()">Aceptar</button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>