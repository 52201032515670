import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Input } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ComponentesService } from '../../../domain/servicios/componentes.service';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment as env } from "src/environments/environment"
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { LogService } from '../../../domain/servicios/log.service'

declare var window: any;
@Component({
  selector: 'app-contactenos',
  templateUrl: './contactenos.component.html',
  styleUrls: ['./contactenos.component.css']
})
export class ContactenosComponent implements OnInit {

  @ViewChild('inputRefNombres')
  inputRefNombres!: { nativeElement: any; };

  @ViewChild('inputRefApellidos')
  inputRefApellidos!: { nativeElement: any; };

  @ViewChild('inputRefNumero')
  inputRefNumero!: { nativeElement: any; };
  @ViewChild('inputRefCorreo')
  inputRefCorreo!: { nativeElement: any; };
  @ViewChild('inputRefRazonSocial')
  inputRefRazonSocial!: { nativeElement: any; };
  @ViewChild('inputRefDisponibilidad')
  inputRefDisponibilidad!: { nativeElement: any; };

  @ViewChild('margen', { read: ElementRef, static: false }) margen!: ElementRef;
  @ViewChild('content', { read: ElementRef, static: false }) content!: any;
  formaForm!: FormGroup;
  contactos: any;
  tipoCliente: string = '';
  tamano!: number;
  comas: boolean = false;
  quitar: any;
  closeResult: string = '';
  @Input() public redirigir: any
  usuario: any

  constructor(private fb: FormBuilder,
    private router: Router,
    private componentesService: ComponentesService,
    private alert: AlertsService,
    private modalService: NgbModal,
    private changeDetector: ChangeDetectorRef,
    private ssps: SspsService,
    private logger: LogService, 
    private el: ElementRef
  ) {


    /*validacion de campos validators*/
    this.formaForm = this.fb.group({
      tipo: ['', [Validators.required]],
      numero: ['', [Validators.required,
        // Validators.pattern('([A-Za-z]+[0-9]|[0-9]+[A-Za-z])[A-Za-z0-9]*')
      ]],
      nombre: ['', [Validators.required,
      Validators.maxLength(60),
      Validators.pattern("[a-zA-Z ]{2,60}")
      ]],
      apellido: ['', [Validators.required,
      Validators.minLength(3),
      Validators.maxLength(60),
      Validators.pattern("[a-zA-Z ]{2,60}")
      ]],
      empresa: ['', [Validators.required,
      Validators.minLength(4),
      Validators.maxLength(60),
      Validators.pattern("[a-zA-Z ]{2,60}")]],

      correo: ['', [Validators.required,
      Validators.maxLength(60),
      Validators.pattern('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$')]],

      celular: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      disponibilidad: ['', [
        Validators.required,
        Validators.minLength(5),
      ]],
      terminosp: [false, Validators.requiredTrue],
      terminost: [false, Validators.requiredTrue],
      terminostpro: [false, Validators.requiredTrue],
    }, {
      validators: [this.componentesService.codigonit('tipo', 'numero')
      ]
    });

    this.usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
    this.tipoCliente = this.ssps.getLocalStorage('tipocliente')!;
    if (this.tipoCliente == "juridica") {
      //   this.formaForm.get('nombre').clearValidators();
      // this.formaForm.get('apellido').clearValidators();
      this.formaForm.controls['nombre'].clearValidators();
      this.formaForm.controls['nombre'].updateValueAndValidity();

      this.formaForm.controls['apellido'].clearValidators();
      this.formaForm.controls['apellido'].updateValueAndValidity();

      this.formaForm.removeControl('nombre');
      this.formaForm.removeControl('apellido');
    } else if (this.tipoCliente == "natural") {
      this.formaForm.controls['empresa'].clearValidators();
      this.formaForm.controls['empresa'].updateValueAndValidity();

      this.formaForm.removeControl('empresa');
    }
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.setOnChangesInputs()
  }


  setOnChangesInputs() {
    this.formaForm.get("nombre")?.valueChanges.subscribe(val => {
      if (this.inputRefNombres) {
        const elRef = this.inputRefNombres.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("nombre")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("apellido")?.valueChanges.subscribe(val => {
      if (this.inputRefApellidos) {
        const elRef = this.inputRefApellidos.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("apellido")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numero")?.valueChanges.subscribe(val => {
      if (this.inputRefNumero) {
        const elRef = this.inputRefNumero.nativeElement;
        const orVal = elRef.getAttribute('data-model-value') || '';
        val = val.replace(orVal.toUpperCase(), orVal);
        elRef.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("numero")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("correo")!.valueChanges.subscribe(val => {
      if (this.inputRefCorreo) {
        const elRef = this.inputRefCorreo?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("correo")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("empresa")?.valueChanges.subscribe(val => {
      if (this.inputRefRazonSocial) {
        const elRef = this.inputRefRazonSocial?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("empresa")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("disponibilidad")!.valueChanges.subscribe(val => {
      if (this.inputRefDisponibilidad) {
        const elRef = this.inputRefDisponibilidad?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal?.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("disponibilidad")!.setValue(val?.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });
  }

  onResize(event: any) {
    this.tamano = event.target.innerWidth;

    if (this.tamano <= 984) {
      this.margen.nativeElement.classList.remove('row-margenes');
    } else {
      this.margen.nativeElement.classList.add('row-margenes');
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  /* funcion para validacion de campos*/
  camposvalidos(campo: any) {
    return this.formaForm.controls[campo].errors && this.formaForm.controls[campo].touched;
  }

  //implementacion de validacion de comas
  noPuntoComa(event: any) {

    var e = event || window.event;
    var key = e.keyCode || e.which;


    if (key === 110 || key === 190 || key === 188) {

      this.comas = true;
    } else {
      this.comas = false;
    }
  }

  /*quitar numeros ceros a la izquierda*/
  quitarceros(numero: string) {
    this.usuario.celular = numero.replace(/^0+/, '');
  }


  /*ingreso y validacion de datos de formulario*/
  ngsubmit() {
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl?.focus();
          return;
        }
      });
      return;
    }
    //this.contactos.show();
    //Llamar servicio de formularioContacto
    this.alert.showSpiner("Estimado usuario, recuerda que para completar tu proceso, se realizará proceso de validación de identidad");
    let { nombre, apellido, numero, tipo, terminosp, terminost, terminostpro, empresa, correo, celular, disponibilidad } = this.formaForm.value;
    let request = {
      nombresRazonSocial: this.tipoCliente === "natural" ? nombre + " " + apellido : empresa,
      tipoIdentificacion: tipo,
      numeroIdentificacion: numero,
      email: correo,
      contacto: celular,
      disponibilidadHoraria: disponibilidad,
      polTratamientoDatos: terminost,
      aceptaTerminosCondiciones: terminosp,
      anexoSagrilaf: terminostpro,
      codigoProducto: env.productoFormularioContacto
    }
    this.ssps.formularioContacto(request)
      .subscribe({
        next: (res: any) => {
          this.alert.closesSpiner()
          this.logger.log("respuesta formularioContacto", res)
          this.alert.closeContact();
          this.alert.showAlert(this.redirigir);
        },
        error: (error: any) => {
          this.alert.closesSpiner()
          let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
            error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
              error.error.message ? error.error.message :
                (error.message ? error.message : "No fue posible realizar la petición")) :
            (error.message ? error.message : "No fue posible realizar la petición")
          console.error('Error en subscriber formularioContacto', error)
          this.alert.showError(mensaje, true, null, false, null, null)
        }
      })

  }

  /*abrir modal*/


  //TODO:cerrar modal
  close() {
    if (this.redirigir) {
      let externalUrl = `${env.urlWebCerti}`;
      localStorage.clear()
      window.location.replace(externalUrl);
    }
    else {
      this.alert.closeContact();
      this.alert.showFiels();
    }
  }

  //TODO:se reliza configuracion de permisos


}
